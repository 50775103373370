import {
  Descriptions,
  Tabs,
  Card,
  Image,
  Row,
  Col,
  Popconfirm,
  Button,
} from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { AddEditCategory } from './AddEditCategory'
import styles from 'common/components/styles/WhiteBox.module.css'
import { PropsDetail } from '../interface'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  LeftCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { useCategoryProduct } from '../hook/useCategoryProduct'

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const ContentView = (data: any) => {
  return (
    <Row gutter={[16, 16]}>
      <Col sm={6}>
        <Descriptions size="default" column={3}>
          <Descriptions.Item>
            <Image
              style={{ objectFit: 'cover', width: '100px', height: '100px' }}
              src={data.icon_url}
            />
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col sm={18}>
        <Descriptions size="default" column={3}>
          <Descriptions.Item label="Tên danh mục">
            {data.name}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {data.status ? 'Đang hoạt động' : 'Ngừng hoạt động'}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày tạo">
            {moment(data.create_at).format('DD/MM/YYYY')}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

function CategoryDetail({
  data,
  getData,
  onDeleteCategory,
  onChangeStatusCategory,
}: PropsDetail) {
  const [loading, setLoading] = useState<boolean>(true)
  const {
    showEditCategory,
    setShowEditCategory,
    updateCategorys,
  } = useCategoryProduct()

  return (
    <div>
      {showEditCategory && (
        <AddEditCategory
          data={data}
          visible={showEditCategory}
          isLoading={false}
          onCancel={() => {
            setShowEditCategory(false)
            getData()
          }}
          onUpdateCategory={(newData: any, resetFields: any) => {
            updateCategorys(newData, resetFields)
            setLoading(true)
            // getData()
          }}
        />
      )}
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        style={{ padding: '0px 10px' }}
      >
        <TabPane tab="Thông tin danh mục" key="1">
          <Card
            className={styles.expand}
            actions={[
              <Popconfirm
                disabled={data.is_default === 1 ? true : false}
                title={
                  data.status
                    ? 'Bạn chắc chắn muốn ngừng hoạt động danh mục này?'
                    : 'Bạn chắc chắn muốn hoạt động danh mục này?'
                }
                onConfirm={async () => {
                  try {
                    onChangeStatusCategory()
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <CheckCircleOutlined /> Đồng ý
                  </>
                }
                cancelText={
                  <>
                    <LeftCircleOutlined /> Trở lại
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    color: '#1890ff',
                    borderColor: '#1890ff',
                  },
                }}
                cancelButtonProps={{
                  danger: true,
                  // type: 'ghost',
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
              >
                <Button
                  type="text"
                  danger
                  style={
                    data.status
                      ? {
                          minWidth: '70px',
                          fontWeight: 'bold',
                          color: '#ff4d4f',
                        }
                      : {
                          minWidth: '70px',
                          fontWeight: 'bold',
                          color: '#1890ff',
                        }
                  }
                  disabled={data.is_default === 1 ? true : false}
                >
                  {data.status ? (
                    <span>
                      <PauseCircleOutlined />
                      &nbsp;Ngừng hoạt động
                    </span>
                  ) : (
                    <span>
                      <PlayCircleOutlined />
                      &nbsp;Hoạt động
                    </span>
                  )}
                </Button>
              </Popconfirm>,

              <Button
                type="text"
                icon={<EditOutlined />}
                style={{ fontWeight: 'bold', color: '#1890ff' }}
                onClick={() => {
                  setShowEditCategory(true)
                }}
              >
                Chỉnh sửa
              </Button>,

              <Popconfirm
                disabled={data.is_default === 1 ? true : false}
                title="Bạn chắc chắn muốn xoá danh mục này?"
                onConfirm={async () => {
                  try {
                    onDeleteCategory(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <CloseCircleOutlined /> Xóa
                  </>
                }
                cancelText={
                  <>
                    <LeftCircleOutlined /> Trở lại
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    color: '#1890ff',
                    borderColor: '#1890ff',
                  },
                }}
                cancelButtonProps={{
                  danger: true,
                  // type: 'ghost',
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
              >
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  style={{ fontWeight: 'bold', color: '#ff4d4f' }}
                  disabled={data.is_default === 1 ? true : false}
                >
                  Xóa danh mục
                </Button>
              </Popconfirm>,
            ]}
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default CategoryDetail
