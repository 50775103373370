import {
  CloseCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Modal, Upload } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { notificationError, notificationSuccess } from 'utils/notification'
import { uploadImageToServer } from 'utils/uploadImageToServer'
import ButtonBottomModal from '../Buttons/ButtonBottomModal'
import { updateInfoUser, updateInfoUserPetrol } from '../NotifyApi'
type Props = {
  visible: boolean
  setOpenModal: any
  openModal: any
  onCancel?: any
  data?: any
  isLoading: boolean
  authState: any
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    notificationError('Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    notificationError('Cho phép ảnh có dung lượng tối đa là 2MB')
  }
  return isJpgOrPng && isLt2M
}

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      name: null,
      email: null,
      role_ids: null,
      avatar: null,
    }
  } else {
    return {
      ...data,
      name: data.name,
      email: data.email,
      role_ids: data.role_ids,
      avatar: data.avatar,
    }
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export const PersonInfor = ({
  visible,
  onCancel,
  data,
  authState,
  setOpenModal,
  openModal,
}: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  type imagesUpload = {
    loading: boolean
    imageUrl: any
  }
  const [upload, setUpload] = useState<imagesUpload>({
    loading: false,
    imageUrl: '',
  })
  const [form] = Form.useForm()
  // const initialValues = convertDataToFrom(authState.userInfo)
  useEffect(() => {
    if (authState.userInfo && visible) {
      setUpload({
        ...upload,
        imageUrl: authState.userInfo.avatar,
      })
    }
    form.setFieldsValue({
      name: authState.userInfo?.name,
      phone: authState.userInfo?.phone,
      email: authState.userInfo?.email,
      role_ids: authState.userInfo?.role_ids,
      avatar: authState.userInfo?.avatar,
    })
  }, [authState.userInfo, visible])

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setUpload({
          imageUrl: imageUrl,
          loading: false,
        })
      )
    }
  }

  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onUpdateInfo = async (data: any) => {
    setIsLoading(true)
    try {
      if (
        authState?.userInfo?.role_ids[0] === 23 ||
        authState?.userInfo?.role_ids[0] === 24 ||
        authState?.userInfo?.role_ids[0] === 28
      ) {
        const res = await updateInfoUserPetrol(authState.userInfo.id, data)
        if (res.code === 201) {
          notificationSuccess(`Thay đổi thông tin thành công`)
          dispatch(getUserInfoAction())
        }
        onCancel()
        form.resetFields()
      } else {
        const res = await updateInfoUser(authState.userInfo.id, data)
        if (res.code === 200) {
          notificationSuccess(`Thay đổi thông tin thành công`)
          dispatch(getUserInfoAction())
        }
        onCancel()
        form.resetFields()
      }
    } catch (error) {
      notificationError(`Thông tin bạn nhập không chính xác`)
    } finally {
      setIsLoading(false)
      form.resetFields()
    }
  }
  const onFinish = async (values: any, onCancel: any) => {
    setIsLoading(true)
    try {
      //Upload image lên Server
      if (values.avatar?.fileList && values.avatar) {
        var resUploadImage: any
        var last_element = []

        if (values.avatar.fileList.length > 1) {
          last_element = [values.avatar.fileList.slice(-1)[0]]
        } else {
          last_element = values.avatar.fileList
        }
        resUploadImage = await uploadImageToServer(last_element)
      }

      delete values.confirm
      let newData

      //check anh thay doi
      if (upload.imageUrl === authState.userInfo.avatar) {
        newData = {
          name: values.name.trim(),
          email: values?.email?.trim() === '' ? null : values?.email?.trim(),
          // id: authState.userInfo.id,
          role_ids: values.role_ids,
        }
      } else {
        if (upload.imageUrl === '') {
          newData = {
            name: values.name.trim(),
            email: values?.email?.trim() === '' ? null : values?.email?.trim(),
            // id: authState.userInfo.id,
            avatar: '',
            role_ids: values.role_ids,
          }
        } else {
          newData = {
            name: values.name.trim(),
            email: values?.email?.trim() === '' ? null : values?.email?.trim(),
            // id: authState.userInfo.id,
            avatar: resUploadImage?.location,
            role_ids: values.role_ids,
          }
        }
      }
      onUpdateInfo(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setOpenModal({ ...openModal, personalInfor: false })
      setIsLoading(false)
    }
  }

  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      maskClosable={false}
      footer={null}
      title="Thông tin cá nhân"
      visible={visible}
      destroyOnClose
    >
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        labelAlign="left"
        onFinish={(values: any) => onFinish(values, onCancel)}
        // initialValues={initialValues}
        scrollToFirstError
      >
        <Button
          style={{
            paddingLeft: 270,
          }}
          type="text"
          icon={<CloseCircleOutlined />}
          onClick={() => {
            setUpload({
              imageUrl: '',
              loading: false,
            })
          }}
        />
        {/* )} */}
        <Form.Item label="Ảnh đại diện" name="avatar">
          <Upload
            name="avatar_url"
            listType="picture-card"
            className="avatar_url-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {upload.imageUrl ? (
              <img
                src={upload.imageUrl}
                alt="avatar_url"
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  height: '100%',
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="name"
          label="Họ tên"
          rules={[
            {
              type: 'string',
              message: 'Nhập tài khoản',
            },
            {
              required: true,
              message: 'Vui lòng nhập tài khoản!',
            },
            {
              min: 6,
              max: 55,
              message: 'Vui lòng nhập từ 6 đến 55 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập tài khoản" />
        </Form.Item>
        <Form.Item name="phone" label="Số điện thoại">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'Định dạng email không hợp lệ!',
            },
            {
              min: 6,
              max: 100,
              message: 'Vui lòng nhập từ 6 đến 100 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>

        <Form.Item label="Loại tài khoản" name="role_ids">
          <RadiusSelection
            disabled
            onChange={(value: number) => {}}
            options={[
              { value: 21, text: 'Super Admin' },
              { value: 22, text: 'Supper admin' },
              { value: 23, text: 'Supper admin CHXD' },
              { value: 24, text: 'Nhân viên trạm xăng' },
              { value: 25, text: 'Đối tác' },
              { value: 26, text: 'Kế toán' },
              { value: 27, text: 'Admin' },
              { value: 28, text: 'Đại diện trạm xăng' },
            ]}
            placeholder="Chọn loại tài khoản"
          />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <ButtonBottomModal
            isLoadingButton={isLoading}
            onCancel={() => {
              onCancel()
              form.resetFields()
            }}
            text="Lưu"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
