import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Row } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import { useEffect, useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import { enterNumbersOnly, formatPrice } from 'utils/ruleForm'
import { imposeLimitDebt } from '../CustomerApi'

type Props = {
  visible: boolean
  onCancel: any
  setShowAddDebt?: any
  dataSource: any
  isLoadingButton: boolean
  getData: any
}

export default function AddDebt({
  visible,
  onCancel,
  dataSource,
  getData,
}: Props) {
  const [limitDebt, setLimitDebt] = useState<number>(0)
  const [timeDebt, setTimeDebt] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setLimitDebt(dataSource?.dataInfor?.debt_limit)
    setTimeDebt(dataSource?.dataInfor?.debt_period)
  }, [dataSource])

  const onFinish = async () => {
    try {
      setIsLoading(true)
      const dataPush = {
        enterprise_id: dataSource.dataInfor.enterprise.enterprise_id,
        debt_limit: limitDebt,
        limit_time: timeDebt,
      }
      const res = await imposeLimitDebt(dataPush)

      if (res.code === 201) {
        notificationSuccess('Áp hạn mức công nợ thành công')
        onCancel()
        getData()
      }
    } catch (error: any) {
    } finally {
      setIsLoading(false)
    }
  }
  console.log('dataSource: ', dataSource.dataInfor.debt_limit)
  return (
    <Modal
      title="Áp công nợ"
      visible={visible}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        onCancel()
      }}
      destroyOnClose
    >
      <Row justify="space-between">
        <b style={{ fontSize: '16px' }}>Hạn mức công nợ:</b>
        <Input
          placeholder="Nhập hạn mức công nợ"
          style={{ width: '300px', marginBottom: '10px' }}
          value={limitDebt ? formatPrice(limitDebt) : ''}
          defaultValue={formatPrice(dataSource.dataInfor.debt_limit)}
          onChange={(e: any) => {
            let value = enterNumbersOnly(e.target.value)
            setLimitDebt(parseInt(value))
          }}
        />
      </Row>
      <Row justify="space-between">
        <b style={{ fontSize: '16px' }}>Thời gian công nợ:</b>
        <Input
          addonAfter="ngày"
          placeholder="Nhập thời gian công nợ"
          style={{ width: '300px', marginBottom: '10px' }}
          value={timeDebt ? formatPrice(timeDebt) : ''}
          defaultValue={formatPrice(dataSource.dataInfor.debt_period)}
          onChange={(e: any) => {
            let value = enterNumbersOnly(e.target.value)
            setTimeDebt(parseInt(value))
          }}
        />
      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          danger
          type="primary"
          style={{ marginRight: '10px' }}
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
        >
          Đóng
        </Button>
        <ButtonAdd
          text="Áp dụng"
          onClickButton={() => onFinish()}
          loading={isLoading}
        />
      </div>
    </Modal>
  )
}
