import { Row, Table } from 'antd'
import { ButtonAdd, ButtonEdit } from 'common/components/Buttons'
import { getUnits } from 'features/admin/product/product/ProductApi'
import React from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { formatedDate } from 'utils/TimerHelper'
import { createUnit, editUnit } from '../ConfigApi'
import { IPaging, IUnit } from '../interface'
import AddEditUnitModal from './AddEditUnit'

const UnitTab = () => {
  const [units, setUnits] = React.useState<IUnit[]>([])
  const [unitId, setUnitId] = React.useState<any>('')
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [unitName, setUnitName] = React.useState<string>('')
  const [paging, setPaging] = React.useState<IPaging>({
    page: 1,
    limit: 12,
    totalItem: 0,
  })

  const columns = [
    {
      width: '5%',
      title: <b>STT</b>,
      dataIndex: 'stt',
      render: (text: any, record: any, index: any) => (
        <td id={record.id}>{(paging.page - 1) * paging.limit + index + 1}</td>
      ),
    },
    {
      width: '150px',
      title: <b>Đơn vị tính</b>,
      dataIndex: 'name',
    },
    {
      width: '150px',
      title: <b>Ngày tạo</b>,
      dataIndex: 'date',
    },
    {
      width: '5%',
      //   title: <b></b>,
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <ButtonEdit
          typeButton="text"
          onClickButton={() => {
            setUnitId(record.id)
            setUnitName(record.name)
            setIsVisible(true)
          }}
        />
      ),
    },
  ]

  const getListUnit = async () => {
    try {
      setIsLoading(true)
      const res = await getUnits()
      if (res.status) {
        const data = res.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            date: formatedDate(item.create_at),
          }
        })
        const newPaging = {
          page: res.meta.pagination.page,
          limit: res.meta.pagination.limit,
          totalItems: res.meta.pagination.totalItems,
        }
        setPaging({
          ...paging,
          ...newPaging,
        })
        const reversedData = data.reverse()
        setUnits(reversedData)
      } else {
        console.log('Đã có lỗi xảy ra! Xin vui lòng thử lại!')
      }
    } catch (error) {
      console.log('ERROR: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const addNewUnit = async () => {
    try {
      setIsLoading(true)
      const payload = {
        name: unitName,
        code: unitName,
      }
      if (!unitId) {
        const res = await createUnit(payload)
        if (res.status) {
          notificationSuccess('Thêm đơn vị tính mới thành công!')
          getListUnit()
          setIsVisible(false)
        } else {
          notificationError('Thêm đơn vị tính mới thất bại!')
        }
      } else {
        const res = await editUnit(payload, unitId)
        if (res.status) {
          notificationSuccess('Cập nhật đơn vị tính mới thành công!')
          getListUnit()
          setIsVisible(false)
        } else {
          notificationError('Cập nhật đơn vị tính mới thất bại!')
        }
      }
    } catch (error) {
      console.log('ERROR: ', error)
    } finally {
      setIsLoading(false)
      setUnitName('')
      setUnitId('')
    }
  }

  React.useEffect(() => {
    getListUnit()
  }, [])

  return (
    <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 4 }}>
      <Row justify="end">
        <ButtonAdd
          text="Thêm mới"
          onClickButton={() => setIsVisible(true)}
          styleButton={{ marginBottom: 20 }}
        />
      </Row>
      <Table
        size="middle"
        bordered
        columns={columns}
        dataSource={units}
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
      />
      {isVisible && (
        <AddEditUnitModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          addNewUnit={addNewUnit}
          unitName={unitName}
          setUnitName={setUnitName}
          unitId={unitId}
        />
      )}
    </div>
  )
}

export default UnitTab
