import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { Button, PageHeader, Popconfirm } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import { ORDER_STATUS } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { useOrder } from '../hook/useOrder'
import AddActualPayment from './AddActualPayment'
import AddBillFullTank from './AddBillFullTank'
import DenyStatus from './DenyStatus'
import PrintOrder from './PrintOrder'

type Props = {
  data: any
  isLoading: any
  setIsLoading: any
  onChangeStatus: any
  onCompleteStatus: any
  onDenyStatus: any
  setDenyStatus: any
  denyStatus: any
  detailOrder: any
  fullTank: any
  setFullTank: any
  actualPayment: any
  setActualPayment: any
  showModal: any
  curentRemainLit: number
  handleCallbackDetail?: any
}
export default function Header({
  data,
  isLoading,
  setIsLoading,
  onChangeStatus,
  onCompleteStatus,
  onDenyStatus,
  denyStatus,
  setDenyStatus,
  detailOrder,
  setFullTank,
  fullTank,
  actualPayment,
  setActualPayment,
  showModal,
  curentRemainLit,
  handleCallbackDetail,
}: Props) {
  const { getData } = useOrder()
  const history = useHistory()
  const location: any = useLocation()
  const saveStatus = detailOrder.saveStatus
  const componentRef = useRef<any>()
  const reactToPrintFunc = useReactToPrint({
    content: () => componentRef.current,
  })

  const convertStatus = (value: any) => {
    switch (value) {
      case ORDER_STATUS.PENDING:
        return (
          <>
            {/* {data.order_gasoline !== null &&
            data.total_amount != 0 &&
            saveStatus !== 1 &&
            data.type_payment_id != 4 ? (
              <Button
                // disabled
                style={{
                  borderRadius: '3px',
                  color: 'gray',
                  borderColor: 'gray',
                }}
                onClick={() => {}}
              >
                <b>
                  <PlusCircleOutlined /> Bổ sung tiền đổ thực tế
                </b>
              </Button>
            ) : (
              <></>
            )} */}
            <Button
              type="primary"
              danger
              style={{
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
              onClick={() => {
                setDenyStatus(true)
              }}
            >
              <CloseCircleOutlined />
              Từ chối
            </Button>
            <DenyStatus
              onDenyStatus={onDenyStatus}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              visible={denyStatus}
              onCancel={() => {
                setDenyStatus(false)
              }}
            />

            {/* Nút xác nhận */}
            <Popconfirm
              placement="bottomLeft"
              title="Bạn chắc chắn muốn xác nhận đơn hàng này?"
              onConfirm={() => {
                setIsLoading(true)
                if (
                  data.detailOrder.order_payment.type_payment_id === 1 &&
                  data.detailOrder.order_payment.is_paid === 0
                ) {
                  notificationError(`Đơn hàng VNPay chưa thanh toán`)
                } else {
                  if (data.detailOrder.order_payment.type_payment_id === 2) {
                    if (data.detailOrder.order_payment.is_paid === 0) {
                      notificationError(
                        `Vui lòng thay đổi trạng thái thanh toán`
                      )
                    } else {
                      onChangeStatus()
                    }
                  } else {
                    onChangeStatus()
                  }
                }

                setIsLoading(false)
              }}
              okText={
                <>
                  <CheckCircleOutlined /> Xác nhận
                </>
              }
              cancelText={
                <>
                  <CloseCircleOutlined /> Hủy
                </>
              }
              okButtonProps={{
                type: 'default',
                style: {
                  borderRadius: '3px',
                  borderColor: '#1890ff',
                  color: '#1890ff',
                  fontWeight: 'bold',
                },
              }}
              cancelButtonProps={{
                danger: true,
                style: { borderRadius: '3px', fontWeight: 'bold' },
              }}
            >
              <Button
                loading={isLoading}
                type="primary"
                icon={<CheckCircleOutlined />}
                style={{ fontWeight: 'bold', borderRadius: '3px' }}
              >
                Xác nhận
              </Button>
            </Popconfirm>
          </>
        )
      case ORDER_STATUS.CONFIRMED:
        return (
          <>
            {data.order_gasoline !== null &&
            data.total_amount != 0 &&
            saveStatus !== 1 &&
            data.type_payment_id != 4 ? (
              <Button
                style={{
                  borderRadius: '3px',
                  color: '#1890ff',
                  borderColor: '#1890ff',
                }}
                onClick={() => {
                  setActualPayment(true)
                }}
              >
                <b>
                  <PlusCircleOutlined /> Bổ sung tiền đổ thực tế
                </b>
              </Button>
            ) : (
              <></>
            )}
            <Button
              style={
                data?.is_full_fuel != 1
                  ? {
                      display: 'none',
                    }
                  : {
                      borderRadius: '3px',
                      color: '#1890ff',
                      borderColor: '#1890ff',
                    }
              }
              onClick={() => {
                setFullTank(true)
              }}
            >
              <b>
                <PlusCircleOutlined /> Thêm thông tin HĐ
              </b>
            </Button>
            {saveStatus === 1 && curentRemainLit !== 0 && (
              <Button
                style={{
                  borderRadius: '3px',
                  color: 'orange',
                  borderColor: 'orange',
                }}
                onClick={() => {
                  showModal(true)
                }}
              >
                <b>
                  <PlusCircleOutlined /> Bổ sung hoá đơn đổ xăng
                </b>
              </Button>
            )}
            <AddActualPayment
              setActualPayment={setActualPayment}
              detailOrder={detailOrder}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              visible={actualPayment}
              onCancel={() => {
                setActualPayment(false)
              }}
              handleCallbackDetail={handleCallbackDetail}
            />
            <AddBillFullTank
              setFullTank={setFullTank}
              detailOrder={detailOrder}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              visible={fullTank}
              onCancel={() => {
                setFullTank(false)
                getData()
              }}
            />
            {saveStatus !== 1 && (
              <Button
                type="primary"
                danger
                style={{
                  fontWeight: 'bold',
                  borderRadius: '3px',
                }}
                onClick={() => {
                  setDenyStatus(true)
                }}
              >
                <CloseCircleOutlined />
                Từ chối
              </Button>
            )}
            <DenyStatus
              onDenyStatus={onDenyStatus}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              visible={denyStatus}
              onCancel={() => {
                setDenyStatus(false)
              }}
            />
            {saveStatus !== 1 && (
              <Popconfirm
                placement="bottomLeft"
                title="Bạn chắc chắn muốn hoàn thành đơn hàng này?"
                onConfirm={async () => {
                  setIsLoading(true)
                  onCompleteStatus()
                  setIsLoading(false)
                }}
                okText={
                  <>
                    <CheckCircleOutlined /> Hoàn thành
                  </>
                }
                cancelText={
                  <>
                    <CloseCircleOutlined /> Hủy
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    borderColor: '#1890ff',
                    color: '#1890ff',
                    fontWeight: 'bold',
                  },
                }}
                cancelButtonProps={{
                  danger: true,
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
              >
                <Button
                  loading={isLoading}
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  style={{ fontWeight: 'bold', borderRadius: '3px' }}
                >
                  Hoàn thành
                </Button>
              </Popconfirm>
            )}
          </>
        )
      case ORDER_STATUS.SUCCCESS:
        return <></>
      case ORDER_STATUS.CANCELED:
        return <></>
      case ORDER_STATUS.DENY:
        return <></>
    }
  }

  const handleBack = () => {
    switch (location?.state?.name) {
      case ADMIN_ROUTER_PATH.ORDER:
        history.push({
          pathname: `${ADMIN_ROUTER_PATH.ORDER}`,
          state: location.state,
        })
        return
      case ADMIN_ROUTER_PATH.CUSTOMER_DETAIL:
        history.push({
          pathname: `${ADMIN_ROUTER_PATH.CUSTOMER_DETAIL}/${location.state.idCustomer}`,
          state: location.state,
          search: `tabs=${location?.state?.tabs || 'tabs1'}`,
        })
        return
      case ADMIN_ROUTER_PATH.SHOP_DETAIL:
        history.push({
          pathname: `${ADMIN_ROUTER_PATH.SHOP_DETAIL}/${location.state.idShop}`,
          state: location.state,
        })
        return
      default:
        history.push({
          pathname: `${ADMIN_ROUTER_PATH.ORDER}`,
          state: location.state,
        })
        return
    }
  }

  return (
    <div>
      <PageHeader
        onBack={handleBack}
        title="Đơn hàng chi tiết"
        extra={[
          convertStatus(data.status),
          <>
            <ReactToPrint
              trigger={() => {
                return (
                  <Button
                    loading={isLoading}
                    type="primary"
                    style={{ borderRadius: '3px' }}
                    onClick={() => {
                      setTimeout(() => {
                        setIsLoading(true)
                      }, 3000)
                    }}
                  >
                    <PrinterOutlined />
                    <b> In hóa đơn</b>
                  </Button>
                )
              }}
              content={() => componentRef.current}
            />
            <div style={{ display: 'none' }}>
              <PrintOrder ref={componentRef} detailOrder={detailOrder} />
            </div>
          </>,
        ]}
      />
    </div>
  )
}
