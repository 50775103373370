import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { getListCustomer, requestRanks } from '../CustomerApi'

type TParams = {
  page: number
  search?: string
  customer_type?: number
  rank_id?: number
  from_date?: string
  to_date?: string
}
export const useCustomer = () => {
  const param: string = useParams()
  const history = useHistory()
  const location: any = useLocation()
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const [params, setParams] = useState<TParams>(
    location.state
      ? {
          page: location.state?.page,
          search: location.state?.search,
          customer_type: location.state?.customer_type,
          rank_id: location.state?.rank_id,
          from_date: location.state?.from_date,
          to_date: location.state?.to_date,
        }
      : {
          page: 1,
          search: undefined,
          customer_type: undefined,
          rank_id: undefined,
          from_date: undefined,
          to_date: undefined,
        }
  )
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [listRank, setListRank] = useState<Array<any>>([])
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  })

  const onDeleteProduct = async (id: any) => {}

  const getData = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getListCustomer(params)
      const dataTable = res.data.map((item: any, index: number) => ({
        ...item,
        key: item.id,
        id: item.id,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
        role_names:
          item.enterprise?.approve_status === 1 ? 'Doanh nghiệp' : 'Cá nhân',
      }))
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })

      setDataSource(dataTable)
    } catch (error: any) {
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  const getListRank = async () => {
    const res = await requestRanks()
    const listRanks = res.data?.map((item: any) => ({
      text: item.name,
      value: item.id,
    }))
    setListRank(listRanks)
  }

  useEffect(() => {
    getData()
    getListRank()
  }, [params])

  return {
    history,
    isLoading,
    dataSource,
    paging,
    listRank,
    setListRank,
    setIsLoading,
    onDeleteProduct,
    param,
    params,
    setParams,
  }
}
