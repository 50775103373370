import { Col, Divider, Row, Table } from 'antd'
import images from 'assets/images'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { formatPrice, readMoney } from 'utils/ruleForm'

// type Props = {
//   detailOrder: any
//   data?: any
// }

const printProductQuantity = (product: any, detailOrder: any) => {
  return detailOrder?.order_gasoline != null && detailOrder?.status != 4
    ? detailOrder?.order_receipt_gasoline
      ? formatPrice(
          Number(
            +(
              detailOrder?.order_payment?.total_price /
              detailOrder?.order_receipt_gasoline?.price
            )
          ).toFixed(3)
        )
      : (detailOrder?.total_price + detailOrder?.points_used) /
          detailOrder?.order_items[0]?.price_reality ===
        0
      ? 'Chưa xác định'
      : Number(
          (detailOrder?.total_price + detailOrder?.points_used) /
            detailOrder.order_items[0].price_reality
        ).toFixed(3)
    : product?.total_quantity == 0
    ? 'Đầy bình'
    : formatPrice((+product?.total_quantity).toFixed(3))
}

const printProductPrice = (product: any, detailOrder: any) => {
  return detailOrder?.order_gasoline != null && detailOrder?.status != 4
    ? detailOrder.order_receipt_gasoline != null
      ? formatPrice(detailOrder.order_receipt_gasoline.price)
      : detailOrder.order_items[0].price_reality == 0
      ? '0đ'
      : formatPrice(detailOrder.order_items[0].price_reality) + 'đ'
    : product?.product_price == 0
    ? 'Chưa xác định'
    : formatPrice(product?.product_price)
}

const PrintOrder = React.forwardRef((detailOrder: any, ref: any) => {
  const order = detailOrder?.detailOrder

  const totalPrice =
    order.order_receipt_gasoline == null ||
    order.order_receipt_gasoline.total_price === 0
      ? order.points_used === 0 && order.total_price === 0
        ? 'Chưa xác định'
        : order.total_price + order.points_used === 0
        ? 0
        : order.total_price + order.points_used
      : order.points_used === 0 &&
        order.order_receipt_gasoline.total_price === 0
      ? 'Chưa xác định'
      : order.order_receipt_gasoline.total_price + order.points_used === 0
      ? 0
      : order.order_receipt_gasoline.total_price + order.points_used

  return (
    <PrintContainer ref={ref}>
      <div style={{ padding: '15px' }}>
        <Row>
          <Col span={4}>
            <img
              alt="logo"
              src={images.img_logo}
              style={{ width: '100%', height: '55px' }}
            />
          </Col>
          <Col span={14} style={{ width: '100%' }}>
            <div className="text-center title">HOÁ ĐƠN BÁN HÀNG</div>
            <div className="text-center">(INVOICE)</div>
            <div className="text-center">
              Ngày (day)
              <div style={{ padding: '0 6px', fontWeight: 'bold' }}>
                {moment().format('DD')}
              </div>
              tháng (month)
              <div style={{ padding: '0 6px', fontWeight: 'bold' }}>
                {moment().format('MM')}
              </div>
              năm (year)
              <div style={{ padding: '0 6px', fontWeight: 'bold' }}>
                {moment().format('YYYY')}
              </div>
            </div>
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            Số (Invoice No.): <strong>{order?.code}</strong>
          </Col>
        </Row>
        <Divider style={{ margin: '14px 0' }} />
        <Row gutter={[0, 4]}>
          <Col span={6}>
            Đơn vị bán <i>(Seller)</i>:
          </Col>
          <Col span={18} className="value">
            CÔNG TY CỔ PHẦN HỆ SINH THÁI CÔNG NGHỆ YOYO
          </Col>
          <Col span={6}>
            MST <i>(Tax Code)</i>:
          </Col>
          <Col span={18} className="value">
            0109083491
          </Col>
          <Col span={6}>
            Địa chỉ <i>(Address)</i>:
          </Col>
          <Col span={18} className="value">
            Tầng 5, số 11 ngõ 84 Ngọc Khánh, Ba Đình, Hà Nội
          </Col>
          <Col span={6}>
            Điện thoại <i>(Tel.)</i>:
          </Col>
          <Col span={18} className="value">
            0899.743.888 - 0915467567
          </Col>
          <Col span={6}>
            STK <i>(Account No.)</i>:
          </Col>
          <Col span={18} className="value">
            111603603999 - Ngân hàng TMCP Công thương Việt Nam - Chi Nhánh Nam
            Thăng Long
          </Col>
        </Row>
        <Divider style={{ marginTop: '14px' }} />
        <Row gutter={[0, 4]}>
          <Col span={6}>
            Người mua <i>(Buyer)</i>:
          </Col>
          <Col span={18} className="value">
            {order?.nameCustomer}
          </Col>
          <Col span={6}>
            Tên công ty <i>(Co. name)</i>:
          </Col>
          <Col span={18} className="value">
            {order?.nameCompany}
          </Col>
          <Col span={6}>
            MST <i>(Tax Code)</i>:
          </Col>
          <Col span={18} className="value">
            {order?.taxCompany}
          </Col>
          <Col span={6}>
            Địa chỉ <i>(Address)</i>:
          </Col>
          <Col span={18} className="value">
            {order?.addressReceipt}
          </Col>
          <Col span={6}>
            HTTT <i>(Pay. method)</i>:
          </Col>
          <Col span={18} className="value">
            {order?.type_payment}
          </Col>
          {/* <Col span={6}>
            STK <i>(Account No.)</i>:
          </Col>
          <Col span={18} className="value">
            111603603999
          </Col> */}
        </Row>
      </div>
      <table cellPadding="10" cellSpacing="0">
        <thead>
          <colgroup>
            <col width="60%" />
            <col width="20%" />
            <col width="20%" span={1} style={{ backgroundColor: '#f1f1f1' }} />
          </colgroup>
          <tr>
            <th align="left" className="col-item-stt">
              <div className="text-center">STT</div>
              <div className="text-center">
                <i>(No.)</i>
              </div>
            </th>
            <th align="center" className="col-name">
              <div>Tên hàng hóa, dịch vụ</div>
              <i>(Description)</i>
            </th>
            <th align="center" className="col-quantity">
              <div>ĐVT</div>
              <i>(Unit)</i>
            </th>
            <th align="center" className="col-quantity">
              <div>SL</div>
              <i>(Quantity)</i>
            </th>
            <th align="center" className="col-price">
              <div>Đơn giá</div>
              <i>(Unit Price)</i>
            </th>
            <th align="center" className="col-amount">
              <div>Thành tiền</div>
              <i>(Amount)</i>
            </th>
          </tr>
        </thead>
        <tbody>
          {order?.order_items?.map((item: any, index: number) => (
            <tr key={index}>
              <td align="center">{index + 1}</td>
              <td align="center">{item?.product?.name}</td>
              <td align="center">{item?.product?.unit}</td>
              <td align="center">{printProductQuantity(item, order)}</td>
              <td align="center">{printProductPrice(item, order)}</td>
              <td align="center">{formatPrice(item?.total_price)}</td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td className="price_txt" scope="col" colSpan={5}>
              Tổng cộng tiền hàng <i>(Sub total):</i>
            </td>
            <td align="center" scope="col" colSpan={1}>
              {totalPrice === 'Chưa xác định'
                ? 'Chưa xác định'
                : formatPrice(totalPrice)}
            </td>
          </tr>

          {/* <tr>
            <td className="price_amount_txt" scope="col" colSpan={2}>
              Thuế suất GTGT <i>(Tax rate): %</i>
            </td>
            <td className="price_txt" scope="col" colSpan={3}>
              Cộng tiền thuế GTGT <i>(VAT amount):</i>
            </td>
            <td align="center" scope="col" colSpan={1}>
              -
            </td>
          </tr> */}
          {/* <tr>
            <td className="price_txt" scope="col" colSpan={5}>
              Tổng cộng tiền thanh toán <i>(Total payment):</i>
            </td>
            <td align="center" scope="col" colSpan={1}>
              {formatPrice(total)}
            </td>
          </tr> */}
          <tr>
            <td
              align="left"
              className="price_amount_txt"
              scope="col"
              colSpan={6}
            >
              Số tiền viết bằng chữ <i>(Amount in words)</i>:{' '}
              <span>
                {totalPrice === 'Chưa xác định'
                  ? 'Chưa xác định'
                  : readMoney(totalPrice?.toString())}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
      <Row style={{ marginTop: '15px' }}>
        <Col className="text-center" span={12}>
          <div className="cer">Người mua hàng</div> <i> (Buyer)</i>
        </Col>
        <Col span={12}>
          <div className="text-center" style={{ alignItems: 'center' }}>
            <div className="cer">Người bán hàng</div> <i> (Seller)</i>
          </div>
          <div className="text-center">
            <div className="info-company">
              CÔNG TY CỔ PHẦN HỆ SINH THÁI CÔNG NGHỆ YOYO
              <div className="date-company">Ngày: ..../..../.......</div>
            </div>
          </div>
        </Col>
      </Row>

      <div
        style={{
          color: '#09037a',
          fontSize: '12px',
          textAlign: 'center',
          paddingTop: '20px',
        }}
      >
        (Cần kiểm tra đối chiếu khi lập, giao, nhận hóa đơn)
      </div>
    </PrintContainer>
  )
})

const PrintContainer = styled.div`
  margin: 15px;
  /* padding: 15px; */
  border: 1px solid #3366ac;
  height: 96.4vh;

  & .text-center {
    width: '100%';
    display: flex;
    justify-content: center;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }

  & .label {
  }
  & .value {
    font-weight: bold;
  }
  & caption {
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
  }

  & table {
    table-layout: auto;
    border-spacing: 0; /*  Same as cellspacing="0" */
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
  }

  & table th,
  & table td {
    border: 1px solid #3366ac;
    vertical-align: top;
  }

  .text-center {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  /* No need for this */
  & .col-name {
    width: 40%;
  }

  & .col-price {
    width: 20%;
  }
  & .col-quantity {
    width: 10%;
  }

  & .col-amount {
    width: 30%;
  }

  & table th {
    background-color: #a4c0e2;
    color: #09037a;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.4);
  }

  & table tbody tr:nth-child(even) td {
    background-color: #dcdcdc;
  }

  & .price_txt {
    text-align: right;
    font-weight: bold;
  }

  & .price_amount_txt {
    text-align: left;
    font-weight: bold;
  }

  & .cer {
    font-weight: bold;
    font-size: 16px;
    margin-right: 4px;
    color: #09037a;
  }

  & .info-company {
    color: #09037a;
    font-weight: bold;
    font-size: 16px;
    width: 70%;
    text-align: center;
    padding-top: 50px;
  }

  & .date-company {
    color: #09037a;
    font-size: 12px;
    text-align: center;
  }
`

export default PrintOrder
