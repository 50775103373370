import { Button, Form, Input, Modal, Row } from 'antd'
import { IAddEditUnitProps } from '../interface'

const AddEditUnitModal = (props: IAddEditUnitProps) => {
  const {
    isVisible,
    setIsVisible,
    addNewUnit,
    setUnitName,
    unitName,
    unitId,
  } = props

  return (
    <Modal
      title={unitId === -1 ? 'Thêm mới đơn vị tính' : 'Cập nhật đơn vị tính'}
      open={isVisible}
      footer={null}
      onCancel={() => {
        setUnitName('')
        setIsVisible(false)
      }}
      destroyOnClose
    >
      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={addNewUnit}
        autoComplete="off"
        initialValues={{ unitName: unitName }}
      >
        <Form.Item
          label="Đơn vị tính"
          name="unitName"
          rules={[{ required: true, message: 'Vui lòng nhập đơn vị tính!' }]}
        >
          <Input
            placeholder="Nhập đơn vị tính"
            value={unitName}
            onChange={(e: any) => setUnitName(e.target.value)}
          />
        </Form.Item>
        <Row justify="center" style={{ marginTop: 40 }}>
          <Button
            type="primary"
            style={{
              backgroundColor: '#f2481d',
              borderColor: '#f2481d',
              marginRight: 20,
              color: 'white',
            }}
            onClick={() => {
              setUnitName('')

              setIsVisible(false)
            }}
          >
            Huỷ
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddEditUnitModal
