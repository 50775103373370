import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getListPartner } from '../ParnertApi'
type Params = {
  search?: string
  page: number
  approve_status?: any
  from_date?: any
  to_date?: any
  create_at?: string | Date
}
export const useBecomePartner = () => {
  const location: any = useLocation()
  const [currentSelected, setCurrentSelected] = useState(
    location?.state?.id ? { id: location?.state?.id } : { id: -1 }
  )
  const [dataSource, setDataSource] = useState<any>([])
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)

  const [params, setParams] = useState<Params>({
    search: undefined,
    page: 1,
    approve_status: undefined,
    from_date: undefined,
    to_date: undefined,
    create_at: undefined,
  })

  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 24,
  })
  const handleChangeStatus = (value: any) => {
    setParams({
      ...params,
      approve_status: value,
      page: 1,
    })
  }

  const onSearchSubmit = (searchKey: string) => {
    setParams({ ...params, search: searchKey, page: 1 })
  }

  const getData = async () => {
    try {
      const res = await getListPartner(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          name: item.name,
          nameCUstomer: item.user.name,
          emailCUstomer: item.user.email,
          phoneCUstomer: item.user.phone,
          phone: item.phone,
          email: item.email,
          representative: item.representative,
          approve_status: item.approve_status,
          tax: item.tax,
          key: item.id,
          id: item.id,
          create_at: item.create_at,
          partner_medias: item?.partner_medias,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
        }
      })
      setDataSource(tableData)
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    getData()
  }, [params])

  return {
    paging,
    params,
    currentSelected,
    dataSource,
    setParams,
    setPaging,
    getData,
    setCurrentSelected,
    handleChangeStatus,
    onSearchSubmit,
    isSearchLoading,
  }
}
