import { DatePicker, Form, PageHeader, Row, Spin, Table } from 'antd'
import SaveButton from 'common/components/Buttons/SaveButton'
import openNotificationWithIcon from 'common/components/Notification'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import { requestAllCategory } from 'features/admin/product/product/ProductApi'
import useDebounce from 'features/admin/report/sale/hook/useDebounce'
import moment from 'moment'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ICategory, IProductInventory } from '../../Inventory'
import { getDetailWarehouse, updateANewProduct } from '../service'
import InputnumberRemain from './components/Inputnumber.Remain'
import UpdateInventoryFilter from './UpdateInventoryFilter'

const helpFoundIndex = (list: any[], id: number) => {
  return list.findIndex((item: any) => item.id === id)
}
const UpdateSurviveHead = () => {
  // Danh sách sản phẩm
  const [listProduct, setListProduct] = React.useState<IProductInventory[]>([])

  const [count, setCount] = React.useState<number>(0)

  const onUpdateRemain = (record: IProductInventory, value: number) => {
    const indexFounded = helpFoundIndex(listProduct, record?.id)
    if (indexFounded !== -1) {
      let newArray = [...listProduct]
      let newData = { ...listProduct[indexFounded] }
      newData.remain = value
      if (!newData?.input_date) {
        newData.input_date = moment().format('YYYY-MM-DD HH:mm')
      }
      newData.updateRemainPetrol = value
      newArray[indexFounded] = newData
      setListProduct(newArray)
    }
  }

  const columns = [
    {
      width: 50,
      title: <b>STT</b>,
      dataIndex: 'stt',
      render: (text: any, record: any, index: any) => (
        <td id={record.id}>
          {currentPage === 1 ? ++index : (currentPage - 1) * 12 + ++index}
        </td>
      ),
    },
    {
      title: <b>Mã sản phẩm</b>,
      dataIndex: 'code',
    },
    {
      title: <b>Sản phẩm</b>,
      dataIndex: 'name',
    },
    {
      title: <b>Tồn đầu</b>,
      dataIndex: 'remain',
      render: (value: number) => {
        return <td>{value ? value : 0}</td>
      },
    },
    {
      title: <b>Cập nhật</b>,
      dataIndex: 'updateLossPetrol',
      width: '240px',
      render: (_: any, record: IProductInventory, index: number) => {
        return (
          <InputnumberRemain
            record={record}
            callback={callback}
            onUpdateRemain={onUpdateRemain}
            copiedListProduct={copiedListProduct}
          />
        )
      },
    },
    // {
    //   title: <b>Ngày cập nhật</b>,
    //   dataIndex: 'input_date',
    // },
    {
      title: <b>Ngày cập nhật</b>,
      dataIndex: 'input_date_cur',
      render: (_: any, record: IProductInventory) => {
        return (
          <DatePicker
            format="HH:mm DD/MM/YYYY"
            showTime
            disabled={!!_}
            value={
              record?.input_date || _ ? moment(record?.input_date) : undefined
            }
            onChange={(date, dateString) => {
              const inputDate =
                dateString.split(' ')[1].split('/').reverse().join('-') +
                ' ' +
                dateString.split(' ')[0]

              setListProduct(prev =>
                prev.map(item => {
                  if (item.id === record.id) {
                    return {
                      ...item,
                      input_date: inputDate,
                    }
                  }
                  return item
                })
              )
            }}
          />
        )
      },
    },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   render: (value: number, record: IProductInventory) => {
    //     return <SaveOutlined style={{ color: 'green', cursor: 'pointer' }} />
    //   },
    // },
  ]
  const [currentPage, setCurrentPage] = React.useState<number>(1)

  const location: any = useLocation()
  const history = useHistory()

  const [copiedListProduct, setCopiedListProduct] = React.useState<any>([])
  const [categories, setCategories] = React.useState<ICategory[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [search, setSearch] = React.useState<string>('')
  const [categoryId, setCategoryId] = React.useState<number>(-1)
  const searchDebounce = useDebounce(search, 300)

  const [paging, setPaging] = React.useState<any>(0)

  const [form] = Form.useForm()

  const getListCategory = async () => {
    try {
      const res: any = await requestAllCategory({ limit: 999 })
      const data = res.data.map((item: any) => {
        return { value: item.id, text: item.name }
      })
      setCategories(data)
    } catch (error) {
      console.log('ERROR:', error)
    }
  }

  const [callback, setCallback] = React.useState(false)
  const getDetail = async () => {
    try {
      setIsLoading(true)
      const res = await getDetailWarehouse(location?.state?.id, {
        limit: 999,
      })
      let filteredData: any[] = []
      // if (searchDebounce !== '' && categoryId !== -1) {
      //   filteredData = res.data.filter(
      //     (product: any) =>
      //       product.category_id === categoryId &&
      //       product.product_name
      //         .toLowerCase()
      //         .includes(searchDebounce.toLocaleLowerCase())
      //   )
      // } else if (searchDebounce !== '' && categoryId === -1) {
      //   filteredData = res.data.filter((product: any) =>
      //     product.product_name
      //       .toLowerCase()
      //       .includes(searchDebounce.toLocaleLowerCase())
      //   )
      // } else if (searchDebounce === '' && categoryId !== -1) {
      //   filteredData = res.data.filter(
      //     (product: any) => product.category_id === categoryId
      //   )
      // } else {
      filteredData = res.data
      // }

      if (res.status) {
        const data: any = filteredData.map((product: any) => ({
          id: product.product_id,
          key: product.product_id,
          categoryId: product.category_id,
          code: product.product_code,
          name: product.product_name,
          beginInventory: Number(Number(product.receipt).toFixed(2)),
          loss: product.diminish || 0,
          remain: product?.remain ? Number(product?.remain)?.toFixed(2) : 0,
          remain_cur: product?.remain ? Number(product?.remain)?.toFixed(2) : 0,
          updateBeginInventory: 0,
          updateLossPetrol: 0,
          updateSurvivePetrol: 0,
          input_date: product?.input_date,
          input_date_cur: product?.input_date,
          show: true,
        }))
        setListProduct(data)
        setCopiedListProduct(data)
        // const page = {
        //   total: res.meta.pagination.totalItems,
        //   current: res.meta.pagination.page,
        //   pageSize: res.meta.pagination.limit,
        // }
        // setCurrentPage(res.meta.pagination.page)
        // setPaging(page)
      } else {
        openNotificationWithIcon(
          'error',
          'Thông báo',
          'Đã có lỗi xảy ra! Xin vui lòng thử lại'
        )
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const changedProducts: IProductInventory[] = []
      let check: number = 0
      listProduct.forEach((product: any, index: number) => {
        if (
          product?.remain > copiedListProduct[index]?.remain ||
          product?.updateRemainPetrol >
            copiedListProduct[index]?.updateRemainPetrol
        ) {
          // tăng
          product.type = 1
          changedProducts.push(product)
        } else if (
          product?.remain < copiedListProduct[index]?.remain ||
          product?.updateRemainPetrol <
            copiedListProduct[index]?.updateRemainPetrol
        ) {
          // giảm
          product.type = 2
          changedProducts.push(product)
        }
      })

      // Xử lý bất đồng bộ ở đây
      const response = await Promise.all(
        changedProducts.map(
          async (product: IProductInventory, index: number) => {
            const payload = {
              type: product.type,
              category_id: product.categoryId,
              product_id: product.id,
              remain: product?.remain,
              input_date:
                product?.input_date || moment().format('YYYY-MM-DD HH:mm'),
            }
            const res = await updateANewProduct(location?.state?.id, payload)
            if (!res.status) {
              check += 1
            }
            if (index === changedProducts.length - 1) {
              getDetail()
            }
          }
        )
      )
      if (check > 0) {
        openNotificationWithIcon(
          'error',
          'Thất bại',
          'Cập nhật tồn đầu thất bại!'
        )
      } else {
        openNotificationWithIcon(
          'success',
          'Thành công',
          'Cập nhật tồn đầu thành công!'
        )
        setCount(0)
        setCallback(!callback)
        form.resetFields()
      }
      // }
    } catch (error) {
      console.log('ERROR: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getDetail()
  }, [callback])

  React.useEffect(() => {
    getListCategory()
  }, [])

  React.useEffect(() => {
    if (categoryId == -1 && !searchDebounce)
      return setListProduct((prev: any) =>
        prev.map((prd: any) => ({
          ...prd,
          show: true,
        }))
      )
    setListProduct((prev: any) => {
      return prev.map((prd: any) => {
        // nếu filter chỉ có category và không có search
        if (prd?.categoryId === categoryId && !searchDebounce) {
          return {
            ...prd,
            show: true,
          }
        }

        // nếu search k filter
        if (
          categoryId == -1 &&
          searchDebounce &&
          (prd?.name
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchDebounce?.toLowerCase()) ||
            prd?.code
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchDebounce?.toLowerCase()))
        ) {
          return {
            ...prd,
            show: true,
          }
        }

        if (
          prd?.categoryId === categoryId &&
          searchDebounce &&
          (prd?.name
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchDebounce?.toLowerCase()) ||
            prd?.code
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchDebounce?.toLowerCase()))
        ) {
          return {
            ...prd,
            show: true,
          }
        }

        return {
          ...prd,
          show: false,
        }
      })
    })
  }, [categoryId, searchDebounce])

  // React.useEffect(() => {
  //   if (!searchDebounce)
  //     return setListProduct((prev: any) => {
  //       return prev.map((prd: any) => ({
  //         ...prd,
  //         show: true,
  //       }))
  //     })
  //   setListProduct((prev: any) => {
  //     return prev.map((prd: any) =>
  //       prd?.name
  //         ?.toString()
  //         ?.toLowerCase()
  //         ?.includes(searchDebounce?.toLowerCase()) ||
  //       prd?.code
  //         ?.toString()
  //         ?.toLowerCase()
  //         ?.includes(searchDebounce?.toLowerCase())
  //         ? {
  //             ...prd,
  //             show: true,
  //           }
  //         : {
  //             ...prd,
  //             show: false,
  //           }
  //     )
  //   })
  // }, [searchDebounce])
  // handleSubmit
  return (
    <Form form={form} onFinishFailed={(err: any) => {}} onFinish={handleSubmit}>
      <Container
        header={
          <PageHeader
            title={`Cập nhật tồn đầu tại ${location.state.inventoryDetailName}`}
            extra={[
              <SaveButton
                text="Lưu"
                htmlType="submit"
                isDisable={listProduct.length === 0}
              />,
            ]}
            onBack={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.INVENTORY_DETAIL}/${location.state.inventoryDetailId}`,
                state: {
                  inventoryDetailId: location.state.inventoryDetailId,
                  inventoryDetailName: location.state.inventoryDetailName,
                  search: location?.state.search,
                  currentPage: location?.state.currentPage,
                  id: location?.state.id,
                  currentPageList: location?.state?.currentPageList,
                  categoryId: location?.state?.categoryId,
                  fromDate: location?.state?.fromDate,
                  toDate: location?.state?.toDate,
                },
              })
            }}
          />
        }
        filterComponent={
          <UpdateInventoryFilter
            categories={categories}
            setCategoryId={setCategoryId}
            setSearch={setSearch}
          />
        }
        contentComponent={
          <Spin spinning={isLoading}>
            <Row
              style={{
                width: '100%',
                marginBottom: 20,
              }}
              justify="end"
            ></Row>
            <Row style={{ justifyContent: 'space-between' }}>
              <p>
                Kết quả lọc: <b>{listProduct.length}</b>
              </p>
              {/* <p>
                Thay đổi: <b>{count}/2</b>
              </p> */}
            </Row>
            <Table
              bordered
              columns={columns}
              dataSource={listProduct?.filter((prd: any) => prd.show)}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              pagination={{
                ...paging,
                pageSize: 12,
                showSizeChanger: false,
                onChange: (page: number) => {
                  setPaging(page)
                },
              }}
            />
          </Spin>
        }
      />
    </Form>
  )
}

export default UpdateSurviveHead
