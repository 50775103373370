import {
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import { Table, Divider, Popconfirm, Input, DatePicker } from 'antd'
import Column from 'antd/lib/table/Column'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { enterNumbersOnly, formatPrice } from 'utils/ruleForm'
import { notificationError } from 'utils/notification'
import ChooseShopModal from './ChooseShopModal'
import { ButtonAdd, ButtonFixed } from 'common/components/Buttons'
import styles from '../../product/style/Product.module.css'
import moment from 'moment'
import { getSaleProductInfor } from '../SaleProductSlice'

type Props = {
  id: number
  paging: any
  storesSelected: any
  listPetrolStore: any
  setStoresSelected: any
  arrayDeletePetrol: any
  isLoading: any
  getStoreBySearch: (value?: string) => void
  getStoreByPage: (value: number) => void
  setStoresChecked: any
  storesChecked: any
  paramsChooseShop: any
  setParamsChooseShop: any
  setArrayDeletePetrol: any
}
const { RangePicker } = DatePicker
export default function PriceInformationProduct({
  id,
  listPetrolStore,
  paging,
  storesSelected,
  setStoresSelected,
  isLoading,
  arrayDeletePetrol,
  getStoreBySearch,
  getStoreByPage,
  storesChecked,
  setStoresChecked,
  paramsChooseShop,
  setParamsChooseShop,
  setArrayDeletePetrol,
}: Props) {
  const [visibleChooseShop, setVisibleChooseShop] = useState(false)
  const [valuePrice, setValuePrice] = useState<number>(0)
  const saleProductInfor = useSelector(getSaleProductInfor)

  function disabledDate(current: any) {
    return current && current < moment().subtract(1, 'day')
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <b style={{ fontSize: '20px' }} tabIndex={-1} id="add_store">
          Thông tin giá bán khuyến mãi
        </b>
        <ButtonAdd
          onClickButton={() => {
            if (saleProductInfor.product_id) {
              setVisibleChooseShop(true)
            } else {
              notificationError('Vui lòng chọn sản phẩm khuyến mại trước!')
            }
          }}
          text="Thêm cửa hàng"
        />
      </div>

      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <b style={{ fontSize: '16px' }}>Giá khuyến mại:</b>
        <Input
          placeholder="Nhập giá khuyến mại"
          style={{ width: '300px', marginLeft: '30px' }}
          value={valuePrice ? formatPrice(valuePrice) : ''}
          onChange={(e: any) => {
            let value = enterNumbersOnly(e.target.value)
            if (parseInt(value) > 1000000000) {
              notificationError('Giá sản phẩm không vượt quá 1,000,000,000')
            } else {
              setValuePrice(parseInt(value))
            }
          }}
        />
        <ButtonFixed
          style={{
            marginLeft: '30px',
            borderRadius: '3px',
            borderColor: '#1890ff',
            color: '#1890ff',
          }}
          text="Áp dụng cho tất cả"
          icon={<CopyOutlined />}
          onClickButton={() => {
            const listPrice = storesSelected.map(
              (item: any, index: number) => ({
                ...item,
                price: valuePrice,
              })
            )
            setStoresSelected([...listPrice])
          }}
        />
      </div>
      <Table
        style={{ marginTop: '20px', cursor: 'pointer' }}
        bordered
        scroll={{
          x: 800,
          y: 'calc(100vh-300px)',
          scrollToFirstRowOnChange: true,
        }}
        loading={isLoading.loadingTable}
        dataSource={storesSelected}
        pagination={false}
      >
        <Column
          width="70px"
          title={<b style={{ fontSize: '16px' }}>STT</b>}
          dataIndex="stt"
          key="stt"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{index_col + 1}</p>
          )}
        />
        <Column
          width="190px"
          title={<b style={{ fontSize: '16px' }}>Cửa hàng</b>}
          dataIndex="name"
          key="name"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="200px"
          title={<b style={{ fontSize: '16px' }}>Địa điểm</b>}
          dataIndex="address"
          key="price"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="160px"
          title={<b style={{ fontSize: '16px' }}>Giá gốc</b>}
          dataIndex="price_root"
          key="price_root"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col ? formatPrice(value_col) : '0'} đ</p>
          )}
        />
        <Column
          width="160px"
          title={<b style={{ fontSize: '16px' }}>Chiết khấu</b>}
          dataIndex="discount"
          key="discount"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{formatPrice(record.price_root - record.price)} đ</p>
          )}
        />

        <Column
          width="180px"
          title={<b style={{ fontSize: '16px' }}>Giá khuyến mại</b>}
          dataIndex="price"
          key="price"
          render={(value_col: any, record: any, index_col: number) => (
            <Input
              id={`price-product-${index_col}`}
              style={{
                width: '100%',
              }}
              placeholder="Nhập giá khuyến mại"
              value={value_col ? formatPrice(value_col) : ''}
              onChange={(e: any) => {
                let value = enterNumbersOnly(e.target.value)
                if (parseInt(value) > 1000000000) {
                  notificationError('Giá sản phẩm không vượt quá 1,000,000,000')
                } else {
                  if (record.price_root <= parseInt(value)) {
                    notificationError('Nhập giá khuyến mại nhỏ hơn giá gốc')
                  } else {
                    const arr = [...storesSelected]
                    const new_data_record: any = {
                      ...record,
                      price: parseInt(value === '' ? 0 : value),
                    }
                    arr[index_col] = new_data_record
                    setStoresSelected(arr)
                  }
                }
              }}
            />
          )}
        />
        <Column
          width="330px"
          title={<b style={{ fontSize: '16px' }}>Thời gian khuyến mãi</b>}
          dataIndex="promotion_time"
          key="promotion_time"
          render={(value_col: any, record: any, index_col: number) => (
            <RangePicker
              style={{ fontWeight: 'bold' }}
              id={`promotion-time-product-${index_col}`}
              format="HH:mm DD/MM/YYYY"
              showTime={{ format: 'HH:mm' }}
              // disabledDate={disabledDate}
              placeholder={['Từ ngày', 'Đến ngày']}
              value={
                record.start_at
                  ? [moment(record.start_at), moment(record.finish_at)]
                  : undefined
              }
              onChange={(dates: any, dateStrings: Array<string>) => {
                const arr = [...storesSelected]
                const new_data_record: any = {
                  ...record,
                  start_at: dates?.[0]._d.setSeconds(0),
                  finish_at: dates?.[1]._d.setSeconds(0),
                }
                arr[index_col] = new_data_record
                setStoresSelected(arr)
              }}
            />
          )}
        />
        <Column
          width="70px"
          title={<b style={{ fontSize: '16px' }}>Xóa</b>}
          dataIndex="delete"
          key="delete"
          render={(value: any, record: any, index: number) => (
            <Popconfirm
              placement="bottomLeft"
              title="Bạn có chắc chắn muốn xóa?"
              style={{ margin: 'auto' }}
              onConfirm={() => {
                const arr = [...storesSelected]
                arr.splice(index, 1)
                setStoresSelected(arr)
                setArrayDeletePetrol([...arrayDeletePetrol, record.id])
              }}
              okText={
                <>
                  <CloseCircleOutlined /> Xóa
                </>
              }
              cancelText={
                <>
                  <LeftCircleOutlined /> Quay lại
                </>
              }
              okButtonProps={{
                type: 'default',
                danger: true,
                style: {
                  borderRadius: 3,
                },
              }}
              cancelButtonProps={{
                style: {
                  minWidth: '70px',
                  fontWeight: 'bold',
                  borderColor: '#1890ff',
                  color: '#1890ff',
                  borderRadius: 3,
                },
              }}
            >
              <DeleteOutlined className={styles.icon_delete} />
            </Popconfirm>
          )}
        />
      </Table>
      <ChooseShopModal
        id={id}
        visible={visibleChooseShop}
        onCancel={() => {
          setVisibleChooseShop(false)
        }}
        paging={paging}
        listPetrolStore={listPetrolStore}
        storesSelected={storesSelected}
        setStoresChecked={setStoresChecked}
        isLoading={isLoading}
        getStoreBySearch={getStoreBySearch}
        getStoreByPage={getStoreByPage}
        storesChecked={storesChecked}
        onSave={(listStoreSelected: any) => {
          setStoresSelected(listStoreSelected)
          setStoresChecked([])

          const list_store_cancel = storesSelected.map(
            (item: any) => item.petrol_store_id
          )
          setParamsChooseShop({
            ...paramsChooseShop,
            array_petrol_store_cancle: list_store_cancel,
            page: 1,
          })
          setVisibleChooseShop(false)
        }}
      />
    </div>
  )
}
