import { Descriptions } from 'antd'
import styles from '../../orders/styles/style.module.css'
export default function CustomerInfor() {
  return (
    <Descriptions
      title={
        <div className={styles.title}>
          <b>Thông tin KH</b>
          {/* <Divider /> */}
        </div>
      }
      column={1}
    >
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Tên khách hàng</span>}
      >
        Nguyễn Minh Tú
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Số điện thoại</span>}
      >
        099999999
      </Descriptions.Item>
    </Descriptions>
  )
}
