import { Descriptions, Image } from 'antd'
import styles from 'common/components/styles/WhiteBox.module.css'
import { formatPrice } from 'utils/ruleForm'

type Props = {
  data: any
  getData: any
}

export default function ProductDetailScreen({ data, getData }: Props) {
  return (
    <div className={styles.expand}>
      <Descriptions
        title={<b>THÔNG TIN CHUNG</b>}
        className={styles.tab}
        column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item
          label={<span className={styles.description_label}>Mã sản phẩm</span>}
        >
          <span style={{ color: 'red', width: '130px' }}>{data.code}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Danh mục</span>}
        >
          <span>{data.product_category_name}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Tên sản phẩm</span>}
        >
          <span>{data.name}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <span className={styles.description_label}>
              Trạng thái sản phẩm
            </span>
          }
        >
          <>
            {data.status === 1 ? (
              <span style={{ color: 'green' }}>Đang hoạt động</span>
            ) : (
              <span style={{ color: 'red' }}> Tạm dừng</span>
            )}
          </>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Giá sản phẩm</span>}
        >
          <span style={{ color: 'red' }}>
            {data.product_stock_price == null
              ? '0đ'
              : formatPrice(data.product_stock_price) + 'đ'}
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Đơn vị tính</span>}
        >
          <span>{data?.unit || '--'}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Mô tả</span>}
        >
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: data.product.description as string,
              }}
            />
          </span>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title={<b>THÔNG TIN HÌNH ẢNH</b>} className={styles.tab}>
        <Descriptions.Item
          label={<span className={styles.description_label}>Ảnh sản phẩm</span>}
        >
          {data?.product?.product_medias?.map((item: any, index: number) => {
            return (
              <div
                style={{
                  marginLeft: '10px',
                  marginBottom: '10px',
                }}
              >
                <Image
                  style={{
                    objectFit: 'cover',
                    width: '100px',
                    height: '100px',
                  }}
                  src={item.media_url}
                />
              </div>
            )
          })}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}
