import { Table } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { formatPrice } from 'utils/ruleForm'
import Filter from './components/Filter'
import Header from './components/Header'
import styles from 'common/components/styles/WhiteBox.module.css'

export default function DebtnamesScreen() {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState({
    isLoadingTable: false,
    isLoadingSearch: false,
  })
  const data = [
    {
      id: 1,
      key: 1,
      name: 'Cửa hàng xăng dầu Ukraine',
      phone: '091222222',
      tax_code: '34753456435',
      count_order: 10,
      email: 'abc123@gmail.com',
    },
    {
      id: 2,
      key: 2,
      name: 'Cửa hàng khí đốt Putin',
      phone: '091222222',
      tax_code: '34753456435',
      count_order: 10,
      email: 'abc123@gmail.com',
    },
    {
      id: 3,
      key: 3,
      name: 'Cửa hàng xăng sinh học Biden',
      phone: '091222222',
      tax_code: '34753456435',
      count_order: 10,
      email: 'abc123@gmail.com',
    },
    {
      id: 3,
      key: 3,
      name: 'Cửa hàng phụ tùng, dầu nhớt EU',
      phone: '091222222',
      tax_code: '34753456435',
      count_order: 10,
      email: 'abc123@gmail.com',
    },
  ]

  const column = [
    {
      width: '60px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      width: '180px',
      title: <b>Tên đối tác</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: '100px',
      title: <b>Số điện thoại</b>,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      width: '100px',
      title: <b>Mã số thuế</b>,
      dataIndex: 'tax_code',
      key: 'tax_code',
    },
    {
      width: '100px',
      title: <b>Email</b>,
      dataIndex: 'email',
      key: 'email',
    },
    {
      width: '100px',
      title: <b>Tổng số đơn hàng</b>,
      dataIndex: 'count_order',
      key: 'count_order',
      render: (value: number) => <p>{value ? formatPrice(value) : 0} </p>,
    },
  ]
  return (
    <Container
      header={<Header />}
      filterComponent={<Filter isSearchLoading={isLoading.isLoadingSearch} />}
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{data.length}</b>
          </p>
          <Table
            className={styles.table_hover}
            size="middle"
            // style={{ fontWeight: 600 }}
            loading={isLoading.isLoadingTable}
            bordered
            columns={column}
            dataSource={data}
            scroll={{
              x: 800,
              // scrollToFirstRowOnChange: true,
            }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.DEBT_SHOPS_DETAIL}/${record.id}`,
                  state: record,
                })
              },
              // onMouseEnter: event => {
              //   setItemSelected({ id: record.id, rowIndex: Number(rowIndex) })
              // },
            })}
            // pagination={{
            //   ...paging,
            //   showSizeChanger: false,
            //   onChange: async (page, pageSize) => {
            //     setParams({ ...params, page })
            //   },
            // }}
          />
        </div>
      }
    />
  )
}
