import { Table, Tag, message } from 'antd'
import { Filter } from './components/Filter'
import { Header } from './components/Header'
import DetailAccount from './components/DetailAccount'
import { useAccount } from './hook/useAccount'
import Container from 'common/container/Container'
import { IS_ACTIVE } from 'utils/constants'
import { deleteAccount, changeStatusAccount, resetPassword } from './AccountApi'
import { AddEditAccount } from './components/AddEditAccount'
import styles from 'common/components/styles/WhiteBox.module.css'
import { notificationSuccess } from 'utils/notification'

const columns = [
  {
    width: 70,
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Họ tên</b>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: <b>Email</b>,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <b>Loại tài khoản</b>,
    dataIndex: 'role_names',
    key: 'role_names',
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    key: 'status',
    render: (value: any) => {
      switch (value) {
        case IS_ACTIVE.ACTIVE:
          return <Tag color="green">Hoạt động</Tag>
        case IS_ACTIVE.INACTIVE:
          return <Tag color="volcano">Tạm dùng</Tag>
      }
    },
  },
]

const AccountsScreen = () => {
  const {
    isLoading,
    currentSelected,
    params,
    paging,
    listAccount,
    setCurrentSelected,
    setParams,
    getData,
    showAddAccount,
    setShowAddAccount,
    createAcc,
  } = useAccount()

  return (
    <>
      <Container
        header={<Header setIsCreate={setShowAddAccount} />}
        filterComponent={
          <Filter
            onSearchSubmit={(searchKey: string) => {
              setParams({ ...params, search: searchKey, page: 1 })
            }}
            onStatusSubmit={(statusKey: string) => {
              setParams({ ...params, status: statusKey, page: 1 })
            }}
            onRoleSubmit={(roleKey: string) => {
              setParams({ ...params, role_id: roleKey, page: 1 })
            }}
          />
        }
        contentComponent={
          <div>
            <p>
              Kết quả lọc: <b>{paging.total}</b>
            </p>
            <Table
              className={styles.table_hover}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              bordered
              dataSource={listAccount}
              loading={isLoading}
              columns={columns}
              expandedRowKeys={[currentSelected.id]}
              onRow={(row: any) => ({
                onClick: () => {
                  if (currentSelected !== row) setCurrentSelected(row)
                  else setCurrentSelected({ id: -1 })
                },
              })}
              expandable={{
                expandedRowRender: (record: any) => (
                  <DetailAccount
                    onResetPassword={async (id: any) => {
                      try {
                        await resetPassword({ id: [id] })
                        notificationSuccess('Reset mật khẩu thành công')
                        getData()
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                    onChangeStatus={async (id: any) => {
                      try {
                        const res = await changeStatusAccount({ id: [id] })
                        notificationSuccess('Cập nhật trạng thái thành công')
                        getData()
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                    onDeleteAccount={async (id: any) => {
                      try {
                        await deleteAccount({ id: [id] })
                        notificationSuccess('Xóa tài khoản thành công')
                        getData()
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                    data={record}
                    getData={getData}
                  />
                ),
              }}
              pagination={{
                ...paging,
                showSizeChanger: false,
                onChange: async (page, pageSize) => {
                  setParams({ ...params, page })
                },
              }}
            />
            {showAddAccount && (
              <AddEditAccount
                visible={showAddAccount}
                isLoading={false}
                onCancel={() => {
                  setShowAddAccount(false)
                }}
                onCreateNewAccount={createAcc}
              />
            )}
          </div>
        }
      />
    </>
  )
}

export default AccountsScreen
