import { ENVIRONMENT } from './../common/config/environment'
import io, { Socket } from 'socket.io-client'
import Cookie from 'js-cookie'
import { SESSION_ID } from 'common/config'

export class WebSocket {
  public static socketClient: Socket
  private static currentToken: string | undefined

  public static async init(token: string | undefined) {
    if (!this.socketClient && this.currentToken !== token) {
      this.currentToken = token
      this.socketClient = io(ENVIRONMENT.WS_URL as string, {
        auth: { token: Cookie.get(SESSION_ID) },
      })
    }
  }
  public static async disconnect() {
    if (this.socketClient) {
      this.socketClient.disconnect()
    }
  }
}

// const createSocketClient = (): Socket => {
//   // const socketInstance: Socket = io(ENVIRONMENT.WS_URL as string, {
//   console.log('token', Cookie.get(SESSION_ID))
//   const socketInstance: Socket = io('http://yoyodev.api.winds.vn', {
//     auth: { token: Cookie.get(SESSION_ID) },
//   })
//   return socketInstance
// }
// const socketClient = createSocketClient()
// export default socketClient
