import { Col, PageHeader, Row } from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ImagesInformationProduct from './components/ImageInformationProduct'
import PriceInformationProduct from './components/PriceInformationProduct'
import GeneralInformationProduct from './components/GeneralInformationProduct'
import { CheckCircleOutlined } from '@ant-design/icons'
import { ButtonAdd } from 'common/components/Buttons'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useAddEditProduct } from './hook/useAddEditProduct'
import { clearDataProduct } from './ProductSlice'
import { useDispatch } from 'react-redux'
import { ADMIN_ROUTER_PATH } from 'common/config'

export default function AddEditProductScreen() {
  const location: any = useLocation()
  const param: { id: any } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    listImages,
    storesSelected,
    isLoading,
    arrayDeletePetrol,
    setArrayDeletePetrol,
    setListImages,
    setStoresSelected,
    onSave,
  } = useAddEditProduct()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PageHeader
          title={param.id ? 'Sửa sản phẩm' : 'Thêm sản phẩm'}
          onBack={() => {
            dispatch(clearDataProduct())
            if (param.id) {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${location.state.idProduct}`,
                state: location.state,
              })
            } else {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.PRODUCT}`,
                state: location.state,
              })
            }
          }}
          extra={[
            <ButtonAdd
              onClickButton={onSave}
              text="Lưu"
              loading={isLoading.loadingUpdate}
              icon={<CheckCircleOutlined />}
            />,
          ]}
        />
      </div>
      <div className={styles.content}>
        <GeneralInformationProduct id={param?.id} />
      </div>
      <div className={styles.content}>
        <ImagesInformationProduct
          id={param?.id}
          listImages={listImages}
          setListImages={setListImages}
        />
      </div>
      <div className={styles.content}>
        <PriceInformationProduct
          id={param?.id}
          storesSelected={storesSelected}
          arrayDeletePetrol={arrayDeletePetrol}
          setStoresSelected={setStoresSelected}
          setArrayDeletePetrol={setArrayDeletePetrol}
        />
        <Row
          justify="center"
          style={{
            padding: '15px 15px 15px',
          }}
        >
          <Col>
            <ButtonAdd
              onClickButton={onSave}
              text="Lưu"
              loading={isLoading.loadingUpdate}
              icon={<CheckCircleOutlined />}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
