import { Descriptions, Tabs, Card, Image, Row, Col } from 'antd'
import styles from 'common/components/styles/WhiteBox.module.css'
import moment from 'moment'
import {} from '../CategoryProductApi'

type Props = {
  data: any
  getData: any
}

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const ContentView = (data: any) => {
  return (
    <Row gutter={[16, 16]}>
      <Col sm={6}>
        <Descriptions size="default" column={3}>
          <Descriptions.Item>
            <Image
              style={{ objectFit: 'cover', width: '100px', height: '100px' }}
              src={data.icon_url}
            />
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col sm={18}>
        <Descriptions size="default" column={3}>
          <Descriptions.Item label="Tên danh mục">
            {data.name}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {data.status ? 'Đang hoạt động' : 'Ngừng hoạt động'}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày tạo">
            {moment(data.create_at).format('DD/MM/YYYY')}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

function CategoryDetail({ data, getData }: Props) {
  return (
    <div>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Thông tin danh mục" key="1">
          <Card className={styles.expand}>{ContentView(data)}</Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default CategoryDetail
