import {
  Row,
  Input,
  Divider,
  Col,
  Switch,
  Tooltip,
  Checkbox,
  InputNumber,
} from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import { useEffect, useState } from 'react'
import { getUnits, requestAllCategory } from '../ProductApi'
import styles from '../style/Product.module.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCodeProduct,
  setNameProduct,
  setCategoryProduct,
  setDescriptionProduct,
  setListedPriceProduct,
  getProductInfor,
  setPriority,
  setQuantityHome,
  setStatusHome,
  setUnitProduct,
  setExpireTimeProduct,
  setMinQuantityProduct,
  setMaxQuantityProduct,
  setSaveStatus,
  setIsAllSpaces,
} from '../ProductSlice'
import { enterNumbersOnly, formatPrice } from 'utils/ruleForm'
import { notificationError } from 'utils/notification'
import EditorComponent from 'common/components/editor/EditorComponent'
import FormItemComponent from 'common/components/FormItemComponent'

const { TextArea } = Input
type Props = {
  id?: number
}

interface IUnit {
  id: number
  value: number
  text: string
}

export default function GeneralInformationProduct({ id }: Props) {
  const dispatch = useDispatch()
  const productInfor = useSelector(getProductInfor)

  const [listCategory, setListCategory] = useState<Array<any>>([])
  const [status, setStatus] = useState<number>(0)
  const [units, setUnits] = useState<IUnit[]>([])
  const [isAllSpace, setIsAllSpace] = useState<boolean>(false)

  const getListCategory = async () => {
    const res = await requestAllCategory({ status: 1, limit: 999 })
    const list_data = res.data.map((item: any) => {
      return { value: item.id, text: item.name }
    })
    setListCategory(list_data)
  }

  const getListUnit = async () => {
    try {
      const res = await getUnits()
      if (res.status) {
        const data = res.data.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            text: item.name,
          }
        })
        setUnits(data)
      } else {
        console.log('Đã có lỗi xảy ra! Xin vui lòng thử lại!')
      }
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  useEffect(() => {
    getListCategory()
    getListUnit()
  }, [])

  useEffect(() => {
    dispatch(setIsAllSpaces(isAllSpace))
  }, [isAllSpace])

  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin chung</b>
      <br />
      <br />
      <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormItemComponent isRequired label="Mã sản phẩm:">
            <Input
              autoComplete="off"
              placeholder="Nhập mã sản phẩm"
              id="code_product"
              value={productInfor.code}
              onChange={(e: any) => {
                const value = e.target.value
                if (value.length < 56) {
                  dispatch(setCodeProduct(e.target.value))
                }
              }}
            />
          </FormItemComponent>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormItemComponent isRequired label="Tên sản phẩm:">
            <Input
              autoComplete="off"
              placeholder="Nhập tên sản phẩm"
              id="name_product"
              value={productInfor.name}
              onChange={(e: any) => {
                if (e.target.value.length < 56) {
                  dispatch(setNameProduct(e.target.value))
                }
              }}
            />
          </FormItemComponent>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormItemComponent isRequired label="Danh mục:">
            <RadiusSelection
              placeholder="Danh mục"
              value={productInfor.category_id}
              onChange={(value: number) => {
                dispatch(setCategoryProduct(value))
                dispatch(setSaveStatus(0))
              }}
              showSearch={true}
              options={listCategory}
            />
          </FormItemComponent>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormItemComponent isRequired label="Giá niêm yết:">
            <Input
              autoComplete="off"
              placeholder="Nhập giá niêm yết"
              id="listed_price_product"
              value={formatPrice(productInfor.price)}
              onChange={(e: any) => {
                const value = enterNumbersOnly(e.target.value)
                if (parseInt(value) > 1000000000) {
                  notificationError('Giá sản phẩm không vượt quá 1,000,000,000')
                } else {
                  dispatch(setListedPriceProduct(parseInt(value)))
                }
              }}
            />
          </FormItemComponent>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormItemComponent isRequired label="Thứ tự ưu tiên:">
            <Input
              autoComplete="off"
              placeholder="Nhập thứ tự ưu tiên"
              id="priority"
              value={formatPrice(productInfor.stt)}
              onChange={(e: any) => {
                const value = enterNumbersOnly(e.target.value)
                if (parseInt(value) || !value)
                  dispatch(setPriority(parseInt(value)))
              }}
            />
          </FormItemComponent>
        </Col>
        {/* ẩn đơn vị tính nếu là bảo hiểm */}
        {productInfor.category_id !== 3 && (
          <Col
            span={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <FormItemComponent isRequired label="Đơn vị tính:">
              <RadiusSelection
                placeholder="Chọn đơn vị tính"
                value={productInfor?.unit}
                onChange={(value: number) => {
                  dispatch(setUnitProduct(value))
                }}
                showSearch={true}
                options={units}
              />
            </FormItemComponent>
          </Col>
        )}

        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormItemComponent label="Lấy tại nhà:">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={productInfor.status_home === 1 ? true : false}
                defaultChecked={false}
                style={{ marginRight: 15 }}
                onChange={(checked: any) => {
                  if (checked) {
                    setStatus(1)
                    dispatch(setStatusHome(1))
                  } else {
                    setStatus(0)
                    dispatch(setStatusHome(0))
                  }
                }}
              />
              <Tooltip title="Số lượng cho phép lấy tại nhà">
                {productInfor.status_home ? (
                  <Input
                    autoComplete="off"
                    id="quantity_home"
                    placeholder="Số lượng cho phép lấy tại nhà"
                    value={formatPrice(productInfor.quantity_home)}
                    onChange={(e: any) => {
                      const value = enterNumbersOnly(e.target.value)
                      if (parseInt(value) || !value)
                        dispatch(setQuantityHome(parseInt(value)))
                    }}
                  />
                ) : (
                  <></>
                )}
              </Tooltip>
            </div>
          </FormItemComponent>
        </Col>
        {productInfor?.category_id === 1 && (
          <Col
            span={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <FormItemComponent label="">
              <Checkbox
                value={productInfor.save_status}
                checked={productInfor.save_status}
                onChange={(e: any) => {
                  dispatch(setSaveStatus(e.target.checked))
                }}
              >
                Mua xăng giữ hàng
              </Checkbox>
            </FormItemComponent>
          </Col>
        )}

        {/* cấu hình mưa xăng giữ hàng khi chọn danh mục là xăng dầu và tick checkbox */}
        {!!productInfor?.save_status && productInfor.category_id === 1 && (
          <>
            <Col span={24} style={{ marginTop: '10px' }}>
              <b
                style={{
                  fontSize: '20px',
                  // textTransform: 'uppercase',
                }}
              >
                Cấu hình mua xăng giữ hàng
              </b>
            </Col>
            <Col span={24}>
              <Row style={{ padding: '0' }} gutter={[16, 16]}>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginRight: '20px' }}>
                    <b>Số lượng được phép đăng ký giữ xăng: </b>
                    <b style={{ color: 'red' }}>&#42;</b>
                  </div>
                  <Input
                    autoComplete="off"
                    placeholder="Tối thiểu"
                    id="min_quantity"
                    style={{ width: '14%', borderRadius: 4 }}
                    value={formatPrice(productInfor?.min_quantity)}
                    onChange={(e: any) => {
                      const value = enterNumbersOnly(e.target.value)
                      if (parseInt(value) || !value)
                        dispatch(setMinQuantityProduct(parseInt(value)))
                    }}
                  />
                  <div style={{ margin: '0 20px' }}> đến </div>
                  <Input
                    autoComplete="off"
                    placeholder="Tối đa"
                    id="max_quantity"
                    style={{ width: '14%', borderRadius: 4 }}
                    value={formatPrice(productInfor?.max_quantity)}
                    onChange={(e: any) => {
                      const value = enterNumbersOnly(e.target.value)
                      if (parseInt(value) || !value)
                        dispatch(setMaxQuantityProduct(parseInt(value)))
                    }}
                  />
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <FormItemComponent isRequired label="Thời gian giữ hàng:">
                    <Input
                      autoComplete="off"
                      placeholder="Nhập thời gian giữ hàng"
                      id="expire_time"
                      style={{
                        marginLeft: '-10px',
                        borderRadius: '6px',
                        width: '100%',
                      }}
                      suffix="Ngày"
                      value={formatPrice(productInfor.expire_time)}
                      onChange={(e: any) => {
                        const value = enterNumbersOnly(e.target.value)
                        dispatch(setExpireTimeProduct(value))
                      }}
                    />
                  </FormItemComponent>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
      <br />
      <b className={styles.form}>Mô tả sản phẩm </b>
      <b style={{ color: 'red' }}>&#42;</b>
      <EditorComponent
        defaultValue={id ? productInfor.description : ''}
        logData={value => {
          dispatch(setDescriptionProduct(value))
        }}
        editorStyle={{
          border: '1px solid #ACB0B0',
          borderRadius: '5px',
          overflow: 'hidden scroll',
          padding: '0 16px',
        }}
        height={320}
        setIsAllSpace={setIsAllSpace}
      />
    </div>
  )
}
