import { PageHeader } from 'antd'
import ButtonExportExcelV2 from 'common/components/Buttons/ButtonExportExcelV2'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import React from 'react'
import { getListDebtCustomer } from '../DebtCustomerApi'

export default function Header({ params }: { params: any }) {
  const [
    isLoadingBtnExportData,
    setLoadingBtnExportData,
  ] = React.useState<boolean>(false)

  const onExportData = async () => {
    try {
      const workbook = new Excel.Workbook()

      setLoadingBtnExportData(true)
      const worksheet = workbook.addWorksheet('Công nợ')

      // columns
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10 },
        { header: 'Tên khách hàng', key: 'customer_name', width: 32 },
        { header: 'Số điện thoại', key: 'phone', width: 20 },
        { header: 'Hạn mức công nợ', key: 'debt_limit', width: 20 },
        { header: 'Nợ đầu kỳ', key: 'debt_start', width: 20 },
        { header: 'Nợ cuối kỳ', key: 'debt_end', width: 20 },
      ]

      worksheet.insertRow(1, ['TỔNG HỢP CÔNG NỢ PHẢI THU'])
      worksheet.mergeCells('A1', 'F1')
      worksheet.getRow(1).font = { size: 18, color: { argb: '00000' } }

      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
      }
      worksheet.getCell('A1').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      worksheet.insertRow(2, [
        params.from_date
          ? `Thời gian thực hiện: Từ ngày ${moment(params.from_date)?.format(
              'DD/MM/YYYY'
            )} đến ngày ${moment(params.to_date)?.format('DD/MM/YYYY')}`
          : 'Thời gian thực hiện: -',
      ])
      worksheet.mergeCells('A2', 'F2')
      //Todo:  styl;e từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }

      // todo: boder style
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      // todo: boder style
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      const dataListDebt = await getListDebtCustomer({
        ...params,
        limit: 999,
      })

      dataListDebt?.data?.forEach((o: any, i: number) => {
        worksheet.addRow({
          id: i + 1,
          customer_name: o.name,
          phone: o.phone,
          debt_limit: o.debt_limit,
          debt_end: o.total_debt,
          debt_start: o.old_debt,
        })
      })

      worksheet.eachRow({ includeEmpty: true }, function (row: any, rowNumber) {
        if (row?._number === 3) {
          row.eachCell({ includeEmpty: true }, function (cell: any) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '87CEEB' },
            }
          })
        }

        row.eachCell({ includeEmpty: true }, function (cell: any) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(new Blob([buf]), `Công nợ.xlsx`)
    } catch (error) {
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <div>
      <PageHeader
        title="Công nợ khách hàng"
        extra={[
          // <ButtonExport
          //   sheetName={['SalesReport']}
          //   fileName="Báo cáo bán hàng"
          //   onClick={fn => onExportData(fn)}
          //   loading={isLoadingBtnExportData}
          //   sheets={{
          //     SalesReport: ButtonExport.getSheets(dataExport),
          //   }}
          // />,
          <ButtonExportExcelV2
            key="export_excel"
            loading={isLoadingBtnExportData}
            onClick={onExportData}
          />,
        ]}
      />
    </div>
  )
}
