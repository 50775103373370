import { PlusOutlined } from '@ant-design/icons'
import { Divider, Upload, Modal, Row, Col } from 'antd'
import { useState } from 'react'
import { notificationError } from 'utils/notification'
import { uploadImageToServer } from 'utils/uploadImageToServer'
import {} from '../ProductApi'
import styles from '../style/Product.module.css'

type Props = {
  id?: number
  listImages: any
  setListImages: any
}

const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default function ImageInformationProduct({
  id,
  listImages,
  setListImages,
}: Props) {
  const [previewImage, setPreviewImage] = useState<string>('')
  const [isShowModalPreview, setShowModalPreview] = useState<boolean>(false)

  const handlePreviewImage = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setShowModalPreview(true)
  }

  const handleChangeImage = async (file: any, index: number) => {
    // case remove image
    var resUploadImage: any

    if (file.file.status === 'removed') {
      listImages[index].fileList = []
      listImages[index].url = ''
      setListImages([...listImages])
      return
    }
    const isLt3M = file.file.type ? file.file.size / 1024 / 1024 < 3 : true
    const isJpgOrPng =
      file.file.type === 'image/jpeg' ||
      file.file.type === 'image/png' ||
      file.file.type === 'image/jpg'
    if (!isJpgOrPng) {
      notificationError('Bạn chỉ có thể upload ảnh có định dạng JPG/PNG/JPEG!')
      return
    } else if (!isLt3M) {
      notificationError('Dung lượng ảnh tối đa là 3MB!')
      return
    }
    // case uploading image
    if (file?.fileList[0]?.status === 'uploading') {
      listImages[index].fileList = file.fileList || []
      setListImages([...listImages])
    }
    // case upload image
    else if (file.file.status !== 'removed') {
      try {
        resUploadImage = await uploadImageToServer(file.fileList)
        listImages[index].fileList = [
          {
            status: 'done',
            size: '10000',
            type: 'image/jpeg',
            uid: index,
            url: resUploadImage?.location,
          },
        ]
        listImages[index].url = resUploadImage?.location
        setListImages([...listImages])
      } catch (error) {
        notificationError(
          'Tải ảnh thất bại, vui lòng kiểm tra kết nối và thử lại.'
        )
      }
    }
  }

  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin hình ảnh</b>
      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <Row>
        <Col style={{ fontSize: '16px' }} span={4}>
          <b style={{ color: 'red' }}>&#42;</b>
          <b> Hình ảnh sản phẩm: </b>
        </Col>
        <Col span={20}>
          <div className={styles.img_array} tabIndex={-1} id="image_product">
            {listImages.map((item: any, index: number) => {
              return index === 0 ? (
                <Upload
                  listType="picture-card"
                  accept="image/jpeg,image/png,image/jpg"
                  fileList={item?.fileList}
                  onPreview={handlePreviewImage}
                  onChange={(value: any) => {
                    handleChangeImage(value, index)
                  }}
                >
                  {item?.fileList.length >= 1 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ fontWeight: 600 }}>Ảnh bìa</div>
                    </div>
                  )}
                </Upload>
              ) : (
                <Upload
                  listType="picture-card"
                  accept="image/jpeg,image/png,image/jpg"
                  fileList={item?.fileList}
                  onPreview={handlePreviewImage}
                  onChange={(value: any) => {
                    handleChangeImage(value, index)
                  }}
                >
                  {item?.fileList.length >= 1 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ fontWeight: 600 }}>Ảnh số {index}</div>
                    </div>
                  )}
                </Upload>
              )
            })}
            {/* {listImages.length < 10 ? (
              <Button
                style={{
                  width: '104px',
                  height: '104px',
                  border: '1px dashed #d9d9d9 ',
                  backgroundColor: '#fafafa',
                  display: 'inline-block',
                }}
                onClick={() => {
                  setListImages([
                    ...listImages,
                    {
                      id: listImages.length,
                      fileList: [],
                      buffer: null,
                      url: '',
                    },
                  ])
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ fontWeight: 600 }}>Thêm ảnh</div>
                </div>
              </Button>
            ) : (
              ''
            )} */}
          </div>
        </Col>
      </Row>

      <Modal
        visible={isShowModalPreview}
        footer={null}
        onCancel={() => setShowModalPreview(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}
