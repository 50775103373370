import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, PageHeader } from 'antd'
import { ButtonDelete, ButtonFixed } from 'common/components/Buttons'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router'

export default function Header() {
  const history = useHistory()
  return (
    <div>
      <PageHeader
        onBack={() => {
          history.goBack()
        }}
        title="Đơn hàng chi tiết"
        extra={[
          <ButtonFixed
            icon={<CloseCircleOutlined />}
            text="Hủy"
            style={{ borderColor: 'red', color: 'red' }}
          />,
          <ButtonFixed
            icon={<CheckCircleOutlined />}
            text="Xác nhận"
            style={{ borderColor: '#00abba', color: '#00abba' }}
          />,
        ]}
      />
    </div>
  )
}
