import { Button, PageHeader } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router'

export default function HeaderBanner() {
  const history = useHistory()
  return (
    <div>
      <PageHeader
        title="Banner + tin tức"
        extra={[
          <ButtonAdd
            text="Thêm mới"
            onClickButton={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.ADD_NEWS}`,
              })
            }}
          />,
        ]}
      />
    </div>
  )
}
