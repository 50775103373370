import { Row, Col, DatePicker, Input } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import Icon from '@ant-design/icons'
import { ORDER_STATUS } from 'utils/constants'
import moment from 'moment'

const { RangePicker } = DatePicker
type Props = {
  dataFilter: any
  onSearchSubmit: (searchKey: string) => void
  onStatusOrder: (status: number) => void
  onTimeSubmit: (from_date: string | Date, to_date: string | Date) => void
  onStatusPayment: (is_paid: number) => void
  onTypePaymentOrder: (type_payment: number) => void
}

export default function FilterDetail({
  dataFilter,
  onSearchSubmit,
  onStatusOrder,
  onTimeSubmit,
  onStatusPayment,
  onTypePaymentOrder,
}: Props) {
  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Mã đơn hàng"
          addonAfter={<Icon type="close-circle-o" />}
          // value={data.search}
          onChange={(e: any) => {
            onSearchSubmit(e.target.value)
          }}
        />
      </Col>
      <Col span={4}>
        <RadiusSelection
          placeholder="PT thanh toán"
          onChange={value => {
            onTypePaymentOrder(value)
          }}
          options={[
            { value: 1, text: 'VNPay' },
            { value: 2, text: 'Chuyển khoản' },
            { value: 3, text: 'Công nợ' },
            { value: 4, text: 'Tiền mặt' },
          ]}
        />
      </Col>
      <Col span={4}>
        <RadiusSelection
          placeholder="TT Đơn hàng"
          onChange={value => {
            onStatusOrder(value)
          }}
          options={[
            { value: ORDER_STATUS.PENDING, text: 'Chờ xác nhận' },
            { value: ORDER_STATUS.CONFIRMED, text: 'Đang thực hiện' },
            { value: ORDER_STATUS.SUCCCESS, text: 'Hoàn thành' },
            { value: ORDER_STATUS.CANCELED, text: 'Hủy' },
          ]}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="TT Thanh toán"
          onChange={value => {
            onStatusPayment(value)
          }}
          options={[
            { value: 0, text: 'Chưa thanh toán' },
            { value: 1, text: 'Đã thanh toán' },
          ]}
        />
      </Col>
      <Col span={6}>
        <RangePicker
          value={[
            dataFilter.from_date ? moment(dataFilter.from_date) : null,
            dataFilter.to_date ? moment(dataFilter.to_date) : null,
          ]}
          showTime
          format="HH:mm YYYY-MM-DD"
          placeholder={['Từ ngày', 'Đến ngày']}
          onChange={(dates, dateStrings) => {
            onTimeSubmit(dateStrings[0], dateStrings[1])
          }}
        />
      </Col>
    </Row>
  )
}
