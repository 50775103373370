import { PageHeader, Table, Typography } from 'antd'
import Container from 'common/container/Container'
import Filter from './component/Filter'
import { formatPrice } from 'utils/ruleForm'
import ButtonExport from 'common/components/Buttons/ButtonExport'

const ShopReportScreen = () => {
  const { Text } = Typography
  const data = [
    {
      id: '1',
      shop: 'Cửa hàng phụ tùng, dầu nhớt EU',
      product_numb: '50',
      product_sale: '60',
      order_numb: '700',
      revenue: '5000000',
    },
    {
      id: '2',
      shop: 'Cửa hàng xăng dầu Ukraine',
      product_numb: '10',
      product_sale: '20',
      order_numb: '300',
      revenue: '1000000',
    },
    {
      id: '3',
      shop: 'Cửa hàng khí đốt Putin',
      product_numb: '10',
      product_sale: '20',
      order_numb: '300',
      revenue: '1000000',
    },
    {
      id: '4',
      shop: 'Cửa hàng xăng sinh học Biden',
      product_numb: '10',
      product_sale: '20',
      order_numb: '300',
      revenue: '1000000',
    },
    {
      id: '5',
      shop: 'Cửa hàng xăng dầu số 1 Việt Nam',
      product_numb: '10',
      product_sale: '20',
      order_numb: '300',
      revenue: '1000000',
    },
    {
      id: '6',
      shop: 'Cửa hàng xăng dầu Quatar',
      product_numb: '20',
      product_sale: '30',
      order_numb: '400',
      revenue: '2000000',
    },
    {
      id: '7',
      shop: 'Cửa hàng Chu Bá Thảo',
      product_numb: '30',
      product_sale: '40',
      order_numb: '500',
      revenue: '3000000',
    },
    {
      id: '8',
      shop: 'Cửa hàng dầu nhớt nhập khẩu chính hãng',
      product_numb: '40',
      product_sale: '50',
      order_numb: '600',
      revenue: '4000000',
    },
  ]

  const column = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: <b>Tên gian hàng</b>,
      dataIndex: 'shop',
      key: 'shop',
    },
    {
      title: <b>Số sản phẩm</b>,
      dataIndex: 'product_numb',
      key: 'product_numb',
    },
    {
      title: <b>SL đã bán</b>,
      dataIndex: 'product_sale',
      key: 'product_sale',
    },
    {
      title: <b>Số đơn hàng</b>,
      dataIndex: 'order_numb',
      key: 'order_numb',
    },
    {
      title: <b>Doanh thu đơn hàng</b>,
      dataIndex: 'revenue',
      key: 'revenue',
      render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)} đ</>,
    },
  ]
  return (
    <Container
      header={
        <PageHeader
          title="Báo cáo cửa hàng"
          extra={[
            <ButtonExport
              sheetName={['OrderLisst']}
              fileName="Danh sách đơn hàng"
              sheets={{}}
              // loading={isLoadingBtnExportData}
              // onClick={fn => onExportDataToExcel(fn)}
            />,
          ]}
        />
      }
      filterComponent={<Filter />}
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>100</b>
          </p>
          <Table
            bordered
            columns={column}
            dataSource={data}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            summary={pageData => {
              let totalProduct_numb = 0
              let totalProduct_sale = 0
              let totalOrder_numb = 0
              let totalRevenue = 0

              pageData.forEach(
                ({ product_numb, product_sale, order_numb, revenue }) => {
                  totalProduct_numb += parseInt(product_numb)
                  totalProduct_sale += parseInt(product_sale)
                  totalOrder_numb += parseInt(order_numb)
                  totalRevenue += parseInt(revenue)
                }
              )

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={2} align="center">
                      <b>Tổng</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <b>{formatPrice(totalProduct_numb)}</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <b>{formatPrice(totalProduct_sale)}</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <b>{formatPrice(totalOrder_numb)}</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <b>{formatPrice(totalRevenue)} đ</b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }}
            // sticky
            // pagination={{
            //   ...paging,
            //   showSizeChanger: false,
            //   onChange: async (page, pageSize) => {
            //     setParams({ ...params, page })
            //   },
            // }}
          />
        </div>
      }
    />
  )
}

export default ShopReportScreen
