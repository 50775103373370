import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin: 5px 10px 15px;
  // position: relative;
  // width: 100%;
  // max-width: 1280px;
  // max-width: 1600px;
  // margin-right: auto;
  // margin-left: auto;
  // overflow: hidden;

  // @media only screen and (max-width: 1024px) {
  //   max-width: 950px;
  // }

  // @media only screen and (max-width: 768px) {
  //   max-width: 700px;
  // }

  // @media only screen and (max-width: 414px) {
  //   max-width: 370px;
  // }
`
