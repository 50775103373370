import {
  DatePicker,
  Form,
  notification,
  PageHeader,
  Row,
  Spin,
  Table,
} from 'antd'
import SaveButton from 'common/components/Buttons/SaveButton'
import openNotificationWithIcon from 'common/components/Notification'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import { requestAllCategory } from 'features/admin/product/product/ProductApi'
import useDebounce from 'features/admin/report/sale/hook/useDebounce'
import React from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { ICategory, IProductInventory } from '../../Inventory'
import { IPaging } from '../../InventoryList'
import { getDetailWarehouse, updateANewProduct } from '../service'
import InputNumberInventory, {
  RECORD_UPDATE,
} from './components/InputNumber.Inventory'
import InputNumberLoss from './components/Inputnumber.Loss'
import UpdateInventoryFilter from './UpdateInventoryFilter'

const delay = (ms: any) => new Promise(res => setTimeout(res, ms))

const UpdateInventory = () => {
  // Danh sách sản phẩm
  const [listProduct, setListProduct] = React.useState<IProductInventory[]>([])

  const columns = [
    {
      width: 50,
      title: <b>STT</b>,
      dataIndex: 'stt',
      render: (text: any, record: any, index: any) => (
        <td id={record.id}>
          {currentPage === 1 ? ++index : (currentPage - 1) * 12 + ++index}
        </td>
      ),
    },
    {
      title: <b>Mã sản phẩm</b>,
      dataIndex: 'code',
    },
    {
      title: <b>Sản phẩm</b>,
      dataIndex: 'name',
    },
    {
      title: <b>Nhập kho</b>,
      dataIndex: 'beginInventory',
      render: (value: number) => {
        if (value > 0) {
          return <td>{formatPrice(value)}</td>
        } else if (value === 0) {
          return <td>{value}</td>
        } else return <td style={{ color: 'red' }}>{formatPrice(value)}</td>
      },
    },
    {
      title: <b>Cập nhật</b>,
      dataIndex: 'updateBeginInventory',
      render: (_: number, record: IProductInventory) => {
        return (
          <td style={{ display: 'flex' }}>
            <InputNumberInventory
              callback={callback}
              record={record}
              count={count}
              onUpdateBeginInventory={onUpdateBeginInventory}
            />
          </td>
        )
      },
    },
    {
      title: <b>Hao hụt</b>,
      dataIndex: 'loss',
      render: (value: number) => {
        if (value > 0) {
          return <span>{formatPrice(value)}</span>
        } else if (value === 0) {
          return <span>{value}</span>
        } else return <span>{formatPrice(value)}</span>
      },
    },
    {
      title: <b>Cập nhật</b>,
      dataIndex: 'updateLossPetrol',
      render: (_: any, record: IProductInventory) => {
        return (
          <td style={{ display: 'flex' }}>
            <InputNumberLoss
              count={count}
              record={record}
              callback={callback}
              onUpdateLoss={onUpdateLoss}
              copiedListProduct={copiedListProduct}
            />
          </td>
        )
      },
    },
    {
      title: <b>Ngày cập nhật</b>,
      dataIndex: 'input_date',
      render: (_: any, record: IProductInventory) => {
        return (
          <DatePicker
            format="HH:mm DD/MM/YYYY"
            showTime
            allowClear
            disabled={count >= RECORD_UPDATE && !record?.updateBeginInventory}
            onChange={(date, dateString) => {
              const inputDate =
                dateString.split(' ')[1].split('/').reverse().join('-') +
                ' ' +
                dateString.split(' ')[0]

              setListProduct(prev =>
                prev.map(item => {
                  if (item.id === record.id) {
                    return {
                      ...item,
                      input_date: inputDate,
                    }
                  }
                  return item
                })
              )
            }}
          />
        )
      },
    },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   render: (value: number, record: IProductInventory) => {
    //     return <SaveOutlined style={{ color: 'green', cursor: 'pointer' }} />
    //   },
    // },
  ]

  const location: any = useLocation()

  // Danh sách sản phẩm copied, set bằng giá trị hiện tại của listProduct trước khi lưu
  const [copiedListProduct, setCopiedListProduct] = React.useState<any>([])
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [count, setCount] = React.useState<number>(0)
  const [categories, setCategories] = React.useState<ICategory[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [search, setSearch] = React.useState<string>('')
  const [categoryId, setCategoryId] = React.useState<number>(-1)

  const [paging, setPaging] = React.useState<IPaging>({
    total: 0,
    current: currentPage,
    pageSize: 12,
  })

  const [form] = Form.useForm()

  const [callback, setCallback] = React.useState(false)
  const getDetail = async () => {
    try {
      setIsLoading(true)
      const res = await getDetailWarehouse(location?.state?.id, {
        // search: search === '' ? undefined : search,
        // category_id: categoryId === -1 ? undefined : categoryId,
        limit: 999,
      })
      let filteredData: any[] = []
      // if (searchDebounce !== '' && categoryId !== -1) {
      //   filteredData = res.data.filter(
      //     (product: any) =>
      //       product.category_id === categoryId &&
      //       product.product_name
      //         .toLowerCase()
      //         .includes(searchDebounce.toLocaleLowerCase())
      //   )
      // } else if (searchDebounce !== '' && categoryId === -1) {
      //   filteredData = res.data.filter((product: any) =>
      //     product.product_name
      //       .toLowerCase()
      //       .includes(searchDebounce.toLocaleLowerCase())
      //   )
      // } else if (searchDebounce === '' && categoryId !== -1) {
      //   filteredData = res.data.filter(
      //     (product: any) => product.category_id === categoryId
      //   )
      // } else {
      filteredData = res.data
      // }

      if (res.status) {
        const data = filteredData.map((product: any) => ({
          id: product.product_id,
          key: product.product_id,
          categoryId: product.category_id,
          code: product.product_code,
          name: product.product_name,
          beginInventory: Number(Number(product.receipt).toFixed(2)),
          loss: product.diminish || 0,
          updateBeginInventory: 0,
          updateLossPetrol: 0,
          show: true,
        }))
        setListProduct(data)
        setCopiedListProduct(data)
        const page = {
          total: res.meta.pagination.totalItems,
          current: res.meta.pagination.page,
          pageSize: res.meta.pagination.limit,
        }
        setCurrentPage(res.meta.pagination.page)
        setPaging(page)
      } else {
        openNotificationWithIcon(
          'error',
          'Thông báo',
          'Đã có lỗi xảy ra! Xin vui lòng thử lại'
        )
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getListCategory = async () => {
    try {
      const res: any = await requestAllCategory({ limit: 999 })
      const data = res.data.map((item: any) => {
        return { value: item.id, text: item.name }
      })
      setCategories(data)
    } catch (error) {
      console.log('ERROR:', error)
    }
  }

  const validateNegative = () => {
    const data = listProduct.find(
      (product: IProductInventory) =>
        product.beginInventory < 0 || product.loss < 0
    )
    if (data) {
      openNotificationWithIcon(
        'error',
        'Thất bại',
        'Tồn dầu và hao hụt không thể âm!'
      )
      return false
    }
    return true
  }

  const sendAlert = (indexes: string[]) => {
    listProduct.forEach((product: IProductInventory) => {
      if (indexes.includes(`${product.id}`)) {
        const element = document.getElementById(`loss_${product.id}`)
        if (element) {
          element.style.borderColor = 'red'
        }
      } else {
        const element = document.getElementById(`loss_${product.id}`)
        if (element) {
          element.style.borderColor = 'lightgray'
        }
      }
    })
  }

  const validateLoss = () => {
    let count: number = 0
    let indexes: string[] = []
    listProduct.forEach((product: IProductInventory, index: number) => {
      if (product.beginInventory <= copiedListProduct[index].beginInventory) {
        if (product.loss !== copiedListProduct[index].loss) {
          count += 1
          indexes.push(`${product.key}`)
        }
      } else if (
        product.beginInventory > copiedListProduct[index].beginInventory
      ) {
        if (product.loss !== copiedListProduct[index].loss) {
          if (
            product.beginInventory - copiedListProduct[index].beginInventory <=
            product.loss - copiedListProduct[index].loss
          ) {
            count += 1
            indexes.push(`${product.key}`)
          }
        }
      }
    })
    sendAlert(indexes)

    if (count !== 0) {
      openNotificationWithIcon(
        'error',
        'Thất bại',
        'Hao hụt không thể lớn hơn hoặc bằng nhập kho!'
      )
      return false
    }
    return true
  }

  const validateData = () => {
    if (!validateNegative() || !validateLoss()) {
      return false
    }
    return true
  }

  const helpFoundIndex = (list: any[], id: number) => {
    return list.findIndex((item: any) => item.id === id)
  }

  const onUpdateBeginInventory = (record: IProductInventory, value: number) => {
    const indexFounded = helpFoundIndex(listProduct, record?.id)
    if (indexFounded !== -1) {
      let newArray = [...listProduct]
      let newData = { ...listProduct[indexFounded] }
      newData.beginInventory = Number(
        (
          Number(copiedListProduct[indexFounded].beginInventory) + +value
        ).toFixed(2)
      )

      newData.updateBeginInventory = value
      newArray[indexFounded] = newData
      setListProduct(newArray)
      let check: number = 0
      newArray.forEach((product: IProductInventory, index: number) => {
        if (
          product.beginInventory !== copiedListProduct[index].beginInventory
        ) {
          check += 1
        }
      })
      setCount(check)
    }
  }

  const onUpdateLoss = (record: IProductInventory, value: number) => {
    const indexFounded = helpFoundIndex(listProduct, record?.id)
    if (indexFounded !== -1) {
      let newArray = [...listProduct]
      let newData = { ...listProduct[indexFounded] }
      newData.loss = Number(
        (+copiedListProduct[indexFounded].loss + +value).toFixed(2)
      )
      newData.updateLossPetrol = value
      newArray[indexFounded] = newData
      setListProduct(newArray)
    }
  }

  const fetchDataUpdate = async (payload: any) => {
    try {
      const res = await updateANewProduct(location?.state?.id, payload)
      if (res?.status) {
        return res
      }

      return null
    } catch {
      return null
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      setCallback(!callback)
      const changedProducts: IProductInventory[] = []

      let check: number = 0
      listProduct.forEach((product: any, index: number) => {
        if (
          product.beginInventory > copiedListProduct[index].beginInventory ||
          product?.updateLossPetrol > copiedListProduct[index]?.updateLossPetrol
        ) {
          // tăng
          product.type = 1
          changedProducts.push(product)
        } else if (
          product.beginInventory < copiedListProduct[index].beginInventory ||
          product?.updateLossPetrol < copiedListProduct[index]?.updateLossPetrol
        ) {
          // giảm
          product.type = 2
          changedProducts.push(product)
        }
      })

      const dataSend = changedProducts.map(
        (product: IProductInventory, index: number) => {
          return {
            type: product.type,
            diminish: product.categoryId === 1 ? product.loss : undefined,
            receipt: product.beginInventory,
            category_id: product.categoryId,
            product_id: product.id,
            input_date: product?.input_date,
          }
        }
      )

      let countSuccess = 0
      let countError = 0

      for (let i = 0; i < dataSend.length; i++) {
        const res = await fetchDataUpdate(dataSend[i])
        if (res?.status) {
          countSuccess += 1
        } else {
          countError += 1
        }
        if (i < dataSend.length - 1) {
          await delay(300)
        }

        if (i === changedProducts.length - 1) {
          getDetail()
          notification.open({
            message: 'Kết quả cập nhật',
            description: (
              <div>
                Cập nhật hành công{' '}
                <strong style={{ color: 'green' }}>{countSuccess}</strong> -
                Thất bại <strong style={{ color: 'red' }}>{countError}</strong>
              </div>
            ),
          })
          form.resetFields()
          setCount(0)
        }
      }
    } catch (error) {
      console.log('ERROR: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const searchDebounce = useDebounce(search, 300)

  React.useEffect(() => {
    getDetail()
    // }, [searchDebounce, categoryId])
  }, [])

  React.useEffect(() => {
    getListCategory()
  }, [])

  React.useEffect(() => {
    if (categoryId == -1 && !searchDebounce)
      return setListProduct((prev: any) =>
        prev.map((prd: any) => ({
          ...prd,
          show: true,
        }))
      )
    setListProduct((prev: any) => {
      return prev.map((prd: any) => {
        // nếu filter chỉ có category và không có search
        if (prd?.categoryId === categoryId && !searchDebounce) {
          return {
            ...prd,
            show: true,
          }
        }

        // nếu search k filter
        if (
          categoryId == -1 &&
          searchDebounce &&
          (prd?.name
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchDebounce?.toLowerCase()) ||
            prd?.code
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchDebounce?.toLowerCase()))
        ) {
          return {
            ...prd,
            show: true,
          }
        }

        if (
          prd?.categoryId === categoryId &&
          searchDebounce &&
          (prd?.name
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchDebounce?.toLowerCase()) ||
            prd?.code
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchDebounce?.toLowerCase()))
        ) {
          return {
            ...prd,
            show: true,
          }
        }

        return {
          ...prd,
          show: false,
        }
      })
    })
  }, [categoryId, searchDebounce])

  // React.useEffect(() => {
  //   if (!searchDebounce)
  //     return setListProduct((prev: any) => {
  //       return prev.map((prd: any) => ({
  //         ...prd,
  //         show: true,
  //       }))
  //     })
  //   setListProduct((prev: any) => {
  //     return prev.map((prd: any) =>
  //       prd?.name
  //         ?.toString()
  //         ?.toLowerCase()
  //         ?.includes(searchDebounce?.toLowerCase()) ||
  //       prd?.code
  //         ?.toString()
  //         ?.toLowerCase()
  //         ?.includes(searchDebounce?.toLowerCase())
  //         ? {
  //             ...prd,
  //             show: true,
  //           }
  //         : {
  //             ...prd,
  //             show: false,
  //           }
  //     )
  //   })
  // }, [searchDebounce])

  return (
    <Form form={form} onFinishFailed={(err: any) => {}} onFinish={handleSubmit}>
      <Container
        header={
          <PageHeader
            title={`Cập nhật kho tại ${location.state.inventoryDetailName}`}
            extra={[
              <SaveButton
                text="Lưu"
                // onClickButton={handleSubmit}
                htmlType="submit"
                isDisable={listProduct.length === 0}
              />,
            ]}
            onBack={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.INVENTORY_DETAIL}/${location.state.inventoryDetailId}`,
                state: {
                  inventoryDetailId: location.state.inventoryDetailId,
                  inventoryDetailName: location.state.inventoryDetailName,
                  search: location?.state.search,
                  currentPage: location?.state.currentPage,
                  id: location?.state.id,
                  currentPageList: location?.state?.currentPageList,
                  categoryId: location?.state?.categoryId,
                  fromDate: location?.state?.fromDate,
                  toDate: location?.state?.toDate,
                },
              })
            }}
          />
        }
        filterComponent={
          <UpdateInventoryFilter
            categories={categories}
            setCategoryId={setCategoryId}
            setSearch={setSearch}
          />
        }
        contentComponent={
          <Spin spinning={isLoading}>
            <div>
              <Row
                style={{
                  width: '100%',
                  marginBottom: 20,
                }}
                justify="end"
              ></Row>
              <Row style={{ justifyContent: 'space-between' }}>
                <p>
                  Kết quả lọc: <b>{listProduct.length}</b>
                </p>
                <p>
                  Thay đổi:{' '}
                  <b>
                    {count}/{RECORD_UPDATE}
                  </b>
                </p>
              </Row>
              <Table
                bordered
                columns={columns}
                dataSource={
                  !isLoading ? listProduct?.filter((prd: any) => prd.show) : []
                }
                scroll={{
                  x: 800,
                  scrollToFirstRowOnChange: true,
                }}
                pagination={{
                  ...paging,
                  pageSize: 12,
                  showSizeChanger: false,
                  onChange: (page: number) => {
                    setCurrentPage(page)
                    setPaging({ ...paging, current: page })
                  },
                }}
              />
            </div>
          </Spin>
        }
      />
    </Form>
  )
}

export default UpdateInventory
