import { Descriptions, Tag } from 'antd'
import styles from '../../orders/styles/style.module.css'
export default function ReceiverInfor() {
  return (
    <Descriptions
      title={
        <div className={styles.title}>
          <b>Thông tin Người nhận hàng</b>
          <span>
            &nbsp;
            <Tag color="volcano">Lấy tại cửa hàng</Tag>
          </span>
        </div>
      }
      column={1}
    >
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Tên người nhận</span>}
      >
        Nguyễn Minh Tú
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Số điện thoại</span>}
      >
        099999999
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Địa chỉ chi tiết</span>}
      >
        số 1, đường Láng, P.Láng, Q.Thanh Xuấn, TP.Hà Nội
      </Descriptions.Item>
    </Descriptions>
  )
}
