import { ApiClient } from 'services/ApiService'

export const getListNews = (payload: any) =>
  ApiClient.get('/admin/config-banner', payload)
export const getDetailBanner = (banner_id: number) =>
  ApiClient.get(`/admin/config-banner/${banner_id}`)
export const createNews = (payload: any) =>
  ApiClient.post('/admin/config-banner', payload)
export const updateNews = (payload: any) =>
  ApiClient.put(`/admin/config-banner/${payload.id}`, payload.data)
export const changeStatusNews = (payload: any) =>
  ApiClient.patch(`/admin/config-banner/${payload.id}/status`, payload.status)
export const deleteNews = (payload: number) =>
  ApiClient.delete(`admin/config-banner/${payload}`)
