import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { notificationError } from 'utils/notification'
import { TGeneralInforProduct } from '../Product'
import { getUnits, requestDetailProduct } from '../ProductApi'

export const useDetailProduct = () => {
  const param: { id: string } = useParams()
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const [productData, setProductData] = useState<TGeneralInforProduct>({
    code: '',
    name: '',
    price: 0,
    category_id: undefined,
    category_name: '',
    category_status: 0,
    description: '',
    status: 1,
    quantity_home: 0,
    status_home: 0,
    save_status: 0,
    min_quantity: 0,
    max_quantity: 0,
    expire_time: 0,
    unit: undefined,
    stt: undefined,
  })
  const [dataSource, setDataSource] = useState<Array<any>>([])

  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 0,
  })

  const [listImages, setListImages] = useState<Array<any>>(
    Array.from(Array(4).keys()).map(i => {
      return {
        id: i,
        fileList: [],
        buffer: null,
        url: '',
      }
    })
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    getDataProduct()
  }, [param])

  const getDataProduct = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await requestDetailProduct({ product_id: parseInt(param.id) })
      const resUnit = await getUnits()
      const targetUnit = resUnit.data.filter(
        (item: any) => item.id === res?.data?.product_unit_id
      )

      setProductData({
        ...productData,
        name: res?.data?.name,
        code: res?.data?.code,
        price: res?.data?.price,
        category_name: res?.data?.product_category.name,
        category_status: res?.data?.product_category.status,
        status: res?.data?.status,
        description: res?.data?.description,
        quantity_home: res?.data?.quantity_home,
        status_home: res?.data?.status_home,
        stt: res?.data?.stt,
        // unit: res?.data?.unit,
        unit: targetUnit.length > 0 ? targetUnit[0].name : '---',
        save_status: res?.data?.save_status,
        min_quantity: res?.data?.min_quantity,
        max_quantity: res?.data?.max_quantity,
        expire_time: res?.data?.expire_time,
      })
      const images = res?.data?.product_medias.map(
        (item: any, index: number) => {
          return {
            id: index,
            fileList: [{ url: item.media_url }],
            buffer: null,
            url: item.media_url,
          }
        }
      )
      setListImages(images)
      const dataTable = res?.data?.product_stocks.map((item: any) => {
        return {
          name: item?.petrol_store?.name,
          location_address:
            item?.petrol_store?.location_address +
            ', ' +
            item?.petrol_store?.ward?.name +
            ', ' +
            item?.petrol_store?.district?.name +
            ', ' +
            item?.petrol_store?.province?.name,
          ward_name: item?.petrol_store?.ward?.name,
          district_name: item?.petrol_store?.district?.name,
          province_name: item?.petrol_store?.province?.name,
          price: item?.price,
          status: item?.status,
          pertrol_store_id: item?.id,
        }
      })
      setDataSource(dataTable)
    } catch (error: any) {
      notificationError(error)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  return {
    productData,
    paging,
    listImages,
    isLoading,
    dataSource,
    setDataSource,
    setProductData,
    setListImages,
    setPaging,
    setIsLoading,
  }
}
