import { Col, DatePicker, message, Row, Select } from 'antd'
import React from 'react'
import { formatPrice } from 'utils/ruleForm'
import { IDetailFilter } from '../ReportPoint'
import { getCurrentPoint } from '../ReportPointApi'

const DetailFilter = (props: IDetailFilter) => {
  const { setActionType, setFromDate, setToDate, id } = props
  const [currentPoint, setCurrentPoint] = React.useState<number>(0)

  const handleChange = (value: number) => {
    setActionType(value)
  }

  const onChangeDate = (date: any, formatString: string[]) => {
    const fromDate = formatString[0].split('/').reverse().join('-')
    const toDate = formatString[1].split('/').reverse().join('-')
    setFromDate(fromDate)
    setToDate(toDate)
  }

  const getPoint = async () => {
    try {
      const res = await getCurrentPoint(id)
      if (res.status) {
        setCurrentPoint(res.data.point)
      } else {
        message.error('Đã có lỗi xảy ra! Xin vui lòng thử lại!')
      }
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  React.useEffect(() => {
    getPoint()
  }, [])

  return (
    <Row gutter={[16, 16]}>
      <Col span={4} style={{ paddingTop: '12px' }}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Tác vụ"
          onChange={handleChange}
        >
          <Select.Option value={1}>Cộng điểm</Select.Option>
          <Select.Option value={0}>Trừ điểm</Select.Option>
        </Select>
      </Col>
      <Col span={7} style={{ paddingTop: '12px' }}>
        <DatePicker.RangePicker
          style={{ width: '100%' }}
          format={'DD/MM/YYYY'}
          onCalendarChange={onChangeDate}
        />
      </Col>
      <Col span={13} style={{ paddingTop: '14px' }}>
        <div>
          <p style={{ float: 'right', fontSize: 16 }}>
            Tổng điểm hiện tại: <b>{formatPrice(currentPoint)}</b>
          </p>
        </div>
      </Col>
    </Row>
  )
}

export default DetailFilter
