import { Layout } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import Menu from 'common/components/Header'
import { SESSION_KEY } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import LoginScreen from 'features/auth/Login'
import Cookie from 'js-cookie'
import { useDispatch } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import history from 'utils/history'
import Nav from './Nav'
import PrivateRoute from './PrivateRoute'
const { Sider } = Layout

export default function AppNavigator() {
  const cookie = Cookie.get(SESSION_KEY.SESSION)
  const dispatch = useDispatch()
  if (cookie) dispatch(getUserInfoAction())

  const MainNavigator = () => (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          breakpoint="xl"
          collapsible
          theme="light"
          width={240}
        >
          <Menu />
        </Sider>
        <Layout>
          <Header
            style={{ padding: 0, background: '#dddd', height: 50 }}
          ></Header>
          <Nav />
        </Layout>
      </Layout>
    </>
  )
  return (
    <Router history={history}>
      <Switch>
        <Route path={'/login'} exact component={LoginScreen} />
        {/* <Route path={'/webViewVNP'} exact component={WebViewVNP} /> */}
        <PrivateRoute path="/" component={MainNavigator} />
      </Switch>
    </Router>
  )
}
