import { useEffect, useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import { getListCategory } from '../CategoryProductApi'

type Params = {
  search: undefined | string
  page: number
  status: undefined | number
  from_date: undefined | string
  to_date: undefined | string
  limit: number
}
export const useCategoryProduct = () => {
  const [category, setCategory] = useState<any>([])
  const [isLoading, setIsLoading] = useState({
    loadingTable: false,
    loadingSearch: false,
    loadingAddEdit: false,
  })
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [params, setParams] = useState<Params>({
    search: undefined,
    page: 1,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    limit: 12,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setIsLoading({ ...isLoading, loadingTable: true })
    try {
      const res = await getListCategory(params)
      const tableData = res.data.map((item: any) => {
        return { ...item, key: item.id }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setCategory(tableData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false, loadingSearch: false })
    }
  }

  return {
    category,
    isLoading,
    currentSelected,
    params,
    paging,
    setIsLoading,
    setPaging,
    setParams,
    setCurrentSelected,
    setCategory,
    getData,
  }
}
