import { notification } from 'antd'

export const notificationSuccess = (message: string) => {
  notification.open({
    message: message,
    className: 'custom-class-notification',
    style: {
      width: 'auto',
      backgroundColor: '#50C783',
    },
    duration: 1.6,
  })
}

export const notificationError = (message: string) => {
  notification.open({
    message: message,
    className: 'custom-class-notification',
    style: {
      width: 'auto',
      backgroundColor: '#C75050',
    },
    duration: 1.6,
  })
}

export const notificationWarning = (message: string) => {
  notification.open({
    message: message,
    className: 'custom-class-notification',
    style: {
      width: 'auto',
      backgroundColor: '#E8852E',
    },
    duration: 1.6,
  })
}
export const notificationNote = (message: string) => {
  notification.open({
    message: message,
    className: 'note-notification',
    style: {
      width: 'auto',
      fontWeight: 600,
      // backgroundColor: '#bae7ff',
      backgroundColor: '#e6f7ff',
      borderStyle: 'solid',
      borderWidth: 2,
      // borderColor: '#0050b3',
      borderColor: '#91d5ff',
      borderRadius: 5,
    },
    duration: 4,
  })
}
