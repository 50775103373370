import { Descriptions } from 'antd'
import styles from '../../orders/styles/style.module.css'
export default function Insurance() {
  return (
    <Descriptions
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <b>Thông tin bảo hiểm xe</b>
          {/* <Divider /> */}
        </div>
      }
      column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
    >
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Tên Công ty</span>}
      >
        Mui trần đi mưa
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}>Hãng xe</span>}
      >
        Ford`
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Phương tiện</span>}
      >
        Oto
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Biển số xe</span>}
      >
        29A-1234
      </Descriptions.Item>
    </Descriptions>
  )
}
