import { Col, Row, DatePicker, Tooltip } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { TYPE_DEBT } from 'utils/constants'

const { RangePicker } = DatePicker
type Props = {
  onActionSubmit: (actionKey: number) => void
  onPaymentDateSubmit: (
    payment_from_date?: string | Date,
    payment_to_date?: string | Date
  ) => void
  onDateSubmit: (from_date?: string | Date, to_date?: string | Date) => void
  params?: any
  setParams?: any
}
export default function FilterDetail({
  onActionSubmit,
  onPaymentDateSubmit,
  onDateSubmit,
  params,
  setParams,
}: Props) {
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={4}>
        <RadiusSelection
          placeholder="Tác vụ"
          options={[
            { value: TYPE_DEBT.COMPLETE_ORDER_DEBT, text: 'KH thanh toán' },
            { value: TYPE_DEBT.INCREASE_DEBT, text: 'Tăng dư nợ' },
            { value: TYPE_DEBT.CANCEL_ORDER_DEBT, text: 'Hủy đơn' },
          ]}
          onChange={(value: number) => {
            onActionSubmit(value)
          }}
        />
      </Col>
      {/* <Col span={8}>
        <RadiusSelection
          placeholder="Tên cửa hàng"
          options={[
            { value: TYPE_DEBT.COMPLETE_ORDER_DEBT, text: 'Cửa hàng 1' },
            { value: TYPE_DEBT.INCREASE_DEBT, text: 'Cửa hàng 2' },
            { value: TYPE_DEBT.CANCEL_ORDER_DEBT, text: 'Cửa hàng 3' },
          ]}
          onChange={(value: number) => {
            onActionSubmit(value)
          }}
        />
      </Col> */}
      <Tooltip title="Hạn thanh toán" placement="topLeft">
        <Col span={6}>
          <RangePicker
            placeholder={['Từ ngày', 'Đến ngày']}
            onChange={(dates: any, dateString: any) => {
              onPaymentDateSubmit(dateString[0], dateString[1])
            }}
          />
        </Col>
      </Tooltip>
      <Tooltip title="Ngày tạo" placement="topLeft">
        <Col span={6}>
          <RangePicker
            placeholder={['Từ ngày', 'Đến ngày']}
            format="HH:mm DD/MM/YYYY"
            showTime={{ format: 'HH:mm' }}
            onChange={(dates: any, dateString) => {
              if (
                dates &&
                moment(dates[0]).format('YYYY-MM-DD HH:mm') ===
                  moment(dates[1]).format('YYYY-MM-DD HH:mm')
              ) {
                setParams({
                  ...params,
                  from_date: dates
                    ? moment(dates[0])?.seconds(0).format('YYYY-MM-DD HH:mm:ss')
                    : '',
                  to_date: dates
                    ? moment(dates[0])
                        ?.seconds(0)
                        .add(59, 'seconds')
                        .format('YYYY-MM-DD HH:mm:ss')
                    : moment().format('YYYY-MM-DD HH:mm:ss'),
                  page: 1,
                })
              } else {
                setParams({
                  ...params,
                  from_date: dates
                    ? `${dateString[0]
                        .split(' ')[1]
                        .split('/')
                        .reverse()
                        .join('-')} ${dateString[0].split(' ')[0]}`
                    : '',
                  to_date: dates
                    ? `${dateString[1]
                        .split(' ')[1]
                        .split('/')
                        .reverse()
                        .join('-')} ${dateString[1].split(' ')[0]}`
                    : moment().format('YYYY-MM-DD HH:mm:ss'),
                  page: 1,
                })
              }
            }}
            // value={
            //   params.from_date
            //     ? [moment(params.from_date), moment(params.to_date)]
            //     : null
            // }
            // onChange={(dates: any, dateString: any) => {
            //   onDateSubmit(
            //     dates
            //       ? `${dateString[0]
            //           .split(' ')[0]
            //           .split('/')
            //           .reverse()
            //           .join('-')} ${dateString[0].split(' ')[1]}`
            //       : '',
            //     dates
            //       ? `${dateString[1]
            //           .split(' ')[0]
            //           .split('/')
            //           .reverse()
            //           .join('-')} ${dateString[1].split(' ')[1]}`
            //       : ''
            //   )
            // }}
          />
        </Col>
      </Tooltip>
    </Row>
  )
}
