import { Col, DatePicker, Input, Row } from 'antd'
import Icon from '@ant-design/icons'
import { RadiusSelection } from 'common/components/Inputs'
import { ENTERPRISE_REQUEST_STATUS, IS_ACTIVE } from 'utils/constants'
import moment from 'moment'
const { RangePicker } = DatePicker
const { Search } = Input

type Props = {
  onSearchSubmit: (searchKey: string) => void
  onTimeSubmit: (create_at: string | Date, to_date: string | Date) => void
  onStatusSubmit: (status: number) => void
}

export default function Filter({
  onSearchSubmit,
  onTimeSubmit,
  onStatusSubmit,
}: Props) {
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={12}>
        <Search
          allowClear
          addonAfter={<Icon type="close-circle-o" />}
          placeholder="Tên cửa hàng, số điện thoại, mã số thuế"
          onChange={e => {
            onSearchSubmit(e.target.value)
          }}
        />
      </Col>

      <Col span={6}>
        <RadiusSelection
          placeholder="Trạng thái"
          onChange={(value: number) => {
            onStatusSubmit(value)
          }}
          options={[
            { value: ENTERPRISE_REQUEST_STATUS.PENDING, text: 'Chờ phê duyệt' },
            { value: ENTERPRISE_REQUEST_STATUS.ACCEPT, text: 'Phê duyệt' },
            { value: ENTERPRISE_REQUEST_STATUS.REJECT, text: 'Từ chối' },
          ]}
        />
      </Col>

      <Col span={6}>
        <RangePicker
          allowClear
          style={{ width: '100%' }}
          format="YYYY-MM-DD"
          onChange={(value, dateStrings) => {
            // onTimeSubmit(value ? moment(value).format('MMMM Do YYYY') : '')
            console.log('Date string: ', dateStrings)
            if (dateStrings[0] !== '') {
              onTimeSubmit(dateStrings[0], dateStrings[1])
            } else {
              onTimeSubmit('', '')
            }
          }}
        />
      </Col>
    </Row>
  )
}
