import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Spin,
} from 'antd'
import styles from 'common/components/styles/WhiteBox.module.css'
import { ItemRefuelHistory } from 'features/enterprise/order/OrderDetailScreen'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { notificationError, notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { formatedDate } from 'utils/TimerHelper'
import CustomerInfor from './components/CustomerInfo'
import HeaderDetail from './components/HeaderDetail'
import HistoryOrder from './components/HistoryOrder'
import HistoryRefueling from './components/HistoryRefueling'
import Insurance from './components/Insurance'
import OrderInfor from './components/OrderInfor'
import ProductList from './components/ProductList'
import Receipt from './components/Receipt'
import ReceiverInfor from './components/ReceiverInfor'
import {
  addHistoryBill,
  changeStatus,
  getCurrentLit,
  getDetailOrder,
  getRefuelHistory,
} from './OrderApi'
export interface IPaging {
  page: number
  limit: number
  totalItems: number
}
export default function OrderDetailScreen() {
  const param: any = useParams()
  const location: any = useLocation()
  const [form] = Form.useForm()
  const [denyStatus, setDenyStatus] = useState(false)
  const [fullTank, setFullTank] = useState(false)
  const [actualPayment, setActualPayment] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [detailOrder, setDetailOrder] = useState<any>({})
  const [refuelList, setRefuelList] = useState<ItemRefuelHistory[]>([])
  const [isAddPetrolModal, setIsAddPetrolModal] = useState<boolean>(false)
  const [curentRemainLit, setCurrentRemainLit] = useState<number>(0)
  const [homeQuantity, setHomeQuantity] = useState<number>(0)
  const [refuellit, setRefuelLit] = useState<number>(0)
  const [remainLit, setRemainLit] = useState<number>(curentRemainLit)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paging, setPaging] = useState<IPaging>({
    page: 1,
    limit: 6,
    totalItems: 0,
  })
  const [orderAddress, setOrderAddress] = useState<number>(-1)
  const [saveStatus, setSaveStatus] = useState<number>(-1)

  const getDataDetail = async () => {
    try {
      setIsLoading(true)
      const res = await getDetailOrder(param.id)

      const dataDetail = {
        id: res.data?.detailOrder?.id,
        code: res.data?.detailOrder?.code,
        nameCustomer: res.data?.detailOrder?.user.name,
        nameReceiver: res.data?.detailOrder?.address?.name,
        nameCompany: res.data?.detailOrder?.user?.enterprise?.name,
        taxCompany: res.data?.detailOrder?.user?.enterprise?.tax,
        phoneCustomer: res.data.detailOrder?.user.phone,
        phoneReceiver: res.data?.detailOrder?.address?.phone,
        addressShop:
          res.data?.detailOrder?.petrol_store?.location_address +
          ', ' +
          res.data?.detailOrder?.petrol_store?.ward?.name +
          ', ' +
          res.data?.detailOrder?.petrol_store?.district?.name +
          ', ' +
          res.data?.detailOrder?.petrol_store?.province?.name,
        shop: res.data?.detailOrder?.petrol_store?.name,
        phoneShop: res.data?.detailOrder?.petrol_store?.phone,
        tax: res.data?.detailOrder?.petrol_store?.tax,
        addressReceiver: res.data?.detailOrder?.address?.address,
        email: res.data?.detailOrder?.petrol_store.email,
        owner: res.data?.detailOrder?.order_insurance?.name,
        vehicle_brand: res.data?.detailOrder?.order_insurance?.vehicle_brand,
        number_plate: res.data?.detailOrder?.order_insurance?.number_plate,
        total_amount: res.data?.detailOrder?.total_amount,
        type_payment: res.data?.detailOrder?.order_payment?.type_payment.name,
        type_payment_id: res.data?.detailOrder?.order_payment?.type_payment_id,
        is_paid: res.data?.detailOrder?.order_payment?.is_paid,
        total_price: res.data?.detailOrder?.total_price,
        user_address_id: res.data?.detailOrder?.user_address_id,
        points_received: res.data?.detailOrder?.points_received,
        points_used: res.data?.detailOrder?.points_used,
        license_plate: res.data?.detailOrder?.user_vehicle?.number_plate,
        type_vehicle:
          res.data?.detailOrder?.user_vehicle?.type_vehicle_id === 1
            ? 'Xe máy'
            : 'Ô tô',
        f_user_address: res.data?.detailOrder?.f_user_address?.address,
        status: res.data?.detailOrder?.status,
        order_items: res.data?.detailOrder?.order_items.map(
          (item: any, index: number) => ({ ...item, stt: index + 1 })
        ),
        productName: res.data?.detailOrder?.order_items?.[0].product?.name,
        price_reality: res.data?.detailOrder?.order_items?.[0].price_reality,
        nameReceipt: res.data?.detailOrder?.order_receipt?.name,
        taxReceipt: res.data?.detailOrder?.order_receipt?.tax,
        addressReceipt: res.data?.detailOrder?.order_receipt?.address,
        emailReceipt: res.data?.detailOrder?.order_receipt?.email,
        history: res.data?.listOrderHistory,
        insurance: res.data?.detailOrder?.order_insurance,
        order_receipt: res.data?.detailOrder?.order_receipt,
        detailOrder: res.data?.detailOrder,
        reason: res.data?.detailOrder?.reason,
        price_per_liter: res.data?.detailOrder?.order_gasoline?.price_per_liter,
        gas_price: res.data?.detailOrder?.order_receipt_gasoline?.total_price,
        order_gasoline: res.data?.detailOrder?.order_gasoline,
        is_full_fuel: res.data?.detailOrder?.order_gasoline?.is_full_fuel,
        order_receipt_gasoline: res.data?.detailOrder?.order_receipt_gasoline,
        total_price_reality:
          res.data?.detailOrder?.order_receipt_gasoline?.total_price_reality,
        total_price_receipt:
          res.data?.detailOrder?.order_receipt_gasoline?.total_price,
        price: res.data?.detailOrder?.order_receipt_gasoline?.price,
        amount: Number(
          res.data?.detailOrder?.order_receipt_gasoline?.amount
        ).toFixed(3),
        saveStatus: res?.data?.detailOrder?.save_status,
        product_price: res.data?.detailOrder?.order_items?.[0].product_price,
        productId: res.data?.detailOrder?.order_items?.[0].product_id,
      }
      setSaveStatus(res?.data?.detailOrder?.save_status)
      setHomeQuantity(res.data.detailOrder.order_items[0].product.quantity_home)
      setDetailOrder(dataDetail)
      setIsLoading(false)
      setOrderAddress(res?.data?.detailOrder?.address)
      console.log(
        'res?.data?.detailOrder?.address: ',
        res?.data?.detailOrder?.address
      )
    } catch (error) {
      console.log('error', error)
    }
  }

  const onGetRefuelHistory = async () => {
    if (!location?.state?.orderId) return
    try {
      const res = await getRefuelHistory(
        location?.state?.orderId,
        location?.state?.userId,
        location?.state?.storeId,
        currentPage
      )
      if (res.status) {
        const data = res.data.map((item: any) => {
          return {
            id: item.id,
            productName: item.product_name,
            refueledLit: item.amount,
            remainedLit: item.remain,
            status: item.status,
            date: formatedDate(item.create_at),
          }
        })
        const page = {
          page: res.meta.pagination.page,
          totalItems: res.meta.pagination.totalItems,
          limit: res.meta.pagination.limit,
        }
        setPaging(page)
        setRefuelList(data)
      }
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  const onChangeStatus = async () => {
    setIsLoading(true)
    try {
      const res = await changeStatus(param.id, {
        status: 2,
        reason: '',
      })
      if (res.code === 200) {
        notificationSuccess(`Thay đổi trạng thái thành công`)
        getDataDetail()
        setIsLoading(false)
      }
      // notificationSuccess(`Thay đổi trạng thái thành công`)
      // window.location.reload()
    } catch (error) {
      notificationError(`Đã có lỗi xảy ra!`)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onCompleteStatus = async () => {
    setIsLoading(true)
    try {
      const res = await changeStatus(param.id, {
        status: 4,
        reason: '',
      })
      if (res.code === 200) {
        notificationSuccess(`Thay đổi trạng thái thành công`)
        getDataDetail()
        setIsLoading(false)
      }
    } catch (error) {
      notificationError(`Đã có lỗi xảy ra! Xin vui lòng thử lại!`)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onDenyStatus = async (data: any) => {
    setIsLoading(true)
    try {
      const res = await changeStatus(param.id, data)
      if (res.code === 200) {
        // window.location.reload()
        notificationSuccess(`Thay đổi trạng thái thành công`)
        getDataDetail()
        setIsLoading(false)
      }
      // notificationSuccess(`Thay đổi trạng thái thành công`)
      // window.location.reload()
    } catch (error) {
      notificationError(`Đã có lỗi xảy ra!`)
    } finally {
      setIsLoading(false)
      setDenyStatus(false)
    }
  }

  const getRemainLit = async () => {
    try {
      const res = await getCurrentLit(param.id)
      if (res.status) {
        const data = res.data.detailOrder?.order_items[0].remain
        setCurrentRemainLit(data)
        // if (homeQuantity >= data && orderAddress) {
        //   form.setFieldsValue({
        //     refueledLit: curentRemainLit,
        //     remainLit: 0,
        //   })
        // }
      } else {
        message.error('Đã có lỗi xảy ra, xin vui lòng thử lại!')
      }
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  const submitForm = async () => {
    try {
      setIsLoading(true)
      // let dataPush
      // if (homeQuantity >= curentRemainLit && orderAddress) {
      //   dataPush = {
      //     product_id: detailOrder.productId,
      //     price: detailOrder.product_price,
      //     amount: curentRemainLit,
      //     remain: 0,
      //   }
      // } else {
      //   dataPush = {
      //     product_id: detailOrder.productId,
      //     price: detailOrder.product_price,
      //     amount: refuellit,
      //     remain: remainLit,
      //   }
      // }
      const dataPush = {
        product_id: detailOrder.productId,
        price: detailOrder.product_price,
        amount: refuellit,
        remain: remainLit,
      }
      const res = await addHistoryBill(
        location.state.orderId,
        location.state.userId,
        location.state.storeId,
        dataPush
      )
      if (res.status) {
        setIsAddPetrolModal(false)
        form.setFieldsValue({
          refueledLit: '',
          // remainLit: curentRemainLit,
        })
        if (remainLit === 0) {
          getDataDetail()
        }
        message.success('Thêm lịch sử đổ xăng thành công!')
        onGetRefuelHistory()
        getRemainLit()
      } else {
        message.error('Thêm lịch sử đổ xăng thất bại!')
      }
    } catch (error) {
      console.log('ERROR: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    setIsAddPetrolModal(false)
    form.setFieldsValue({
      refueledLit: '',
      remainLit: curentRemainLit,
    })
  }

  const showModal = () => {
    setIsAddPetrolModal(true)
    getRemainLit()
  }

  useEffect(() => {
    setPaging({
      ...paging,
      page: currentPage,
    })
  }, [currentPage])

  useEffect(() => {
    if (param.id) getDataDetail()
    getRemainLit()
    onGetRefuelHistory()
  }, [])

  const handleCallbackDetail = () => {
    getDataDetail()
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeaderDetail
          actualPayment={actualPayment}
          setActualPayment={setActualPayment}
          fullTank={fullTank}
          setFullTank={setFullTank}
          detailOrder={detailOrder}
          denyStatus={denyStatus}
          setDenyStatus={setDenyStatus}
          data={detailOrder}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onChangeStatus={onChangeStatus}
          onCompleteStatus={onCompleteStatus}
          onDenyStatus={onDenyStatus}
          showModal={showModal}
          curentRemainLit={curentRemainLit}
          handleCallbackDetail={handleCallbackDetail}
        />
      </div>
      <Spin spinning={isLoading}>
        <Row gutter={[16, 0]} justify="space-between">
          <Col span={12}>
            <div style={{ paddingTop: '15px' }}>
              <CustomerInfor data={detailOrder} />
            </div>
            <div style={{ paddingTop: '15px' }}>
              <ReceiverInfor data={detailOrder} />
            </div>
            <div
              style={
                detailOrder.order_receipt == null ||
                detailOrder.detailOrder == null
                  ? { display: 'none' }
                  : { paddingTop: '15px' }
              }
            >
              <Receipt data={detailOrder} />
            </div>
            <div
              style={
                detailOrder.insurance == null || detailOrder.detailOrder == null
                  ? { display: 'none' }
                  : { paddingTop: '15px' }
              }
            >
              <Insurance data={detailOrder} />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ paddingTop: '15px' }}>
              <HistoryOrder data={detailOrder} />
            </div>
          </Col>
          <Col span={24}>
            <div style={{ paddingTop: '15px' }}>
              <OrderInfor data={detailOrder} />
            </div>
            {/* <div style={{ paddingTop: '15px' }}>
              <InvoiceInformation />
            </div> */}
            <div style={{ paddingTop: '15px' }}>
              <ProductList data={detailOrder} />
            </div>
            {saveStatus === 1 && (
              <div style={{ paddingTop: '15px' }}>
                <HistoryRefueling
                  refuelList={refuelList}
                  paging={paging}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </Col>
          <Modal
            centered
            title="Bổ sung lịch sử đổ xăng"
            visible={isAddPetrolModal}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              autoComplete="off"
              onFinish={submitForm}
            >
              <Form.Item label="Tên sản phẩm" name="username">
                <Input defaultValue={detailOrder.productName} disabled />
              </Form.Item>
              <Form.Item label="Giá sản phẩm" name="productPrice">
                <Input
                  defaultValue={formatPrice(detailOrder.product_price)}
                  suffix={'VNĐ'}
                  disabled
                />
              </Form.Item>
              <Form.Item
                label="Số lượng"
                name="refueledLit"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập số lít xăng muốn đổ',
                  },
                  {
                    message: `Vui lòng nhập số lít trong khoảng 1 - ${curentRemainLit}`,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve()
                      } else {
                        if (value <= curentRemainLit && value >= 1) {
                          return Promise.resolve()
                        }
                        return Promise.reject()
                        // if (orderAddress) {
                        //   if (
                        //     value <= curentRemainLit &&
                        //     value >= homeQuantity
                        //   ) {
                        //     return Promise.resolve()
                        //   }
                        //   return Promise.reject()
                        // } else {
                        //   if (value <= curentRemainLit && value >= 1) {
                        //     return Promise.resolve()
                        //   }
                        //   return Promise.reject()
                        // }
                      }
                    },
                  },
                ]}
                // rules={
                //   orderAddress
                //     ? homeQuantity < curentRemainLit
                //       ? [
                //           {
                //             required: true,
                //             message: 'Vui lòng nhập số lít xăng muốn đổ',
                //           },
                //           {
                //             message: `Vui lòng nhập số lít trong khoảng ${homeQuantity} - ${curentRemainLit}`,
                //             validator: (_, value) => {
                //               if (!value) {
                //                 return Promise.resolve()
                //               } else {
                //                 if (
                //                   value <= curentRemainLit &&
                //                   value >= homeQuantity
                //                 ) {
                //                   return Promise.resolve()
                //                 }
                //                 return Promise.reject()
                //               }
                //             },
                //           },
                //         ]
                //       : [
                //           {
                //             required: true,
                //             message: 'Vui lòng nhập số lít xăng muốn đổ',
                //           },
                //           {
                //             message: `Vui lòng nhập số lít trong khoảng 1 - ${curentRemainLit}`,
                //             validator: (_, value) => {
                //               if (!value) {
                //                 return Promise.resolve()
                //               } else {
                //                 if (orderAddress) {
                //                   if (value <= curentRemainLit && value >= 1) {
                //                     return Promise.resolve()
                //                   }
                //                   return Promise.reject()
                //                 } else {
                //                   if (value <= curentRemainLit && value >= 1) {
                //                     return Promise.resolve()
                //                   }
                //                   return Promise.reject()
                //                 }
                //               }
                //             },
                //           },
                //         ]
                //     : [
                //         {
                //           required: true,
                //           message: 'Vui lòng nhập số lít xăng muốn đổ',
                //         },
                //         {
                //           message: orderAddress
                //             ? `Vui lòng nhập số lít trong khoảng ${homeQuantity} - ${curentRemainLit}`
                //             : `Vui lòng nhập số lít trong khoảng 1 - ${curentRemainLit}`,
                //           validator: (_, value) => {
                //             if (!value) {
                //               return Promise.resolve()
                //             } else {
                //               if (orderAddress) {
                //                 if (
                //                   value <= curentRemainLit &&
                //                   value >= homeQuantity
                //                 ) {
                //                   return Promise.resolve()
                //                 }
                //                 return Promise.reject()
                //               } else {
                //                 if (value <= curentRemainLit && value >= 1) {
                //                   return Promise.resolve()
                //                 }
                //                 return Promise.reject()
                //               }
                //             }
                //           },
                //         },
                //       ]
                // }
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Nhập số lít xăng"
                  value={refuellit}
                  // defaultValue={
                  //   orderAddress
                  //     ? homeQuantity >= curentRemainLit
                  //       ? curentRemainLit
                  //       : null
                  //     : null
                  // }
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  min={1}
                  autoFocus
                  // disabled={homeQuantity >= curentRemainLit && orderAddress}
                  onChange={(value: number | null) => {
                    if (value) {
                      setRefuelLit(value)
                      setRemainLit(curentRemainLit - value)
                      form.setFieldsValue({
                        remainLit: curentRemainLit - value,
                      })
                    } else {
                      setRefuelLit(0)
                      setRemainLit(curentRemainLit)
                      form.setFieldsValue({
                        remainLit: curentRemainLit,
                      })
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Còn lại" name="remainLit">
                <Input
                  defaultValue={
                    // orderAddress
                    //   ? homeQuantity >= curentRemainLit
                    //     ? 0
                    //     : formatPrice(curentRemainLit)
                    //   : curentRemainLit
                    formatPrice(curentRemainLit)
                  }
                  value={homeQuantity >= curentRemainLit ? 0 : remainLit}
                  suffix={'lít'}
                  style={{ width: '100%' }}
                  disabled
                />
              </Form.Item>
              {orderAddress && homeQuantity < curentRemainLit && (
                <p
                  style={{
                    color: 'gray',
                    paddingBottom: 10,
                    paddingLeft: 10,
                    fontSize: 13,
                  }}
                >
                  * Lưu ý: Vui lòng nhập số lít trong khoảng {homeQuantity} -{' '}
                  {curentRemainLit} lít
                </p>
              )}
              {orderAddress && homeQuantity >= curentRemainLit && (
                <p
                  style={{
                    color: 'gray',
                    paddingBottom: 10,
                    paddingLeft: 10,
                    fontSize: 13,
                  }}
                >
                  * Lưu ý: Vui lòng nhập số lít trong khoảng 1 -{' '}
                  {curentRemainLit} lít
                </p>
              )}
              <div
                style={{
                  paddingLeft: '38%',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                >
                  Lưu
                </Button>
                <Button type="default" onClick={handleCancel}>
                  Huỷ
                </Button>
              </div>
            </Form>
          </Modal>
        </Row>
      </Spin>
    </div>
  )
}
