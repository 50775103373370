import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getListEnterprise } from '../EnterpriseApi'

type TParam = {
  search?: string
  page: number
  approve_status?: number
  from_date?: string | Date
  to_date?: string | Date
}
export const useEnterprise = () => {
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const location: any = useLocation()
  const [currentSelected, setCurrentSelected] = useState(
    location?.state?.id ? { id: location?.state?.id } : { id: -1 }
  )
  const [dataSource, setDataSource] = useState<any>([])

  const [params, setParams] = useState<TParam>({
    search: undefined,
    page: 1,
    approve_status: undefined,
    from_date: undefined,
    to_date: undefined,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      setCurrentSelected({ id: -1 })
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getListEnterprise(params)
      const dataTable = res.data.map((item: any, index: number) => ({
        ...item,
        key: item.id,
        id: item.id,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
        name: item.name,
        phone: item.user.phone,
        tax_code: item.tax,
        tax_address: item.tax_address,
        email: item.user.email,
        approve_status: item.approve_status,
        enterprise_medias: item?.enterprise_medias,
        create_at: item.create_at,
      }))
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })

      setDataSource(dataTable)
    } catch (error: any) {
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  return {
    isLoading,
    paging,
    params,
    currentSelected,
    dataSource,
    setParams,
    setPaging,
    getData,
    setCurrentSelected,
    setDataSource,
  }
}
