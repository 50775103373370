import { useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { getLisOrder } from '../OrderApi'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  is_paid: undefined | string
  limit: number
  from_date?: string
  to_date?: string
  type_payment_id?: any
  category_id?: any
}

export const useOrder = () => {
  const location: any = useLocation()
  const history = useHistory()
  const param: any = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [listOrder, setListOrder] = useState<any>([])
  const [showAddOrder, setShowAddOrder] = useState(false)
  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    page: 1,
    is_paid: undefined,
    from_date: undefined,
    to_date: undefined,
    limit: 12,
    type_payment_id: undefined,
  })

  const [callback, setCallback] = useState(false)

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 13,
  })
  useEffect(() => {
    if (location.state) {
      setParams({
        ...params,
        search: location.state?.search,
        status: location.state?.status,
        page: location.state?.page,
        is_paid: location.state?.is_paid,
        to_date: location.state?.to_date,
        from_date: location.state?.from_date,
      })
    }
  }, [])

  useEffect(() => {
    getData()
  }, [params, location, callback])

  const getData = async () => {
    setIsLoading(true)
    try {
      const res = await getLisOrder(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
        }
      })
      setListOrder(tableData)
      setPaging({
        ...paging,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    currentSelected,
    params,
    paging,
    listOrder,
    setPaging,
    setIsLoading,
    setCurrentSelected,
    setParams,
    getData,
    setListOrder,
    setShowAddOrder,
    showAddOrder,
    param,
    history,
    callback,
    setCallback,
  }
}
