import { DatePicker, Row, Table, Tag } from 'antd'
import moment from 'moment'
import { formatPrice } from 'utils/ruleForm'
import { InfoCircleOutlined } from '@ant-design/icons'
import { IS_ACTIVE, ORDER_STATUS } from 'utils/constants'
import { useEffect, useState } from 'react'
import { getListOrderCustomer } from '../CustomerApi'
import { useHistory, useParams } from 'react-router'
import { notificationError } from 'utils/notification'
import { ADMIN_ROUTER_PATH } from 'common/config'

const { RangePicker } = DatePicker
type TParamOrderList = {
  page: number
  from_date?: string | Date
  to_date?: string | Date
}

export default function OrderList() {
  const param: any = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [params, setParams] = useState<TParamOrderList>({
    page: 1,
    from_date: undefined,
    to_date: undefined,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })
  const column = [
    {
      width: '60px',
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: <b>Mã đơn hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: <b>Tên người nhận</b>,
      dataIndex: 'user',
      key: 'user',
      render: (value: any) => <>{value?.name}</>,
    },
    {
      title: <b>Tên CHXD</b>,
      dataIndex: 'petrol_store',
      key: 'petrol_store',
      render: (value: any) => <>{value?.name}</>,
    },
    {
      width: '120px',
      title: <b>Phương thức thanh toán</b>,
      dataIndex: 'order_payment',
      key: 'order_payment',
      render: (value: any) => <>{value?.type_payment?.name}</>,
    },
    {
      title: <b>Tổng tiền</b>,
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: any) => (
        <>
          {value === 0 || null
            ? 'Chưa xác định'
            : formatPrice(Number(value).toFixed(0)) + 'đ'}
        </>
      ),
    },
    {
      width: '120px',
      title: <b>Trạng thái thanh toán</b>,
      dataIndex: 'order_payment',
      key: 'order_payment',
      render: (value: any) => {
        switch (value?.is_paid) {
          case IS_ACTIVE.ACTIVE:
            return <Tag color="green">Đã thanh toán</Tag>
          case IS_ACTIVE.INACTIVE:
            return <Tag color="red">Chưa thanh toán</Tag>
        }
      },
    },
    {
      width: '120px',
      title: <b>Trạng thái đơn hàng</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        switch (value) {
          case ORDER_STATUS.PENDING:
            return <Tag color="blue">Chờ xác nhận</Tag>
          case ORDER_STATUS.CONFIRMED:
            return <Tag color="blue">Đang thực hiện</Tag>
          case ORDER_STATUS.SUCCCESS:
            return <Tag color="green">Hoàn thành</Tag>
          case ORDER_STATUS.CANCELED:
            return <Tag color="volcano">Hủy</Tag>
          case ORDER_STATUS.DENY:
            return <Tag color="volcano">Từ chối</Tag>
        }
      },
    },
    {
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('DD/MM/YYYY')}</>
      },
    },
    {
      width: '40px',
      title: <b>Chi tiết</b>,
      render: (record: any) => {
        return (
          <InfoCircleOutlined
            style={{ fontSize: '16px', color: 'darkblue' }}
            onClick={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.id}`,
                state: {
                  page: record.current,
                  search: record.search,
                  status: record.status,
                  from_date: record.from_date,
                  to_date: record.to_date,
                  name: ADMIN_ROUTER_PATH.CUSTOMER_DETAIL,
                  idCustomer: param.id,
                  tabs: 'tabs2',
                },
              })
            }}
          />
        )
      },
    },
  ]

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      setIsLoading(true)
      const res = await getListOrderCustomer(param.id, params)

      const dataTable = res.data.map((item: any, index: number) => ({
        ...item,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
      }))
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
      setDataSource(dataTable)
    } catch (error: any) {
      notificationError('Hệ thống gặp sự cố. Vui lòng thử lại!!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Row style={{ paddingBottom: '15px' }} justify="start">
        <RangePicker
          placeholder={['Từ ngày', 'đến ngày']}
          onChange={(value, dateString) => {
            setParams({
              ...params,
              to_date: dateString[1],
              from_date: dateString[0],
              page: 1,
            })
          }}
        />
      </Row>
      <Table
        dataSource={dataSource}
        columns={column}
        loading={isLoading}
        bordered
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            setParams({ ...params, page })
          },
        }}
      />
    </div>
  )
}
