import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import { Descriptions, Tabs, Card, Popconfirm, Button } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { useNotification } from '../hook/useNotification'
import { updateNotify } from '../NotifyApi'
import { AddNotification } from './AddNotification'

type Props = {
  data: any
  getData: any
  onDeleteNotification: any
  // onUpdateNotification: any
  // setEditNotify: any
  // editNotify: any
}

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const ContentView = (data: any) => {
  return (
    <Descriptions size="default">
      <Descriptions.Item label="Tiêu đề">{data.title}</Descriptions.Item>
      <Descriptions.Item label="Loại tài khoản">
        {data.target === 2
          ? 'Tất cả'
          : data.target === 1
          ? 'Nhà cung cấp'
          : 'Khách hàng'}
      </Descriptions.Item>
      <Descriptions.Item label="Ngày tạo">
        {moment(data.create_at).format('HH:mm DD/MM/YYYY')}
      </Descriptions.Item>
      <Descriptions.Item label="Nội dung">{data.content}</Descriptions.Item>
    </Descriptions>
  )
}

function DetailNotification({
  data,
  getData,
  onDeleteNotification,
}: // onUpdateNotification,
// editNotify,
// setEditNotify,
Props) {
  const { setIsLoadingTable } = useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showEditNotify, setShowEditNotify] = useState(false)
  const updateNotifys = async (data: any, resetFields: any) => {
    try {
      let dataPush = {
        id: data.id,
        data: {
          title: data.title,
          content: data.content,
          status: data.status,
          target: data.target,
        },
      }
      const res = await updateNotify(dataPush)
      notificationSuccess(`Cập nhật thành công`)
      resetFields()
      setShowEditNotify(false)
      getData()
    } catch (error) {
      notificationError(`Thông tin bạn nhập không chính xác`)
      console.log(error)
    }
  }
  return (
    <div>
      {showEditNotify && (
        <AddNotification
          data={data}
          visible={showEditNotify}
          // isLoading={false}
          onCancel={() => {
            setShowEditNotify(false)
            getData()
          }}
          onUpdateNotification={(newData: any, resetFields: any) => {
            // console.log(newData)
            updateNotifys(newData, resetFields)
            // setLoading(true)
            getData()
          }}
        />
      )}
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        style={{ padding: '0px 10px' }}
      >
        <TabPane tab="Chi tiết thông báo" key="1">
          <Card
            className="expand"
            actions={[
              <Button
                type="text"
                icon={<EditOutlined />}
                disabled={data.status === 1 ? true : false}
                style={
                  data.status === 1
                    ? { fontWeight: 'bold', color: 'gray' }
                    : { fontWeight: 'bold', color: '#1890ff' }
                }
                onClick={() => {
                  setShowEditNotify(true)
                }}
              >
                Chỉnh sửa
              </Button>,
              // <AddNotification
              //   getData={getData}
              //   // isLoadingButton={isLoadingTable}
              //   data={data}
              //   onUpdateNotification={(newData: any, resetFields: any) => {
              //     // onUpdateNotification(newData, resetFields)
              //     setIsLoadingTable(true)
              //     getData()
              //   }}
              //   visible={editNotify}
              //   onCancel={() => setEditNotify(false)}
              // />,
              <Popconfirm
                title="Bạn chắc chắn muốn xoá thông báo này??"
                onConfirm={async () => {
                  try {
                    onDeleteNotification(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <CloseCircleOutlined /> Xóa
                  </>
                }
                cancelText={
                  <>
                    <LeftCircleOutlined /> Trở lại
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  danger: true,
                  style: { borderRadius: '3px' },
                }}
                cancelButtonProps={{
                  style: {
                    borderRadius: '3px',
                    borderColor: 'black',
                    // color: 'black',
                  },
                }}
              >
                <Button
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                  style={{ fontWeight: 'bold' }}
                >
                  Xóa thông báo
                </Button>
              </Popconfirm>,
            ]}
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default DetailNotification
