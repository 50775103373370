import { PageHeader, Table, Tag } from 'antd'
import Filter from './components/Filter'
import Container from 'common/container/Container'
import { IS_ACTIVE } from 'utils/constants'
import ProductDetailScreen from './ProductDetailScreen'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useProduct } from './hook/useProduct'

const columns = [
  {
    width: '70px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Mã sản phẩm</b>,
    dataIndex: 'code',
  },
  {
    title: <b>Tên sản phẩm </b>,
    dataIndex: 'name',
  },
  {
    title: <b>Danh mục</b>,
    dataIndex: 'product_category_name',
  },
  {
    title: <b>Đơn vị tính</b>,
    dataIndex: 'product',
    render: (value: any) => value?.unit || '--',
  },
  {
    title: <b>Trạng thái hoạt động</b>,
    dataIndex: 'status',
    render: (value: number) => {
      switch (value) {
        case IS_ACTIVE.ACTIVE:
          return <Tag color="green">Hoạt động</Tag>
        case IS_ACTIVE.INACTIVE:
          return <Tag color="volcano">Tạm dừng</Tag>
      }
    },
  },
]

export default function ContentList() {
  const {
    product,
    isLoading,
    currentSelected,
    paging,
    params,
    setParams,
    setCurrentSelected,
    getData,
  } = useProduct()

  return (
    <>
      <Container
        header={<PageHeader title="Sản phẩm" />}
        filterComponent={
          <Filter
            onSearchSubmit={(searchKey: string) => {
              setParams({
                ...params,
                search: searchKey === '' ? undefined : searchKey.trim(),
                page: 1,
              })
            }}
            onCategorySubmit={(category_id: number) => {
              setParams({ ...params, category_id: category_id, page: 1 })
            }}
            onStatusSubmit={(statusKey: number) => {
              setParams({ ...params, status: statusKey, page: 1 })
            }}
          />
        }
        contentComponent={
          <>
            <p>
              Kết quả lọc: <b>{paging.total}</b>
            </p>
            <Table
              className={styles.table_hover}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              expandedRowKeys={[currentSelected.id]}
              loading={isLoading.loadingTable}
              onRow={(row: any) => ({
                onClick: () => {
                  if (currentSelected !== row) setCurrentSelected(row)
                  else setCurrentSelected({ id: -1 })
                },
              })}
              expandable={{
                expandedRowRender: (record: any) => (
                  <ProductDetailScreen data={record} getData={getData} />
                ),
              }}
              dataSource={product}
              bordered
              columns={columns}
              pagination={{
                ...paging,
                showSizeChanger: false,
                onChange: async (page, pageSize) => {
                  setParams({ ...params, page })
                },
              }}
            />
          </>
        }
      />
    </>
  )
}
