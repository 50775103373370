import { PageHeader, Spin, Tabs } from 'antd'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import InforUser from './components/InforUser'
import OrderList from './components/OrderList'
import Debt from './components/Debt'
import ReferralCode from './components/ReferralCode'
import AddDebt from './components/AddDebt'
import { ButtonActive, ButtonFixed } from 'common/components/Buttons'
import { PlusOutlined } from '@ant-design/icons'
import styles from 'common/components/styles/WhiteBox.module.css'
import { changeStatusCustomer, getInforCustomer } from './CustomerApi'
import { notificationError, notificationSuccess } from 'utils/notification'
import { TDataSource } from '../Customer'
import HistoryPoint from './components/HistoryPoint'
import { ADMIN_ROUTER_PATH } from 'common/config'

const { TabPane } = Tabs

export default function CustomerDetailScreen() {
  const GetTabsUrl = (): string => {
    const searchParams = new URLSearchParams(window.location.search)
    const tabs: string = searchParams.get('tabs') as string
    return tabs
  }
  const history = useHistory()
  const param: any = useParams()
  const location: any = useLocation()

  const [activeKey, setActiveKey] = useState<string>(GetTabsUrl() || 'tabs1')
  const [isLoading, setIsLoading] = useState(false)
  const [showAddDebt, setShowAddDebt] = useState(false)
  const [dataSource, setDataSource] = useState<TDataSource>({
    dataInfor: {},
    dataTable: [],
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
  }, [])

  const getData = async () => {
    try {
      const res = await getInforCustomer(param.id)

      const convertData = {
        dataInfor: {
          name: res.data?.name,
          rank: res.data?.rank,
          email: res.data?.email,
          phone: res.data?.phone,
          status: res.data.status,
          status_debt: res.data.status_debt,
          gender: res.data?.gender,
          date_of_birth: res.data?.date_of_birth,
          total_paid: res.data?.total_paid,
          point: res.data.point,
          rank_point: res.data.rank_point,
          code: res.data.code,
          enterprise: {
            enterprise_id: res.data?.enterprise?.id,
            approve_status: res.data?.enterprise?.approve_status,
            enterprise_name: res.data?.enterprise?.name,
            enterprise_tax: res.data?.enterprise?.tax,
            enterprise_address_tax: res.data?.enterprise?.tax_address,
          },
          debt_limit: res.data?.enterprise?.debt_enterprises[0]?.debt_limit,
          debt_period: res.data?.enterprise?.debt_enterprises[0]?.debt_period,
        },
        dataTable: res.data.user_addresses.map((item: any, index: number) => ({
          ...item,
          stt: index + 1,
        })),
      }
      setDataSource(convertData)
    } catch (error: any) {}
  }

  const onChangeStatus = async () => {
    try {
      setIsLoading(true)
      const res = await changeStatusCustomer(param.id)
      if (res.code === 200) {
        setDataSource({
          ...dataSource,
          dataInfor: {
            ...dataSource.dataInfor,
            status: dataSource.dataInfor?.status ? 0 : 1,
          },
        })
        notificationSuccess(res.message)
      }
    } catch (error) {
      notificationError('Hệ thống gặp sự cố. Vui lòng thử lại!')
    } finally {
      setIsLoading(false)
    }
  }

  const SetSearchPrams = (keys: string, values: string): string => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(keys, values)
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
      state: location.state,
    })
    return searchParams.toString()
  }

  return (
    <Spin size="large" spinning={false}>
      <div className={styles.container}>
        <div className={styles.header}>
          <PageHeader
            onBack={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.CUSTOMER}`,
                state: location.state,
              })
            }}
            title="Chi tiết khách hàng"
            extra={[
              <ButtonFixed
                type="primary"
                text="Áp hạn mức nợ công"
                icon={<PlusOutlined />}
                onClickButton={() => {
                  setShowAddDebt(true)
                }}
                disabled={
                  dataSource.dataInfor?.enterprise?.approve_status === 1
                    ? false
                    : true
                }
              />,
              <ButtonActive
                isActive={dataSource.dataInfor?.status}
                onConfirm={() => {
                  onChangeStatus()
                }}
                loading={isLoading}
                title={
                  dataSource.dataInfor?.status
                    ? 'Bạn thực sự muốn ngừng hoạt động khách hàng này?'
                    : 'Bạn thực sự muốn khách hàng này hoạt động?'
                }
              />,
            ]}
          />
          <AddDebt
            isLoadingButton={location}
            visible={showAddDebt}
            dataSource={dataSource}
            getData={getData}
            onCancel={() => {
              setShowAddDebt(false)
            }}
          />
        </div>
        <div className={styles.content}>
          <Tabs
            style={{
              fontWeight: 'bold',
            }}
            activeKey={activeKey}
            onTabClick={(value: string) => {
              SetSearchPrams('tabs', value)
              setActiveKey(value)
              // localStorage.setItem('page', localStorage.getItem('page'))
            }}
          >
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Thông tin cá nhân</p>}
              key="tabs1"
            >
              <InforUser dataSource={dataSource} />
            </TabPane>
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Danh sách đơn hàng</p>}
              key="tabs2"
            >
              <OrderList />
            </TabPane>
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Công nợ</p>}
              key="tabs3"
            >
              <Debt
                statusDebt={dataSource?.dataInfor?.status_debt}
                infoCustomer={dataSource}
                reloadPage={getData}
              />
            </TabPane>
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Lịch sử tích điểm</p>}
              key="tabs4"
            >
              <HistoryPoint
                point={dataSource.dataInfor.point}
                rank_point={dataSource.dataInfor.rank_point}
                rank={dataSource.dataInfor.rank}
              />
            </TabPane>
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Danh sách mã giới thiệu</p>}
              key="tabs5"
            >
              <ReferralCode />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Spin>
  )
}
