import { Tabs } from 'antd'
import BatteryReliefTab from './components/BatteryReliefTab'
import { Header } from './components/Header'
import OilChangeAtHomeTab from './components/OilChangeAtHomeTab'
import styles from 'common/components/styles/WhiteBox.module.css'

const { TabPane } = Tabs
const RequestScreen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.content}>Tính năng đang phát triền ....</div>
      {/* <div className={styles.content}>
        <Tabs>
          <TabPane
            tab={
              <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                Cứu trợ ắc quy
              </p>
            }
            key="1"
          >
            <BatteryReliefTab />
          </TabPane>
          <TabPane
            tab={
              <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                Thay dầu nhớt tại nhà
              </p>
            }
            key="2"
          >
            <OilChangeAtHomeTab />
          </TabPane>
        </Tabs>
      </div> */}
    </div>
  )
}

export default RequestScreen
