import { Table, Tag } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Filter from './components/Filter'
import Header from './components/Header'
import Container from 'common/container/Container'
import { useShop } from './hook/useShop'
import moment from 'moment'
import AddEditShop from './components/AddEditShop'
import styles from 'common/components/styles/WhiteBox.module.css'

export default function ContentList() {
  const {
    history,
    isModalVisible,
    dataSource,
    params,
    isLoading,
    paging,
    setParams,
    setIsModalVisible,
    CreateShop,
  } = useShop()

  const columns = [
    {
      title: <b>STT</b>,
      dataIndex: 'stt',
    },
    {
      title: <b>Tên cửa hàng</b>,
      dataIndex: 'name',
      render: (value: any) => {
        return <td className={styles.rowhover}>{value}</td>
      },
    },
    {
      title: <b>Số điện thoại</b>,
      dataIndex: 'phone',
    },
    {
      title: <b>Mã số thuế</b>,
      dataIndex: 'tax',
    },
    {
      title: <b>Email</b>,
      dataIndex: 'email',
    },
    {
      title: <b>Tên người đại diện </b>,
      dataIndex: 'representative',
    },
    {
      title: <b>Tên công ty</b>,
      dataIndex: 'company_name',
    },
    {
      title: <b>Loại cửa hàng</b>,
      dataIndex: 'type_petrol_store_name',
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      render: (value: any) => {
        return (
          <td>
            {value === 1 ? (
              <Tag color="blue">Đang hoạt động</Tag>
            ) : (
              <Tag color="red">Ngừng hoạt động</Tag>
            )}
          </td>
        )
      },
    },
    {
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      render: (value: any) => {
        return <td>{moment(value).format('DD/MM/YYYY')}</td>
      },
    },
  ]

  return (
    <>
      <Container
        header={<Header CreateShop={CreateShop} />}
        filterComponent={
          <Filter
            data={params}
            onSearchSubmit={(searchKey: string) => {
              setParams({
                ...params,
                search:
                  searchKey.trim() === '' ? undefined : searchKey.trimStart(),
                page: 1,
              })
            }}
            onTypeShopSubmit={(type_shop_id: number) => {
              setParams({
                ...params,
                type_petrol_store_id: type_shop_id,
                page: 1,
              })
            }}
            onStatusSubmit={(statusKey: number) => {
              setParams({ ...params, status: statusKey, page: 1 })
            }}
          />
        }
        contentComponent={
          <div>
            <span>
              Kết quả lọc: <b>{paging.total}</b>
            </span>
            <Table
              className={styles.table_hover}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              bordered
              dataSource={dataSource}
              pagination={{
                ...paging,
                onChange: async (page, pageSize) => {
                  setParams({ ...params, page })
                },
              }}
              loading={isLoading.loadingTable}
              columns={columns}
              onRow={record => ({
                onClick: () => {
                  history.push({
                    pathname: `${ADMIN_ROUTER_PATH.SHOP_DETAIL}/${record.id}`,
                    state: {
                      search: params.search,
                      status: params.status,
                      type_petrol_store_id: params.type_petrol_store_id,
                      page: params.page,
                    },
                  })
                },
              })}
            />
          </div>
        }
      />

      <AddEditShop
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
      />
    </>
  )
}
