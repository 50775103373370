import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

export const useDetailDebt = () => {
  const param: string = useParams()
  const [dataSource, setDataSource] = useState({
    name: 'Nguyễn Tuấn Anh',
    total_debt: 40000000,
    dataTable: [
      {
        id: 1,
        order_id: 'DH0001',
        customer: 'Trần Văn A (09122222222)',
        count_product: 10,
        total_price: 3000000,
        status_order: 0,
        payment_methods: 'Chuyển khoản',
        status_pay: 0,
      },
      {
        id: 2,
        order_id: 'DH0002',
        customer: 'Trần Văn B (09122222222)',
        count_product: 10,
        total_price: 3000000,
        status_order: 0,
        payment_methods: 'Chuyển khoản',
        status_pay: 1,
      },
      {
        id: 3,
        order_id: 'DH0003',
        customer: 'Trần Văn C (09122222222)',
        count_product: 10,
        total_price: 3000000500,
        status_order: 2,
        payment_methods: 'Chuyển khoản',
        status_pay: 0,
      },
      {
        id: 4,
        order_id: 'DH0004',
        customer: 'Trần Văn D (09122222222)',
        count_product: 10,
        total_price: 3000000,
        status_order: 1,
        payment_methods: 'Chuyển khoản',
        status_pay: 1,
      },
    ],
  })

  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    // call api get data
  }

  return {
    param,
    dataSource,
    setDataSource,
  }
}
