import { QrcodeOutlined } from '@ant-design/icons'
import { Button, DatePicker, PageHeader } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import ScanQRCode from '../../order/orders/components/ScanQRCode'
const dateFormat = 'DD/MM/YYYY'

type Props = {
  setParams: any
}
const { RangePicker } = DatePicker
export const Header = ({ setParams }: Props) => {
  const [scanQRCode, setScanQRCode] = useState<boolean>(false)
  return (
    <div>
      <PageHeader
        title="Tổng quan"
        style={{
          backgroundColor: 'white',
          padding: 15,
          margin: 10,
        }}
        extra={[
          <RangePicker
            key="filter_picker"
            onChange={(date: any, dateStrings: string[]) => {
              setParams({
                from_date: dateStrings[0].split('/').reverse().join('-'),
                to_date: dateStrings[1].split('/').reverse().join('-'),
              })
            }}
            allowClear={false}
            placeholder={['Từ ngày', 'Đến ngày']}
            defaultValue={[moment().startOf('month'), moment()]}
            format={dateFormat}
          />,

          // <Button
          //   key="qr_code"
          //   icon={<QrcodeOutlined />}
          //   onClick={() => setScanQRCode(true)}
          // >
          //   Quét QR Code
          // </Button>,
        ]}
      />
      <ScanQRCode
        visible={scanQRCode}
        onCancel={() => {
          setScanQRCode(false)
        }}
      />
    </div>
  )
}
