import Icon from '@ant-design/icons'
import { Col, DatePicker, Empty, Input, Row, Select } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import { requestAllCategory } from 'features/admin/product/product/ProductApi'
import { getListPetrolStore } from 'features/admin/shop/shops/ShopApi'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const { Option } = Select
const { RangePicker } = DatePicker

type IFilter = {
  data: any
  onSearchSubmit: (searchKey: string) => void
  onCategoryIdSubmit: (category_id: number) => void
  onSelectPetrolStore: (petrol_store_id: number) => void
  params?: any
  setParams?: any
  onSearchTime: (
    from_date: string | undefined | moment.Moment,
    to_date: string | undefined | moment.Moment
  ) => void
}

type TRadiusSelect = {
  text: string
  value: number
}

export default function Filter(props: IFilter) {
  const {
    data,
    onSearchSubmit,
    onCategoryIdSubmit,
    onSelectPetrolStore,
    onSearchTime,
    params,
    setParams,
  } = props
  const location: any = useLocation()
  const [listCategory, setListCategory] = useState<Array<TRadiusSelect>>([])
  const [listPetrolStore, setListPetrolStore] = useState<Array<TRadiusSelect>>(
    []
  )

  useEffect(() => {
    getListCategory()
    getPetrolStore()
  }, [])

  const getListCategory = async () => {
    const res: any = await requestAllCategory({ limit: 999 })
    const list_data = res?.data?.map((item: any) => {
      return { value: item.id, text: item.name }
    })
    setListCategory(list_data)
  }

  const getPetrolStore = async () => {
    const res = await getListPetrolStore({ page: 1, limit: 999 })
    const tableData = res.data?.map((item: any, index: number) => {
      return {
        text: item.name,
        value: item.id,
      }
    })
    setListPetrolStore(tableData)
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={7}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Tên hoặc mã sản phẩm"
          addonAfter={<Icon type="close-circle-o" />}
          value={data?.search}
          onChange={(e: any) => {
            onSearchSubmit(e.target.value)
          }}
        />
      </Col>
      <Col span={5}>
        <RadiusSelection
          placeholder="DM sản phẩm"
          onChange={(value: number) => {
            onCategoryIdSubmit(value)
          }}
          value={data?.category_id}
          options={listCategory}
          showSearch={true}
        />
      </Col>
      <Col span={6}>
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="Tên cửa hàng"
          optionFilterProp="children"
          allowClear
          value={data?.petrol_store_id}
          onChange={(value: number) => {
            onSelectPetrolStore(value)
          }}
        >
          {listPetrolStore.length >= 1 ? (
            listPetrolStore?.map((item: any) => (
              <Option value={item.value}>{item.text}</Option>
            ))
          ) : (
            <Option value={1} disabled>
              <Empty />
            </Option>
          )}
        </Select>
      </Col>
      <Col span={6}>
        {/* <RangePicker
          style={{ width: '100%' }}
          placeholder={['Từ ngày', 'Đến ngày']}
          format="DD-MM-YYYY"
          defaultValue={
            location.state?.fromDate && [
              moment(location.state?.from_date, 'DD-MM-YYYY'),
              moment(location.state?.to_date, 'DD-MM-YYYY'),
            ]
          }
          onChange={value => {
            onSearchTime(
              value ? moment(value?.[0]) : undefined,
              value ? moment(value?.[1]) : undefined
            )
          }}
        /> */}
        <RangePicker
          style={{ width: '100%' }}
          placeholder={['Từ ngày', 'Đến ngày']}
          format="HH:mm DD/MM/YYYY"
          showTime={{ format: 'HH:mm' }}
          value={
            params?.from_date
              ? [moment(params.from_date), moment(params.to_date)]
              : [null, params.to_date ? moment(params.to_date) : moment()]
          }
          onChange={(dates: any, dateString) => {
            setParams({
              ...params,
              from_date: dates
                ? moment(
                    `${dateString[0]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[0].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .format('YYYY-MM-DD HH:mm:ss')
                : '',
              to_date: dates
                ? moment(
                    `${dateString[1]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[1].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .add(59, 'seconds')
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss'),
              page: 1,
            })
          }}
          // onChange={(value, dateString) => {
          //   onSearchTime(
          //     value ? moment(value?.[0]).format('HH:mm YYYY-MM-DD') : undefined,
          //     value ? moment(value?.[1]).format('HH:mm YYYY-MM-DD') : undefined
          //   )
          // }}
        />
      </Col>
    </Row>
  )
}
