export const ADMIN_ROUTER_PATH = {
  DASH_BOARD: '/admin-dash-board',
  ACCOUNTS: '/admin-accounts',
  SHOP: '/admin-shop',
  SHOP_DETAIL: '/admin-shop-detail',
  PRODUCT: '/admin-product',
  ADD_PRODUCT: '/admin-add-product',
  EDIT_PRODUCT: '/admin-edit-product',
  CATEGORY_PRODUCT: '/admin-category-product',
  SALE_PRODUCT: '/admin-sale-product',
  ADD_SALE_PRODUCT: '/admin-add-sale-product',
  EDIT_SALE_PRODUCT: '/admin-edit-sale-product',
  SALE_PRODUCT_DETAIL: '/admin-detail-sale-product',
  CUSTOMER: '/admin-customer',
  ENTERPRISE_CUSTOMER: '/admin-enterprise_customer',
  ORDER_INSURANCE: '/admin-order_insurance',
  ORDER_DETAIL_INSURANCE: '/admin-order-insurance',
  CUSTOMER_DETAIL: '/admin-customer-detail',
  ORDER: '/admin-order',
  ORDER_PRINT: '/admin-order-print',
  ORDER_DETAIL: '/admin-order-detail',
  DEBT_CUSTOMERS: '/admin-debt-customer',
  DEBT_CUSTOMERS_DETAIL: '/admin-debt-customer-detail',
  DEBT_SHOPS: '/admin-debt-shops',
  DEBT_SHOPS_DETAIL: '/admin-debt-shops-detail',
  NEWS: '/admin-news',
  ADD_NEWS: '/admin-add-news',
  EDIT_NEWS: '/admin-edit-news',
  REQUEST: '/admin-request',
  SHOP_REPORT: '/admin-shop-report',
  NOTIFICATION: '/admin-notification',
  SALE_REPORT: '/admin-sale-report',
  SALE_REPORT_DETAIL: '/admin-sale-report-detail',
  POINT_REPORT: '/admin-point-report',
  POINT_REPORT_DETAIL: '/admin-point-report-detail',
  CONFIG: '/admin-config',
  PRODUCT_DETAIL: '/admin-detail-product',
  BECOME_PARTNER: '/admin-become-partner',
  INVENTORY: '/admin-inventory',
  INVENTORY_DETAIL: '/admin-inventory-detail',
  CHANGE_WAREHOUSE: '/admin-change-warehouse',

  // tồn kho
  UPDATE_INVENTORY: '/admin-update-inventory',

  // cập nhật tồn
  UPDATE_SURVIVE_HEAD: '/admin-update-survive_head',

  HISTORY_INVENTORY: '/admin-history-inventory',
  HISTORY_INVENTORY_DETAIL: '/admin-history-inventory-detail',
}
export const ENTERPRISE_ROUTER_PATH = {
  DASH_BOARD: '/dash-board',
  PRODUCT: '/product-list',
  PRODUCT_DETAIL: '/product-detail',
  ADD_PRODUCT: '/add-product',
  EDIT_PRODUCT: '/edit-product',
  CATEGORY_PRODUCT: '/category-product',
  SALE_PRODUCT: '/sale-product',
  ADD_SALE_PRODUCT: '/add-sale-product',
  EDIT_SALE_PRODUCT: '/edit-sale-product',
  SALE_PRODUCT_DETAIL: '/detail-sale-product',
  WARE_HOUSE: '/ware-house',
  CUSTOMER: '/customer',
  CUSTOMER_DETAIL: '/customer-detail',
  ENTERPRISE_CUSTOMER: '/enterprise_customer',
  ORDER: '/order',
  ORDER_DETAIL: '/order-detail',
  ORDER_INSURANCE: '/order_insurance',
  ORDER_DETAIL_INSURANCE: '/order_detail_insurance',
  LIVE_STREAM: '/live-stream',
  CHAT: '/chat',
  FORUM_POST: '/forum-chat',
  SALES_REPORT: '/sales-report',
  LIVE_STREAM_REPORT: '/live-stream-report',
  ACCOUNTS: '/accounts',
  DEBT_REPORT: '/debt-report',
  SHOP_REPORT: '/shop-report',
  SALE_REPORT: '/sale-report',
  SALE_REPORT_DETAIL: '/sale-report-detail',
  DEBT_REPORT_DETAIL: '/debt-report-detail',
}

export const SESSION_ID = 'session'
