import Icon from '@ant-design/icons'
import { DatePicker, Row, Input, Col } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import React from 'react'

const { RangePicker } = DatePicker
const { Search } = Input
export default function Filter() {
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={10}>
        <Search
          // style={{ minWidth: '310px' }}
          allowClear
          addonAfter={
            <Icon
              type="close-circle-o"
              onClick={() => {
                //   onSearchSubmit('')
              }}
            />
          }
          onKeyDown={e => {
            if (e.keyCode === 13) {
              // onSearchSubmit(searchKey)
            }
          }}
          onSearch={(value, event) => {
            //   onSearchSubmit(value)
          }}
          placeholder="Mã đơn hàng, tên khách hàng, SĐT"
          onChange={e => {
            // setSearchKey(e.target.value)
            // if (timeOut.current) {
            //   setIsTyping(true)
            //   clearTimeout(timeOut.current)
            // }
            // timeOut.current = setTimeout(() => {
            //   setIsTyping(false)
            // }, 300)
          }}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="TT Đơn hàng"
          onChange={() => {}}
          options={[1, 2, 3].map(item => ({
            value: item,
            text: `Trạng thái ${item}`,
          }))}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="TT Thanh toán"
          onChange={() => {}}
          options={[1, 2, 3].map(item => ({
            value: item,
            text: `Trạng thái ${item}`,
          }))}
        />
      </Col>

      <Col span={6}>
        <RangePicker
          // style={{ width: '220px' }}
          placeholder={['Từ ngày', 'Đến ngày']}
          onChange={(dates, dateStrings) => {
            // onTimeSubmit(dateStrings[0], dateStrings[1])
          }}
        />
      </Col>
    </Row>
  )
}
