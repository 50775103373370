import moment from 'moment'
import {
  Button,
  Checkbox,
  notification,
  Popover,
  Row,
  Space,
  Table,
  Tag,
} from 'antd'
import { formatPrice } from 'utils/ruleForm'
import Filter from './components/Filter'
import HeaderBanner from './components/Header'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useOrder } from './hook/useOrder'
import Container from 'common/container/Container'
import { ORDER_STATUS } from 'utils/constants'
import styles from 'common/components/styles/WhiteBox.module.css'
import { PrinterFilled } from '@ant-design/icons'
import React from 'react'
import { changeStatusPayment, getReportTotalOrder } from './OrderApi'

const checkOrder = (order: any) => {
  if (
    [2]?.includes(+order?.order_payment?.type_payment_id) &&
    !order?.order_payment?.is_paid &&
    [1].includes(+order?.status)
  ) {
    return true
  }

  if (
    [3]?.includes(+order?.order_payment?.type_payment_id) &&
    !order?.order_payment?.is_paid &&
    [1, 2, 4].includes(+order?.status)
  ) {
    return true
  }

  return false
}

// kiểm tra diều kiện đơn hàng
const checkConditionOrder = (orders: any) => {
  return orders.filter((item: any) => {
    return checkOrder(item)
  })
}

// tìm những id chung
function findCommonIds(a: any, b: any) {
  let commonIds = []
  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b.length; j++) {
      if (a[i].id === b[j].id) {
        commonIds.push(a[i])
        break
      }
    }
  }
  return commonIds
}

// tìm những id k trùng của mảng a và b
function findNonDuplicateIds(a: any, b: any) {
  let nonDuplicateIds = new Set()
  let allIds = new Set()
  for (let i = 0; i < a.length; i++) {
    allIds.add(a[i].id)
  }
  for (let j = 0; j < b.length; j++) {
    if (!allIds.has(b[j].id)) {
      nonDuplicateIds.add(b[j].id)
      allIds.add(b[j].id)
    }
  }
  for (let i = 0; i < a.length; i++) {
    if (!allIds.has(a[i].id)) {
      nonDuplicateIds.add(a[i].id)
    }
  }
  return Array.from(nonDuplicateIds)
}

export default function Order() {
  const {
    history,
    isLoading,
    listOrder,
    paging,
    params,
    setParams,
    setCallback,
    callback,
  } = useOrder()

  const [rowSelected, setRowSelected] = React.useState<any>([])

  //  đơn hàng thoả mãn điều kiện duyệt đơn
  const dataConditionOrders = checkConditionOrder(listOrder)

  // đơn hàng thoả mãn điều kiện duyệt đơn và được chọn
  const dataSelectedConditionOrders = findCommonIds(listOrder, rowSelected)
  const [loading, setLoading] = React.useState(false)

  // đơn hàng thoả mãn điều kiện duyệt đơn và không được chọn
  const dataMissingConditionOrders = dataConditionOrders.filter((item: any) => {
    return rowSelected?.some((item2: any) => item2.id !== item.id)
  })

  const content = (
    <Row>
      <Button>In</Button>
      <Button>Hủy</Button>
    </Row>
  )
  const column = [
    listOrder?.some((item: any) => {
      return checkOrder(item)
    })
      ? {
          width: '40px',
          title: (
            <Row justify="center">
              <Checkbox
                checked={dataConditionOrders.every((item: any) =>
                  rowSelected.some((item2: any) => item2.id === item.id)
                )}
                onChange={e => {
                  if (e.target.checked) {
                    if (dataMissingConditionOrders.length === 0) {
                      setRowSelected((prev: any) => [
                        ...prev,
                        ...dataConditionOrders,
                      ])
                    } else {
                      setRowSelected((prev: any) => [
                        ...prev,
                        ...dataMissingConditionOrders,
                      ])
                    }
                  } else {
                    setRowSelected(
                      rowSelected.filter((item: any) => {
                        return !dataConditionOrders.some(
                          (item2: any) => item2.id === item.id
                        )
                      })
                    )
                  }
                }}
              />
            </Row>
          ),
          dataIndex: 'id',
          key: 'id',
          render: (_: any, row: any) => {
            return (
              checkOrder(row) && (
                <Row onClick={e => e.stopPropagation()} justify="center">
                  <Checkbox
                    checked={rowSelected.some(
                      (item: any) => item?.id === row.id
                    )}
                    onChange={e => {
                      if (e.target.checked) {
                        setRowSelected([...rowSelected, row])
                      } else {
                        setRowSelected(
                          rowSelected.filter((item: any) => item?.id !== row.id)
                        )
                      }
                    }}
                  />
                </Row>
              )
            )
          },
        }
      : {
          tittle: '',
          width: '40px',
        },
    {
      width: '40px',
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      width: '50px',
      title: <b>Mã đơn hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      width: '100px',
      title: <b>Khách hàng</b>,
      dataIndex: 'user',
      key: 'user',
      render: (value: any, _: any, index: number) =>
        index > 0 && (
          <>
            {value?.name}
            <br />({value?.phone})
          </>
        ),
    },
    {
      width: '100px',
      title: <b>Người nhận</b>,
      dataIndex: 'user',
      key: 'user',
      render: (value: any) => <>{value?.name}</>,
    },
    {
      width: '110px',
      title: <b>Cửa hàng</b>,
      dataIndex: 'petrol_store',
      key: 'petrol_store',
      render: (value: any) => <>{value?.name}</>,
    },
    {
      title: <b>Số SP</b>,
      width: '40px',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (value: any) => <>{value == 0 ? 1 : formatPrice(value)}</>,
    },
    {
      width: '80px',
      title: <b>Tiền thanh toán</b>,
      dataIndex: 'total_price',
      render: (value: any, record: any) => (
        <>
          {record.order_gasoline?.is_full_fuel === 1 && record.total_price === 0
            ? 'Chưa xác định'
            : record.order_gasoline?.is_full_fuel === 0 &&
              record.total_price === 0
            ? '0đ'
            : !record.order_gasoline && record.total_price === 0
            ? '0đ'
            : formatPrice(record.total_price) + 'đ'}
        </>
      ),
    },
    {
      width: '120px',
      title: <b>TT Đơn hàng</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        switch (value) {
          case ORDER_STATUS.PENDING:
            return <Tag color="blue">Chờ xác nhận</Tag>
          case ORDER_STATUS.CONFIRMED:
            return <Tag color="blue">Đang thực hiện</Tag>
          case ORDER_STATUS.SUCCCESS:
            return <Tag color="green">Hoàn thành</Tag>
          case ORDER_STATUS.CANCELED:
            return <Tag color="volcano">Hủy</Tag>
          case ORDER_STATUS.DENY:
            return <Tag color="volcano">Từ chối</Tag>
        }
      },
    },
    {
      width: '100px',
      title: <b>Phương thức thanh toán</b>,
      dataIndex: 'order_payment',
      key: 'order_payment',
      render: (value: any) => <>{value?.type_payment?.name}</>,
    },
    {
      width: '90px',
      title: <b>TT Thanh toán</b>,
      dataIndex: 'order_payment',
      key: 'order_payment',
      render: (value: any, _: any, index: number) => {
        return (
          index > 0 && (
            <div>
              {value?.is_paid === 1 ? (
                <Tag style={{ fontSize: 12 }} color="green">
                  Đã thanh toán
                </Tag>
              ) : (
                <Tag style={{ fontSize: 12 }} color="volcano">
                  Chưa thanh toán
                </Tag>
              )}
            </div>
          )
        )
      },
    },
    {
      width: '80px',
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any, _: any, index: number) => {
        return index > 0 && <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
      },
    },
    // {
    //   width: '50px',
    //   title: <b>In hóa đơn</b>,
    //   render: (value: any) => {
    //     return (
    //       <>
    //         <Popover content={content} placement="topLeft">
    //           <div style={{ padding: '10px 0 10px 0' }}>
    //             <PrinterFilled />
    //           </div>
    //         </Popover>
    //       </>
    //     )
    //   },
    // },
  ]

  const handleRequestPaymentState = async () => {
    if (loading) return
    setLoading(true)
    const removeDuplicates = Array.from(
      new Set(rowSelected.map((item: any) => item.id))
    ).map(id => ({ id }))

    for (let index = 0; index < removeDuplicates.length; index++) {
      const element: any = removeDuplicates[index]

      await changeStatusPayment(element.id)
      if (index === removeDuplicates.length - 1) {
        // setParams({ ...params})

        setCallback(!callback)
        setRowSelected([])
        notification.success({
          message: 'Thành công',
          description: 'Cập nhật trạng thái thanh toán thành công',
        })
        setLoading(false)
      }
    }
  }

  const [firstRecord, setFirstRecord] = React.useState<any>({})

  const getTotalReport = async () => {
    try {
      const res = await getReportTotalOrder(params)

      setFirstRecord({
        id: Math.random().toString(),
        total_price: res.data?.total_price,
      })
    } catch (err) {
      console.log(
        '🚀 ~ file: SaleReportScreen.tsx:347 ~ getTotalReport ~ err',
        err
      )
    }
  }
  React.useEffect(() => {
    getTotalReport()
  }, [params])

  return (
    <Container
      header={<HeaderBanner params={params} />}
      filterComponent={
        <Filter
          data={params}
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search:
                searchKey.trim() === '' ? undefined : searchKey.trimStart(),
              page: 1,
            })
          }}
          onStatusOrder={(statusKey: string) => {
            setParams({ ...params, status: statusKey, page: 1 })
          }}
          onStatusPayment={(statusPaymentKey: string) => {
            setParams({ ...params, is_paid: statusPaymentKey, page: 1 })
          }}
          onDateSubmit={(from_date: string, to_date: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
            })
          }}
          onTypePayment={(type_payment: any) => {
            setParams({ ...params, type_payment_id: type_payment, page: 1 })
          }}
          onCategorySubmit={(category_id: number) => {
            setParams({ ...params, category_id: category_id, page: 1 })
          }}
          params={params}
          setParams={setParams}
        />
      }
      contentComponent={
        <div>
          <Row
            style={{ marginBottom: '10px' }}
            align="middle"
            justify="space-between"
          >
            <span>
              Kết quả lọc: <b>{paging.total}</b>
            </span>
            {rowSelected.length > 0 && (
              <Space>
                {/* <Button
                  onClick={() => {
                    setRowSelected([])
                  }}
                >
                  Bỏ chọn
                </Button> */}
                <Button
                  loading={loading}
                  onClick={handleRequestPaymentState}
                  type="primary"
                >
                  Duyệt đơn
                </Button>
              </Space>
            )}
          </Row>
          <Table
            className={styles.table_hover}
            size="middle"
            loading={isLoading}
            bordered
            columns={column}
            dataSource={[firstRecord, ...listOrder]}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
              // y: 'calc(100vh - 430px)',
            }}
            // rowSelection={
            //   !params?.type_payment_id ||
            //   [2, 3].includes(params?.type_payment_id)
            //     ? {
            //         type: 'checkbox',
            //         ...rowSelection,
            //         selectedRowKeys: rowSelected.map((item: any) => item.id),
            //       }
            //     : undefined
            // }
            onRow={(record, rowIndex: any) => ({
              onClick: () => {
                if (rowIndex < 1) return
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.id}`,
                  state: {
                    page: paging.current,
                    search: params.search,
                    status: params.status,
                    from_date: params.from_date,
                    to_date: params.to_date,
                    is_paid: params.is_paid,
                    name: ADMIN_ROUTER_PATH.ORDER,
                    orderId: record.id,
                    userId: record.user_id,
                    storeId: record.petrol_store_id,
                  },
                })
              },
            })}
            pagination={{
              // ...paging,
              pageSize: 13,
              // page: params.page,
              current: params.page,
              total: paging?.total,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
