import { PageHeader, Table, Tag } from 'antd'
import moment from 'moment'
import { formatPrice } from 'utils/ruleForm'
import Filter from './components/Filter'
import { useHistory } from 'react-router-dom'
import { ENTERPRISE_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import { IS_ACTIVE } from 'utils/constants'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useCustomer } from './hook/useCustomer'

const column = [
  {
    width: '60px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    width: '150px',
    title: <b>Họ tên</b>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    width: '100px',
    title: <b>Loại khách hàng</b>,
    dataIndex: 'role_names',
    key: 'role_names',
  },
  {
    width: '100px',
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    width: '150px',
    title: <b>Email</b>,
    dataIndex: 'email',
    key: 'email',
  },
  {
    width: '140px',
    title: <b>Tổng điểm</b>,
    dataIndex: 'point',
    key: 'point',
    render: (value: any) => {
      return <>{value === 0 ? '0' : formatPrice(value)} </>
    },
  },
  {
    width: '90px',
    title: <b>Hạng</b>,
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    width: '150px',
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    key: 'status',
    render: (value: any) => {
      switch (value) {
        case IS_ACTIVE.ACTIVE:
          return <Tag color="green">Hoạt động</Tag>
        case IS_ACTIVE.INACTIVE:
          return <Tag color="volcano">Tạm dùng</Tag>
      }
    },
  },
  {
    width: '90px',
    title: <b>Ngày tạo</b>,
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]
export default function CustomerScreen() {
  const history = useHistory()
  const { dataSource, paging, params, setParams, isLoading } = useCustomer()

  return (
    <Container
      header={<PageHeader title="Khách hàng" />}
      filterComponent={
        <Filter
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search: searchKey === '' ? undefined : searchKey,
              page: 1,
            })
          }}
          onTimeSubmit={(from_date: any, to_date: any) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
          onTypeUserSubmit={(typeUserKey: number) => {
            setParams({
              ...params,
              customer_type: typeUserKey,
              page: 1,
            })
          }}
          onRankSubmit={(rankKey: number) => {
            setParams({
              ...params,
              rank_id: rankKey,
              page: 1,
            })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            size="middle"
            className={styles.table_hover}
            loading={isLoading.loadingTable}
            bordered
            columns={column}
            dataSource={dataSource}
            scroll={{
              x: 800,
              // scrollToFirstRowOnChange: true,
            }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                history.push({
                  pathname: `${ENTERPRISE_ROUTER_PATH.CUSTOMER_DETAIL}/${record.id}`,
                  state: record,
                })
              },
            })}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
