export const isStrongPassword = (pass: string) => {
  // if (!pass || typeof pass != 'string') return false;
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W])(?!.*['"]).{8,}$/.test(
    pass
  )
}

export function formatPrice(num: any) {
  if (
    num === null ||
    num === undefined ||
    Number.isNaN(parseFloat(num)) ||
    num === 0
  )
    return ''
  const result = num.toString().replace(/,/g, '')

  return result.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function injectObject2String(str: string, obj: any) {
  if (obj || obj === null) {
    var keys = Object.keys(obj)
    keys.forEach((key: string) => {
      str = str?.replace(`{{${key}}}`, obj[key])
    })
    return str
  }
  return ''
}

export function enterNumbersOnly(value: any) {
  if (value === null || value === undefined || value == 0) return ''
  var result = value?.replace(/[^\w\s]/gi, '')
  return result
}

export function enterNumbersOnlys(value: any) {
  if (value === null || value === undefined || value == 0) return ''
  var result = value?.replace(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/g, '$1')
  return result
}

export const parseFloatComas = (value: number) => {
  if (!value) return 0
  return parseFloat(value.toString().replace(/,/g, ''))
}

export const parseIntComas = (value: number) => {
  if (!value) return 0
  return parseInt(value.toString().replace(/,/g, ''))
}
export const maxLength = (maxLength: number, msg: string) => (
  value: string | undefined | null
) => (value && value.length > maxLength ? msg : undefined)

export const minLength4 = (value: string | undefined | null) =>
  value && value.length < 4 ? 'Must be' : undefined

export const minValue = (min: number) => (value: number) =>
  value && value < min ? `Must be at least ${min}` : undefined

export const number = (msg: string) => (value: string | undefined | null) =>
  value && isNaN(Number(value)) ? msg : undefined

export const isEmail = (value: string | undefined | null) => {
  if (!value) return false
  return !/^[a-z][a-z0-9%_\.]{3,32}@[a-z0-9]{3,}(\.[a-z]{3,4}){1,2}$/i.test(
    value
  )
}

export const strongPassword = (value: string | undefined | null) =>
  value &&
  !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W])(?!.*['"]).{8,}$/.test(value)
    ? 'Must like Abc@1234'
    : undefined

export const normalizePhone = (value: string | undefined | null) => {
  if (!value) {
    return value
  }

  const onlyNumber = value.replace(/[^\d]/g, '')
  if (onlyNumber.length <= 3) {
    return onlyNumber
  }
  if (onlyNumber.length <= 7) {
    return `${onlyNumber.slice(0, 4)}-${onlyNumber.slice(4)}`
  }
  return `${onlyNumber.slice(0, 4)}-${onlyNumber.slice(
    4,
    7
  )}-${onlyNumber.slice(7, 11)}`
}

function readGroup(group: any) {
  let readDigit = [
    ' Không',
    ' Một',
    ' Hai',
    ' Ba',
    ' Bốn',
    ' Năm',
    ' Sáu',
    ' Bảy',
    ' Tám',
    ' Chín',
  ]
  var temp = ''
  if (group == '000') return ''
  temp = readDigit[parseInt(group.substring(0, 1))] + ' Trăm'
  if (group.substring(1, 2) == '0')
    if (group.substring(2, 3) == '0') return temp
    else {
      temp += ' Lẻ' + readDigit[parseInt(group.substring(2, 3))]
      return temp
    }
  else temp += readDigit[parseInt(group.substring(1, 2))] + ' Mươi'
  if (group.substring(2, 3) == '5') temp += ' Lăm'
  else if (group.substring(2, 3) != '0')
    temp += readDigit[parseInt(group.substring(2, 3))]
  return temp
}

export function readMoney(num: any) {
  if (num == null || num == '') return ''
  let temp = ''
  while (num.length < 18) {
    num = '0' + num
  }
  let g1 = num.substring(0, 3)
  let g2 = num.substring(3, 6)
  let g3 = num.substring(6, 9)
  let g4 = num.substring(9, 12)
  let g5 = num.substring(12, 15)
  let g6 = num.substring(15, 18)
  if (g1 != '000') {
    temp = readGroup(g1)
    temp += ' Triệu'
  }
  if (g2 != '000') {
    temp += readGroup(g2)
    temp += ' Nghìn'
  }
  if (g3 != '000') {
    temp += readGroup(g3)
    temp += ' Tỷ'
  } else if ('' != temp) {
    temp += ' Tỷ'
  }
  if (g4 != '000') {
    temp += readGroup(g4)
    temp += ' Triệu'
  }
  if (g5 != '000') {
    temp += readGroup(g5)
    temp += ' Nghìn'
  }
  temp = temp + readGroup(g6)
  temp = temp.replaceAll('Một Mươi', 'Mười')
  temp = temp.trim()
  temp = temp.replaceAll('Không Trăm', '')
  temp = temp.trim()
  temp = temp.replaceAll('Mười Không', 'Mười')
  temp = temp.trim()
  temp = temp.replaceAll('Mươi Không', 'Mươi')
  temp = temp.trim()
  if (temp.indexOf('Lẻ') == 0) temp = temp.substring(2)
  temp = temp.trim()
  temp = temp.replaceAll('Mươi Một', 'Mươi Mốt')
  temp = temp.trim()
  let result =
    temp.substring(0, 1).toUpperCase() + temp.substring(1).toLowerCase()
  return (result == '' ? 'Không' : result) + ' đồng chẵn'
}
