import { Modal, Input, Form, message } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { RadiusSelection } from 'common/components/Inputs'
import { useEffect, useState } from 'react'
import { notificationSuccess } from 'utils/notification'

type Props = {
  visible: boolean
  onCancel: () => void
  data?: any
  onCreateNotification?: any
  onUpdateNotification?: any
  getData?: any
  // isLoading: boolean
}

const { TextArea } = Input

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      title: null,
      target: null,
      status: null,
      content: null,
    }
  } else {
    return {
      ...data,
      title: data.title,
      target: data.target,
      status: data.status,
      content: data.content,
    }
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

export const AddNotification = ({
  visible,
  onCancel,
  data,
  onCreateNotification,
  onUpdateNotification,
  getData,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const initialValues = convertDataToFrom(data)

  const onFinish = async (values: any) => {
    setIsLoading(true)
    try {
      let newData
      if (!data) {
        newData = {
          ...values,
        }
        onCreateNotification(newData, form.resetFields)

        getData()
      } else {
        newData = {
          ...values,
          id: data.id,
          content: values.content,
          target: values.target,
          status: values.status,
          title: values.title,
        }
        const res = await onUpdateNotification(newData, form.resetFields)

        getData()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      getData()
    }
  }

  useEffect(() => {
    if (data) {
    }
  }, [data])

  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      maskClosable={false}
      footer={null}
      title={data ? 'Sửa thông báo' : 'Thêm thông báo '}
      visible={visible}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        labelAlign="left"
        onFinish={(values: any) => onFinish(values)}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[
            {
              required: true,
              message: 'Vui lòng điền tiêu đề',
            },
            {
              whitespace: true,
              message: 'Không được nhập khoảng trắng!',
            },
            {
              max: 255,
              message: 'Vui lòng không nhập quá 255 ký tự!',
            },
          ]}
        >
          <Input placeholder="Tiêu đề" />
        </Form.Item>
        <Form.Item
          label="Loại tài khoản"
          name="target"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn loại tài khoản',
            },
          ]}
        >
          <RadiusSelection
            placeholder="Loại tài khoản"
            options={[
              { value: 2, text: 'Tất cả' },
              { value: 0, text: 'Khách hàng' },
              { value: 1, text: 'Nhà cung cấp' },
            ]}
            onChange={(value: number) => {
              form.setFieldsValue({ target: value })
            }}
          />
        </Form.Item>
        <Form.Item
          label="Trạng thái thông báo"
          name="status"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn trạng thái',
            },
          ]}
        >
          <RadiusSelection
            placeholder="Trạng thái thông báo"
            options={[
              { value: 0, text: 'Lưu nháp' },
              { value: 1, text: 'Thông báo' },
            ]}
            onChange={(value: number) => {
              form.setFieldsValue({ status: value })
            }}
          />
        </Form.Item>
        <Form.Item
          label="Nội dung"
          name="content"
          rules={[
            {
              required: true,
              message: 'Vui lòng điền nội dung',
            },
            {
              whitespace: true,
              message: 'Không được nhập khoảng trắng!',
            },
          ]}
        >
          <TextArea
            id="text_area_category"
            allowClear
            placeholder="Nhập nội dung thông báo"
            // onKeyPress={e =>
            //   e.shiftKey && e.key === 'Enter' ? onAppendNewLine(e) : ''
            // }
            // value={generalData.description}
          />
        </Form.Item>
        <ButtonBottomModal
          isLoadingButton={isLoading}
          onCancel={onCancel}
          text="Lưu"
        />
      </Form>
    </Modal>
  )
}
