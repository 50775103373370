import { Col, DatePicker, Input, Row } from 'antd'
import Icon from '@ant-design/icons'
import { RadiusSelection } from 'common/components/Inputs'
import moment from 'moment'
import { useCustomer } from '../hook/useCustomer'

const { RangePicker } = DatePicker
const { Search } = Input

type Props = {
  onSearchSubmit: (searchKey: string) => void
  onTimeSubmit: (from_date?: string, to_date?: string) => void
  onRankSubmit: (rankKey: number) => void
  onTypeUserSubmit: (typeUserKey: number) => void
}

export default function Filter({
  onSearchSubmit,
  onTimeSubmit,
  onTypeUserSubmit,
  onRankSubmit,
}: Props) {
  const { listRank, params } = useCustomer()
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={10}>
        <Search
          allowClear
          addonAfter={
            <Icon
              type="close-circle-o"
              onClick={() => {
                //   onSearchSubmit('')
              }}
            />
          }
          defaultValue={params.search}
          placeholder="Tên hoặc số điện thoại khách hàng"
          onChange={e => {
            setTimeout(() => {
              onSearchSubmit(e.target.value.trim())
            }, 300)
          }}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="Loại khách hàng"
          defaultValue={params.customer_type}
          onChange={(value: any) => {
            onTypeUserSubmit(value)
          }}
          options={[
            { text: 'Cá nhân', value: 1 },
            { text: 'Doanh nghiệp', value: 2 },
          ]}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="Hạng khách hàng"
          onChange={(value: any) => {
            onRankSubmit(value)
          }}
          options={listRank}
          defaultValue={params.rank_id}
        />
      </Col>

      <Col span={6}>
        <RangePicker
          placeholder={['Từ ngày', 'Đến ngày']}
          defaultValue={
            params.from_date
              ? [
                  moment(params.from_date, 'YYYY/MM/DD'),
                  moment(params.to_date, 'YYYY/MM/DD'),
                ]
              : null
          }
          onChange={(_, dateString) => {
            console.log('DATE STRING: ', dateString)
            onTimeSubmit(dateString[0], dateString[1])
          }}
        />
      </Col>
    </Row>
  )
}
