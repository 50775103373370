import { ApiClient } from 'services/ApiService'

export const getReportProduct = (payload: any) =>
  ApiClient.get('/petrol-store/report-product', payload)
export const getDetailReportProduct = (payload: any) =>
  ApiClient.get('/petrol-store/detail-report-product', payload)
export const getAllReportProduct = (payload: any) =>
  ApiClient.get('/petrol-store/report-product-in', payload)
export const getAllDetailReportProduct = (payload: any) =>
  ApiClient.get('/petrol-store/detail-report-product-in', payload)
export const getReportTotal = (payload: any) =>
  ApiClient.get('/petrol-store/sum-report-product', payload)
