import { PageHeader } from 'antd'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import { useHistory } from 'react-router'

type Props = {
  title: string
}
export default function HeaderDetail({ title }: Props) {
  const history = useHistory()
  return (
    <div>
      <PageHeader
        title={
          <>
            Chi tiết công nợ cửa hàng
            <span style={{ color: '#B40000' }}> {title}</span>
          </>
        }
        onBack={() => {
          history.goBack()
        }}
        extra={[
          <ButtonExport
            sheetName={['OrderLisst']}
            fileName="Danh sách đơn hàng"
            sheets={{}}
            // loading={isLoadingBtnExportData}
            // onClick={fn => onExportDataToExcel(fn)}
          />,
        ]}
      />
    </div>
  )
}
