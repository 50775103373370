import { Col, DatePicker, Row } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import moment from 'moment'
import { useState } from 'react'
import { PARTNER_REQUEST_STATUS } from 'utils/constants'
import { useBecomePartner } from '../hook/useBecomePartner'

type Props = {
  onSearchSubmit: (searchKey: string) => void
  onStatusSubmit: (statusKey: any) => void
  onTimeSubmit: (from_date?: string, to_date?: string) => void
}
export default function Filter({
  onSearchSubmit,
  onStatusSubmit,
  onTimeSubmit,
}: Props) {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const { setParams, params } = useBecomePartner()
  const handleChangeTime = (dates: any, dateStrings: string) => {
    setParams({
      ...params,
      to_date: dateStrings[1],
      from_date: dateStrings[0],
      page: 1,
    })
  }
  return (
    <div>
      <Row gutter={[16, 16]} justify="start">
        <Col span={10}>
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              onSearchSubmit(searchKey.trim())
            }}
            isSearchLoading={isSearchLoading}
            placeholder="Tên cửa hàng,số điện thoại, Mã số thuế"
          />
        </Col>

        <Col span={5}>
          <RadiusSelection
            placeholder="Trạng thái"
            onChange={value => {
              onStatusSubmit(value)
            }}
            options={[
              { value: PARTNER_REQUEST_STATUS.PENDING, text: 'Chờ phê duyệt' },
              { value: PARTNER_REQUEST_STATUS.ACCEPT, text: 'Phê duyệt' },
              { value: PARTNER_REQUEST_STATUS.REJECT, text: 'Từ chối' },
            ]}
          />
        </Col>

        <Col span={8}>
          <DatePicker.RangePicker
            // placeholder=["Ngày tạo"]
            allowClear
            style={{ width: '100%' }}
            format="YYYY-MM-DD"
            defaultValue={
              params.from_date
                ? [
                    moment(params.from_date, 'YYYY-MM-DD'),
                    moment(params.to_date, 'YYYY-MM-DD'),
                  ]
                : null
            }
            onChange={(_, dateString) => {
              onTimeSubmit(dateString[0], dateString[1])
            }}
          />
        </Col>
      </Row>
    </div>
  )
}
