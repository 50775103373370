import { PageHeader, Table } from 'antd'
import Container from 'common/container/Container'
import Filter from './component/Filter'
import { formatPrice } from 'utils/ruleForm'
import { ENTERPRISE_ROUTER_PATH } from 'common/config'
import history from 'utils/history'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import styles from 'common/components/styles/WhiteBox.module.css'

const DebtReportScreen = () => {
  const data = [
    {
      id: '1',
      name: 'Hải âu hấp hối',
      order_numb: '10',
      debt: '100000',
      debt_total: '1000000',
    },
    {
      id: '2',
      name: 'Rừng Xà Beng',
      order_numb: '10',
      debt: '100000',
      debt_total: '1000000',
    },
  ]

  const column = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: <b>Tên khách hàng</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <b>Số đơn hàng chưa thanh toán</b>,
      dataIndex: 'order_numb',
      key: 'order_numb',
    },
    {
      title: <b>Hạn mức công nợ đăng ký</b>,
      dataIndex: 'debt',
      key: 'debt',
      render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    },
    {
      title: <b>Tổng công nợ hiện tại</b>,
      dataIndex: 'debt_total',
      key: 'debt_total',
      render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    },
  ]
  return (
    <Container
      header={
        <PageHeader
          title="Báo cáo công nợ"
          extra={[
            <ButtonExport
              sheetName={['OrderLisst']}
              fileName="Danh sách đơn hàng"
              sheets={{}}
              // loading={isLoadingBtnExportData}
              // onClick={fn => onExportDataToExcel(fn)}
            />,
          ]}
        />
      }
      filterComponent={<Filter />}
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>1 ..00</b>
          </p>
          <Table
            className={styles.table_hover}
            bordered
            columns={column}
            dataSource={data}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            onRow={record => ({
              onClick: () => {
                history.push({
                  pathname: `${ENTERPRISE_ROUTER_PATH.DEBT_REPORT_DETAIL}/${record.id}`,
                  state: record,
                })
              },
            })}
          />
        </div>
      }
    />
  )
}

export default DebtReportScreen
