import Container from 'common/container/Container'
import { useEffect, useState } from 'react'
import { Table } from 'antd'
import Filter from './components/Filter'
import Header from './components/Header'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useHistory, useLocation } from 'react-router'
import { formatPrice } from 'utils/ruleForm'
import styles from 'common/components/styles/WhiteBox.module.css'
import { getListDebtCustomer } from './DebtCustomerApi'

const column = [
  {
    width: 60,
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    width: 180,
    title: <b>Tên khách hàng</b>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    width: 100,
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  // {
  //   width: 100,
  //   title: <b>Số đơn hàng</b>,
  //   dataIndex: 'total_order',
  //   key: 'total_order',
  //   render: (value: number) => <td>{value ? formatPrice(value) : 0}</td>,
  // },
  {
    width: '100px',
    title: <b>Hạn mức công nợ</b>,
    dataIndex: 'debt_limit',
    key: 'debt_limit',
    render: (value: number) => <td>{value ? formatPrice(value) : 0}</td>,
  },
  {
    width: '100px',
    title: <b>Nợ đầu kỳ</b>,
    dataIndex: 'old_debt',
    key: 'old_debt',
    render: (value: number) => <td>{value ? formatPrice(value) : 0}</td>,
  },
  {
    width: '100px',
    title: <b>Nợ cuối kỳ</b>,
    dataIndex: 'total_debt',
    key: 'total_debt',
    render: (value: number) => <td>{value ? formatPrice(value) : 0}</td>,
  },
  // {
  //   width: '150px',
  //   title: <b>Tổng nợ hiện tại </b>,
  //   dataIndex: 'total_debt',
  //   key: 'total_debt',
  //   render: (value: number) => <td>{value ? formatPrice(value) : 0}</td>,
  // },
]

export default function DebtCustomerScreen() {
  const history = useHistory()
  const location: any = useLocation()
  const [isLoading, setIsLoading] = useState({
    isLoadingTable: false,
    isLoadingSearch: false,
  })
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [params, setParams] = useState<any>({ search: undefined, page: 1 })
  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
    debt_from_date: '',
    debt_to_date: '',
  })

  const getData = async () => {
    try {
      setIsLoading({ ...isLoading, isLoadingTable: true })
      const res = await getListDebtCustomer(params)
      const dataTable = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
        }
      })

      setPaging({
        ...paging,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
      setDataSource(dataTable)
    } catch (error: any) {
    } finally {
      setIsLoading({ ...isLoading, isLoadingTable: false })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getData()
    }, 500)
  }, [params])

  useEffect(() => {
    setParams({
      ...params,
      debt_from_date: location.state?.debt_from_date,
      debt_to_date: location.state?.debt_to_date,
    })
  }, [location?.state])

  return (
    <Container
      header={<Header params={params} />}
      filterComponent={
        <Filter
          params={params}
          isSearchLoading={isLoading.isLoadingSearch}
          onSearchSubmit={(searchKey: string | undefined) => {
            setParams({
              ...params,
              search: searchKey?.trim() ? searchKey.trim() : undefined,
              page: 1,
            })
          }}
          onDateSubmit={(from_date: string, to_date: string) => {
            setParams({
              ...params,
              debt_from_date: from_date,
              debt_to_date: to_date,
            })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            size="middle"
            loading={isLoading.isLoadingTable}
            bordered
            columns={column}
            dataSource={dataSource}
            scroll={{
              x: 800,
            }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.DEBT_CUSTOMERS_DETAIL}/${record.id}`,
                  state: {
                    ...record,
                    ...params,
                    debt_from_date: params.debt_from_date,
                    debt_to_date: params.debt_to_date,
                  },
                })
              },
            })}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
