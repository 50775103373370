import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import { Button, Descriptions, Tabs, Popconfirm, Card, Row, Col } from 'antd'
import {
  RenderChatImage,
  RenderViewerFile,
} from 'features/admin/shop/partner/PartnerDetailScreen'
import moment from 'moment'
import { isImage } from 'utils'
import { PARTNER_REQUEST_STATUS } from 'utils/constants'

type Props = {
  data: any
  onRefuse: (id: number) => void
  onAccept: (id: number) => void
  onOpenModal: () => void
}

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const ContentView = (data: any) => {
  return (
    <>
      <Descriptions size="default" column={2}>
        <Descriptions.Item label="Tên doanh nghiệp/khách hàng">
          {data.name}
        </Descriptions.Item>
        <Descriptions.Item label="Mã số thuế">
          {data.tax_code}
        </Descriptions.Item>
        <Descriptions.Item label="Số điện thoại">
          {data.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Địa chỉ đăng ký thuế">
          {data.tax_address}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
        <Descriptions.Item label="Mã giới thiệu">
          {data?.user?.code}
        </Descriptions.Item>
        <Descriptions.Item label="Thời gian gửi">
          {moment(data.create_at).format('DD/MM/YYYY')}
        </Descriptions.Item>
        {data.approve_status === PARTNER_REQUEST_STATUS.REJECT ? (
          <Descriptions.Item label="Lý do từ chối">
            {data.reason}
          </Descriptions.Item>
        ) : (
          ''
        )}
      </Descriptions>
      <Row gutter={[24, 0]}>
        {data?.enterprise_medias?.map((partner: any) => {
          if (isImage(partner?.media_url)) {
            return (
              <Col span={4}>
                <RenderChatImage key={partner.id} item={partner} />
              </Col>
            )
          }
          return (
            <Col span={4}>
              <RenderViewerFile key={partner.id} item={partner} />
            </Col>
          )
        })}
      </Row>
    </>
  )
}

function EnterpriseDetail({ data, onRefuse, onAccept, onOpenModal }: Props) {
  return (
    <div>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Chi tiết đăng ký" key="1">
          <Card
            className="expand"
            actions={
              data.approve_status !== 2
                ? []
                : [
                    <Popconfirm
                      title="Đồng ý phản hồi yêu cầu của người liên hệ ?"
                      onConfirm={() => {
                        try {
                          onAccept(data.id)
                        } catch (error) {
                          console.log(error)
                        }
                      }}
                      okText={
                        <>
                          <CheckCircleOutlined /> Phê duyệt
                        </>
                      }
                      cancelText={
                        <>
                          <LeftCircleOutlined /> Đóng
                        </>
                      }
                      cancelButtonProps={{
                        style: {
                          borderColor: 'black',
                          borderRadius: 3,
                        },
                      }}
                      okButtonProps={{
                        style: {
                          color: '#1890ff',
                          backgroundColor: 'white',
                          borderColor: '#1890ff',
                        },
                      }}
                    >
                      <Button
                        type="text"
                        size="large"
                        style={{
                          color: '#1890ff',
                          borderRadius: 3,
                        }}
                        icon={<CheckCircleOutlined />}
                      >
                        <b> Phê duyệt</b>
                      </Button>
                    </Popconfirm>,

                    <Button
                      type="text"
                      size="large"
                      icon={<CloseCircleOutlined />}
                      style={{ color: 'red' }}
                      onClick={onOpenModal}
                    >
                      <b> Từ chối</b>
                    </Button>,
                  ]
            }
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default EnterpriseDetail
