import { Empty, Select } from 'antd'
import './Input.css'

const { Option } = Select

export interface OptionItem {
  value: string | number
  text: string | number
}

export interface PropsRadiusSelection {
  placeholder: string
  defaultValue?: number | string
  options: Array<OptionItem>
  onChange: (value: any, text: any) => void
  onFocus?: () => void
  onBlur?: () => void
  showSearch?: boolean
  onSearch?: () => void
  style?: any
  disabled?: boolean
  value?: any
  modeSelect?: string
  emtpyText?: string
  showArrow?: boolean
  dropdownStyle?: any
}

export default function RadiusSelection({
  placeholder,
  defaultValue,
  options,
  onChange,
  onFocus,
  onBlur,
  showSearch,
  onSearch,
  style,
  disabled,
  value,
  modeSelect,
  emtpyText,
  showArrow,
  dropdownStyle,
}: PropsRadiusSelection) {
  return (
    <Select
      mode={!modeSelect ? undefined : 'multiple'}
      className="radius-select"
      style={style ? style : { width: '100%' }}
      allowClear
      dropdownStyle={dropdownStyle}
      showArrow={showArrow}
      placeholder={placeholder}
      defaultValue={defaultValue}
      optionFilterProp="children"
      showSearch={showSearch}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      disabled={disabled ? disabled : false}
      value={value}
    >
      {options.length >= 1 ? (
        options?.map((item: OptionItem) => (
          <Option value={item.value}>{item.text}</Option>
        ))
      ) : (
        <Option value={1} disabled>
          {emtpyText ? emtpyText : 'Trống'}
        </Option>
      )}
    </Select>
  )
}
