import FilterOrderTab from './FilterOrderTab'
import { Table, Tag } from 'antd'
import { formatPrice } from 'utils/ruleForm'
import moment from 'moment'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ORDER_STATUS } from 'utils/constants'
import { useDetailShop } from '../hook/useDetailShop'
import history from 'utils/history'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useParams } from 'react-router-dom'
import React from 'react'
import { getOrderPetrolStore } from '../ShopApi'

export default function OrderTab() {
  const { setParamsOrder, paramsOrder }: any = useDetailShop()

  const params: { id: any } = useParams()

  const [orders, setOrders] = React.useState<Array<any>>([])

  const [pagingOrder, setPagingOrder] = React.useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })

  React.useEffect(() => {
    if (!params.id) return
    ;(async () => {
      const res = await getOrderPetrolStore(params.id, {
        ...paramsOrder,
      })
      if (res?.status) {
        const tableData = res.data.map((item: any, index: number) => {
          return {
            key: item.id,
            stt:
              (res.meta.pagination.page - 1) * res.meta.pagination.limit +
              index +
              1,
            code: item.code,
            name: item?.user?.name,
            phone: item?.user?.phone,
            total_amount: item.total_amount,
            total_price: item.total_price,
            create_at: item.create_at,
            status: item.status,
          }
        })

        const formattedPaging = {
          total: res.meta.pagination.totalItems,
          current: res.meta.pagination.page,
          pageSize: res.meta.pagination.limit,
        }
        setPagingOrder(formattedPaging)
        setOrders(tableData)
      }
    })()
  }, [paramsOrder, params.id])

  const columns = [
    {
      title: <b>STT</b>,
      dataIndex: 'stt',
      width: 70,
    },
    {
      title: <b>Mã đơn</b>,
      dataIndex: 'code',
    },
    {
      title: <b>Khách hàng</b>,
      dataIndex: 'name',
    },
    {
      title: <b>Số điện thoại</b>,
      dataIndex: 'phone',
    },
    {
      title: <b>Số SP</b>,
      dataIndex: 'total_amount',
      render: (value: any) => {
        return <p>{value == 0 ? 1 : formatPrice(Number(value).toFixed(2))}</p>
      },
    },
    {
      title: <b>Tổng tiền</b>,
      dataIndex: 'total_price',
      render: (value: any) => {
        return (
          <p>
            {value == 0
              ? 'Chưa xác định'
              : formatPrice(Number(value).toFixed(0)) + 'đ'}
          </p>
        )
      },
    },
    {
      width: 150,
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      render: (value: number) => {
        switch (value) {
          case ORDER_STATUS.PENDING:
            return <Tag color="blue">Chờ xác nhận</Tag>
          case ORDER_STATUS.CONFIRMED:
            return <Tag color="blue">Đang thực hiện</Tag>
          // case ORDER_STATUS.SHIP:
          //   return <Tag color="green">Đang vận chuyển</Tag>
          case ORDER_STATUS.SUCCCESS:
            return <Tag color="green">Hoàn thành</Tag>
          case ORDER_STATUS.CANCELED:
            return <Tag color="volcano">Hủy</Tag>
          case ORDER_STATUS.DENY:
            return <Tag color="volcano">Từ chối</Tag>
        }
      },
    },
    {
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      render: (value: any) => {
        return <td>{moment(value).format('DD/MM/YYYY')}</td>
      },
    },
    {
      title: <b>Chi tiết</b>,
      render: (record: any) => {
        return (
          <InfoCircleOutlined
            style={{ fontSize: '16px', color: 'darkblue' }}
            onClick={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.key}`,
                state: {
                  page: record.current,
                  search: record.search,
                  status: record.status,
                  from_date: record.from_date,
                  to_date: record.to_date,
                  name: ADMIN_ROUTER_PATH.SHOP_DETAIL,
                  idShop: params.id,
                },
              })
            }}
          />
        )
      },
      width: 60,
    },
  ]
  return (
    <div>
      <FilterOrderTab
        onSearchSubmit={(searchKey: string) => {
          setParamsOrder({ ...paramsOrder, search: searchKey, page: 1 })
        }}
        onDateSubmit={(fromDate: string, toDate: string) => {
          setParamsOrder({
            ...paramsOrder,
            from_date: fromDate,
            to_date: toDate,
          })
        }}
        onStatusSubmit={(statusKey: string) => {
          setParamsOrder({ ...paramsOrder, order_status: statusKey, page: 1 })
        }}
      />
      <div style={{ marginTop: '10px' }}>
        <span>
          Kết quả lọc: <b>{pagingOrder.total}</b>
        </span>
        <Table
          style={{ marginTop: '10px' }}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
          }}
          // onRow={(record, rowIndex) => ({
          //   onClick: () => {
          //     history.push({
          //       pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.key}`,
          //       state: {
          //         page: pagingOrder.current,
          //         search: paramsOrder.search,
          //         category_id: paramsOrder.category_id,
          //         status: paramsOrder.status,
          //       },
          //     })
          //   },
          // })}
          dataSource={orders}
          bordered
          columns={columns}
          pagination={{
            ...pagingOrder,
            onChange: async (page, pageSize) => {
              setParamsOrder({ ...paramsOrder, page })
            },
          }}
        />
      </div>
    </div>
  )
}
