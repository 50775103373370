import moment from 'moment'
import { Table, Tag } from 'antd'
import Filter from './components/Filter'
import { useNotification } from './hook/useNotification'
import Container from 'common/container/Container'
import DetailNotification from './components/DetailNotification'
import styles from 'common/components/styles/WhiteBox.module.css'
import HeaderNotify from './components/Header'
import { notificationError, notificationSuccess } from 'utils/notification'
import {
  creatNotify,
  deleteNotify,
  getListNotify,
  updateNotify,
} from './NotifyApi'
import { IS_ACTIVE } from 'utils/constants'
import { useEffect, useState } from 'react'
import { AddNotification } from './components/AddNotification'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  from_date?: string
  to_date?: string
  target?: number
}

export default function Banner() {
  const {
    isLoadingTable,
    currentSelected,
    setCurrentSelected,
    setIsLoadingTable,
  } = useNotification()

  const [dataSource, setDataSource] = useState()
  const [addNotify, setAddNotify] = useState(false)
  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 24,
  })
  const [params, setParams] = useState<Params>({
    search: undefined,
    page: 1,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    target: undefined,
  })

  const getData = async () => {
    try {
      const res = await getListNotify(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          key: item.id,
          id: item.id,
          title: item.title,
          content: item.content,
          create_at: item.create_at,
          status: item.status,
          target: item.target,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
        }
      })
      setDataSource(tableData)
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getData()
  }, [params])

  const createNotify = async (data: any, resetFields: any) => {
    try {
      const res = await creatNotify(data)
      setAddNotify(false)
      resetFields()
      notificationSuccess(res.message)
      getData()
      window.location.reload()
    } catch (error) {
      console.log(error)
      notificationError(`Đã có lỗi xảy ra`)
    }
    // setAddNotify(false)
    // getData()
  }

  const columns = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: <b>Tiêu đề </b>,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: <b>Loại tài khoản</b>,
      dataIndex: 'target',
      key: 'target',
      render: (value: number) => {
        switch (value) {
          case 0:
            return <span>Khách hàng</span>
          case 1:
            return <span>Nhà cung cấp</span>
          case 2:
            return <span>Tất cả</span>
        }
      },
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: number) => {
        switch (value) {
          case IS_ACTIVE.ACTIVE:
            return <Tag color="green">Đăng bài</Tag>
          case IS_ACTIVE.INACTIVE:
            return <Tag color="volcano">Lưu nháp</Tag>
        }
      },
    },
    {
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(new Date(value)).format('HH:mm DD/MM/YYYY')}</>
      },
    },
  ]

  return (
    <Container
      header={<HeaderNotify setAddNotify={setAddNotify} />}
      filterComponent={
        <Filter
          data={params}
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search:
                searchKey.trim() === '' ? undefined : searchKey.trimStart(),
              page: 1,
            })
          }}
          onTypeUser={(userKey: number) => {
            setParams({ ...params, target: userKey, page: 1 })
          }}
          onDateSubmit={(from_date: string, to_date: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
        />
      }
      contentComponent={
        <>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            bordered
            dataSource={dataSource}
            loading={isLoadingTable}
            columns={columns}
            expandedRowKeys={[currentSelected.id]}
            onRow={(row: any) => ({
              onClick: () => {
                if (currentSelected.id !== row.id) setCurrentSelected(row)
                else setCurrentSelected({ id: -1 })
              },
            })}
            expandable={{
              expandedRowRender: (record: any) => (
                <DetailNotification
                  // setEditNotify={setEditNotify}
                  // editNotify={editNotify}
                  // onUpdateNotification={updateNotifys}
                  onDeleteNotification={async (id: any) => {
                    try {
                      const res = await deleteNotify({ id: [id] })
                      notificationSuccess(res.message)
                      getData()
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                  data={record}
                  getData={getData}
                />
              ),
            }}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
          {addNotify && (
            <AddNotification
              onCreateNotification={createNotify}
              getData={getData}
              visible={addNotify}
              onCancel={() => setAddNotify(false)}
            />
          )}
        </>
      }
    />
  )
}
