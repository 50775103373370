import { LoadingOutlined, SplitCellsOutlined } from '@ant-design/icons'
import { Button } from 'antd'

type ButtonFixedProps = {
  onClickButton?: Function
  text?: string
  icon?: any
  loading?: boolean
  size?: any
  htmlType?: any
  styleButton?: any
  isDisable?: any
}

const ButtonMoveInventory = ({
  text,
  onClickButton,
  icon,
  loading,
  size,
  htmlType,
  styleButton,
  isDisable,
}: ButtonFixedProps) => {
  return (
    <Button
      disabled={isDisable && isDisable}
      type="primary"
      style={
        !styleButton
          ? {
              borderRadius: '3px',
              backgroundColor: '#369DF1',
              borderColor: '#369DF1',
              color: 'white',
            }
          : styleButton
      }
      htmlType={htmlType ? htmlType : 'button'}
      onClick={() => {
        onClickButton && onClickButton()
      }}
      size={size ? size : 'middle'}
    >
      <b>
        {loading ? <LoadingOutlined /> : icon ? icon : <SplitCellsOutlined />}
        &nbsp;
        {text}
      </b>
    </Button>
  )
}

export default ButtonMoveInventory
