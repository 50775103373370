import Icon from '@ant-design/icons'
import { Col, Input, Row, Select } from 'antd'
import { Dispatch, SetStateAction } from 'react'

interface IFilter {
  setSearchKey: Dispatch<SetStateAction<string>>
  setCurrentAccountType: Dispatch<SetStateAction<number>>
  currentAccountType: number
  searcKey: string
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const Filter = (props: IFilter) => {
  const {
    setSearchKey,
    setCurrentAccountType,
    currentAccountType,
    searcKey,
    setCurrentPage,
  } = props
  const handleChange = (value: number) => {
    setCurrentAccountType(value)
    setCurrentPage(1)
  }
  return (
    <Row gutter={[16, 16]}>
      <Col span={7}>
        <Input.Search
          allowClear
          style={{ width: '100%' }}
          placeholder="Tên người dùng"
          addonAfter={<Icon type="close-circle-o" />}
          defaultValue={searcKey}
          onChange={(e: any) => {
            setSearchKey(e.target.value)
            setCurrentPage(1)
          }}
        />
      </Col>
      <Col span={7}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Loại tài khoản"
          onChange={handleChange}
          defaultValue={
            currentAccountType === 0 ? undefined : currentAccountType
          }
        >
          <Select.Option value={1}>Cá nhân</Select.Option>
          <Select.Option value={2}>Doanh nghiệp</Select.Option>
        </Select>
      </Col>
    </Row>
  )
}

export default Filter
