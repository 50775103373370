import { useEffect, useState } from 'react'
import { getConfigBank, createConfigBank, updateConfigBank } from '../ConfigApi'
import { notificationError, notificationSuccess } from 'utils/notification'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  from_date: undefined | string
  to_date: undefined | string
  role_id?: string
  limit: number
}
export const useConfigBank = () => {
  const [itemUpdate, setItemUpdate] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [listConfigBank, setListConfigBank] = useState<any>([])
  const [showAddConfigBank, setShowAddConfigBank] = useState(false)
  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    role_id: undefined,
    page: 1,
    limit: 12,
  })

  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      const res = await getConfigBank(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt: (paging.current - 1) * paging.pageSize + index + 1,
        }
      })
      setListConfigBank(tableData)
      setPaging({
        ...paging,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const createBank = async (data: any, resetFields: any, setUpload: any) => {
    try {
      const res = await createConfigBank(data)
      setShowAddConfigBank(false)
      resetFields()
      notificationSuccess(res.message)
      getData()
    } catch (error) {
      console.log(error)
      notificationError(`Đã có lỗi xảy ra`)
    } finally {
      setUpload({
        loading: false,
        imageUrl: '',
      })
    }
    getData()
  }

  // const updateBank = async (data: any, resetFields: any, index: number) => {
  //   try {
  //     let dataPush = {
  //       id: data.id,
  //       data: {
  //         name: data.name,
  //         icon_url: data.icon_url,
  //         account_number: data.account_number,
  //         owner: data.owner,
  //       },
  //     }
  //     const res = await updateConfigBank(dataPush)
  //     console.log('response:', res)
  //     setItemUpdate(res)
  //     notificationSuccess(res.message)
  //     getData()
  //     setShowEditBank(false)
  //     resetFields()
  //   } catch (error) {
  //     notificationError(`Đã có lỗi xảy ra`)
  //     console.log(error)
  //   }
  // }
  // console.log('itemUpdate', itemUpdate)

  return {
    isLoading,
    currentSelected,
    params,
    paging,
    listConfigBank,
    setPaging,
    setIsLoading,
    setCurrentSelected,
    setParams,
    getData,
    setListConfigBank,
    setShowAddConfigBank,
    showAddConfigBank,
    createBank,
    // showEditBank,
    // setShowEditBank,
    // updateBank,
    itemUpdate,
  }
}
