import { Col, Row } from 'antd'
import React, { ReactNode } from 'react'

const FormItemComponent = ({
  label,
  children,
  isRequired,
  layout = 'horizontal',
}: {
  label: ReactNode
  children: ReactNode
  isRequired?: boolean
  layout?: 'horizontal' | 'vertical'
}) => {
  return (
    <Row
      gutter={layout === 'vertical' ? [0, 12] : [0, 0]}
      style={{ width: '100%' }}
    >
      <Col span={layout === 'vertical' ? 24 : 8}>
        <div style={{ marginRight: '10px' }}>
          <b>{label} </b>
          {isRequired && <b style={{ color: 'red' }}>&#42;</b>}
        </div>
      </Col>
      <Col span={layout === 'vertical' ? 24 : 16}>{children}</Col>
    </Row>
  )
}

export default FormItemComponent
