import { PageHeader, Table, Tag } from 'antd'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import { ENTERPRISE_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ORDER_STATUS } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import FilterDetail from './component/FilterDetail'
import {
  getAllDetailReportProduct,
  getDetailReportProduct,
} from './ReportSaleProductApi'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import ButtonExportExcelV2 from 'common/components/Buttons/ButtonExportExcelV2'
type TParams = {
  page: number
  search?: string
  petrol_store_id?: number
  from_date?: string | Date
  to_date?: string | Date
  product_id?: number
  status?: number
  status_payment?: number
  type_payment?: number
  is_paid?: number
}

const column = [
  {
    width: '70px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Ngày tạo</b>,
    dataIndex: 'create_at',
    key: 'create_at',
    render: (data: any) => <div>{moment(data).format('DD/MM/YYYY')}</div>,
  },
  {
    title: <b>Mã đơn hàng</b>,
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: <b>Giá bán</b>,
    dataIndex: 'product_price',
    key: 'product_price',
    render: (value: any) => (
      <>{value == null || 0 ? '0' : formatPrice(Number(value).toFixed(0))}</>
    ),
  },
  {
    title: <b>Số lượng đặt</b>,
    dataIndex: 'amount',
    key: 'amount',
    render: (value: any) => (
      <>
        {value == null || value == 0
          ? '0'
          : formatPrice(Number(value).toFixed(3))}
      </>
    ),
  },
  {
    title: <b>Thành tiền</b>,
    dataIndex: 'total_price',
    key: 'total_price',
    render: (value: any) => (
      <>
        {value == null || value == 0
          ? '0'
          : formatPrice(Number(value).toFixed(0))}{' '}
        đ
      </>
    ),
  },
  {
    title: <b>Người mua</b>,
    dataIndex: 'user',
    key: 'user',
  },
  {
    width: '100px',
    title: <b>Phương thức thanh toán</b>,
    dataIndex: 'order_payment',
    key: 'order_payment',
    render: (value: any) => <>{value?.type_payment?.name}</>,
  },
  {
    width: '120px',
    title: <b>TT Đơn hàng</b>,
    dataIndex: 'status',
    key: 'status',
    render: (value: any) => {
      switch (value) {
        case ORDER_STATUS.PENDING:
          return <Tag color="blue">Chờ xác nhận</Tag>
        case ORDER_STATUS.CONFIRMED:
          return <Tag color="blue">Đang thực hiện</Tag>
        case ORDER_STATUS.SUCCCESS:
          return <Tag color="green">Hoàn thành</Tag>
        case ORDER_STATUS.CANCELED:
          return <Tag color="volcano">Hủy</Tag>
        case ORDER_STATUS.DENY:
          return <Tag color="volcano">Từ chối</Tag>
      }
    },
  },
  {
    width: '90px',
    title: <b>TT Thanh toán</b>,
    dataIndex: 'order_payment',
    key: 'order_payment',
    render: (value: any) => {
      return (
        <td>
          {value?.is_paid === 1 ? (
            <Tag style={{ fontSize: 12 }} color="green">
              Đã thanh toán
            </Tag>
          ) : (
            <Tag style={{ fontSize: 12 }} color="volcano">
              Chưa thanh toán
            </Tag>
          )}
        </td>
      )
    },
  },
  {
    title: <b>Biển kiểm soát</b>,
    dataIndex: 'number_plate',
    key: 'number_plate',
  },
]
const columns = [
  {
    width: '70px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Ngày tạo</b>,
    dataIndex: 'create_at',
    key: 'create_at',
    render: (data: any) =>
      data && <div>{moment(data).format('HH:mm DD/MM/YYYY')}</div>,
  },
  {
    title: <b>Tên sản phẩm</b>,
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: <b>Mã đơn hàng</b>,
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: <b>Số lượng</b>,
    dataIndex: 'amount',
    key: 'amount',
    render: (value: any, row: any, index: number) => (
      <>
        {!value ? (
          'Chưa xác định'
        ) : index > 0 ? (
          formatPrice(Number(value).toFixed(2))
        ) : (
          <Tag color="processing">{formatPrice(Number(value).toFixed(2))}</Tag>
        )}
      </>
    ),
  },
  {
    title: <b>Đơn giá</b>,
    dataIndex: 'product_price',
    key: 'product_price',
    render: (value: any, row: any, index: number) =>
      index > 0 && <>{!value ? '0' : formatPrice(Number(value).toFixed(0))}</>,
  },
  {
    title: <b>Thành tiền</b>,
    dataIndex: 'total_price',
    key: 'total_price',
    render: (value: any, row: any, index: number) => (
      <>
        {!value ? (
          'Chưa xác định'
        ) : index > 0 ? (
          formatPrice(Number(value).toFixed(2))
        ) : (
          <Tag color="processing">{formatPrice(Number(value).toFixed(2))}</Tag>
        )}
      </>
    ),
  },
  {
    title: <b>Người mua hàng</b>,
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: <b>SĐT</b>,
    dataIndex: 'user_phone',
    key: 'user_phone',
  },
  {
    title: <b>Người thao tác</b>,
    dataIndex: 'manager',
    key: 'manager',
  },
  // {
  //   width: '100px',
  //   title: <b>Phương thức thanh toán</b>,
  //   dataIndex: 'order_payment',
  //   key: 'order_payment',
  //   render: (value: any) => <>{value?.type_payment?.name}</>,
  // },
  {
    width: '120px',
    title: <b>TT Đơn hàng</b>,
    dataIndex: 'status',
    key: 'status',
    render: (value: any) => {
      switch (value) {
        case ORDER_STATUS.PENDING:
          return <Tag color="blue">Chờ xác nhận</Tag>
        case ORDER_STATUS.CONFIRMED:
          return <Tag color="blue">Đang thực hiện</Tag>
        case ORDER_STATUS.SUCCCESS:
          return <Tag color="green">Hoàn thành</Tag>
        case ORDER_STATUS.CANCELED:
          return <Tag color="volcano">Hủy</Tag>
        case ORDER_STATUS.DENY:
          return <Tag color="volcano">Từ chối</Tag>
      }
    },
  },
  {
    width: '90px',
    title: <b>TT Thanh toán</b>,
    dataIndex: 'order_payment',
    key: 'order_payment',
    render: (value: any) => {
      return (
        <td>
          {value?.is_paid === 1 ? (
            <Tag style={{ fontSize: 12 }} color="green">
              Đã thanh toán
            </Tag>
          ) : (
            <Tag style={{ fontSize: 12 }} color="volcano">
              Chưa thanh toán
            </Tag>
          )}
        </td>
      )
    },
  },
  {
    title: <b>Biển kiểm soát</b>,
    dataIndex: 'number_plate',
    key: 'number_plate',
  },
  {
    title: <b>Ghi chú</b>,
    dataIndex: 'note',
    key: 'note',
  },
]
export default function DetailSaleReport() {
  const param: any = useParams()
  const history = useHistory()
  const location: any = useLocation()

  const [isLoading, setisLoading] = useState<boolean>(false)
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 13,
  })
  const [dataSource, setDataSource] = useState<any>([])
  console.log(
    '🚀 ~ file: DetailSaleReportScreen.tsx:285 ~ DetailSaleReport ~ dataSource:',
    dataSource
  )
  const [params, setParams] = useState<TParams>({
    page: 1,
    search: undefined,
    petrol_store_id: param.petrol_store_id,
    from_date: undefined,
    to_date: undefined,
    product_id: param.product_id,
    status: undefined,
    status_payment: undefined,
  })

  // useEffect(() => {
  //   if (location?.state) {
  //     setParams({
  //       ...params,
  //       from_date: location?.state.from_date,
  //       to_date: location?.state.to_date,
  //     })
  //   }
  // }, [])

  useEffect(() => {
    getDetail()
  }, [params])

  const getDetail = async () => {
    try {
      setisLoading(true)
      let countOrderQuantity = 0
      let countIntoMoney = 0

      const res = await getDetailReportProduct(params)

      const dataTable = res.data?.map((item: any, index: number) => {
        countOrderQuantity += item.order_items[0]?.amount
          ? +item.order_items[0]?.amount
          : 0
        countIntoMoney += item.order_items[0]?.total_price
          ? +item.order_items[0]?.total_price
          : 0

        return {
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          key: item.id,
          code: item.code,
          create_at: item.create_at,
          amount: item.order_items[0].amount,
          product_price: item.order_items[0].product_price,
          total_price: item?.order_payment?.total_price,
          user: item.user.name,
          user_phone: item.user?.phone,
          manager: item.order_states[0]?.manager?.name,
          // order_payment: item.order_payment,
          status: item.status,
          is_full_fuel: item?.order_gasoline?.is_full_fuel,
          product_name: location.state.product_name,
          note: item?.note,
          number_plate: item?.user_vehicle?.number_plate,
          order_payment:
            item?.order_payment?.is_paid === 1
              ? 'Đã thanh toàn'
              : 'Chưa thanh toán',
        }
      })
      setDataSource([
        {
          id: Math.random().toString(),
          amount: countOrderQuantity,
          total_price: countIntoMoney,
        },
        ...dataTable,
      ])
      setPaging({
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: 13,
      })
    } catch (error: any) {
      console.log(error)
    } finally {
      setisLoading(false)
    }
  }
  const StatusOrder = (status: number) => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return 'Chờ xác nhận'
      case ORDER_STATUS.CONFIRMED:
        return 'Đang thực hiện'
      case ORDER_STATUS.SUCCCESS:
        return 'Hoàn thành'
      case ORDER_STATUS.CANCELED:
        return 'Hủy'
      case ORDER_STATUS.DENY:
        return 'Từ chối'
    }
  }
  const [isLoadingBtnExportData, setLoadingBtnExportData] = useState<boolean>(
    false
  )

  // export
  const onExportData = async () => {
    try {
      const workbook = new Excel.Workbook()

      setLoadingBtnExportData(true)
      const worksheet = workbook.addWorksheet('Báo cáo chi tiết bán hàng')

      // columns
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10 },
        { header: 'Ngày tạo', key: 'created_at', width: 20 },
        { header: 'Mã đơn hàng', key: 'code_order', width: 20 },
        { header: 'Tên SP', key: 'product_name', width: 20 },
        { header: 'Số lượng', key: 'amount', width: 20 },
        { header: 'Đơn giá', key: 'price', width: 20 },
        { header: 'Thành tiền', key: 'total_price', width: 20 },
        { header: 'Người mua hàng', key: 'customer', width: 20 },
        { header: 'SĐT người mua', key: 'customer_phone', width: 30 },
        { header: 'Người thao tác', key: 'manager', width: 30 },
        { header: 'Trạng thái đơn hàng', key: 'order_status', width: 30 },
        { header: 'TT thanh toán', key: 'order_payment', width: 20 },
        { header: 'Biển số xe', key: 'number_plate', width: 20 },
        { header: 'Ghi chú', key: 'note', width: 20 },
      ]

      worksheet.insertRow(1, [
        'BÁO CÁO BÁN HÀNG SẢN PHẨM ' + location.state?.product_name,
      ])
      worksheet.mergeCells('A1', 'J1')
      worksheet.insertRow(2, [
        params.from_date
          ? `Thời gian thực hiện: Từ ngày ${moment(params.from_date)?.format(
              'DD/MM/YYYY'
            )} đến ngày ${moment(params.to_date)?.format('DD/MM/YYYY')}`
          : 'Thời gian thực hiện: -',
      ])
      worksheet.mergeCells('A2', 'J2')
      worksheet.insertRow(3, [
        'GIAN HÀNG: ' + location.state?.petrol_store_name,
      ])
      worksheet.mergeCells('A3', 'E3')

      //Todo:  style báo cáo bán hnagf
      worksheet.getRow(1).font = { size: 18, color: { argb: '00000' } }
      worksheet.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      //Todo:  style từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }
      worksheet.getCell('A3').font = { size: 15 }

      // todo: boder style
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      // end

      const dataListOrderNotPagging = await getDetailReportProduct({
        ...params,
        limit: 999,
      })

      let totalAmount = 0
      let totalPrice = 0
      let totalPayment = 0

      dataListOrderNotPagging?.data.forEach((o: any, i: number) => {
        worksheet.addRow({
          id: i + 1,
          created_at: moment(o?.create_at).format('HH:mm DD/MM/YYYY'),
          code_order: o?.code,
          amount: +o?.order_items[0]?.amount
            ? +o?.order_items[0]?.amount
            : 'Chưa xác định',
          price: +o.order_items[0]?.product_price,
          total_price: +o?.order_payment?.total_price,
          customer: o?.user?.name,
          customer_phone: o?.user?.phone,
          order_status: StatusOrder(o?.status),
          note: o?.note,
          product_name: location?.state?.product_name,
          number_plate: o?.user_vehicle?.number_plate,
          order_payment:
            o?.order_payment?.is_paid === 1
              ? 'Đã thanh toàn'
              : 'Chưa thanh toán',
        })
        totalAmount += +o?.order_items[0]?.amount
        totalPrice += +o?.order_items[0]?.product_price
        totalPayment += +o?.order_items[0]?.total_price
      })

      worksheet.insertRow(dataListOrderNotPagging?.data.length + 5, ['TỔNG'])
      worksheet.mergeCells(
        `A${dataListOrderNotPagging?.data.length + 5}`,
        `D${dataListOrderNotPagging?.data.length + 5}`
      )

      //Todo:  style báo cáo bán hnagf
      worksheet.getRow(dataListOrderNotPagging?.data.length + 5).font = {
        size: 13,
        color: { argb: '00000' },
        bold: true,
      }
      worksheet.getRow(dataListOrderNotPagging?.data.length + 5).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      worksheet.getCell(
        `A${dataListOrderNotPagging?.data.length + 5}`
      ).border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell(
        `E${dataListOrderNotPagging?.data.length + 5}`
      ).value = totalAmount
      // worksheet.getCell(
      //   `F${dataListOrderNotPagging?.data.length + 5}`
      // ).value = totalPrice
      worksheet.getCell(
        `G${dataListOrderNotPagging?.data.length + 5}`
      ).value = totalPayment

      worksheet.eachRow({ includeEmpty: true }, function (row: any, rowNumber) {
        if (row?._number === 4) {
          row.eachCell({ includeEmpty: true }, function (cell: any) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '87CEEB' },
            }
          })
        }

        row.eachCell({ includeEmpty: true }, function (cell: any) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(
        new Blob([buf]),
        `Báo cáo chi tiết ${location.state?.product_name}.xlsx`
      )
    } catch (error) {
      console.log(
        '🚀 ~ file: DetailSaleReport.tsx ~ line 480 ~ onExportData ~ error',
        error
      )
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  console.log('locatioin', location)

  return (
    <Container
      header={
        <PageHeader
          title={
            <>
              <span>Chi tiết sản phẩm {location.state.product_name}</span>
              <br />
              <span>tại {location.state.petrol_store_name}</span>
            </>
          }
          onBack={() => {
            history.push({
              pathname: `${ENTERPRISE_ROUTER_PATH.SALE_REPORT}`,
              state: {
                page: location.state?.page,
                search: location.state?.search,
                category_id: location.state?.category_id,
                petrol_store_id: location.state?.petrol_store_id,
                from_date: location?.state?.from_date,
                to_date: location?.state?.to_date,
              },
            })
          }}
          extra={[
            // <ButtonExport
            //   sheetName={['SalesReportDetail']}
            //   fileName="Chi tiết báo cáo bán hàng"
            //   onClick={fn => onExportData(fn)}
            //   loading={isLoadingBtnExportData}
            //   sheets={{
            //     SalesReportDetail: ButtonExport.getSheets(dataExport),
            //   }}
            // />,
            <ButtonExportExcelV2
              key="export_excel"
              loading={isLoadingBtnExportData}
              onClick={onExportData}
            />,
          ]}
        />
      }
      filterComponent={
        <FilterDetail
          dataFilter={params}
          onSearchSubmit={(searchKey: string) => {
            setTimeout(() => {
              setParams({
                ...params,
                search: searchKey.trim() ? searchKey : undefined,
                page: 1,
              })
            }, 300)
          }}
          onStatusOrder={(status: number) => {
            setParams({ ...params, status: status, page: 1 })
          }}
          onTimeSubmit={(from_date: string | Date, to_date: string | Date) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
          onStatusPayment={(is_paid: number) => {
            setParams({
              ...params,
              is_paid: is_paid,
              page: 1,
            })
          }}
          onTypePaymentOrder={(type_payment: number) => {
            setParams({
              ...params,
              type_payment: type_payment,
              page: 1,
            })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            bordered
            columns={dataSource.is_full_fuel === 0 ? column : columns}
            dataSource={dataSource}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            loading={isLoading}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
