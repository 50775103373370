import { useEffect, useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { createAccount, getAccounts, updateAccount } from '../AccountApi'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  from_date: undefined | string
  to_date: undefined | string
  role_id?: string
  limit: number
}
export const useAccount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [listAccount, setListAccount] = useState<any>([])
  const [showAddAccount, setShowAddAccount] = useState(false)
  const [showEditAccount, setShowEditAccount] = useState(false)

  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    role_id: undefined,
    page: 1,
    // offset: 0,
    limit: 12,
  })
  const [images, setImages] = useState<Array<any>>(
    Array.from(Array(4).keys()).map(i => {
      return {
        id: i,
        fileList: [],
        buffer: null,
        url: '',
      }
    })
  )
  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      const res = await getAccounts(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
        }
      })
      setListAccount(tableData)
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const createAcc = async (data: any, resetFields: any) => {
    try {
      const res = await createAccount(data)
      setShowAddAccount(false)
      resetFields()
      const new_list = listAccount.unshift(res.data)
      // setListAccount([...listAccount])
      notificationSuccess(`Thêm mới tài khoản thành công`)
      // window.location.reload()
    } catch (error) {
      notificationError(`Thông tin bạn nhập không chính xác`)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
    getData()
  }

  return {
    isLoading,
    currentSelected,
    params,
    paging,
    listAccount,
    setPaging,
    setIsLoading,
    setCurrentSelected,
    setParams,
    getData,
    setListAccount,
    setShowAddAccount,
    showAddAccount,
    createAcc,
    // updateAcc,
    showEditAccount,
    setShowEditAccount,
    setImages,
    images,
  }
}
