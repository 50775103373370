import { Row, Col, DatePicker, Input } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import { useEffect, useState } from 'react'
import Icon from '@ant-design/icons'
import { getListCategory } from 'features/enterprise/product/category/CategoryProductApi'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

const { RangePicker } = DatePicker
type Props = {
  data: any
  onSearchSubmit: (searchKey: string) => void
  onCategoryIdSubmit: (category_id: number) => void
  params?: any
  setParams?: any
  onTimeSubmit: (
    from_date: string | undefined,
    to_date: string | undefined
  ) => void
  onSelectPetrolStore: (petrol_store_id: number) => void
}
type TRadiusSelect = {
  text: string
  value: number
}

export default function Filter({
  data,
  onSearchSubmit,
  onCategoryIdSubmit,
  params,
  setParams,
  onTimeSubmit,
  onSelectPetrolStore,
}: Props) {
  const location: any = useLocation()
  const [listCategory, setListCategory] = useState<Array<TRadiusSelect>>([])

  useEffect(() => {
    getListCategorys()
  }, [])

  const getListCategorys = async () => {
    const res: any = await getListCategory({ limit: 999 })
    const list_data = res?.data?.map((item: any) => {
      return { value: item.id, text: item.name }
    })
    setListCategory(list_data)
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Tên hoặc mã sản phẩm"
          addonAfter={<Icon type="close-circle-o" />}
          value={data?.search}
          onChange={(e: any) => {
            onSearchSubmit(e.target.value)
          }}
        />
      </Col>
      <Col span={8}>
        <RadiusSelection
          placeholder="DM sản phẩm"
          onChange={(value: number) => {
            onCategoryIdSubmit(value)
          }}
          value={data?.category_id}
          options={listCategory}
          showSearch={true}
        />
      </Col>
      <Col span={6}>
        <RangePicker
          style={{ width: '100%' }}
          placeholder={['Từ ngày', 'Đến ngày']}
          format="HH:mm DD/MM/YYYY"
          showTime={{ format: 'HH:mm' }}
          value={
            params?.from_date
              ? [moment(params.from_date), moment(params.to_date)]
              : [null, params.to_date ? moment(params.to_date) : moment()]
          }
          // onChange={(value, dateString) => {
          //   onTimeSubmit(
          //     value ? moment(value?.[0]).format('HH:mm YYYY-MM-DD') : undefined,
          //     value ? moment(value?.[1]).format('HH:mm YYYY-MM-DD') : undefined
          //   )
          // }}
          onChange={(dates: any, dateString) => {
            setParams({
              ...params,
              from_date: dates
                ? moment(
                    `${dateString[0]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[0].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .format('YYYY-MM-DD HH:mm:ss')
                : '',
              to_date: dates
                ? moment(
                    `${dateString[1]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[1].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .add(59, 'seconds')
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss'),
              page: 1,
            })
          }}
        />
      </Col>
    </Row>
  )
}
