import { PageHeader, Table, Tag } from 'antd'
import R from 'assets'
import { ButtonAdd } from 'common/components/Buttons'
import styles from 'common/components/styles/WhiteBox.module.css'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import moment from 'moment'
import history from 'utils/history'
import Filter from './components/Filter'
import { useSaleProduct } from './hook/useSaleProduct'

const columns = [
  {
    width: 70,
    title: 'STT',
    dataIndex: 'stt',
  },
  {
    title: 'Mã sản phẩm',
    dataIndex: 'product_code',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'product_name',
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'product',
    render: (value: any) => value?.product_unit?.name,
  },
  {
    title: 'Danh mục',
    dataIndex: 'category',
  },
  {
    title: 'Trạng thái khuyến mãi',
    dataIndex: 'sale_status',
    render: (value: number) => (
      <Tag color={value ? 'green' : 'volcano'}>
        {value ? 'Đang khuyến mại' : 'Không khuyến mại'}
      </Tag>
    ),
  },
  {
    title: 'Ngày tạo ',
    dataIndex: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]

export default function ContentList() {
  const { dataSource, params, isLoading, setParams, paging } = useSaleProduct()

  return (
    <div>
      <Container
        header={
          <PageHeader
            className={styles.header}
            title="Sản phẩm khuyến mại"
            extra={[
              <ButtonAdd
                onClickButton={() => {
                  history.push({
                    pathname: `${ADMIN_ROUTER_PATH.ADD_SALE_PRODUCT}`,
                    state: {
                      search: params.search,
                      status: params.status,
                      category_id: params.category_id,
                      page: params.page,
                    },
                  })
                }}
                text={R.strings().action_create}
              />,
            ]}
          />
        }
        filterComponent={
          <Filter
            data={params}
            onSearchSubmit={(searchKey: string) => {
              setParams({
                ...params,
                search:
                  searchKey.trim() === '' ? undefined : searchKey.trimStart(),
                page: 1,
              })
            }}
            onCategorySubmit={(category_id: number) => {
              setParams({ ...params, category_id: category_id, page: 1 })
            }}
            onStatusSubmit={(statusKey: number) => {
              setParams({ ...params, status: statusKey, page: 1 })
            }}
          />
        }
        contentComponent={
          <>
            <p>
              Kết quả lọc: <b>{paging.total}</b>
            </p>
            <Table
              className={styles.table_hover}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              onRow={record => ({
                onClick: () => {
                  history.push({
                    pathname: `${ADMIN_ROUTER_PATH.SALE_PRODUCT_DETAIL}/${record.id}`,
                    state: {
                      search: params.search,
                      status: params.status,
                      category_id: params.category_id,
                      page: params.page,
                    },
                  })
                },
              })}
              dataSource={dataSource}
              loading={isLoading.loadingTable}
              bordered
              columns={columns}
              pagination={{
                ...paging,
                showSizeChanger: false,
                onChange: async (page, pageSize) => {
                  setParams({ ...params, page })
                },
              }}
            />
          </>
        }
      />
    </div>
  )
}
