import { createSlice } from '@reduxjs/toolkit'

let initialState: any = {
  saleProductInfor: {
    category_id: null,
    product_id: null,
    product_name: null,
    description: '',
  },
  orginStoresSelected: [],
}

export const getSaleProductInfor = (state: any) =>
  state.saleProductReducer.saleProductInfor
export const getSaleStoreSelected = (state: any) =>
  state.saleProductReducer.orginStoresSelected

export const saleProductSlice = createSlice({
  name: 'saleProduct',
  initialState,
  reducers: {
    setProductId: (state, action) => {
      state.saleProductInfor.product_id = action.payload
    },
    setProductName: (state, action) => {
      state.saleProductInfor.product_name = action.payload
    },
    setCategoryId: (state, action) => {
      state.saleProductInfor.category_id = action.payload
    },
    setDescriptionProduct: (state, action) => {
      state.saleProductInfor.description = action.payload
    },
    setOriginStoresSelected: (state, action) => {
      state.orginStoresSelected = action.payload
    },
    clearDataSaleProduct: state => {
      state.saleProductInfor.category_id = null
      state.saleProductInfor.product_id = null
      state.saleProductInfor.product_name = null
      state.saleProductInfor.description = ''
      state.orginStoresSelected = []
    },
  },
})

export const {
  setProductId,
  setCategoryId,
  setProductName,
  setDescriptionProduct,
  setOriginStoresSelected,
  clearDataSaleProduct,
} = saleProductSlice.actions

export default saleProductSlice.reducer
