import { ApiClient } from 'services/ApiService'

export const getLisOrder = (payload: any) =>
  ApiClient.get('/admin/order/list-order', payload)
export const getAllLisOrder = (payload: any) =>
  ApiClient.get('/admin/order/list-order-in', payload)
export const getDetailOrder = (id: number) =>
  ApiClient.get(`/admin/order/${id}`)
export const changeStatus = (id: number, payload: any) =>
  ApiClient.patch(`/admin/order/${id}`, payload)
export const changeStatusPayment = (order_id: number) =>
  ApiClient.patch(`/admin/order/change-paid/${order_id}`)
export const billFullTank = (payload: any) =>
  ApiClient.post(`/admin/order/receipt-gasoline`, payload)

export const getRefuelHistory = (
  orderId: number,
  userId: number,
  storeId: number,
  page: number
) =>
  ApiClient.get(
    `/admin/order/${orderId}/${userId}/${storeId}/history?page=${page}`
  )

export const addHistoryBill = (
  orderId: number,
  userId: number,
  storeId: number,
  payload: any
) =>
  ApiClient.post(
    `/client/order/${orderId}/${userId}/${storeId}/create`,
    payload
  )

export const getCurrentLit = (id: number) => ApiClient.get(`/admin/order/${id}`)

export const getReportTotalOrder = (payload: any) =>
  ApiClient.get('/admin/order/list-order-summary', payload)
