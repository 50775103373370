import { ApiClient } from 'services/ApiService'

export const getAccounts = (payload: any) =>
  ApiClient.get('/petrol-store/user', payload)
export const createAccount = (payload: any) =>
  ApiClient.post('/petrol-store/user', payload)
export const updateAccount = (payload: any) =>
  ApiClient.put(`/petrol-store/user/${payload.id}`, payload.data)
export const resetPassword = (payload: any) =>
  ApiClient.put(
    `petrol-store/user/${payload.id}/reset-password`,
    payload.resetPassword
  )
export const changeStatusAccount = (payload: any) =>
  ApiClient.patch(`/petrol-store/user/${payload.id}/status`, payload.status)
export const deleteAccount = (payload: any) =>
  ApiClient.delete(`petrol-store/user/${payload.id}`)
export const requestUploadImageAccount = (payload: any) =>
  ApiClient.post(`/files/upload/single/${payload.type}`, payload.data)
