import { Descriptions } from 'antd'
import styles from '../../orders/styles/style.module.css'

export default function Receipt() {
  return (
    <Descriptions
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <b>Thông tin hóa đơn</b>
          {/* <Divider /> */}
        </div>
      }
      column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
    >
      <Descriptions.Item
        label={
          <span style={{ paddingLeft: '15px' }}> Tên người sở hữu xe</span>
        }
      >
        Nguyễn Minh Tú
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Mã số thuế</span>}
      >
        099999999
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Địa chỉ chi tiết</span>}
      >
        số 1, đường Láng, P.Láng, Q.Thanh Xuấn, TP.Hà Nội
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}>Email</span>}
      >
        sjdhg@gmail.com
      </Descriptions.Item>
    </Descriptions>
  )
}
