import { PageHeader } from 'antd'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router'

export default function Header() {
  const history = useHistory()
  return (
    <div>
      <PageHeader
        title="Đơn hàng bảo hiểm"
        extra={[
          <ButtonExport
            sheetName={['OrderLisst']}
            fileName="Danh sách đơn hàng"
            sheets={{}}
            // loading={isLoadingBtnExportData}
            // onClick={fn => onExportDataToExcel(fn)}
          />,
        ]}
      />
    </div>
  )
}
