import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tag,
} from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { AddEditCancel } from './components/AddEditCancel'
import OrderPromotion from './components/OrderPromotion'
import Referral from './components/Referral'
import {
  createReason,
  deleteReason,
  getConfigSystem,
  getReason,
  updateConfigSystem,
  updateReason,
} from './ConfigApi'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  from_date: undefined | string
  to_date: undefined | string
  limit: number
}

export default function SystemInfor() {
  const [isLoading, setIsLoading] = useState(false)
  const [showEditCancel, setShowEditCancel] = useState(false)
  const [showAddConfigCancel, setShowAddConfigCancel] = useState(false)
  const [listReason, setListReason] = useState<any>([])
  const [listConfigSystem, setListConfigSystem] = useState<any>({})
  const [value, setValue] = useState({})
  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
    // offset: 0,
    limit: 12,
  })
  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getDataSystems()
  }, [])

  useEffect(() => {
    getListReason()
  }, [params])

  const getDataSystems = async () => {
    try {
      const res = await getConfigSystem()
      setListConfigSystem(res)
    } catch (error) {
      console.log('error', error)
    }
  }
  const onUpdateReasons = (record: any) => {
    setValue(record)
    setShowEditCancel(true)
  }

  const getListReason = async () => {
    setIsLoading(true)
    try {
      const res = await getReason()
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          id: item.id,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          content: item.content,
          content_en: item.content_en,
          status: item.status,
          create_at: item.create_at,
        }
      })
      setListReason(tableData)
      setIsLoading(false)
      setPaging({
        ...paging,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const updateReasons = async (data: any, resetFields: any) => {
    try {
      let dataPush = {
        id: data.id,
        data: {
          status: data.status,
          content: data.content,
          content_en: data.content_en,
        },
      }
      const res = await updateReason(dataPush)
      const itemUpdate = { ...res.data, key: res.data.id }
      const index = _.findIndex(listReason, { id: itemUpdate.id })
      listReason.splice(index, 1, itemUpdate)
      setListReason([...listReason])
      notificationSuccess(res.message)
      setShowEditCancel(false)
      getListReason()
      resetFields()
    } catch (error) {
      console.log(error)
    }
  }
  const [form] = Form.useForm()
  const onFinish = async (values: any) => {
    setIsLoading(true)
    try {
      delete values.confirm
      let newData

      newData = {
        ...values,
        id: 3,
        point: +Number(values.point),
      }
      updateConfigSystem(newData)
      notificationSuccess('Cập nhật thành công')
      getDataSystems()
    } catch (error) {
      console.log(error)
    } finally {
    }
    setIsLoading(false)
  }

  const columns = [
    {
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: <b>Nội dung lý do</b>,
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => (
        <Tag color={value === 1 ? 'green' : 'volcano'}>
          {value === 1 ? 'HOẠT ĐỘNG' : 'TẠM DỪNG'}
        </Tag>
      ),
    },
    {
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (data: any) => <div>{moment(data).format('DD/MM/YYYY')}</div>,
    },
    {
      title: <b>Thao tác</b>,
      width: '120px',
      render: (record: any) => {
        return (
          <td>
            <>
              <Button
                type="text"
                style={{ fontSize: 16, color: '#1890ff' }}
                onClick={() => {
                  onUpdateReasons(record)
                }}
              >
                <EditOutlined />
              </Button>
            </>
            <Popconfirm
              placement="right"
              title="Bạn có chắc chắn muốn xóa nội dung hủy?"
              onConfirm={async () => {
                try {
                  const res = await deleteReason(record)
                  notificationSuccess(res.message)
                  getListReason()
                } catch (error) {
                  notificationError(`Đã xảy ra lỗi`)
                  console.log(error)
                }
              }}
              okText={
                <span>
                  <CloseCircleOutlined />
                  &nbsp;Xóa
                </span>
              }
              cancelText={
                <span>
                  <LeftCircleOutlined />
                  &nbsp;Trở lại
                </span>
              }
              okButtonProps={{
                danger: true,
                type: 'default',
                style: { borderRadius: '3px', fontWeight: 'bold' },
              }}
              cancelButtonProps={{
                style: {
                  borderRadius: '3px',
                  borderColor: '#1890ff',
                  color: '#1890ff',
                  fontWeight: 'bold',
                },
              }}
            >
              <Button
                type="text"
                style={{ fontSize: 16, color: 'red' }}
                onClick={() => {}}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </td>
        )
      },
    },
  ]

  return (
    <>
      {showEditCancel && (
        <AddEditCancel
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          data={value}
          visible={showEditCancel}
          onCancel={() => {
            setShowEditCancel(false)
            setIsLoading(false)
            getListReason()
          }}
          getListReason={getListReason}
          onUpdateCancel={(newData: any, resetFields: any) => {
            updateReasons(newData, resetFields)
            // setIsLoading(true)
            getListReason()
          }}
        />
      )}
      <div style={{ padding: '15px', backgroundColor: 'white' }}>
        <b style={{ fontSize: 18, textDecoration: 'underline' }}>
          Thông tin mua hàng
        </b>
        <Spin spinning={isLoading}>
          <Row
            style={{ width: '100%', paddingTop: '10px' }}
            justify="space-between"
          >
            <Col style={{ marginBottom: '20px', width: '49%' }}>
              <OrderPromotion
                listConfigSystem={listConfigSystem}
                setListConfigSystem={setListConfigSystem}
                getDataSystems={getDataSystems}
              />
            </Col>

            <Col style={{ marginBottom: '20px', width: '49%' }}>
              <Referral
                getDataSystems={getDataSystems}
                listConfigSystem={listConfigSystem}
                setListConfigSystem={setListConfigSystem}
              />
            </Col>
          </Row>
        </Spin>
      </div>
      <div
        style={{ padding: '15px', backgroundColor: 'white', marginTop: '10px' }}
      >
        <b style={{ fontSize: 18, textDecoration: 'underline' }}>
          Cấu hình thời gian hủy
        </b>
        <Row style={{ margin: '10px 0px 0 0', alignItems: 'center' }}>
          <Form
            // {...formItemLayout}
            // labelAlign="left"
            layout="inline"
            form={form}
            onFinish={(values: any) => onFinish(values)}
            scrollToFirstError
          >
            <Form.Item
              label={
                <span style={{ fontSize: 16 }}>
                  Thời gian hủy đơn hàng xăng (giờ)
                </span>
              }
              name="point"
              rules={[
                {
                  message: 'Vui lòng nhập số giờ đúng định dạng',
                  validator: (_, value) => {
                    const reg = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/
                    const array = value.split('.')
                    if (
                      reg.test(value) &&
                      value !== '' &&
                      value >= 0 &&
                      array[0] !== '' &&
                      array[1] !== ''
                      // &&
                      // array[0] <= 10000
                    ) {
                      if (
                        (array[1] && array[1].length > 3) ||
                        (array[1] && array[0].length > 2 && array[1].length > 0)
                      )
                        return Promise.reject()
                      return Promise.resolve()
                    }
                    return Promise.reject()
                  },
                },
              ]}
            >
              <Input
                allowClear
                style={{ width: 150 }}
                defaultValue={listConfigSystem.cancellation}
                placeholder={listConfigSystem?.data?.[2].value}
              />
            </Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              style={{
                margin: '0 0 0 15px',
                minWidth: '70px',
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
              htmlType="submit"
            >
              <CheckCircleOutlined />
              Lưu
            </Button>
          </Form>
        </Row>
      </div>

      <div
        style={{ padding: '15px', backgroundColor: 'white', marginTop: '10px' }}
      >
        <Row justify="space-between">
          <b style={{ fontSize: 18, textDecoration: 'underline' }}>
            Danh sách lý do hủy của khách
          </b>
          <ButtonAdd
            text="Thêm mới"
            onClickButton={() => {
              setShowAddConfigCancel(true)
            }}
          />
          <AddEditCancel
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            getListReason={getListReason}
            visible={showAddConfigCancel}
            onCancel={() => {
              setShowAddConfigCancel(false)
              getListReason()
            }}
            onCreateCancel={(newData: any, resetFields: any) => {
              setIsLoading(true)
              try {
                createReason(newData)
                setIsLoading(true)
                notificationSuccess(`Thêm mới thành công`)
                setShowAddConfigCancel(false)
                setIsLoading(false)
                resetFields()
              } catch (error) {
                notificationError(`Đã xảy ra lỗi`)
                console.log(error)
              } finally {
                getListReason()
                setIsLoading(false)
              }
            }}
          />
        </Row>

        <Table
          style={{ paddingTop: '20px' }}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
          }}
          bordered
          dataSource={listReason}
          loading={isLoading}
          columns={columns}
        />
      </div>
    </>
  )
}
