import { ApiClient } from 'services/ApiService'

export const getListCustomer = (payload: any) =>
  ApiClient.get('/petrol-store/customer', payload)
export const getInforCustomer = (id: number) =>
  ApiClient.get(`/petrol-store/customer/${id}/info`)
export const getListOrderCustomer = (id: number, payload: any) =>
  ApiClient.get(`/petrol-store/customer/${id}/order`, payload)
export const getListDebtCustomer = (id: number, payload: any) =>
  ApiClient.get(`/petrol-store/customer/${id}/debt`, payload)
export const getListPointHistoryCustomer = (id: number, payload: any) =>
  ApiClient.get(`/petrol-store/customer/${id}/point-history`, payload)
export const getListReferralCustomer = (id: number, payload: any) =>
  ApiClient.get(`/petrol-store/customer/${id}/referral`, payload)
export const getListRankCustomer = (id: number, payload: any) =>
  ApiClient.get(`/petrol-store/customer/${id}/rank-history`, payload)

// Api lấy danh sách rank
export const requestRanks = () => ApiClient.get('/admin/config-ranks')
