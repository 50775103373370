import { Modal } from 'antd'
import QrReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom'

type Props = {
  visible: boolean
  onCancel: () => void
}
export default function ScanQRCode({ visible, onCancel }: Props) {
  const history = useHistory()
  const handleError = (err: any) => {
    console.error(err)
  }
  const handleScan = (data: any) => {
    if (data) {
      history.replace(data)
    }
  }
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      style={{ height: '350px', width: '320px' }}
      bodyStyle={{ height: '400px' }}
    >
      <QrReader
        delay={500}
        style={{
          height: 280,
          width: 350,
        }}
        onError={handleError}
        onScan={handleScan}
      />
    </Modal>
  )
}
