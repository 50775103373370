import { createSlice } from '@reduxjs/toolkit'

let initialState: any = {
  productInfor: {
    code: '',
    name: '',
    category_id: null,
    price: null,
    description: '',
    stt: null,
    quantity_home: null,
    status_home: 0,
    unit: null,
    expire_time: '',
    min_quantity: '',
    max_quantity: '',
    product_unit_id: -1,
    isAllSpace: false,
  },
  orginStoresSelected: [],
}

export const getProductInfor = (state: any) => state.productReducer.productInfor
export const getOriginStoreSelected = (state: any) =>
  state.productReducer.orginStoresSelected

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setCodeProduct: (state, action) => {
      state.productInfor.code = action.payload
    },
    setNameProduct: (state, action) => {
      state.productInfor.name = action.payload
    },
    setListedPriceProduct: (state, action) => {
      state.productInfor.price = action.payload
    },
    setCategoryProduct: (state, action) => {
      state.productInfor.category_id = action.payload
    },
    setDescriptionProduct: (state, action) => {
      state.productInfor.description = action.payload
    },
    setPriority: (state, action) => {
      state.productInfor.stt = action.payload
    },
    setArrayPetrolStores: (state, action) => {
      state.productInfor.array_petrol_stores = action.payload
    },
    setOriginStoresSelected: (state, action) => {
      state.orginStoresSelected = action.payload
    },
    setStatusHome: (state, action) => {
      state.productInfor.status_home = action.payload
    },
    setQuantityHome: (state, action) => {
      state.productInfor.quantity_home = action.payload
    },
    setUnitProduct: (state, action) => {
      state.productInfor.unit = action.payload
    },
    setExpireTimeProduct: (state, action) => {
      state.productInfor.expire_time = action.payload
    },
    setMinQuantityProduct: (state, action) => {
      state.productInfor.min_quantity = action.payload
    },
    setMaxQuantityProduct: (state, action) => {
      state.productInfor.max_quantity = action.payload
    },
    setSaveStatus: (state, action) => {
      state.productInfor.save_status = action.payload
    },
    setIsAllSpaces: (state, action) => {
      console.log('Action payload: ', action)
      state.productInfor.isAllSpace = action.payload
    },
    clearDataProduct: state => {
      state.productInfor.name = ''
      state.productInfor.code = ''
      state.productInfor.price = 0
      state.productInfor.category_id = null
      state.productInfor.stt = null
      state.productInfor.description = ''
      state.productInfor.status_home = 0
      state.productInfor.quantity_home = 0
      state.productInfor.unit = null
      state.productInfor.expire_time = ''
      state.productInfor.min_quantity = null
      state.productInfor.max_quantity = null
      state.productInfor.save_status = false
      state.productInfor.isAllSpace = false
    },
  },
})

export const {
  setCodeProduct,
  setNameProduct,
  setCategoryProduct,
  setDescriptionProduct,
  setPriority,
  setArrayPetrolStores,
  setListedPriceProduct,
  setOriginStoresSelected,
  clearDataProduct,
  setStatusHome,
  setQuantityHome,
  setUnitProduct,
  setExpireTimeProduct,
  setMinQuantityProduct,
  setMaxQuantityProduct,
  setSaveStatus,
  setIsAllSpaces,
} = productSlice.actions

export default productSlice.reducer
