import './css/Header.css'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Input, Form, Upload } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { notificationError } from 'utils/notification'
import { uploadImageToServer } from 'utils/uploadImageToServer'
import { useCategoryProduct } from '../hook/useCategoryProduct'

type Props = {
  visible: boolean
  onCancel?: any
  data?: any
  onCreateNewCategory?: any
  onUpdateCategory?: any
  isLoading: boolean
}

const { TextArea } = Input
function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    notificationError('Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!')
  }
  const isLt2M = file.size / 1024 / 1024 < 3
  if (!isLt2M) {
    notificationError('Cho phép ảnh có dung lượng tối đa là 3MB')
  }
  return isJpgOrPng && isLt2M
}

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      name: null,
      // name_en: '',
      expired_at: null,
      icon_url: null,
      description: '',
    }
  } else {
    return {
      ...data,
      name: data.name,
      // name_en: data.name,
      date_of_birth: moment.unix(1616620499),
      expired_at: moment.unix(data.expired_at),
      description: data.description,
      is_default: data.is_default,
    }
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

export const AddEditCategory = ({
  visible,
  onCancel,
  data,
  onCreateNewCategory,
  onUpdateCategory,
}: // isLoadingButton,
Props) => {
  const [upload, setUpload] = useState({
    loading: false,
    imageUrl: '',
  })

  const { isLoading, setIsLoading, getData } = useCategoryProduct()
  const [form] = Form.useForm()

  const initialValues = convertDataToFrom(data)

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setUpload({
          imageUrl: imageUrl,
          loading: false,
        })
      )
    }
  }

  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const onFinish = async (values: any, onCancel: any) => {
    setIsLoading({ ...isLoading, loadingAddEdit: true })
    try {
      //Upload image lên Server
      if (values.icon_url?.fileList) {
        var resUploadImage: any
        var last_element = []

        if (values.icon_url.fileList.length > 1) {
          last_element = [values.icon_url.fileList.slice(-1)[0]]
        } else {
          last_element = values.icon_url.fileList
        }

        resUploadImage = await uploadImageToServer(last_element)
      }

      let newData

      if (!data) {
        newData = {
          ...values,
          icon_url: resUploadImage?.location,
        }
        onCreateNewCategory(newData, form.resetFields, setUpload)
      } else {
        if (data.icon_url === values.icon_url) {
          newData = {
            ...values,
            id: data.id,
          }
        } else {
          newData = {
            ...values,
            id: data.id,
            icon_url: resUploadImage?.location,
          }
        }
        onUpdateCategory(newData, form.resetFields)
      }
      // getData()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading({ ...isLoading, loadingAddEdit: false })
      getData()
    }
  }

  useEffect(() => {
    if (data) {
      setUpload({
        ...upload,
        imageUrl: data.icon_url,
      })
    }
  }, [data])

  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      maskClosable={false}
      footer={null}
      title={data ? 'Sửa danh mục' : 'Thêm danh mục '}
      visible={visible}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        labelAlign="left"
        onFinish={(values: any) => onFinish(values, onCancel)}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          label="Tên danh mục"
          name="name"
          rules={[
            {
              required: true,
              message: 'Vui lòng điền tên danh mục',
            },
            {
              whitespace: true,
              message: 'Không được nhập khoảng trắng!',
            },
            {
              max: 55,
              message: 'Vui lòng không nhập quá 55 ký tự!',
            },
          ]}
        >
          <Input
            placeholder="Tên danh mục"
            disabled={form.getFieldValue('is_default') === 1 ? true : false}
          />
        </Form.Item>

        <Form.Item
          label="Icon danh mục"
          name="icon_url"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn icon danh mục!',
            },
          ]}
        >
          <Upload
            name="icon_url"
            listType="picture-card"
            className="icon_url-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {upload.imageUrl ? (
              <img
                src={upload.imageUrl}
                alt="icon_url"
                style={{ width: '100%', objectFit: 'contain', height: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Mô tả"
          name="description"
          rules={[
            {
              whitespace: true,
              message: 'Không được nhập khoảng trắng!',
            },
            {
              max: 256,
              message: 'Không nhập quá 256 ký tự',
            },
          ]}
        >
          <TextArea
            id="text_area_category"
            allowClear
            placeholder="Nhập mô tả danh mục"
            // onKeyPress={e =>
            //   e.shiftKey && e.key === 'Enter' ? onAppendNewLine(e) : ''
            // }
            // value={generalData.description}
          />
        </Form.Item>

        <ButtonBottomModal
          isLoadingButton={isLoading.loadingAddEdit}
          onCancel={() => {
            onCancel()
            form.resetFields()
            // getData()
          }}
          text={data ? 'Cập nhật' : 'Thêm danh mục'}
        />
      </Form>
    </Modal>
  )
}
