import { Divider, Descriptions, Upload, Modal, Image } from 'antd'
import { useState } from 'react'
import {} from '../ProductApi'
import { PlusOutlined } from '@ant-design/icons'
import { useDetailProduct } from '../hook/useDetailProduct'

const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default function DetailImage() {
  const { listImages } = useDetailProduct()
  const [previewImage, setPreviewImage] = useState<string>('')
  const [isShowModalPreview, setShowModalPreview] = useState<boolean>(false)
  const handlePreviewImage = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setShowModalPreview(true)
  }

  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin hình ảnh</b>
      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <Descriptions>
        <Descriptions.Item
          label="Hình ảnh sản phẩm"
          span={3}
          labelStyle={{ fontWeight: 'bold' }}
        >
          {listImages.map((item: any, index: number) => {
            return (
              <div style={{ marginRight: '10px', width: 120, height: 120 }}>
                <Image
                  style={{
                    width: 120,
                    height: 120,
                    objectFit: 'contain',
                  }}
                  src={item?.url}
                />
              </div>
            )
          })}
        </Descriptions.Item>
      </Descriptions>
      <Modal
        visible={isShowModalPreview}
        footer={null}
        onCancel={() => setShowModalPreview(false)}
      >
        <img
          alt="example"
          style={{ width: '100%', marginTop: '20px' }}
          src={previewImage}
        />
      </Modal>
    </div>
  )
}
