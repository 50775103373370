import { message } from 'antd'
import { changeStatusNews, updateNews } from './../NewApi'
import { notificationError, notificationSuccess } from 'utils/notification'
import React, { useEffect, useState } from 'react'
import { getListNews, createNews } from '../NewApi'
import { useHistory, useParams } from 'react-router'
import moment from 'moment'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  banner_status: undefined | string
  from_date: undefined | Date
  to_date: undefined | Date
  limit: number
  type_banner: undefined | string
  is_notify: undefined | boolean
}

export const useNews = () => {
  const history = useHistory()
  const param: any = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [listNew, setListNew] = useState<any>([])
  const [showAddNew, setShowAddNew] = useState(false)
  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    banner_status: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
    limit: 12,
    type_banner: undefined,
    is_notify: undefined,
  })

  const [callback, setCallback] = React.useState(false)

  const [pagingtion, setPagingtion] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params, callback])

  const getData = async () => {
    try {
      const res = await getListNews(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta?.pagination?.page - 1) * res.meta?.pagination?.limit +
            index +
            1,
        }
      })
      setListNew(tableData)
      setPagingtion({
        ...pagingtion,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const onCreateNews = async (data: any, resetFields: any) => {
    // setIsLoading(true)
    try {
      const res = await createNews(data)
      setTimeout(() => {
        notificationSuccess(res.message)
        resetFields()
        const new_list = listNew.unshift(res.data)
        setListNew(new_list)
        history.goBack()
        getData()
      }, 300)
    } catch (error) {
      console.log(error)
      notificationError(`Đã có lỗi xảy ra`)
    }
  }

  const onUpdateNews = async (data: any, resetFields: any) => {
    // setIsLoading(true)
    try {
      const res = await updateNews(data)
      notificationSuccess(res.message)
      resetFields()
      history.goBack()
    } catch (error) {
      console.log(error)
      notificationError(`Đã có lỗi xảy ra`)
    }
  }

  const onChangeStatus = async (id: any) => {
    console.log('id: ', id)
    try {
      const res = await changeStatusNews({ id: [id] })
      notificationSuccess(res.message)
      getData()
      console.log(getData)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    isLoading,
    currentSelected,
    params,
    pagingtion,
    listNew,
    setPagingtion,
    setIsLoading,
    setCurrentSelected,
    setParams,
    getData,
    setListNew,
    setShowAddNew,
    showAddNew,
    onCreateNews,
    param,
    history,
    onUpdateNews,
    onChangeStatus,
    callback,
    setCallback,
  }
}
