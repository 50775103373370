import Container from 'common/container/Container'
import FilterDetail from './components/FilterDetail'
import { Table, Tag } from 'antd'
import { useEffect, useState } from 'react'
import HeaderDetail from './components/HeaderDetail'
import { formatPrice } from 'utils/ruleForm'
import { DollarOutlined, InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { getDetailDebtCustomer } from './DebtCustomerApi'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ORDER_STATUS } from 'utils/constants'
import { ADMIN_ROUTER_PATH } from 'common/config'

export const DEFINED_TYPE_DEBT = {
  PAYMENT_NUMBER: '2',
  ORTHER: '1,3',
}

type TParam = {
  page: number
  action?: number
  payment_to_date?: string | Date
  payment_from_date?: string | Date
  from_date?: string | Date
  to_date?: string | Date
}
type TDataSource = {
  name: string
  total_debt: number
  dataTable: Array<any>
}

export default function DetailDebtCustomerScreen() {
  const history = useHistory()

  const column = [
    {
      width: 70,
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      width: 90,
      title: <b>Ngày thay đổi</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
      },
    },
    {
      width: 120,
      title: <b>Người thay đổi</b>,
      dataIndex: 'create_name',
      key: 'create_name',
      render: (value: any) => value,
    },
    {
      width: 120,
      title: <b>Tác vụ</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: 100,
      title: <b>Đơn hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      width: 180,
      title: <b>Sản phẩm</b>,
      dataIndex: 'data_product',
      key: 'data_product',
      render: (value: any) => <b>{value?.name}</b>,
    },
    {
      width: 100,
      title: <b>ĐVT</b>,
      dataIndex: 'unit',
      key: 'data_product_unit',
      render: (value: any) => <b>{value}</b>,
    },
    {
      width: 100,
      title: <b>Số lượng</b>,
      dataIndex: 'amount',
      key: 'data_product_quantity',
      render: (value: any) => <b>{+value}</b>,
    },
    {
      width: 110,
      title: <b>Đơn giá</b>,
      dataIndex: 'data_product',
      key: 'data_product_price',
      render: (value: any) => (
        <b>{value?.price ? formatPrice(value?.price) : 0}</b>
      ),
    },
    {
      width: 100,
      title: <b>Phát sinh</b>,
      dataIndex: 'total_price',
      key: 'total_price2',
      render: (value: any, row: any) => (
        <b>
          {row?.type_debt_id !== +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
            ? value > 0
              ? '+' + formatPrice(value)
              : formatPrice(value)
            : ''}
        </b>
      ),
    },
    {
      width: 100,
      title: <b>Số thanh toán</b>,
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: any, row: any) => (
        <b>
          {row?.type_debt_id === +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
            ? value > 0
              ? '+' + formatPrice(value)
              : formatPrice(value)
            : ''}
        </b>
      ),
    },
    {
      width: 100,
      title: <b>Số còn phải thu</b>,
      dataIndex: 'total_debt',
      key: 'total_debt',
      render: (value: any) => <b>{value ? formatPrice(value) : 0}</b>,
    },
    // {
    //   width: 110,
    //   title: <b>Nợ lũy kế</b>,
    //   dataIndex: 'total_debt',
    //   key: 'total_debt',
    //   render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)} đ</>,
    // },
    // {
    //   width: 90,
    //   title: <b>Hạn thanh toán</b>,
    //   dataIndex: 'payment_time',
    //   key: 'payment_time',
    //   render: (value: any) => (
    //     <>{value == null ? '' : moment(value).format('HH:mm DD/MM/YYYY')}</>
    //   ),
    // },
    // {
    //   width: 120,
    //   title: <b>TT Đơn hàng</b>,
    //   dataIndex: 'order_status',
    //   key: 'order_status',
    //   render: (value: any) => {
    //     switch (value) {
    //       case ORDER_STATUS.PENDING:
    //         return <Tag color="blue">Chờ xác nhận</Tag>
    //       case ORDER_STATUS.CONFIRMED:
    //         return <Tag color="blue">Đang thực hiện</Tag>
    //       case ORDER_STATUS.SUCCCESS:
    //         return <Tag color="green">Hoàn thành</Tag>
    //       case ORDER_STATUS.CANCELED:
    //         return <Tag color="volcano">Hủy</Tag>
    //       case ORDER_STATUS.DENY:
    //         return <Tag color="volcano">Từ chối</Tag>
    //     }
    //   },
    // },
    // {
    //   width: 120,
    //   title: <b>Trạng thái công nợ</b>,
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (value: number, record: any) => {
    //     return record.is_paid ? (
    //       <Tag color="green">Đã thanh toán</Tag>
    //     ) : moment().isBefore(record.payment_time) ? (
    //       <Tag color="blue">Chờ thanh toán</Tag>
    //     ) : (
    //       <Tag color="volcano">Quá hạn</Tag>
    //     )
    //   },
    // },
    {
      width: 30,
      title: <b>Chi tiết</b>,
      render: (value: any, record: any) => (
        <InfoCircleOutlined
          style={{ fontSize: '18px' }}
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.order_id}`,
              state: {
                name: ADMIN_ROUTER_PATH.CUSTOMER_DETAIL,
                idCustomer: param.id,
              },
            })
          }}
        />
      ),
    },
  ]
  const param: any = useParams()
  const location: any = useLocation()

  const [isLoading, setIsLoading] = useState({
    isLoadingTable: false,
    isLoadingSearch: false,
  })
  const [params, setParams] = useState<TParam>({
    page: 1,
    action: undefined,
    payment_to_date: undefined,
    payment_from_date: undefined,
    from_date: undefined,
    to_date: undefined,
  })
  const [dataSource, setDataSource] = useState<TDataSource>({
    name: '',
    total_debt: 0,
    dataTable: [],
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      setIsLoading({ ...isLoading, isLoadingTable: true })
      const res = await getDetailDebtCustomer(param.id, params)

      const dataTable = res.data[0]?.rows?.map((item: any, index: number) => ({
        ...item,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
      }))
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
      setDataSource({
        name: location?.state?.name,
        dataTable: dataTable,
        total_debt: res.data[0]?.debtEnterprise,
      })
    } catch (error: any) {
    } finally {
      setIsLoading({ ...isLoading, isLoadingTable: false })
    }
  }

  useEffect(() => {
    if (location?.state) {
      setParams(prev => ({
        ...prev,
        from_date: location?.state?.debt_from_date,
        to_date: location?.state?.debt_to_date,
      }))
    }
  }, [location?.state])

  return (
    <Container
      header={
        <HeaderDetail
          customer_id={param.id}
          params={params}
          title={dataSource?.name}
          debtStart={dataSource?.total_debt}
        />
      }
      filterComponent={
        <FilterDetail
          params={params}
          setParams={setParams}
          onActionSubmit={(actionKey: number) => {
            setParams({
              ...params,
              action: actionKey,
              page: 1,
            })
          }}
          onPaymentDateSubmit={(
            payment_from_date?: string | Date,
            payment_to_date?: string | Date
          ) => {
            setParams({
              ...params,
              payment_to_date: payment_to_date,
              payment_from_date: payment_from_date,
              page: 1,
            })
          }}
          onDateSubmit={(
            from_date?: string | Date,
            to_date?: string | Date
          ) => {
            setParams({
              ...params,
              to_date: to_date,
              from_date: from_date,
              page: 1,
            })
          }}
        />
      }
      contentComponent={
        <div>
          <div
            style={{
              fontSize: '18px',
              marginBottom: '10px',
              fontWeight: 'bold',
              color: '#B40000',
            }}
          >
            <DollarOutlined /> Số dư đầu kỳ :{' '}
            {/* <DollarOutlined /> Tổng dư nợ hiện tại :{' '}
            {dataSource.total_debt ? formatPrice(dataSource.total_debt) : '0'} đ */}
            {dataSource.total_debt ? formatPrice(dataSource.total_debt) : '0'} đ
          </div>
          <Table
            size="middle"
            loading={isLoading.isLoadingTable}
            bordered
            columns={column}
            dataSource={dataSource.dataTable}
            scroll={{
              x: 800,
            }}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
