import { useState } from 'react'

export const useNotification = () => {
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })

  return {
    isLoadingTable,
    currentSelected,
    setIsLoadingTable,
    setCurrentSelected,
  }
}
