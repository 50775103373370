import { PageHeader, Table, Tag } from 'antd'
import { useState } from 'react'
import Filter from './components/Filter'
import moment from 'moment'
import styles from 'common/components/styles/WhiteBox.module.css'
import SaleProductDetailScreen from './SaleProductDetailScreen'
import { useSaleProduct } from './hook/useSaleProduct'

const columns = [
  {
    width: 70,
    title: 'STT',
    dataIndex: 'stt',
  },
  {
    title: 'Mã sản phẩm',
    dataIndex: 'code',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'name',
  },
  {
    title: 'Danh mục',
    dataIndex: 'product_category_name',
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'promotional_product',
    render: (value: any) => value?.product?.product_unit?.name,
  },
  {
    title: 'Thời gian bắt đầu',
    dataIndex: 'start_at',
    render: (value: any) => {
      return <>{moment(value).format(' HH:mm DD/MM/YYYY')}</>
    },
  },
  {
    title: 'Thời gian kết thúc',
    dataIndex: 'finish_at',
    render: (value: any) => {
      return <>{moment(value).format(' HH:mm DD/MM/YYYY')}</>
    },
  },
  {
    title: 'Trạng thái khuyến mãi',
    dataIndex: 'sale_status',
    render: (value: number) => (
      <Tag color={value ? 'green' : 'volcano'}>
        {value ? 'Đang khuyến mại' : 'Không khuyến mại'}
      </Tag>
    ),
  },

  {
    title: 'Ngày tạo ',
    dataIndex: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]

export default function ContentList() {
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const { dataSource, params, paging, isLoading, setParams } = useSaleProduct()

  return (
    <div className={styles.container}>
      <PageHeader className={styles.header} title="Sản phẩm khuyến mại" />
      <div className={styles.content}>
        <Filter
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search: searchKey === '' ? undefined : searchKey.trim(),
              page: 1,
            })
          }}
          onCategorySubmit={(category_id: number) => {
            setParams({ ...params, category_id: category_id, page: 1 })
          }}
          onStatusSubmit={(statusKey: number) => {
            setParams({ ...params, status: statusKey, page: 1 })
          }}
          onChangeTime={(
            from_date?: string | Date,
            to_date?: string | Date
          ) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
        />
      </div>
      <div className={styles.content}>
        <p>
          Kết quả lọc: <b>{paging.total}</b>
        </p>
        <Table
          className={styles.table_hover}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
          }}
          loading={isLoading.loadingTable}
          expandedRowKeys={[currentSelected.id]}
          onRow={(row: any) => ({
            onClick: () => {
              if (currentSelected !== row) setCurrentSelected(row)
              else setCurrentSelected({ id: -1 })
            },
          })}
          expandable={{
            expandedRowRender: (record: any) => {
              return <SaleProductDetailScreen data={record} />
            },
          }}
          dataSource={dataSource}
          bordered
          columns={columns}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page })
            },
          }}
        />
      </div>
    </div>
  )
}
