export function getExtension(filename: string) {
  var parts = filename.split('.')
  return parts[parts.length - 1]
}

export function isImage(filename: string) {
  var ext = getExtension(filename)
  switch (ext?.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      //etc
      return true
  }
  return false
}

export function isVideo(filename: string) {
  var ext = getExtension(filename)
  switch (ext?.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true
  }
  return false
}

export function isWord(filename: string) {
  var ext = getExtension(filename)
  switch (ext?.toLowerCase()) {
    case 'doc':
    case 'docx':
      // etc
      return true
  }
  return false
}

export function isExcel(filename: string) {
  var ext = getExtension(filename)
  switch (ext?.toLowerCase()) {
    case 'xls':
    case 'xlsx':
      // etc
      return true
  }
  return false
}
