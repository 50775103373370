import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import React from 'react'

const ButtonExportExcelV2 = ({
  onClick,
  loading,
}: {
  onClick: () => void
  loading: boolean
}) => {
  return (
    <Button
      type="primary"
      style={{
        borderRadius: '3px',
        backgroundColor: 'green',
        borderColor: 'green',
      }}
      onClick={onClick}
    >
      {loading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 24, color: 'white', marginRight: '10px' }}
              spin
            />
          }
        />
      )}
      <b>
        <PrinterOutlined /> Xuất Excel
      </b>
    </Button>
  )
}

export default ButtonExportExcelV2
