import { getListCategory, getListProduct } from './../ProductApi'
import { useEffect, useState } from 'react'

type Params = {
  search: undefined | string
  page: number
  status: undefined | number
  category_id: undefined | number
  limit: number
}
export const useProduct = () => {
  const [product, setProduct] = useState<any>([])
  const [isLoading, setIsLoading] = useState({
    loadingTable: false,
    loadingSearch: false,
    loadingAddEdit: false,
  })
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [params, setParams] = useState<Params>({
    search: undefined,
    page: 1,
    status: undefined,
    category_id: undefined,
    limit: 12,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })
  const [listCategory, setListCategory] = useState([])

  useEffect(() => {
    requestListCategory()
  }, [])
  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setIsLoading({ ...isLoading, loadingTable: true, loadingSearch: true })
    try {
      const res = await getListProduct(params)

      const tableData = res.data.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          code: item.product.code,
          name: item.product.name,
          product_category_name: item.product?.product_category?.name,
          status: item.status && item.product_status ? 1 : 0,
        }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setProduct(tableData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false, loadingSearch: false })
    }
  }

  const requestListCategory = async () => {
    const res = await getListCategory()
    const data = res.data.map((item: any) => ({
      value: item.id,
      text: item.name,
    }))
    setListCategory(data)
  }

  return {
    product,
    isLoading,
    currentSelected,
    params,
    paging,
    listCategory,
    setIsLoading,
    setPaging,
    setParams,
    setCurrentSelected,
    setProduct,
    getData,
  }
}
