import { Descriptions, Tag } from 'antd'
import { useState } from 'react'
import { formatPrice } from 'utils/ruleForm'
import styles from '../../orders/styles/style.module.css'
import ChangePayment from './ChangePayment'
export default function OrderInfor() {
  const [changePayment, setChangePayment] = useState(false)
  return (
    <Descriptions
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <b>Thông tin đơn hàng</b>
          {/* <Divider /> */}
          <Tag color="blue">Chờ xác nhận</Tag>
        </div>
      }
      column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
    >
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Mã đơn hàng</span>}
      >
        ABC1234
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Sản phẩm</span>}
      >
        1 sản phẩm
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span style={{ paddingLeft: '15px' }}> Hình thức thanh toán</span>
        }
      >
        Chuyển khoản
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Tổng tiền</span>}
      >
        {formatPrice(10000000)} đ
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span style={{ paddingLeft: '15px' }}> Trạng thái hoàn thành</span>
        }
      >
        Đã thanh toán
        <a
          onClick={() => {
            setChangePayment(true)
          }}
        >
          &nbsp;(Thay đổi)
        </a>
        <ChangePayment
          isLoadingButton
          visible={changePayment}
          onCancel={() => {
            setChangePayment(false)
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span style={{ paddingLeft: '15px' }}>
            {' '}
            Tổng tiền giảm (Điểm tích lũy)
          </span>
        }
      >
        {formatPrice(100000)} đ
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Phương tiện</span>}
      >
        Oto
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span style={{ paddingLeft: '15px' }}> Tổng tiền thanh toán</span>
        }
      >
        {formatPrice(10000000)} đ
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Biển số</span>}
      >
        29A-1234
      </Descriptions.Item>
    </Descriptions>
  )
}
