import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const useOrder = () => {
  const history = useHistory()
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const onDeleteProduct = async (id: any) => {}
  const param: any = useParams()
  return { history, isLoadingTable, setIsLoadingTable, onDeleteProduct, param }
}
