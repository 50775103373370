import { LeftCircleOutlined } from '@ant-design/icons'
import { Form, Input, Modal } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { useEffect } from 'react'

type Props = {
  visible: boolean
  onCancel: any
  setShowAddDebt?: any
  data?: any
  // isLoadingButton: boolean
  setIsLoading: any
  onDenyStatus: any
  isLoading: any
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      reason: null,
    }
  } else {
    return {
      ...data,
    }
  }
}
const { TextArea } = Input

export default function DenyStatus({
  visible,
  onCancel,
  data,
  setIsLoading,
  onDenyStatus,
  isLoading,
}: // isLoadingButton,
Props) {
  // const { setIsLoading, onDenyStatus, isLoading } = useDetailOrder()
  const [form] = Form.useForm()
  const initialValues = convertDataToFrom(data)

  const onFinish = async (values: any) => {
    setIsLoading(true)
    try {
      delete values.confirm
      let newData

      newData = {
        ...values,
        status: 6,
        reason: values.reason.trim(),
      }
      onDenyStatus(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (data) {
      //   setUpload({
      //     ...upload,
      //     imageUrl: data.avatar,
      //   })
    }
  }, [data])

  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      title="Lý do từ chối đơn hàng"
      visible={visible}
      maskClosable={false}
      footer={null}
    >
      <Form
        style={{ padding: 0 }}
        {...formItemLayout}
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          style={{
            width: '150%',
          }}
          name="reason"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập lý do từ chối!',
            },
            {
              max: 55,
              message: 'Tối đa 55 ký tự!',
            },
          ]}
        >
          <TextArea placeholder="Lý do từ chối" rows={3} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <ButtonBottomModal
            isLoadingButton={isLoading}
            onCancel={() => {
              onCancel()
              form.resetFields()
            }}
            text="OK"
            textCancel={'Quay lại'}
            iconCancel={<LeftCircleOutlined />}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
