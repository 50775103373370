import { Col, Row } from 'antd'
import { useParams } from 'react-router'
import CustomerInfor from './components/CustomerInfo'
import HeaderDetail from './components/HeaderDetail'
import HistoryOrder from './components/HistoryOrder'
import Insurance from './components/Insurance'
import OrderInfor from './components/OrderInfor'
import ProductList from './components/ProductList'
import Receipt from './components/Receipt'
import ReceiverInfor from './components/ReceiverInfor'
import styles from 'common/components/styles/WhiteBox.module.css'
type Props = {
  data: any
}

export default function InsuranceDetailScreen({ data }: Props) {
  //   const [order, setOrder] = useState()
  const param = useParams()
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeaderDetail />
      </div>
      <Row style={{ paddingTop: '15px' }}>
        <Col
          style={{
            backgroundColor: 'white',
            width: '49%',
            borderRadius: '5px',
          }}
        >
          <div>
            <CustomerInfor />
            <ReceiverInfor />
          </div>
        </Col>
        <div style={{ width: '2%' }}></div>
        <Col
          style={{
            backgroundColor: 'white',
            width: '49%',
            borderRadius: '5px',
          }}
        >
          <HistoryOrder />
        </Col>
      </Row>
      <div style={{ paddingTop: '15px' }}>
        <OrderInfor />
      </div>
      <div style={{ paddingTop: '15px' }}>
        <Insurance />
      </div>
      <div style={{ paddingTop: '15px' }}>
        <Receipt />
      </div>
      <div style={{ paddingTop: '15px' }}>
        <ProductList />
      </div>
    </div>
  )
}
