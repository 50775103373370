import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Modal, Divider, Table, Empty, Button, Row } from 'antd'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSaleProductInfor, getSaleStoreSelected } from '../SaleProductSlice'

type Props = {
  id: number
  visible: boolean
  onCancel: () => void
  onSave: (listChoose: Array<any>) => void
  listPetrolStore: Array<any>
  isLoading: any
  paging: any
  getStoreBySearch: (value?: string) => void
  getStoreByPage: (value: number) => void
  storesSelected: Array<any>
  storesChecked: any
  setStoresChecked: any
}

const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    width: 70,
  },
  {
    title: 'Tên cửa hàng',
    dataIndex: 'name',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
  },
  {
    title: 'Mã số thuế',
    dataIndex: 'tax',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'location_address',
    render: (data: any, value: any) => {
      return (
        <div>
          {(data ? data + ',' : '') +
            (value?.district?.name ? value?.district?.name + ',' : '') +
            (value?.province?.name || '-')}
        </div>
      )
    },
  },
]

export default function ChooseShopModal({
  id,
  visible,
  paging,
  isLoading,
  getStoreBySearch,
  getStoreByPage,
  storesSelected,
  listPetrolStore,
  onCancel,
  onSave,
  storesChecked,
  setStoresChecked,
}: Props) {
  const saleProductInfor = useSelector(getSaleProductInfor)
  const orginStoresSelected = useSelector(getSaleStoreSelected)
  const [stores, setStores] = useState<Array<any>>([])

  useEffect(() => {
    setStores(storesSelected)
  }, [storesSelected])

  const rowSelection = {
    preserveSelectedRowKeys: true, // cho phép lưu lại những row đã chọn trên nhiều trang
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      const listStore = selectedRows.map((item: any) => {
        return {
          id: item?.id,
          petrol_store_id: item?.id,
          name: item?.name,
          address:
            (item?.location_address ? item?.location_address + ',' : '') +
            (item?.district?.name ? item?.district?.name + ',' : '') +
            item?.province?.name,
          price: 0,
          price_root: item?.product_stocks.find(
            (itemX: any) => itemX.product_id === saleProductInfor.product_id
          ).price,
          start_at: undefined,
          finish_at: undefined,
        }
      })
      setStoresChecked(listStore)
    },
  }

  return (
    <Modal
      title={<b>Thêm cửa hàng xăng dầu</b>}
      visible={visible}
      maskClosable={false}
      footer={null}
      width={800}
      onCancel={() => {
        onCancel()
      }}
      destroyOnClose
    >
      <TypingAutoSearch
        onSearchSubmit={(searchKey: string) => {
          setTimeout(() => {
            const searchValue = searchKey === '' ? undefined : searchKey.trim()
            getStoreBySearch(searchValue)
          }, 300)
        }}
        isSearchLoading={isLoading.loadingSearch}
        placeholder="Tên công ty, số điện thoại, mã số thuế"
      />
      <Divider
        dashed
        style={{ backgroundColor: '#BFBFBF', margin: '15px 0px' }}
      />
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 'calc(100vh - 300px)',
        }}
        bordered
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={listPetrolStore}
        loading={isLoading.loadingTableStore}
        columns={columns}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu.</span>}
            />
          ),
        }}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            getStoreByPage(page)
          },
        }}
      />
      <Row gutter={[16, 16]} justify="end">
        <Button
          danger
          type="primary"
          style={{ marginRight: '10px' }}
          icon={<CloseCircleOutlined />}
          onClick={() => {
            onCancel()
          }}
        >
          Đóng
        </Button>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          onClick={() => {
            onSave(storesSelected.concat(storesChecked)) //ahihi
          }}
        >
          Lưu
        </Button>
      </Row>
    </Modal>
  )
}
