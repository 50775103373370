import React, { useEffect } from 'react'
import PrivateRoute from './PrivateRoute'
import screenRouter from './routerType'
import io, { Socket } from 'socket.io-client'
import Cookie from 'js-cookie'
import { SESSION_ID } from 'common/config'
import { WebSocket } from 'services/WebSocket'

export default function Nav() {
  const cookie = Cookie.get(SESSION_ID)
  useEffect(() => {
    if (cookie) {
      WebSocket.init(cookie)
    }
  }, [cookie])
  return (
    <>
      {screenRouter.map((val, index) => (
        <PrivateRoute
          key={index}
          path={`${val.path}`}
          component={val.component}
          exact
        />
      ))}
    </>
  )
}
