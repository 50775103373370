import moment from 'moment'
import { Table, Tag } from 'antd'
import { formatPrice } from 'utils/ruleForm'
import Filter from './components/Filter'
import HeaderBanner from './components/Header'
import { ENTERPRISE_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import { ORDER_STATUS } from 'utils/constants'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useOrder } from './hook/useOrder'
import { getReportTotalOrder } from './OrderApi'
import React from 'react'

export default function Order() {
  const {
    history,
    isLoading,
    listOrder,
    paging,
    params,
    setParams,
  } = useOrder()
  const column = [
    {
      width: '50px',
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      width: '70px',
      title: <b>Mã đơn hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      width: '250px',
      title: <b>Khách hàng</b>,
      dataIndex: 'user',
      key: 'user',
      render: (value: any, _: any, index: number) =>
        index > 0 && (
          <>
            {value?.name}
            <br />({value?.phone})
          </>
        ),
    },
    {
      width: '100px',
      title: <b>Người nhận</b>,
      dataIndex: 'user',
      key: 'user',
      render: (value: any) => <>{value?.name}</>,
    },
    {
      title: <b>Số SP</b>,
      width: '40px',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (value: any) => <>{value == 0 ? 1 : formatPrice(value)}</>,
    },
    {
      width: '80px',
      title: <b>Tiền thanh toán</b>,
      dataIndex: 'total_price',
      render: (value: any, record: any) => (
        <>
          {record.order_gasoline?.is_full_fuel === 1 && record.total_price === 0
            ? 'Chưa xác định'
            : record.order_gasoline?.is_full_fuel === 0 &&
              record.total_price === 0
            ? '0đ'
            : !record.order_gasoline && record.total_price === 0
            ? '0đ'
            : formatPrice(record.total_price) + 'đ'}
        </>
      ),
    },
    {
      width: '90px',
      title: <b>TT Đơn hàng</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        switch (value) {
          case ORDER_STATUS.PENDING:
            return <Tag color="blue">Chờ xác nhận</Tag>
          case ORDER_STATUS.CONFIRMED:
            return <Tag color="blue">Đang thực hiện</Tag>
          // case ORDER_STATUS.SHIP:
          //   return <Tag color="blue">Đang vận chuyển</Tag>
          case ORDER_STATUS.SUCCCESS:
            return <Tag color="green">Hoàn thành</Tag>
          case ORDER_STATUS.CANCELED:
            return <Tag color="volcano">Hủy</Tag>
          case ORDER_STATUS.DENY:
            return <Tag color="volcano">Từ chối</Tag>
        }
      },
    },
    {
      width: '100px',
      title: <b>Phương thức thanh toán</b>,
      dataIndex: 'order_payment',
      key: 'order_payment',
      render: (value: any) => <>{value?.type_payment?.name}</>,
    },
    {
      width: '80px',
      title: <b>TT Thanh toán</b>,
      dataIndex: 'order_payment',
      key: 'order_payment',
      render: (value: any, _: any, index: number) => {
        return (
          index > 0 && (
            <div>
              {value?.is_paid === 1 ? (
                <Tag style={{ fontSize: 12 }} color="green">
                  Đã thanh toán
                </Tag>
              ) : (
                <Tag style={{ fontSize: 12 }} color="volcano">
                  Chưa thanh toán
                </Tag>
              )}
            </div>
          )
        )
      },
    },
    {
      width: '80px',
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any, _: any, index: number) => {
        return index > 0 && <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
      },
    },
  ]

  const [firstRecord, setFirstRecord] = React.useState<any>({})

  const getTotalReport = async () => {
    try {
      const res = await getReportTotalOrder(params)

      setFirstRecord({
        id: Math.random().toString(),
        total_price: res.data?.total_price,
      })
    } catch (err) {
      console.log(
        '🚀 ~ file: SaleReportScreen.tsx:347 ~ getTotalReport ~ err',
        err
      )
    }
  }
  React.useEffect(() => {
    getTotalReport()
  }, [params])

  return (
    <Container
      header={<HeaderBanner params={params} />}
      filterComponent={
        <Filter
          data={params}
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search:
                searchKey.trim() === '' ? undefined : searchKey.trimStart(),
              page: 1,
            })
          }}
          onStatusOrder={(statusKey: string) => {
            setParams({ ...params, status: statusKey, page: 1 })
          }}
          onStatusPayment={(statusPaymentKey: string) => {
            setParams({ ...params, is_paid: statusPaymentKey, page: 1 })
          }}
          onDateSubmit={(from_date: string, to_date: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
            })
          }}
          onTypePayment={(type_payment: any) => {
            setParams({ ...params, type_payment_id: type_payment, page: 1 })
          }}
          onCategorySubmit={(category_id: number) => {
            setParams({ ...params, category_id: category_id, page: 1 })
          }}
          params={params}
          setParams={setParams}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            size="middle"
            loading={isLoading}
            bordered
            columns={column}
            dataSource={[firstRecord, ...listOrder]}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
              // y: 'calc(100vh - 430px)',
            }}
            onRow={(record: any, rowIndex: any) => ({
              onClick: () => {
                if (rowIndex < 1) return
                history.push({
                  pathname: `${ENTERPRISE_ROUTER_PATH.ORDER_DETAIL}/${record.id}`,
                  state: {
                    storeId: record.petrol_store_id,
                    orderId: record.id,
                    userId: record.user_id,
                  },
                })
              },
            })}
            pagination={{
              pageSize: 13,
              total: paging?.total,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
