import { ApiClient } from 'services/ApiService'

export const getListPetrolStore = (payload: any) =>
  ApiClient.get('/admin/petrol-store', payload)

export const getDetailPetrolStore = (petrol_store_id: number) =>
  ApiClient.get(`/admin/petrol-store/${petrol_store_id}/detail`)

export const createPetrolStore = (payload: any) =>
  ApiClient.post(`/admin/petrol-store`, payload)

export const updatePetrolStore = (payload: any) =>
  ApiClient.put(`/admin/petrol-store/${payload.id}`, payload.data)

export const changeStatusPetrolStore = (petrol_store_id: number) =>
  ApiClient.patch(`/admin/petrol-store/${petrol_store_id}/status`)

export const deletePetrolStore = (petrol_store_id: number) =>
  ApiClient.delete(`/admin/petrol-store/${petrol_store_id}`)

export const getPlace = (payload: any) =>
  ApiClient.get('/google-address/place', payload)

export const getPlaceAuto = (payload: any) =>
  ApiClient.get('/google-address/place-auto', payload)

export const getProvince = () => ApiClient.get('/google-address/province')

export const getDistrict = (payload: any) =>
  ApiClient.get('/google-address/district', payload)

export const getWard = (payload: any) =>
  ApiClient.get('/google-address/ward', payload)

export const getProductPetrolStore = (petrol_store_id: number, payload: any) =>
  ApiClient.get(`/admin/petrol-store/${petrol_store_id}/product`, payload)

export const getOrderPetrolStore = (petrol_store_id: number, payload: any) =>
  ApiClient.get(`/admin/petrol-store/${petrol_store_id}/order`, payload)

export const requestListCategory = (payload: any) =>
  ApiClient.get('/admin/product/list-category', payload)

export const listRates = (petrol_store_id: number, payload: any) =>
  ApiClient.get(`/customer/review/${petrol_store_id}/review-admin`, payload)

export const changeStatusRates = (review_id: number) =>
  ApiClient.patch(`/customer/review/${review_id}/status`)

export const deleteRates = (petrol_store_id: number) =>
  ApiClient.delete(`/customer/review/${petrol_store_id}`)
