import { PageHeader, Modal, Button } from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import PriceInformationProduct from './components/PriceInformationProduct'
import GeneralInformationProduct from './components/GeneralInformationProduct'
import { CheckCircleOutlined } from '@ant-design/icons'
import { ButtonAdd } from 'common/components/Buttons'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearDataSaleProduct,
  getSaleProductInfor,
  getSaleStoreSelected,
  setCategoryId,
  setDescriptionProduct,
  setOriginStoresSelected,
  setProductId,
  setProductName,
} from './SaleProductSlice'
import { useEffect, useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { TDataPush, TParamsChooseShop } from './SaleProduct'
import {
  requestDetailProduct,
  requestListPetrolStore,
  createSaleProduct,
  updateSaleProduct,
  getListProductByCategory,
} from './SaleProductApi'
import { ADMIN_ROUTER_PATH } from 'common/config'
import moment, { Moment } from 'moment'

export default function AddEditProductScreen() {
  const param: any = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const orginStoresSelected = useSelector(getSaleStoreSelected)
  const saleProductInfor = useSelector(getSaleProductInfor)
  const [pastTime, setPastTime] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState({
    loadingUpdate: false,
    loadingTable: false,
    loadingTableStores: false,
    loadingSearch: false,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })
  const [listProduct, setListProduct] = useState<Array<any>>([])
  const [listPetrolStore, setListPetrolStore] = useState<Array<any>>([])
  const [storesSelected, setStoresSelected] = useState<Array<any>>([])
  const [storesChecked, setStoresChecked] = useState<Array<any>>([])
  const [arrayDeletePetrol, setArrayDeletePetrol] = useState<Array<number>>([])
  const [paramsChooseShop, setParamsChooseShop] = useState<TParamsChooseShop>({
    search: undefined,
    product_id: undefined,
    array_petrol_store_cancle: [],
    page: 1,
  })

  const location = useLocation()

  useEffect(() => {
    if (param.id) {
      getDataProduct()
    }
  }, [])

  useEffect(() => {
    if (saleProductInfor.category_id) getProductByCategory()
  }, [saleProductInfor.category_id])

  useEffect(() => {
    if (saleProductInfor.product_id) {
      setParamsChooseShop({
        ...paramsChooseShop,
        product_id: saleProductInfor.product_id,
      })
    }
  }, [saleProductInfor.product_id])

  useEffect(() => {
    const list_store_cancel = storesSelected.map(
      (item: any) => item.petrol_store_id
    )
    setParamsChooseShop({
      ...paramsChooseShop,
      array_petrol_store_cancle: list_store_cancel,
      page: 1,
    })
  }, [storesSelected.length])

  useEffect(() => {
    if (paramsChooseShop.product_id) {
      getListShop()
    }
  }, [paramsChooseShop])

  // Lấy danh sách petrol store
  const getListShop = async () => {
    try {
      setIsLoading({
        ...isLoading,
        loadingTableStores: true,
        loadingSearch: true,
      })
      const res = await requestListPetrolStore(paramsChooseShop)
      const tableData = res.data.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          name: item.name,
          address: item.address,
          phone: item.phone,
          tax: item.tax,
        }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setListPetrolStore(tableData)
    } catch (error: any) {
      console.log(error)
    } finally {
      setIsLoading({
        ...isLoading,
        loadingTableStores: false,
        loadingSearch: false,
      })
    }
  }

  const getProductByCategory = async () => {
    try {
      const res = await getListProductByCategory({
        category_id: saleProductInfor?.category_id,
      })
      const listProduct = res.data.map((item: any) => {
        return { value: item.id, text: item.name }
      })
      setListProduct(listProduct)
    } catch (error: any) {}
  }

  const getDataProduct = async () => {
    try {
      // setIsLoading({ ...isLoading, loadingTable: true }) //   mở lại sau
      const res = await requestDetailProduct(parseInt(param.id))
      dispatch(setCategoryId(res?.data?.product_category.id))
      dispatch(setProductId(res?.data?.promotional_product.product_id))
      dispatch(
        setDescriptionProduct(res?.data?.promotional_product.description)
      )
      dispatch(setProductName(res?.data?.name))
      const dataTable = res.data.promotional_product.store_promotional_products.map(
        (item: any) => {
          return {
            id: item.id,
            name: item?.petrol_store?.name,
            address: item?.petrol_store?.address,
            price: item?.price,
            price_root: item?.base_price,
            promotion_time: [item.start_at, item.finish_at],
            petrol_store_id: item?.petrol_store_id,
            start_at: item.start_at,
            finish_at: item.finish_at,
          }
        }
      )

      setStoresSelected(dataTable)
      dispatch(setOriginStoresSelected(dataTable))
    } catch (error: any) {
      notificationError(error?.message)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  const getStoreBySearch = (value?: string) => {
    setParamsChooseShop({
      ...paramsChooseShop,
      search: value,
      page: 1,
    })
  }

  const getStoreByPage = (value: number) => {
    setParamsChooseShop({
      ...paramsChooseShop,
      page: value,
    })
  }

  const validateBeforeConfirm = () => {
    if (!saleProductInfor.category_id) {
      notificationError('Vui lòng chọn danh mục sản phẩm')
      return false
    }
    if (!saleProductInfor.product_id) {
      notificationError('Vui lòng chọn sản phẩm')
      return false
    }
    if (storesSelected.length === 0) {
      notificationError('Vui lòng chọn cửa hàng muốn khuyến mãi')
      setPastTime(false)
      document.getElementById('add_store')?.focus()
      return false
    }
    for (let i = 0; i < storesSelected.length; i++) {
      if (storesSelected[i].price === 0 && !storesSelected[i].price) {
        notificationError('Vui lòng nhập giá sản phẩm')
        document.getElementById(`price-product-${i}`)?.focus()
        return false
      }
      if (!storesSelected[i].start_at || !storesSelected[i].finish_at) {
        notificationError('Vui lòng chọn thời gian khuyến mại')
        document.getElementById(`promotion-time-product-${i}`)?.focus()
        return false
      }
      if (storesSelected[i].price_root <= storesSelected[i].price) {
        notificationError('Vui lòng nhập giá khuyến mại nhỏ hơn giá gốc')
        setOpenConfirm(false)
        setPastTime(false)
        document.getElementById(`price-product-${i}`)?.focus()
        return false
      }
    }
    return true
  }

  const confirmPastTime = () => {
    const date: any = moment(moment().format('YYYY-MM-DD'))
    const returnDate: any = (dt: string) => moment(dt)

    for (let i = 0; i < storesSelected.length; i++) {
      if (
        // present >= new Date(storesSelected[i].start_at) ||
        // new Date(storesSelected[i].start_at).setSeconds(0) >=
        //   new Date(storesSelected[i].finish_at).setSeconds(0)
        // ngày hiện tại lớn hơn ngày bắt đầu thì là quá khứ
        date._d.setSeconds(0) >=
        (typeof storesSelected[i].start_at === 'number'
          ? storesSelected[i].start_at
          : returnDate(moment(storesSelected[i].start_at))?._d?.setSeconds(0))
      ) {
        setOpenConfirm(true)
        return
      }
    }
    setPastTime(true)
  }

  const onSave = async () => {
    if (validateBeforeConfirm() && !isLoading.loadingUpdate) {
      setIsLoading({ ...isLoading, loadingUpdate: true })
      try {
        const dataPush: TDataPush = {
          product_id: saleProductInfor.product_id,
          description: saleProductInfor.description,
          category_id: saleProductInfor.category_id,
        }
        if (!param.id) {
          // *****thêm sản phẩm*****
          dataPush.petrol_store_data = storesSelected.map((item: any) => ({
            start_at: item.start_at,
            finish_at: item.finish_at,
            petrol_store_id: item.petrol_store_id,
            price: item.price,
          }))

          const res = await createSaleProduct(dataPush)
          setTimeout(() => {
            notificationSuccess(res.message)
            history.replace(`${ADMIN_ROUTER_PATH.SALE_PRODUCT}`)
          }, 300)
        } else {
          // *****sửa sản phẩm*****
          dataPush.petrol_store_data_update = []
          dataPush.petrol_store_data = []
          delete dataPush.product_id
          delete dataPush.category_id
          dataPush.array_delete_petrol_store_id = arrayDeletePetrol // Cửa hàng bị xóa
          // array_update: lấy danh sách những cửa hàng được thêm và sửa data
          const array_update = storesSelected
            .filter((item: any) => !orginStoresSelected.includes(item))
            .map((store: any) => ({
              id: store.id,
              petrol_store_id: store.petrol_store_id,
              start_at: store.start_at,
              finish_at: store.finish_at,
              price: store.price,
            }))
          // Update thông tin cửa hàng
          for (let index = 0; index < orginStoresSelected.length; index++) {
            array_update.filter(itemX => {
              if (
                itemX.petrol_store_id ===
                  orginStoresSelected[index].petrol_store_id &&
                !orginStoresSelected.includes(itemX)
              )
                dataPush.petrol_store_data_update?.push(itemX)
            })
          }
          // Add cửa hàng
          dataPush.petrol_store_data = array_update
            .filter(
              (item: any) => !dataPush.petrol_store_data_update?.includes(item)
            )
            .map((item: any) => ({
              start_at: item.start_at,
              finish_at: item.finish_at,
              petrol_store_id: item.petrol_store_id,
              price: item.price,
            }))
          const res = await updateSaleProduct(param.id, dataPush)
          setTimeout(() => {
            notificationSuccess(res.message)
            history.replace(
              `${ADMIN_ROUTER_PATH.SALE_PRODUCT_DETAIL}/${param.id}`
            )
          }, 300)
        }
      } catch (error: any) {
        notificationError(error.message)
      } finally {
        dispatch(clearDataSaleProduct())
        setIsLoading({ ...isLoading, loadingUpdate: false })
      }
    }
  }

  useEffect(() => {
    if (pastTime) onSave()
  }, [pastTime])

  return (
    <div className={styles.container}>
      <div>
        <PageHeader
          className={styles.header}
          title={
            param.id ? 'Sửa sản phẩm khuyến mại' : 'Thêm sản phẩm khuyến mại'
          }
          onBack={() => {
            dispatch(clearDataSaleProduct())
            history.goBack()
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.SALE_PRODUCT}`,
              state: location.state,
            })
          }}
          extra={[
            <ButtonAdd
              onClickButton={() => {
                confirmPastTime()
              }}
              text="Lưu"
              loading={isLoading.loadingUpdate}
              icon={<CheckCircleOutlined />}
            />,
          ]}
        />
      </div>
      <div className={styles.content}>
        <GeneralInformationProduct
          id={param?.id}
          saleProductInfor={saleProductInfor}
          listProduct={listProduct}
        />
      </div>
      <div className={styles.content}>
        <PriceInformationProduct
          id={param?.id}
          paging={paging}
          isLoading={isLoading}
          listPetrolStore={listPetrolStore}
          paramsChooseShop={paramsChooseShop}
          arrayDeletePetrol={arrayDeletePetrol}
          storesChecked={storesChecked}
          storesSelected={storesSelected}
          setParamsChooseShop={setParamsChooseShop}
          setStoresSelected={setStoresSelected}
          setArrayDeletePetrol={setArrayDeletePetrol}
          setStoresChecked={setStoresChecked}
          getStoreBySearch={getStoreBySearch}
          getStoreByPage={getStoreByPage}
        />
      </div>
      <Modal
        title=""
        visible={openConfirm}
        closable={false}
        footer={
          <>
            <Button
              danger
              onClick={() => {
                setPastTime(false)
                setOpenConfirm(false)
              }}
            >
              Hủy
            </Button>
            <ButtonAdd
              onClickButton={() => {
                onSave()
              }}
              text="Đồng ý"
              loading={isLoading.loadingUpdate}
              icon={<CheckCircleOutlined />}
            />
          </>
        }
      >
        <b>Bạn muốn để thời gian khuyến mại trong quá khứ?</b>
      </Modal>
    </div>
  )
}
