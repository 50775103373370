import { Modal, Input, Form } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { RadiusSelection } from 'common/components/Inputs'
import { useEffect } from 'react'
import { useConfigSysterm } from '../hook/useConfigSysterm'

type Props = {
  visible: boolean
  onCancel?: any
  data?: any
  onCreateCancel?: any
  onUpdateCancel?: any
  getListReason: any
  isLoading: boolean
  setIsLoading: any
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
function convertDataToFrom(data: any) {
  if (!data) {
    return {
      content: null,
      status: null,
      content_en: null,
    }
  } else {
    return {
      ...data,
      content: data.content,
      content_en: data.content_en,
      status: data.status,
    }
  }
}
export const AddEditCancel = ({
  visible,
  onCancel,
  data,
  onCreateCancel,
  onUpdateCancel,
  getListReason,
  setIsLoading,
  isLoading,
}: Props) => {
  const initialValues = convertDataToFrom(data)
  const onFinish = async (values: any) => {
    setIsLoading(true)
    try {
      let newData
      if (!data) {
        newData = {
          ...values,
          status: 1,
        }
        onCreateCancel(newData, form.resetFields)
      } else {
        newData = {
          ...values,
          id: data.id,
          status: values.status,
          content_en: values.content_en,
        }
      }
      const res = await onUpdateCancel(newData, form.resetFields)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
      getListReason()
    }
  }
  const [form] = Form.useForm()
  const { TextArea } = Input
  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      maskClosable={false}
      footer={null}
      title={data ? 'Sửa nội dung hủy đơn hàng' : 'Thêm nội dung hủy đơn hàng'}
      visible={visible}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        labelAlign="left"
        onFinish={(values: any) => onFinish(values)}
        initialValues={initialValues}
        scrollToFirstError
      >
        {data ? (
          <Form.Item
            label="Trạng thái"
            name="status"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn trạng thái!',
              },
            ]}
          >
            <RadiusSelection
              onChange={(value: number) => {}}
              options={[
                { value: 0, text: 'Ngưng hoạt động' },
                { value: 1, text: 'Hoạt động' },
              ]}
              placeholder="Chọn trạng thái"
            />
          </Form.Item>
        ) : (
          <></>
        )}
        <Form.Item
          label="Tiếng Việt"
          name="content"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập nội dung hủy bằng Tiếng Việt!',
            },
          ]}
        >
          <TextArea
            placeholder="Nội dung hủy đơn hàng"
            showCount
            rows={3}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Tiếng Anh"
          name="content_en"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập nội dung hủy bằng Tiếng Anh!',
            },
          ]}
        >
          <TextArea
            placeholder="Nội dung hủy đơn hàng"
            showCount
            rows={3}
            allowClear
          />
        </Form.Item>

        <ButtonBottomModal
          isLoadingButton={isLoading}
          onCancel={onCancel}
          text={data ? 'Cập nhật' : 'Thêm lý do'}
        />
      </Form>
    </Modal>
  )
}
