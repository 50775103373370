import { PageHeader, Table, Tag } from 'antd'
import history from 'utils/history'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Filter from './components/Filter'
import R from 'assets'
import { ButtonAdd } from 'common/components/Buttons'
import Container from 'common/container/Container'
import { useProduct } from './hook/useProduct'
import styles from 'common/components/styles/WhiteBox.module.css'
import { formatPrice } from 'utils/ruleForm'

const columns = [
  {
    width: 50,
    title: <b>STT</b>,
    dataIndex: 'stt',
  },
  {
    title: <b>Mã sản phẩm</b>,
    dataIndex: 'code',
  },
  {
    title: <b>Tên sản phẩm </b>,
    dataIndex: 'name',
  },
  {
    width: 100,
    title: <b>Lấy tại nhà</b>,
    dataIndex: 'status_home',
    render: (value: any) => {
      return (
        <Tag color={value ? 'green' : 'volcano'}>{value ? 'Có' : 'Không'}</Tag>
      )
    },
  },
  {
    width: 90,
    title: <b>Số lượng được phép lấy tại nhà</b>,
    dataIndex: 'quantity_home',
    render: (data: any, value: any) => {
      return <>{value?.status_home == 1 ? formatPrice(data) : '--'}</>
    },
  },
  { width: 170, title: <b>Danh mục</b>, dataIndex: 'category' },
  {
    width: 70,
    title: <b>Thứ tự ưu tiên</b>,
    dataIndex: 'priority',
  },
  {
    width: 70,
    title: <b>Đơn vị tính</b>,
    dataIndex: 'unit',
    render: (value: any) => value || '--',
  },

  {
    width: 100,
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    render: (value: any) => {
      return (
        <Tag color={value ? 'green' : 'volcano'}>
          {value ? 'Đang hoạt động' : 'Tạm dừng'}
        </Tag>
      )
    },
  },
]

export default function ContentList() {
  const { paging, dataSource, params, setParams, isLoading } = useProduct()

  return (
    <Container
      header={
        <PageHeader
          title="Sản phẩm"
          extra={[
            <ButtonAdd
              onClickButton={() => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.ADD_PRODUCT}`,
                  state: {
                    page: paging.current,
                    search: params.search,
                    category_id: params.category_id,
                    status: params.status,
                    name: ADMIN_ROUTER_PATH.PRODUCT,
                    // idProduct: record.id,
                  },
                })
              }}
              text={R.strings().action_create}
            />,
          ]}
        />
      }
      filterComponent={
        <Filter
          data={params}
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search:
                searchKey.trim() === '' ? undefined : searchKey.trimStart(),
              page: 1,
            })
          }}
          onCategorySubmit={(category_id: number) => {
            setParams({ ...params, category_id: category_id, page: 1 })
          }}
          onStatusSubmit={(statusKey: number) => {
            setParams({ ...params, status: statusKey, page: 1 })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            onRow={record => ({
              onClick: () => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${record.id}`,
                  state: {
                    page: paging.current,
                    search: params.search,
                    category_id: params.category_id,
                    status: params.status,
                    name: ADMIN_ROUTER_PATH.PRODUCT,
                    idProduct: record.id,
                  },
                })
              },
            })}
            dataSource={dataSource}
            bordered
            columns={columns}
            loading={isLoading.loadingTable}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
