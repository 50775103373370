import { PageHeader, Spin, Tabs } from 'antd'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import InforUser from './components/InforUser'
import OrderList from './components/OrderList'
import Debt from './components/Debt'
import HistoryPoint from './components/HistoryPoint'
import ReferralCode from './components/ReferralCode'
import AddDebt from './components/AddDebt'
import styles from 'common/components/styles/WhiteBox.module.css'
import { getInforCustomer } from './CustomerApi'

import { TDataSource } from '../Customer'

const { TabPane } = Tabs

export default function CustomerDetailScreen() {
  const history = useHistory()
  const param: any = useParams()
  const location: any = useLocation()
  const [showAddDebt, setShowAddDebt] = useState(false)
  const [dataSource, setDataSource] = useState<TDataSource>({
    dataInfor: {},
    dataTable: [],
  })
  useEffect(() => {
    getData()
  }, [param.id])

  const getData = async () => {
    try {
      const res = await getInforCustomer(param.id)
      const convertData = {
        dataInfor: {
          name: res.data?.name,
          rank: res.data?.rank,
          email: res.data?.email,
          phone: res.data?.phone,
          status: res.data.status,
          gender: res.data?.gender,
          total_paid: res.data?.total_paid,
          date_of_birth: res.data?.date_of_birth,
          point: res.data?.point,
          rank_point: res.data?.rank_point,
          enterprise: {
            enterprise_name: res.data?.enterprise?.name,
            enterprise_tax: res.data?.enterprise?.tax,
            enterprise_address_tax: res.data?.enterprise?.tax_address,
          },
          debt_limit: res.data?.enterprise?.debt_enterprises[0]?.debt_limit,
          debt_period: res.data?.enterprise?.debt_enterprises[0]?.debt_period,
        },
        dataTable: res.data.user_addresses.map((item: any, index: number) => ({
          ...item,
          stt: index + 1,
        })),
      }
      setDataSource(convertData)
    } catch (error: any) {}
  }

  return (
    <Spin size="large" spinning={false}>
      <div className={styles.container}>
        <div className={styles.header}>
          <PageHeader
            onBack={() => {
              history.goBack()
            }}
            title="Chi tiết khách hàng"
            extra={[]}
          />
          <AddDebt
            data={dataSource.dataInfor.enterprise}
            isLoadingButton={location}
            visible={showAddDebt}
            onCancel={() => {
              setShowAddDebt(false)
            }}
          />
        </div>
        <div className={styles.content}>
          <Tabs
            style={{
              fontWeight: 'bold',
            }}
          >
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Thông tin cá nhân</p>}
              key="1"
            >
              <InforUser dataSource={dataSource} />
            </TabPane>
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Danh sách đơn hàng</p>}
              key="2"
            >
              <OrderList />
            </TabPane>
            <TabPane tab={<p style={{ fontSize: '16px' }}>Công nợ</p>} key="3">
              <Debt infoCustomer={dataSource} />
            </TabPane>
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Lịch sử tích điểm</p>}
              key="4"
            >
              <HistoryPoint
                point={dataSource.dataInfor.point}
                rank_point={dataSource.dataInfor.rank_point}
                rank={dataSource.dataInfor.rank}
              />
            </TabPane>
            <TabPane
              tab={<p style={{ fontSize: '16px' }}>Danh sách mã giới thiệu</p>}
              key="5"
            >
              <ReferralCode />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Spin>
  )
}
