import { Divider, Table, Checkbox } from 'antd'
import Column from 'antd/lib/table/Column'
import { useState } from 'react'
import { formatPrice } from 'utils/ruleForm'
import { useDetailProduct } from '../hook/useDetailProduct'

export default function DetailPrice() {
  const { dataSource, setDataSource } = useDetailProduct()
  const [isLoading, setIsLoading] = useState(false)
  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin giá bán</b>
      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <Table
        style={{ marginTop: '20px' }}
        bordered
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        loading={isLoading}
        dataSource={dataSource}
        pagination={false}
      >
        <Column
          width="40px"
          align="center"
          title={<b style={{ fontSize: '16px' }}>STT</b>}
          dataIndex="stt"
          key="stt"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{index_col + 1}</p>
          )}
        />
        <Column
          width="200px"
          title={<b style={{ fontSize: '16px' }}>Cửa hàng</b>}
          dataIndex="name"
          key="name"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="200px"
          title={<b style={{ fontSize: '16px' }}>Địa điểm</b>}
          dataIndex="location_address"
          key="location_address"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="100px"
          title={<b style={{ fontSize: '16px' }}>Giá</b>}
          dataIndex="price"
          key="price"
          render={(value_col: any, record: any, index_col: number) => {
            return <p>{value_col ? formatPrice(value_col) : '0'} đ</p>
          }}
        />
        <Column
          width="40px"
          title={<b style={{ fontSize: '16px' }}>Đơn vị tính</b>}
          dataIndex="unit"
          key="unit"
          align="center"
          render={(value_col: any, record: any, index_col: number) => {
            return <p>lít</p>
          }}
        />
        <Column
          width="45px"
          align="center"
          title={<b style={{ fontSize: '16px' }}>Áp dụng</b>}
          dataIndex="status"
          key="status"
          render={(value: any, record: any, index: number) => (
            <Checkbox
              disabled
              key={index}
              checked={record?.status ? true : false}
              onChange={(e: any) => {
                const row_record = {
                  ...record,
                  status: e.target.checked === true ? 0 : 1,
                }
                dataSource[index] = { ...row_record }
                setDataSource([...dataSource])
              }}
            />
          )}
        />
      </Table>
    </div>
  )
}
