import Icon from '@ant-design/icons'
import { DatePicker, Row, Input, Col } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import { useProduct } from 'features/admin/product/product/hook/useProduct'
import moment from 'moment'
import { ORDER_STATUS } from 'utils/constants'

const { RangePicker } = DatePicker
type Props = {
  data: any
  onSearchSubmit: (searchKey: string) => void
  onStatusOrder: (statusKey: any) => void
  onStatusPayment: (paymentKey: any) => void
  onDateSubmit: (from_date: string, to_date: string) => void
  onTypePayment?: (typePayment: any) => void
  params?: any
  setParams?: any
  onCategorySubmit?: any
}
export default function Filter({
  data,
  onSearchSubmit,
  onStatusOrder,
  onStatusPayment,
  onDateSubmit,
  onTypePayment,
  onCategorySubmit,
  params,
  setParams,
}: Props) {
  const { listCategory } = useProduct()

  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={10}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Mã đơn hàng, tên khách hàng, SĐT"
          addonAfter={<Icon type="close-circle-o" />}
          value={data.search}
          onChange={(e: any) => {
            onSearchSubmit(e.target.value)
          }}
        />
      </Col>

      <Col span={6}>
        <RadiusSelection
          placeholder="TT Đơn hàng"
          onChange={value => {
            onStatusOrder(value)
          }}
          options={[
            { value: ORDER_STATUS.PENDING, text: 'Chờ xác nhận' },
            { value: ORDER_STATUS.CONFIRMED, text: 'Đang thực hiện' },
            { value: ORDER_STATUS.SUCCCESS, text: 'Hoàn thành' },
            { value: ORDER_STATUS.CANCELED, text: 'Hủy' },
            { value: ORDER_STATUS.DENY, text: 'Từ chối' },
          ]}
        />
      </Col>

      <Col span={6}>
        <RadiusSelection
          placeholder="TT Thanh toán"
          onChange={value => {
            onStatusPayment(value)
          }}
          options={[
            { value: 0, text: 'Chưa thanh toán' },
            { value: 1, text: 'Đã thanh toán' },
          ]}
        />
      </Col>
      <Col span={6}>
        <RadiusSelection
          placeholder="Hình thức thanh toán"
          onChange={(value: any) => {
            onTypePayment && onTypePayment(value)
          }}
          options={[
            { value: 1, text: 'VNPAY' },
            { value: 2, text: 'Chuyển khoản' },
            { value: 3, text: 'Công nợ' },
            { value: 4, text: 'Tiền mặt' },
            { value: 5, text: 'VTPAY' },
          ]}
        />
      </Col>
      <Col span={6}>
        <RadiusSelection
          placeholder="DM sản phẩm"
          showSearch={true}
          onChange={(value: number) => {
            onCategorySubmit(value)
          }}
          value={data.category_id}
          options={listCategory}
        />
      </Col>
      <Col span={6}>
        <RangePicker
          style={{ width: '100%' }}
          placeholder={['Từ ngày', 'đến ngày']}
          format="HH:mm DD/MM/YYYY"
          showTime={{ format: 'HH:mm' }}
          value={
            params?.from_date
              ? [moment(params.from_date), moment(params.to_date)]
              : [null, null]
          }
          onChange={(dates: any, dateString) => {
            setParams({
              ...params,
              from_date: dates
                ? moment(
                    `${dateString[0]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[0].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .format('YYYY-MM-DD HH:mm:ss')
                : '',
              to_date: dates
                ? moment(
                    `${dateString[1]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[1].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .add(59, 'seconds')
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss'),
              page: 1,
            })
          }}
          // onChange={(value, dateString) => {
          //   onDateSubmit(dateString[0], dateString[1])
          // }}
        />
      </Col>
    </Row>
  )
}
