import { Col, Input, Row } from 'antd'
import Icon from '@ant-design/icons'
import { IS_ACTIVE } from 'utils/constants'
import { RadiusSelection } from 'common/components/Inputs'
import { useSaleProduct } from '../hook/useSaleProduct'

type Props = {
  data: any
  onSearchSubmit: (searchKey: string) => void
  onCategorySubmit: (category_id: number) => void
  onStatusSubmit: (statusKey: number) => void
}

export default function Filter({
  data,
  onSearchSubmit,
  onCategorySubmit,
  onStatusSubmit,
}: Props) {
  const { listCategory } = useSaleProduct()

  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input.Search
            style={{ width: '100%' }}
            allowClear
            placeholder="Nhập tên hoặc mã sản phẩm"
            addonAfter={<Icon type="close-circle-o" />}
            value={data.search}
            onChange={(e: any) => {
              onSearchSubmit(e.target.value)
            }}
          />
        </Col>
        <Col span={6}>
          <RadiusSelection
            placeholder="DM  sản phẩm"
            onChange={(value: number) => {
              onCategorySubmit(value)
            }}
            showSearch={true}
            value={data.category_id}
            options={listCategory}
          />
        </Col>
        <Col span={6}>
          <RadiusSelection
            placeholder="Trạng thái"
            onChange={(value: number) => {
              onStatusSubmit(value)
            }}
            value={data.status}
            options={[
              { value: IS_ACTIVE.ACTIVE, text: 'Đang khuyến mại' },
              { value: IS_ACTIVE.INACTIVE, text: 'Không khuyến mại' },
            ]}
          />
        </Col>
      </Row>
    </div>
  )
}
