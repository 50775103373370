import { PageHeader, Table, Tag } from 'antd'
import moment from 'moment'
import { formatPrice } from 'utils/ruleForm'
import Filter from './components/Filter'
import { useHistory, useLocation } from 'react-router-dom'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import { IS_ACTIVE } from 'utils/constants'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useCustomer } from './hook/useCustomer'

export default function CustomerScreen() {
  const history = useHistory()
  const { dataSource, paging, params, setParams, isLoading } = useCustomer()

  const column = [
    {
      width: '60px',
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      width: '150px',
      title: <b>Họ tên</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: '100px',
      title: <b>Loại khách hàng</b>,
      dataIndex: 'role_names',
      key: 'role_names',
    },
    {
      width: '100px',
      title: <b>Số điện thoại</b>,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      width: '150px',
      title: <b>Email</b>,
      dataIndex: 'email',
      key: 'email',
    },
    {
      width: '120px',
      title: <b>Tổng điểm</b>,
      dataIndex: 'point',
      key: 'point',
      render: (value: any) => {
        return <>{value === 0 ? '0' : formatPrice(value)} </>
      },
    },
    {
      width: '90px',
      title: <b>Hạng</b>,
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      width: '120px',
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        switch (value) {
          case IS_ACTIVE.ACTIVE:
            return <Tag color="green">Hoạt động</Tag>
          case IS_ACTIVE.INACTIVE:
            return <Tag color="volcano">Tạm dừng</Tag>
        }
      },
    },
    {
      width: '90px',
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('DD/MM/YYYY')}</>
      },
    },
  ]

  return (
    <Container
      header={<PageHeader title="Khách hàng" />}
      filterComponent={
        <Filter
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search: searchKey === '' ? undefined : searchKey,
              page: 1,
            })
          }}
          onTimeSubmit={(from_date?: string, to_date?: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
          onTypeUserSubmit={(typeUserKey: number) => {
            setParams({
              ...params,
              customer_type: typeUserKey,
              page: 1,
            })
          }}
          onRankSubmit={(rankKey: number) => {
            setParams({
              ...params,
              rank_id: rankKey,
              page: 1,
            })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            size="middle"
            loading={isLoading.loadingTable}
            bordered
            columns={column}
            dataSource={dataSource}
            scroll={{
              x: 800,
              // scrollToFirstRowOnChange: true,
            }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.CUSTOMER_DETAIL}/${record.id}`,
                  state: {
                    page: paging.current,
                    search: params.search,
                    rank_id: params.rank_id,
                    customer_type: params.customer_type,
                    name: ADMIN_ROUTER_PATH.CUSTOMER,
                    idCustomer: record.id,
                    from_date: params.from_date,
                    to_date: params.to_date,
                    customer_name: record?.name,
                  },
                })
              },
            })}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
