import { ApiClient } from 'services/ApiService'
import { IDetailParams } from './ReportPoint'
// Api khách hàng

export const getListPoints = (payload: any) =>
  ApiClient.get(`/admin/customer/report-customer?page=${payload.page}`, payload)

export const getPointDetail = (pointId: number, payload: IDetailParams) =>
  ApiClient.get(`/admin/customer/${pointId}/point-history`, payload)

export const getCurrentPoint = (pointId: number) =>
  ApiClient.get(`/admin/customer/${pointId}/info`)

export const getTotalPoint = () =>
  ApiClient.get(`/admin/customer/report-point-customer`)
