import { Card, Col, Row } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import moment from 'moment'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'

type Props = { data: any }

export const Cards = ({ data }: Props) => {
  return (
    <Row
      gutter={16}
      style={{
        backgroundColor: 'white',
        marginTop: 10,
        marginInline: 10,
        padding: 10,
      }}
    >
      <Col span={8}>
        <Card
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.ORDER}`,
            })
          }}
          title={<b>Doanh thu</b>}
          style={{
            cursor: 'pointer',
            borderRadius: 5,
            borderTopColor: '#54e854',
            borderTopWidth: 5,
          }}
        >
          <b style={{ fontSize: 18, float: 'right' }}>
            {data?.data?.count_turnover === 0
              ? '0đ'
              : formatPrice(data?.data?.count_turnover) + 'đ'}
          </b>
        </Card>
      </Col>
      {/* <Col span={6}>
        <Card
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.REQUEST}`,
            })
          }}
          title={<b>Yêu cầu cứu trợ, thay dầu</b>}
          style={{
            cursor: 'pointer',
            borderRadius: 5,
            borderTopColor: '#1890FF',
            borderTopWidth: 5,
          }}
        >
          <b style={{ fontSize: 18, float: 'right' }}>
            {data?.data?.count_succor === 0
              ? '0'
              : formatPrice(data?.data?.count_succor)}
          </b>
        </Card>
      </Col> */}
      <Col span={8}>
        <Card
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.CUSTOMER}`,
            })
          }}
          title={<b>Khách hàng - DN</b>}
          style={{
            cursor: 'pointer',
            borderRadius: 5,
            borderTopColor: 'orange',
            borderTopWidth: 5,
          }}
        >
          <b style={{ fontSize: 18, float: 'right' }}>
            {data?.data?.count_customer === 0
              ? '0'
              : formatPrice(data?.data?.count_customer)}
          </b>
        </Card>
      </Col>
      <Col span={8}>
        <Card
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.SHOP}`,
            })
          }}
          title={<b>CHXD - DT</b>}
          style={{
            cursor: 'pointer',
            borderRadius: 5,
            borderTopColor: 'tomato',
            borderTopWidth: 5,
          }}
        >
          <b style={{ fontSize: 18, float: 'right' }}>
            {data?.data?.count_partner === 0
              ? '0'
              : formatPrice(data?.data?.count_partner)}
          </b>
        </Card>
      </Col>
    </Row>
  )
}
