import { LeftCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'

type Props = {
  visible: boolean
  onCancel: any
  setShowAddDebt?: any
  data?: any
  isLoadingButton: boolean
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}
function convertDataToFrom(data: any) {
  if (!data) {
    return {
      name: null,
      address: null,
    }
  } else {
    return {
      ...data,
    }
  }
}

export default function AddDebt({ visible, onCancel, data }: Props) {
  const [form] = Form.useForm()
  const initialValues = convertDataToFrom(data)

  const onFinish = (values: any) => {
    // console.log('Success:', values)
    // if (!data) {
    //   const newData = {
    //     long: 1,
    //     lat: 1,
    //     df_ward_id: 0,
    //     df_district_id: 0,
    //     df_province_id: 0,
    //     address: values.address.trim(),
    //     name: values.name.trim(),
    //   }
    //   onCreateStock && onCreateStock(newData)
    // } else {
    //   const newData = {
    //     long: 1,
    //     lat: 1,
    //     df_ward_id: 0,
    //     df_district_id: 0,
    //     df_province_id: 0,
    //     address: values.address.trim(),
    //     name: values.name.trim(),
    //     id: data.id,
    //   }
    //   onUpdateStock(newData, form.resetFields)
    // }
  }

  return (
    <Modal
      title="Áp công nợ"
      visible={visible}
      maskClosable={false}
      footer={
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <Button
              onClick={() => {
                form.resetFields()
                onCancel()
              }}
              style={{
                minWidth: '70px',
                fontWeight: 'bold',
                borderColor: 'black',
                color: 'black',
                borderRadius: '5px',
              }}
            >
              <LeftCircleOutlined />
              Trở lại
            </Button>
          </Col>
          <Col>
            <ButtonAdd text="Lưu" />
          </Col>
        </Row>
      }
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
    >
      <Form
        style={{ width: '600px' }}
        {...formItemLayout}
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item {...tailFormItemLayout}>
          &nbsp;
          <Checkbox defaultChecked={true}>
            <span style={{ fontSize: '20px', fontWeight: 500 }}>
              Áp dụng công nợ
            </span>
          </Checkbox>
        </Form.Item>
        <Form.Item
          label={<span>Hạn mức nợ công</span>}
          name="limit"
          rules={[
            {
              type: 'string',
              message: 'Nhập hạn mức công nợ',
            },
            {
              required: true,
              message: 'Vui lòng nhập hạn mức công nợ!',
            },
          ]}
        >
          <Input
            placeholder="Nhập hạn mức"
            style={{
              width: '260px',
              marginLeft: '5px',
            }}
          />
        </Form.Item>

        <Form.Item
          label={<span>Thời gian công nợ đơn hàng</span>}
          name="time"
          rules={[
            {
              type: 'string',
              message: 'Nhập thời gian công nợ đơn hàng',
            },
            {
              required: true,
              message: 'Vui lòng nhập thời gian công nợ đơn hàng!',
            },
          ]}
        >
          <Input
            placeholder="Nhập thời gian công nợ đơn hàng"
            style={{
              width: '260px',
              marginLeft: '5px',
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
