import { RedoOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, Row } from 'antd'
import { IProductInventory } from 'features/admin/inventory/Inventory'
import { isNull } from 'lodash'
import React, { memo } from 'react'
import { RECORD_UPDATE } from './InputNumber.Inventory'

interface IInputNumberLoss {
  // hien tai
  record: IProductInventory
  onUpdateLoss: (record: IProductInventory, value: number) => void
  copiedListProduct: IProductInventory[]
  callback: boolean
  count?: any
}

const InputNumberLoss = (props: IInputNumberLoss) => {
  const { record, onUpdateLoss, copiedListProduct, callback, count } = props

  const [inputLoss, setInputLoss] = React.useState<number | undefined>()

  const [isEmpty, setIsEmpty] = React.useState<boolean>(true)

  const onCheckDisabled = () => {
    const targetRecord = copiedListProduct.find(
      (product: IProductInventory) => product.id === record.id
    )
    if (targetRecord && targetRecord.beginInventory >= record.beginInventory) {
      return true
    }
    return false
  }

  React.useEffect(() => {
    setInputLoss(undefined)
    setIsEmpty(true)
  }, [callback])

  React.useEffect(() => {
    setInputLoss(record.updateLossPetrol || undefined)
  }, [record.updateLossPetrol])

  return (
    <Row style={{ flexFlow: 'row' }}>
      {/* <Button
        disabled={isEmpty}
        onClick={() => {
          if (inputLoss) {
            onUpdateLoss(record, inputLoss)
            setInputLoss(undefined)
            setIsEmpty(true)
          }
        }}
      >
        <PlusOutlined />
      </Button> */}

      <Form.Item
        rules={[
          {
            validator: (_: any, value: any) => {
              if (isNull(value)) {
                return Promise.resolve()
              }

              if (Number(value) <= 0) {
                return Promise.reject(new Error('Hoa hụt phải lớn hơn 0!'))
              }

              if (Number(value) > Number(record?.beginInventory)) {
                return Promise.reject(
                  new Error('Hao hụt không được lớn hơn nhập kho!')
                )
              }

              return Promise.resolve()
            },
          },
        ]}
        name={`loss_${record.id}`}
        style={{ flexWrap: 'nowrap', marginBottom: 0 }}
      >
        {/* <InputNumber
          id={`begin_inventory_${record.id}`}
          placeholder="Nhập tồn kho"
          style={{ width: '100%' }}
          disabled={count >= 2 && !inputBeginInventory}
          value={inputBeginInventory}
          type="number"
          onChange={(value: number | undefined) => {
            setInputBeginInventory(value)
            if (!value) {
              setIsEmpty(true)
              onUpdateBeginInventory(record, 0)
            } else {
              setIsEmpty(false)
              onUpdateBeginInventory(record, value)
            }
          }}
        /> */}
        <InputNumber
          // min={0.01}
          id={`loss_${record.id}`}
          placeholder="Nhập hao hụt"
          type="number"
          style={{ width: '100%' }}
          // max={record?.beginInventory}
          value={inputLoss}
          disabled={
            record.categoryId !== 1 ||
            (count >= RECORD_UPDATE && !record?.updateBeginInventory)
          }
          onChange={(value: number | undefined) => {
            setInputLoss(value)
            if (!value) {
              setIsEmpty(true)
              onUpdateLoss(record, 0)
            } else {
              onUpdateLoss(record, value)
              setIsEmpty(false)
            }
          }}
        />
      </Form.Item>

      {/* <Input
        autoComplete="off"
        placeholder="Nhập hao hụt"
        id={`loss_${record.id}`}
        disabled={  record.categoryId !== 1}
        value={formatPrice(inputLoss)}
        onChange={(e: any) => {
          const value = enterNumbersOnly(e.target.value)
          if (!/^\d+$/.test(value)) {
            setIsEmpty(true)
            onUpdateLoss(record, 0)
          } else {
            onUpdateLoss(record, Number(value))
            setIsEmpty(false)
            setInputLoss(Number(value))
          }
        }}
      /> */}
      <Button
        style={{ marginLeft: 10 }}
        disabled={isEmpty}
        onClick={() => {
          if (inputLoss) {
            onUpdateLoss(record, 0)
            setInputLoss(undefined)
            setIsEmpty(true)
          }
        }}
      >
        <RedoOutlined />
      </Button>
    </Row>
  )
}

export default memo(InputNumberLoss)
