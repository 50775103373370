import { ApiClient } from 'services/ApiService'
// Config Banks
export const getConfigBank = (payload: any) =>
  ApiClient.get('/admin/config-bank', payload)
export const createConfigBank = (payload: any) =>
  ApiClient.post('/admin/config-bank', payload)
export const updateConfigBank = (payload: any) =>
  ApiClient.put(`/admin/config-bank/${payload.id}`, payload.data)
export const changeStatusConfigBank = (payload: any) =>
  ApiClient.patch(`/admin/config-bank/${payload.id}/status`, payload.status)
export const deleteConfigBank = (payload: any) =>
  ApiClient.delete(`/admin/config-bank/${payload.id}`)

// Config Partners
export const getConfigPartner = (payload: any) =>
  ApiClient.get('/admin/config-partner', payload)
export const createConfigPartner = (payload: any) =>
  ApiClient.post('/admin/config-partner', payload)
export const updateConfigPartner = (payload: any) =>
  ApiClient.put(`/admin/config-partner/${payload.id}`, payload.data)
export const changeStatusConfigPartner = (payload: any) =>
  ApiClient.patch(`/admin/config-partner/${payload.id}/status`, payload.status)
export const deleteConfigPartner = (payload: any) =>
  ApiClient.delete(`/admin/config-partner/${payload.id}`)

// Config Ranks
export const getConfigRanks = () => ApiClient.get('/admin/config-ranks')
export const updateConfigRanks = (payload: Array<any>) =>
  ApiClient.put(`/admin/config-ranks/`, payload)

// Config Systerm
export const getConfigSystem = () => ApiClient.get('/admin/config-systems')
export const updateConfigSystem = (payload: any) =>
  ApiClient.put(`/admin/config-systems/`, payload)
export const getReason = () => ApiClient.get('/admin/config-systems/reason')
export const createReason = (payload: any) =>
  ApiClient.post('/admin/config-systems/reason', payload)
export const updateReason = (payload: any) =>
  ApiClient.put(`/admin/config-systems/${payload.id}/reason`, payload.data)
export const deleteReason = (payload: any) =>
  ApiClient.delete(`/admin/config-systems/${payload.id}/reason`)

// Config unit
export const createUnit = (payload: any) =>
  ApiClient.post(`/admin/config-product-unit`, payload)
export const editUnit = (payload: any, id: number) =>
  ApiClient.put(`/admin/config-product-unit/${id}`, payload)
