import { Col, Row, Input, DatePicker } from 'antd'
import Icon from '@ant-design/icons'
import React from 'react'
import useDebounce from 'features/admin/report/sale/hook/useDebounce'
import moment from 'moment'

type Props = {
  onSearchSubmit: (searchKey: string | undefined) => void
  isSearchLoading: boolean
  onDateSubmit?: any
  params?: any
}

const { RangePicker } = DatePicker

export default function Filter({
  isSearchLoading,
  onSearchSubmit,
  onDateSubmit,
  params,
}: Props) {
  const [search, setSearch] = React.useState<string>('')
  const searchDebounce = useDebounce(search, 300)

  const onChangeSearch = (e: any) => {
    setSearch(e.target.value)
  }

  React.useEffect(() => {
    onSearchSubmit(searchDebounce)
  }, [searchDebounce])

  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={10}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Nhập tên khách hàng hoặc SĐT"
          addonAfter={<Icon type="close-circle-o" />}
          // value={data.search}
          onChange={(e: any) => {
            onChangeSearch(e)
          }}
        />
      </Col>
      <Col span={6}>
        <RangePicker
          showTime
          style={{ width: '400px' }}
          placeholder={['Từ ngày', 'đến ngày']}
          // format="HH:mm YYYY-MM-DD"
          format="DD/MM/YYYY HH:mm"
          value={
            params.debt_from_date
              ? [moment(params.debt_from_date), moment(params.debt_to_date)]
              : null
          }
          onChange={(value, dateString) => {
            onDateSubmit(
              value
                ? `${dateString[0]
                    .split(' ')[0]
                    .split('/')
                    .reverse()
                    .join('-')} ${dateString[0].split(' ')[1]}`
                : '',
              value
                ? `${dateString[1]
                    .split(' ')[0]
                    .split('/')
                    .reverse()
                    .join('-')} ${dateString[1].split(' ')[1]}`
                : ''
            )
          }}
        />
      </Col>
    </Row>
  )
}
