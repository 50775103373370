import { message, PageHeader, Row, Space, Spin, Table, Tag } from 'antd'
import { formatPrice } from 'utils/ruleForm'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import Container from 'common/container/Container'
import history from 'utils/history'
import Filter from './components/Filter'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { getListPoints, getTotalPoint } from './ReportPointApi'
import React from 'react'
import { IData, IParams } from './ReportPoint'
import useDebounce from '../sale/hook/useDebounce'
import { useLocation } from 'react-router-dom'

const PointReport = () => {
  const column = [
    {
      width: '70px',
      title: <b>STT</b>,
      key: 'id',
      render: (text: string, record: any, index: number) => {
        return (
          <td id={record.id}>
            {(paging.current - 1) * paging.pageSize + index + 1}
          </td>
        )
      },
    },
    {
      title: <b>Tên người dùng</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <b>Loại tài khoản</b>,
      dataIndex: 'accountType',
      key: 'accountType',
    },
    {
      title: <b>Điểm đã tích</b>,
      dataIndex: 'totalPoints',
      key: 'totalPoints',
      render: (value: any) => <>{value === 0 ? 0 : formatPrice(value)}</>,
    },
    {
      title: <b>Điểm đã dùng</b>,
      dataIndex: 'totalPointsUsed',
      key: 'totalPointsUsed',
      render: (value: any) => <>{value === 0 ? 0 : formatPrice(value)}</>,
    },
    {
      title: <b>Điểm còn lại</b>,
      dataIndex: 'totalPointsLeft',
      key: 'totalPointsLeft',
      render: (value: any) => <>{value === 0 ? 0 : formatPrice(value)}</>,
    },
  ]

  const location: any = useLocation()
  const [totalFilter, setTotalFilter] = React.useState<number>(1)
  const [listData, setListData] = React.useState<IData[]>([])
  const [currentPage, setCurrentPage] = React.useState<number>(
    location?.state?.currentParam?.page
      ? location?.state?.currentParam?.page
      : 1
  )
  const [searchKey, setSearchKey] = React.useState<string>(
    location?.state?.currentParam.search
  )
  const [currentAccountType, setCurrentAccountType] = React.useState<number>(
    location?.state?.currentParam.customer_type
  )
  const [dataExport, setDataExport] = React.useState<any>([])

  const [reportTotal, setReportTotal] = React.useState<any>(null)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [params, setParams] = React.useState<IParams>({
    customer_type: currentAccountType,
    search: searchKey,
    page: currentPage,
  })
  const [paging, setPaging] = React.useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [
    isLoadingBtnExportData,
    setLoadingBtnExportData,
  ] = React.useState<boolean>(false)

  const debounce = useDebounce(searchKey, 300)

  const getListPointOfUser = async () => {
    try {
      setIsLoading(true)
      const res = await getListPoints(params)
      if (res.status) {
        const data = res.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            accountType: item.customer_type,
            totalPoints: item.received_point,
            totalPointsUsed: item.used_point,
            totalPointsLeft: item.point,
          }
        })
        const newPaging = {
          total: res.meta.pagination.totalItems,
          current: res.meta.pagination.page,
          pageSize: res.meta.pagination.limit,
        }
        setTotalFilter(res.meta.pagination.totalItems)
        setListData(data)
        setPaging({ ...paging, ...newPaging })
      } else {
        message.error('Đã có lỗi xảy ra! Xin vui lòng thử lại!')
      }
    } catch (error) {
      console.log('ERROR: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getListPointOfUser()
  }, [params])

  React.useEffect(() => {
    setParams({
      customer_type: currentAccountType,
      search: debounce,
      page: currentPage,
    })
  }, [debounce, currentAccountType, currentPage])

  React.useEffect(() => {
    setPaging({ ...paging, current: currentPage })
  }, [currentPage])

  React.useEffect(() => {
    getTotalPoint().then((res: any) => {
      setReportTotal(res.data)
    })
  }, [])

  const onExportData = async (fn: any) => {
    try {
      setLoadingBtnExportData(true)
      const res = await getListPoints({
        ...params,
        limit: -1,
      })
      const exportData = res?.data?.map((o: any, i: number) => {
        return {
          STT: i + 1,
          'Tên người dùng': o?.name || '---',
          'Loại tài khoản': o?.customer_type || '---',
          'Điểm đã tích': o?.point || 0,
          'Điểm đã dùng': o?.used_point || 0,
          'Điểm còn lại': o?.received_point || 0,
        }
      })
      setDataExport(exportData)
      fn()
    } catch (error) {
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Container
        header={
          <PageHeader
            title="Báo cáo tích điểm"
            extra={[
              <ButtonExport
                sheetName={['PointReport']}
                fileName={'Danh sách báo cáo tích điểm'}
                loading={isLoadingBtnExportData}
                onClick={fn => onExportData(fn)}
                sheets={{
                  PointReport: ButtonExport.getSheets(dataExport),
                }}
                // onClick={fn => onExportDataToExcel(fn)}
              />,
            ]}
          />
        }
        filterComponent={
          <Filter
            setSearchKey={setSearchKey}
            setCurrentAccountType={setCurrentAccountType}
            currentAccountType={currentAccountType}
            searcKey={searchKey}
            setCurrentPage={setCurrentPage}
          />
        }
        contentComponent={
          <div>
            <Row justify="space-between">
              <p>
                Kết quả lọc: <b>{totalFilter}</b>
              </p>
              <Space size="large" style={{ paddingRight: '30px' }}>
                <Tag
                  style={{ fontSize: '16px', fontWeight: 600 }}
                  color="success"
                >
                  Điểm đã tích:{' '}
                  {reportTotal && formatPrice(reportTotal?.[0]?.point)}
                </Tag>
                <Tag
                  style={{ fontSize: '16px', fontWeight: 600 }}
                  color="warning"
                >
                  Điểm đã dùng:{' '}
                  {reportTotal && formatPrice(reportTotal?.[0]?.used_point)}
                </Tag>
                <Tag style={{ fontSize: '16px', fontWeight: 600 }} color="red">
                  Điểm còn lại:{' '}
                  {reportTotal && formatPrice(reportTotal?.[0]?.received_point)}
                </Tag>
              </Space>
            </Row>
            <Table
              bordered
              columns={column}
              dataSource={listData}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              onRow={record => ({
                onClick: () => {
                  history.push({
                    pathname: `${ADMIN_ROUTER_PATH.POINT_REPORT_DETAIL}/${record.id}`,
                    state: {
                      currentParam: params,
                      pointId: record.id,
                    },
                  })
                },
              })}
              pagination={{
                ...paging,
                showSizeChanger: false,
                onChange: (page: number) => {
                  setCurrentPage(page)
                },
              }}
            />
          </div>
        }
      />
    </Spin>
  )
}

export default PointReport
