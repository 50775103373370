import { PageHeader } from 'antd'
import ButtonExportExcelV2 from 'common/components/Buttons/ButtonExportExcelV2'
import Excel from 'exceljs'
import moment from 'moment'
import { useState } from 'react'
import { ORDER_STATUS } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { getAllLisOrder } from '../OrderApi'
import ScanQRCode from './ScanQRCode'

type Props = {
  params: any
}
export default function Header({ params }: Props) {
  const [scanQRCode, setScanQRCode] = useState<boolean>(false)
  const [isLoadingBtnExportData, setLoadingBtnExportData] = useState<boolean>(
    false
  )
  const [dataExport, setDataExport] = useState<any>([])

  const StatusOrder = (status: number) => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return 'Chờ xác nhận'
      case ORDER_STATUS.CONFIRMED:
        return 'Đang thực hiện'
      case ORDER_STATUS.SUCCCESS:
        return 'Hoàn thành'
      case ORDER_STATUS.CANCELED:
        return 'Hủy'
      case ORDER_STATUS.DENY:
        return 'Từ chối'
    }
  }
  // const onExportData = async (fn: any) => {
  //   try {
  //     setLoadingBtnExportData(true)
  //     const dataListOrderNotPagging = await getAllLisOrder(params)
  //     const exportData = dataListOrderNotPagging.data.orders?.map(
  //       (o: any, i: number) => {
  //         return {
  //           STT: i + 1,
  //           'Mã đơn hàng': o.code || '---',
  //           'Khách hàng': o.user.name + '(' + o.user.phone + ')' || '---',
  //           'Người nhận': o.user.name || '',
  //           'Cửa hàng': o.petrol_store.name || '---',
  //           'Số sp': o.total_amount === 0 ? '1' : formatPrice(o.total_amount),
  //           'Tiền thanh toán':
  //             o.order_gasoline?.is_full_fuel === 1 && o.total_price === 0
  //               ? 'Chưa xác định'
  //               : o.order_gasoline?.is_full_fuel === 0 && o.total_price === 0
  //               ? '0đ'
  //               : !o.order_gasoline && o.total_price === 0
  //               ? '0đ'
  //               : formatPrice(o.total_price) + 'đ',
  //           'TT đơn hàng': StatusOrder(o.status),
  //           'Phương thức thanh toán':
  //             o.order_payment.type_payment?.name || '---',
  //           'TT thanh toán':
  //             o.order_payment.is_paid === 1
  //               ? 'Đã thanh toán'
  //               : 'Chưa thanh toán',
  //           'Ngày tạo': moment(o.create_at).format('DD/MM/YYYY'),
  //         }
  //       }
  //     )
  //     setDataExport(exportData)
  //     fn()
  //   } catch (error) {
  //   } finally {
  //     setLoadingBtnExportData(false)
  //   }
  // }

  const onExportData = async () => {
    try {
      const workbook = new Excel.Workbook()

      setLoadingBtnExportData(true)
      const worksheet = workbook.addWorksheet('Báo cáo bán hàng')

      // columns
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10 },
        { header: 'Mã đơn hàng', key: 'order_code', width: 32 },
        { header: 'Khách hàng', key: 'customer', width: 30 },
        { header: 'Người nhận', key: 'receiver', width: 60 },
        { header: 'Cửa hàng', key: 'petrol', width: 60 },
        { header: 'Số sp', key: 'quanlity', width: 15 },
        { header: 'Tiền thanh toán', key: 'price_payment', width: 20 },
        { header: 'TT đơn hàng', key: 'order_status', width: 20 },
        { header: 'Phương thức thanh toán', key: 'payment_method', width: 20 },
        { header: 'TT thanh toán', key: 'order_payment', width: 20 },
        { header: 'Ngày tạo', key: 'create_at', width: 20 },
      ]

      // end
      const dataListReportNotPagging = await getAllLisOrder(params)

      dataListReportNotPagging?.data?.orders?.forEach((o: any, i: number) => {
        worksheet.addRow({
          id: i + 1,
          order_code: o?.code || '---',
          customer:
            (o?.user?.name || '') + '(' + (o?.user?.phone || '') + ')' || '---',
          receiver: o?.user?.name || '' || '',
          petrol: o.petrol_store?.name || '---',
          quanlity: o.total_amount === 0 ? '1' : formatPrice(o.total_amount),
          price_payment:
            o.order_gasoline?.is_full_fuel === 1 && o.total_price === 0
              ? 'Chưa xác định'
              : o.order_gasoline?.is_full_fuel === 0 && o.total_price === 0
              ? '0đ'
              : !o.order_gasoline && o.total_price === 0
              ? '0đ'
              : formatPrice(o.total_price) + 'đ',
          order_status: StatusOrder(o.status),
          payment_method: o.order_payment.type_payment?.name || '---',
          order_payment:
            o.order_payment.is_paid === 1 ? 'Đã thanh toán' : 'Chưa thanh toán',
          create_at: moment(o.create_at).format('DD/MM/YYYY'),
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(new Blob([buf]), `Danh sách đơn hàng.xlsx`)
    } catch (error) {
      console.log('🚀 ~ file: Header.tsx:81 ~ onExportData ~ error:', error)
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <div>
      <PageHeader
        title="Danh sách đơn hàng"
        extra={[
          // <ButtonExport
          //   sheetName={['OrderList']}
          //   fileName="Danh sách đơn hàng"
          //   loading={isLoadingBtnExportData}
          //   onClick={fn => onExportData(fn)}
          //   sheets={{
          //     OrderList: ButtonExport.getSheets(dataExport),
          //   }}
          // />,
          <ButtonExportExcelV2
            key="export_excel"
            loading={isLoadingBtnExportData}
            onClick={onExportData}
          />,
          // <Button icon={<QrcodeOutlined />} onClick={() => setScanQRCode(true)}>
          //   Quét QR Code
          // </Button>,
        ]}
      />
      <ScanQRCode
        visible={scanQRCode}
        onCancel={() => {
          setScanQRCode(false)
        }}
      />
    </div>
  )
}
