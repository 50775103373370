import { RedoOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, Row } from 'antd'
import { isNull } from 'lodash'
import React, { memo } from 'react'
import { enterNumbersOnly, formatPrice } from 'utils/ruleForm'
import { IProductInventory } from '../../../Inventory'

interface IInputNumberInventory {
  record: any
  count: number
  onUpdateBeginInventory: (record: IProductInventory, value: number) => void
  callback: boolean
}

export const RECORD_UPDATE = 12

const InputNumberInventory = (props: IInputNumberInventory) => {
  const { record, onUpdateBeginInventory, callback, count } = props
  const [inputBeginInventory, setInputBeginInventory] = React.useState<
    number | undefined
  >()

  const [isEmpty, setIsEmpty] = React.useState<boolean>(true)

  React.useEffect(() => {
    setInputBeginInventory(undefined)
    setIsEmpty(true)
  }, [callback])

  React.useEffect(() => {
    setInputBeginInventory(record.updateBeginInventory || undefined)
  }, [record.updateBeginInventory])

  return (
    <Row style={{ flexFlow: 'row' }}>
      {/* <Button
        disabled={isEmpty}
        onClick={() => {
          if (inputBeginInventory) {
            onUpdateBeginInventory(record, inputBeginInventory)
            setInputBeginInventory(undefined)
            setIsEmpty(true)
          }
        }}
      >
        <PlusOutlined />
      </Button> */}
      <Form.Item
        rules={[
          {
            validator: (_: any, value: any) => {
              if (isNull(value)) {
                return Promise.resolve()
              }

              if (Number(value) <= 0) {
                return Promise.reject(new Error('Nhập kho phải lớn hơn 0!'))
              }

              return Promise.resolve()
            },
          },
        ]}
        name={`inventory_${record.id}`}
        style={{ flexWrap: 'nowrap', marginBottom: 0 }}
      >
        <InputNumber
          id={`begin_inventory_${record.id}`}
          placeholder="Nhập tồn kho"
          style={{ width: '100%' }}
          disabled={count >= RECORD_UPDATE && !inputBeginInventory}
          value={inputBeginInventory}
          type="number"
          onChange={(value: number | undefined) => {
            setInputBeginInventory(value)
            if (!value) {
              setIsEmpty(true)
              onUpdateBeginInventory(record, 0)
            } else {
              setIsEmpty(false)
              onUpdateBeginInventory(record, value)
            }
          }}
        />
      </Form.Item>

      {/* <Input
        autoComplete="off"
        placeholder="Nhập tồn kho đầu kỳ"
        id={`begin_inventory_${record.id}`}
        disabled={count >= 2 && !inputBeginInventory}
        value={formatPrice(inputBeginInventory)}
        onChange={(e: any) => {
          const value = enterNumbersOnly(e.target.value)
          if (!/^\d+$/.test(value)) {
            setIsEmpty(true)
            onUpdateBeginInventory(record, 0)
          } else {
            setIsEmpty(false)
            setInputBeginInventory(Number(value))
            onUpdateBeginInventory(record, Number(value))
          }
        }}
      /> */}
      <Button
        style={{ marginLeft: 10 }}
        disabled={isEmpty}
        onClick={() => {
          if (inputBeginInventory) {
            onUpdateBeginInventory(record, 0)
            setInputBeginInventory(undefined)
            setIsEmpty(true)
          }
        }}
      >
        <RedoOutlined />
      </Button>
    </Row>
  )
}

export default memo(InputNumberInventory)
