import { Col, Row, Select } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ACCOUNT_R0LE, IS_ACTIVE } from 'utils/constants'

type Props = {
  onSearchSubmit: (searchKey: string) => void
  onStatusSubmit: (statusKey: any) => void
  onRoleSubmit: (roleKey: any) => void
}

export const Filter = ({
  onSearchSubmit,
  onStatusSubmit,
  onRoleSubmit,
}: Props) => {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const userState = useSelector((state: any) => state.authReducer)?.userInfo

  return (
    <div>
      <Row gutter={[16, 16]} justify="start">
        <Col span={10}>
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              onSearchSubmit(searchKey.trim())
            }}
            isSearchLoading={isSearchLoading}
            placeholder="Tên hoặc số điện thoại"
          />
        </Col>

        <Col span={6}>
          <RadiusSelection
            placeholder="Trạng thái"
            onChange={value => {
              onStatusSubmit(value)
            }}
            options={[
              { value: IS_ACTIVE.ACTIVE, text: 'Hoạt động' },
              { value: IS_ACTIVE.INACTIVE, text: 'Tạm ngừng' },
            ]}
          />
        </Col>

        <Col span={8}>
          <RadiusSelection
            placeholder="Loại tài khoản"
            onChange={value => {
              onRoleSubmit(value)
            }}
            options={
              userState?.role_ids[0] == ACCOUNT_R0LE[2]?.id
                ? [
                    { value: 28, text: 'Đại diện trạm xăng' },
                    { value: 24, text: 'Nhân viên trạm xăng' },
                  ]
                : [{ value: 24, text: 'Nhân viên trạm xăng' }]
            }
          />
        </Col>
      </Row>
    </div>
  )
}
