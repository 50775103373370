import { ApiClient } from 'services/ApiService'

export const getListWarehouses = (payload: any) =>
  ApiClient.get('/petrol-storage', payload)

export const getDetailWarehouse = (id: number, payload: any) =>
  ApiClient.get(`/petrol-storage/${id}/detail`, payload)

export const getHistoryChange = (id: number, payload: any) =>
  ApiClient.get(`/petrol-storage/${id}/historyChange`, payload)

export const addANewProduct = (payload: any) =>
  ApiClient.post(`/petrol-storage/`, payload)

export const updateANewProduct = (storeId: number, payload: any) =>
  ApiClient.patch(`/petrol-storage/${storeId}`, payload)
