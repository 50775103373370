import { ApiClient } from 'services/ApiService'

export const getNotifyPetrol = (payload: any) =>
  ApiClient.get('/petrol-store/notification', payload)
export const getNotifyAdmin = (payload: any) =>
  ApiClient.get('/admin/notification/admin/list-notification', payload)
export const readNotify = (notification_id: number) =>
  ApiClient.patch(`/admin/notification/${notification_id}/read`)
export const readAllNotify = () =>
  ApiClient.patch(`/admin/notification/read-all`)
export const readNotifyPetrol = (notification_id: number) =>
  ApiClient.patch(`/petrol-store/notification/${notification_id}/read`)
export const readAllNotifyPetrol = () =>
  ApiClient.patch(`/petrol-store/notification/read-all`)
export const updateInfoUser = (manager_id: number, payload: any) =>
  ApiClient.put(`/admin/user/${manager_id}`, payload)
export const changePasswordUser = (payload: any) =>
  ApiClient.put(`/manager/auth/change-password`, payload)
export const updateInfoUserPetrol = (manager_id: number, payload: any) =>
  ApiClient.put(`/petrol-store/user/${manager_id}`, payload)
