import { Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { notificationError } from 'utils/notification'
import { getListReferralCustomer } from '../CustomerApi'

const column = [
  {
    width: '70px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Tên người GT mua hàng</b>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <b>SĐT người GT mua hàng</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: <b>Thời gian</b>,
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]
export default function ReferralCode() {
  const param: any = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [params, setParams] = useState({
    page: 1,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      setIsLoading(true)
      const res = await getListReferralCustomer(param.id, params)
      const dataTable = res.data.map((item: any, index: number) => ({
        ...item,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
      }))
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
      setDataSource(dataTable)
    } catch (error: any) {
      notificationError('Hệ thống gặp sự cố. Vui lòng thử lại!!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={column}
        loading={isLoading}
        bordered
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            setParams({ ...params, page })
          },
        }}
      />
    </div>
  )
}
