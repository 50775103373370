import Icon from '@ant-design/icons'
import { Col, DatePicker, Input, Row } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import { requestAllCategory } from 'features/admin/product/product/ProductApi'
import moment from 'moment'
import React from 'react'
const { RangePicker } = DatePicker
interface IInventoryDetailFilter {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  categoryId: any
  setCategoryId: any
  setParams?: any
  params?: any
}

const InventoryDetailFilter = (props: IInventoryDetailFilter) => {
  const {
    search,
    setSearch,
    categoryId,
    setCategoryId,
    params,
    setParams,
  } = props

  const [categories, setCategories] = React.useState([])

  React.useEffect(() => {
    ;(async () => {
      const res: any = await requestAllCategory({ limit: 999 })
      const list_data = res?.data?.map((item: any) => {
        return { value: item.id, text: item.name }
      })
      setCategories(list_data)
    })()
  }, [])

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Input.Search
          allowClear
          placeholder="Tên hoặc mã sản phẩm"
          addonAfter={<Icon type="close-circle-o" />}
          value={search}
          onChange={(e: any) => {
            setSearch(e.target.value)
          }}
        />
      </Col>
      <Col span={6}>
        <RadiusSelection
          placeholder="DM sản phẩm"
          showSearch={true}
          onChange={(value: number) => {
            setCategoryId(value)
          }}
          value={categoryId}
          options={categories}
        />
      </Col>
      <Col span={6}>
        <RangePicker
          placeholder={['Từ ngày', 'Đến ngày']}
          showTime
          allowClear
          format="HH:mm DD/MM/YYYY"
          style={{ width: '360px' }}
          value={
            params?.from_date
              ? [moment(params.from_date), moment(params.to_date)]
              : [null, params.to_date ? moment(params.to_date) : moment()]
          }
          onChange={(dates: any, dateString) => {
            setParams({
              ...params,
              from_date: dates
                ? moment(
                    `${dateString[0]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[0].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .format('YYYY-MM-DD HH:mm:ss')
                : '',
              to_date: dates
                ? moment(
                    `${dateString[1]
                      .split(' ')[1]
                      .split('/')
                      .reverse()
                      .join('-')} ${dateString[1].split(' ')[0]}`
                  )
                    ?.seconds(0)
                    .add(59, 'seconds')
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss'),
              page: 1,
            })
          }}
        />
      </Col>
      {/* <ButtonExport
        sheetName={['OrderLisst']}
        fileName="Danh sách đơn hàng"
        sheets={{}}
      /> */}
    </Row>
  )
}

export default InventoryDetailFilter
