import { PrinterOutlined } from '@ant-design/icons'
import { PageHeader, Table, Tag } from 'antd'
import { ButtonFixed } from 'common/components/Buttons'
import Container from 'common/container/Container'
import { formatPrice } from 'utils/ruleForm'
import { ADMIN_ROUTER_PATH } from 'common/config'
import history from 'utils/history'
import React from 'react'
import FilterDetail from './component/FilterDetail'
import moment from 'moment'

const DebtReportDetailScreen = () => {
  const data = [
    {
      id: '1',
      code: 'ASDF',
      action: 'KH Thanh toán công nợ',
      money: '1000000',
      order_numb: '10',
      debt: '100000',
      payment: '2.11.2021',
      create_at: '03:05 01.02.2021',
      status: 1,
    },
    {
      id: '2',
      code: 'GHJK',
      action: 'Tăng dư nợ',
      money: '1000000',
      order_numb: '10',
      debt: '100000',
      payment: '2.11.2021',
      create_at: '03:05 01.02.2021',
      status: 0,
    },
  ]

  const column = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: <b>Mã đơn hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: <b>Số tiền</b>,
      dataIndex: 'money',
      key: 'money',
      render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    },
    {
      title: <b>Nợ lũy kế</b>,
      dataIndex: 'debt',
      key: 'debt',
      render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    },
    {
      width: '200px',
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
      },
    },
    {
      title: <b>Hạn thanh toán</b>,
      dataIndex: 'payment',
      key: 'payment',
      render: (value: any) => {
        return <>{moment(value).format('DD/MM/YYYY')}</>
      },
    },
    {
      width: '170px',
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        switch (value) {
          case (value = 0):
            return <Tag color="green">Đã thanh toán</Tag>
          case (value = 1):
            return <Tag color="blue">Chờ thanh toán</Tag>
          case (value = 2):
            return <Tag color="volcano">Quá hạn</Tag>
        }
      },
    },
  ]
  return (
    <Container
      header={
        <PageHeader
          onBack={() => {
            history.goBack()
          }}
          title="Chi tiết công nợ"
          extra={[
            <ButtonFixed
              style={{
                minWidth: '70px',
                fontWeight: 'bold',
                borderColor: '#11690F',
                color: '#11690F',
                borderRadius: '5px',
              }}
              text="Xuất Excel"
              icon={<PrinterOutlined />}
            />,
          ]}
        />
      }
      filterComponent={<FilterDetail />}
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>100</b>
          </p>
          <Table
            bordered
            columns={column}
            dataSource={data}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
              y: 'calc(100vh - 430px)',
            }}
          />
        </div>
      }
    />
  )
}

export default DebtReportDetailScreen
