import Icon from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Select } from 'antd'
import useDebounce from 'features/admin/report/sale/hook/useDebounce'
import moment from 'moment'
import React from 'react'
const { RangePicker } = DatePicker
interface IInventoryFilter {
  setSearch: React.Dispatch<React.SetStateAction<string>>
  search: string
  onDateSubmit?: any
  params?: any
}

const InventoryFilter = (props: IInventoryFilter) => {
  const { setSearch, search, onDateSubmit } = props

  return (
    <Row gutter={[16, 16]}>
      <Col span={7}>
        <Input.Search
          allowClear
          style={{ width: '100%' }}
          placeholder="Tên cửa hàng"
          addonAfter={<Icon type="close-circle-o" />}
          value={search}
          onChange={(e: any) => {
            setSearch(e.target.value)
          }}
        />
      </Col>

      <Col span={6}>
        <RangePicker
          style={{ width: '100%' }}
          placeholder={['Từ ngày', 'đến ngày']}
          format="YYYY-MM-DD"
          value={
            props.params.from_date
              ? [
                  moment(props.params.from_date, 'YYYY-MM-DD'),
                  moment(props.params.to_date, 'YYYY-MM-DD'),
                ]
              : null
          }
          onChange={(value, dateString) => {
            onDateSubmit(dateString[0], dateString[1])
          }}
        />
      </Col>
    </Row>
  )
}

export default InventoryFilter
