import { Select, Divider, Descriptions } from 'antd'
import { useDetailProduct } from '../hook/useDetailProduct'
import {} from '../SaleProductApi'

type GeneralDataState = {
  description: string
  name: string
  category_id: number
  parent_id: number | undefined
  code: string
  status: number
  stock_status: number
}

export default function DetailGeneral() {
  const { productData, setProductData } = useDetailProduct()

  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin chung</b>
      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <Descriptions column={2}>
        <Descriptions.Item label="Tên sản phẩm">
          {productData.name}
        </Descriptions.Item>
        <Descriptions.Item label="Danh mục">
          {productData.category_name}
        </Descriptions.Item>
        <Descriptions.Item label="Ghi chú">
          {productData.description ? productData.description : '---'}
        </Descriptions.Item>
        <Descriptions.Item label="Đơn vị tính">
          {productData?.unit || '--'}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}
