import React from 'react'
import DocViewer, { DocViewerRenderers, IDocument } from 'react-doc-viewer'

const DocxViewer = ({
  url,
  height = '94.5%',
}: {
  url: IDocument[]
  height?: string
}) => {
  return (
    <DocViewer
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
          retainURLParams: true,
        },
      }}
      style={{ height }}
      // pluginRenderers={[PDFRenderer, PNGRenderer, DocViewerRenderers]}
      pluginRenderers={DocViewerRenderers}
      documents={url}
    />
  )
}

export default DocxViewer
