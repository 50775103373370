import { Table } from 'antd'
import { formatPrice } from 'utils/ruleForm'
import styles from '../../orders/styles/style.module.css'

export default function ProductList() {
  const data = [
    {
      id: '1',
      name_product: 'Dầu nhớt 1L',
      store: 'Cửa hàng số 1',
      product_numb: '10',
      price: '1000000',
      money: '30000000',
    },
    {
      id: '2',
      name_product: 'Dầu nhớt 1L',
      store: 'Cửa hàng số 2',
      product_numb: '10',
      price: '1000000',
      money: '30000000',
    },
    {
      id: '3',
      name_product: 'Dầu nhớt 1L',
      store: 'Cửa hàng số 3',
      product_numb: '10',
      price: '1000000',
      money: '30000000',
    },
  ]

  const column = [
    {
      width: '55px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: <b>Tên sản phẩm</b>,
      dataIndex: 'name_product',
      key: 'name_product',
    },
    {
      title: <b>Cửa hàng</b>,
      dataIndex: 'store',
      key: 'store',
    },
    {
      title: <b>Số lượng</b>,
      dataIndex: 'product_numb',
      key: 'product_numb',
    },
    {
      title: <b>Giá bán</b>,
      dataIndex: 'price',
      key: 'price',
      render: (value: any) => <>{value == 0 ? '0' : formatPrice(value)}</>,
    },
    {
      title: <b>Thành tiền</b>,
      dataIndex: 'money',
      key: 'money',
      render: (value: any) => <>{value == 0 ? '0' : formatPrice(value)}</>,
    },
  ]
  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className={styles.title}>
        <b style={{ fontSize: 16 }}>Danh sách sản phẩm</b>
      </div>
      <div style={{ padding: '15px 15px' }}>
        <Table
          dataSource={data}
          columns={column}
          bordered
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            y: 'calc(100vh - 300px)',
          }}
        />
      </div>
    </div>
  )
}
