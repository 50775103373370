import { Col, Input, Row } from 'antd'
import Icon from '@ant-design/icons'
import { RadiusSelection } from 'common/components/Inputs'
import { useState } from 'react'
import { ACCOUNT_R0LE, IS_ACTIVE } from 'utils/constants'
import { useSelector } from 'react-redux'

type Props = {
  data: any
  onSearchSubmit: (searchKey: string) => void
  onStatusSubmit: (statusKey: any) => void
  onRoleSubmit: (roleKey: any) => void
}

export const Filter = ({
  data,
  onSearchSubmit,
  onStatusSubmit,
  onRoleSubmit,
}: Props) => {
  const userState = useSelector((state: any) => state.authReducer)?.userInfo

  return (
    <div>
      <Row gutter={[16, 16]} justify="start">
        <Col span={10}>
          <Input.Search
            style={{ width: '100%' }}
            allowClear
            placeholder="Nhập tên hoặc số điện thoại"
            addonAfter={<Icon type="close-circle-o" />}
            value={data.search}
            onChange={(e: any) => {
              onSearchSubmit(e.target.value)
            }}
          />
        </Col>

        <Col span={5}>
          <RadiusSelection
            placeholder="Trạng thái "
            onChange={value => {
              onStatusSubmit(value)
            }}
            options={[
              { value: IS_ACTIVE.ACTIVE, text: 'Hoạt động' },
              { value: IS_ACTIVE.INACTIVE, text: 'Tạm dừng' },
            ]}
          />
        </Col>

        <Col span={8}>
          <RadiusSelection
            placeholder="Loại tài khoản"
            onChange={value => {
              onRoleSubmit(value)
            }}
            options={
              userState?.role_ids[0] == ACCOUNT_R0LE[0]?.id
                ? [
                    { value: 27, text: 'Admin' }, // admin web quản trị
                    { value: 22, text: 'Nhân viên kinh doanh' }, // nhân viên kinh doanh web quản trị
                    { value: 26, text: 'Kế toán' }, // kế toán web quản trị
                  ]
                : [
                    { value: 22, text: 'Nhân viên kinh doanh' }, // nhân viên kinh doanh web quản trị
                    { value: 26, text: 'Kế toán' }, // kế toán web quản trị
                  ]
            }
          />
        </Col>
      </Row>
    </div>
  )
}
