import { Component, CSSProperties } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { useDispatch, useSelector } from 'react-redux'

interface IProps {
  logData: (data: string) => any
  defaultValue?: string
  height?: number | string
  width?: number | string
  editorStyle?: CSSProperties
  setIsAllSpace: React.Dispatch<React.SetStateAction<boolean>>
}

class EditorComponent extends Component<IProps, any> {
  constructor(props: any) {
    super(props)
    const html = this.props.defaultValue ? this.props.defaultValue : ''
    const contentBlock = htmlToDraft(html)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorState = EditorState.createWithContent(contentState)
      this.state = {
        editorState: editorState,
      }
    }
  }

  componentDidMount() {
    const html = this.props.defaultValue ? this.props.defaultValue : ''
    const contentBlock = htmlToDraft(html)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorState = EditorState.createWithContent(contentState)
      this.setState({
        editorState: editorState,
      })
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (
      prevProps.defaultValue !== this.props.defaultValue &&
      prevProps.defaultValue === ''
    ) {
      const html = this.props.defaultValue ? this.props.defaultValue : ''
      const contentBlock = htmlToDraft(html)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )
        const editorState = EditorState.createWithContent(contentState)
        this.setState({
          editorState: editorState,
        })
      }
    }
    this.props.setIsAllSpace(this._checkEditorContent())
  }

  onEditorStateChange: Function = (editorState: any) => {
    this.props.logData(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    )
    this.setState({
      editorState: editorState,
    })
  }

  _checkEditorContent = () => {
    const content = this.state.editorState.getCurrentContent()
    const isEditorEmpty = !content.hasText()
    const currentPlainText = content.getPlainText()
    const lengthOfTrimmedContent = currentPlainText.trim().length
    const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent
    return isContainOnlySpaces
  }

  render() {
    return (
      <div>
        <Editor
          placeholder="Thêm mô tả sản phẩm"
          editorState={this.state.editorState}
          onEditorStateChange={value => this.onEditorStateChange(value)}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'image',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'emoji',
              'remove',
              'history',
            ],
          }}
          editorStyle={{
            ...this.props.editorStyle,
            height: this.props.height ? this.props.height : 200,
            width: this.props.width ? this.props.width : '100%',
          }}
        />
      </div>
    )
  }
}

export default EditorComponent
