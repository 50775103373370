import { DatePicker, PageHeader, Row, Col, Table, Tag } from 'antd'
import 'moment/locale/vi'
import CategoryDetail from './components/CategoryDetail'
import moment from 'moment'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import Container from 'common/container/Container'
import { RadiusSelection } from 'common/components/Inputs'
import { IS_ACTIVE } from 'utils/constants'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useCategoryProduct } from './hook/useCategoryProduct'

const { RangePicker } = DatePicker

const Category = () => {
  const {
    category,
    isLoading,
    currentSelected,
    params,
    paging,
    setIsLoading,
    setParams,
    setCurrentSelected,
    getData,
  } = useCategoryProduct()

  const contentView = () => {
    const columns = [
      {
        title: <b>Tên danh mục</b>,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: <b>Trạng thái</b>,
        dataIndex: 'status',
        key: 'status',
        render: (value: any) => {
          switch (value) {
            case IS_ACTIVE.ACTIVE:
              return <Tag color="green">Đang hoạt động</Tag>
            case IS_ACTIVE.INACTIVE:
              return <Tag color="volcano">Ngừng hoạt động</Tag>
          }
        },
      },
      {
        title: <b>Ngày tạo</b>,
        dataIndex: 'create_at',
        key: 'create_at',
        render: (data: any) => <div>{moment(data).format('DD/MM/YYYY')}</div>,
      },
    ]

    function handleChangeStatus(value: any) {
      setParams({
        ...params,
        status: value,
        page: 1,
      })
    }

    const handleChangeTime = (dates: any, dateStrings: any[]) => {
      setParams({
        ...params,
        to_date: dateStrings[1],
        from_date: dateStrings[0],
        page: 1,
      })
    }

    const onSearchSubmit = (searchKey: string) => {
      setIsLoading({ ...isLoading, loadingSearch: true })

      setParams({ ...params, search: searchKey, page: 1 })
    }

    return (
      <>
        <Container
          header={<PageHeader title="Danh mục sản phẩm" />}
          filterComponent={
            <Row gutter={[16, 16]} justify="start">
              <Col span={10}>
                <TypingAutoSearch
                  onSearchSubmit={(searchKey: string) => {
                    onSearchSubmit(searchKey.trim())
                  }}
                  isSearchLoading={isLoading.loadingSearch}
                  placeholder="Tên danh mục "
                />
              </Col>

              <Col span={5}>
                <RadiusSelection
                  placeholder="Trạng thái"
                  onChange={handleChangeStatus}
                  options={[
                    { value: IS_ACTIVE.ACTIVE, text: 'Đang hoạt động' },
                    { value: IS_ACTIVE.INACTIVE, text: 'Ngừng hoạt động' },
                  ]}
                />
              </Col>

              <Col span={8}>
                <RangePicker
                  style={{ width: '100%' }}
                  onChange={(dates, dateStrings) => {
                    handleChangeTime(dates, dateStrings)
                  }}
                  placeholder={['Từ ngày', 'Đến ngày']}
                />
              </Col>
            </Row>
          }
          contentComponent={
            <>
              <p>
                Kết quả lọc: <b>{paging.total}</b>
              </p>
              <Table
                className={styles.table_hover}
                scroll={{
                  x: 800,
                  scrollToFirstRowOnChange: true,
                }}
                bordered
                dataSource={category}
                loading={isLoading.loadingTable}
                columns={columns}
                expandedRowKeys={[currentSelected.id]}
                onRow={(row: any) => ({
                  onClick: () => {
                    if (currentSelected !== row) setCurrentSelected(row)
                    else setCurrentSelected({ id: -1 })
                  },
                })}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <CategoryDetail data={record} getData={getData} />
                  ),
                }}
                pagination={{
                  ...paging,
                  showSizeChanger: false,
                  onChange: async (page, pageSize) => {
                    setParams({ ...params, page })
                  },
                }}
              />
            </>
          }
        />
      </>
    )
  }
  return contentView()
}
export default Category
