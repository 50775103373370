import i18n from './translation'
function strings() {
  return {
    account: i18n.t('account'),
    password: i18n.t('password'),
    login: i18n.t('login'),
    partner: i18n.t('partner'),
    remember_password: i18n.t('remember_password'),
    dialog_error: i18n.t('dialog.error'),
    dialog_cancel: i18n.t('dialog.cancel'),
    dialog_warning: i18n.t('dialog.warning'),
    dialog_info: i18n.t('dialog.info'),
    dialog_loading: i18n.t('dialog.loading'),
    dialog_select: i18n.t('dialog.select'),
    dialog_success: i18n.t('dialog.success'),
    product_category: i18n.t('product_category'),
    category: i18n.t('category'),
    enterprise: i18n.t('enterprise'),
    request: i18n.t('request'),
    banner: i18n.t('banner'),
    config: i18n.t('config'),
    created_at: i18n.t('created_at'),
    time_minus_hour_ago: i18n.t('time_minus.hour_ago'),
    time_minus_minute_ago: i18n.t('time_minus.minute_ago'),
    time_minus_just_now: i18n.t('time_minus.just_now'),
    time_minus_day_ago: i18n.t('time_minus.day_ago'),
    fail_request: i18n.t('fail_request'),
    error_network: i18n.t('error_network'),
    please_enter_category_name: i18n.t('please_enter.category_name'),
    please_enter_account: i18n.t('please_enter.account'),
    please_enter_pass: i18n.t('please_enter.pass'),
    please_select_category_name: i18n.t('please_select.category_name'),
    store: i18n.t('store'),
    payment_info: i18n.t('payment.info'),
    payment_method: i18n.t('payment.method'),
    search_category: i18n.t('search.category'),
    search_product: i18n.t('search.product'),
    search_order: i18n.t('search.order'),
    action_create: i18n.t('action.create'),
    action_remove: i18n.t('action.remove'),
    action_edit: i18n.t('action.edit'),
    action_export: i18n.t('action.export'),
    action_import: i18n.t('action.import'),
    action_update: i18n.t('action.update'),
    action_back: i18n.t('action.back'),
    remove_product: i18n.t('remove.product'),
    status: i18n.t('status'),
    active: i18n.t('active'),
    un_active: i18n.t('un_active'),
    success: i18n.t('success'),
    notification: i18n.t('notification'),
    enter: i18n.t('enter'),
    agent_request: i18n.t('agent_request'),
    withdraw_request: i18n.t('withdraw_request'),
    create_new: i18n.t('create_new'),
    title_header_dashboard: i18n.t('title_header.dashboard'),
    title_header_product: i18n.t('title_header.product'),
    title_header_category_product: i18n.t('title_header.category_product'),
    title_header_sale_product: i18n.t('title_header.sale_product'),
    title_header_store: i18n.t('title_header.store'),
    title_header_store_list: i18n.t('title_header.store_list'),
    title_header_inventory: i18n.t('title_header.inventory'),
    title_header_inventory_xd: i18n.t('title_header.inventory_xd'),
    title_header_order_list: i18n.t('title_header.order_list'),
    title_header_sell: i18n.t('title_header.sell'),
    title_header_customer: i18n.t('title_header.customer'),
    title_header_enterprise_customer: i18n.t(
      'title_header.enterprise_customer'
    ),
    title_header_order_insurance: i18n.t('title_header.order_insurance'),
    title_header_account: i18n.t('title_header.account'),
    title_header_import_goods: i18n.t('title_header.import_goods'),
    title_header_agent: i18n.t('title_header.agent'),
    title_header_report: i18n.t('title_header.report'),
    title_header_shop: i18n.t('title_header.shop'),
    title_header_shop_list: i18n.t('title_header.shop_list'),
    title_header_request_become_partner: i18n.t(
      'title_header.request_become_partner'
    ),
    title_header_send_notification: i18n.t('title_header.send_notification'),
    title_header_order: i18n.t('title_header.order'),
    title_header_debt: i18n.t('title_header.debt'),
    title_header_live_stream: i18n.t('title_header.live_stream'),
    title_header_request: i18n.t('title_header.request'),
    title_header_post: i18n.t('title_header.post'),
    title_header_news: i18n.t('title_header.news_banner'),
    title_header_point: i18n.t('title_header.point'),
    title_header_gift_change: i18n.t('title_header.gift_change'),
    title_header_gift: i18n.t('title_header.gift'),
    title_header_debt_report: i18n.t('title_header.debt_report'),
    title_header_live_stream_report: i18n.t('title_header.live_stream_report'),
    title_header_shop_report: i18n.t('title_header.shop_report'),
    title_header_point_report: i18n.t('title_header.point_report'),
    title_header_sale_report: i18n.t('title_header.sale_report'),
    title_header_config: i18n.t('title_header.config'),
    title_header_ware_house: i18n.t('title_header.ware_house'),
    title_header_chat: i18n.t('title_header.chat'),
    title_header_forum_post: i18n.t('title_header.forum_post'),
    title_header_customer_enterprise: i18n.t(
      'title_header.customer-enterprise'
    ),
    title_header_shop_retail: i18n.t('title_header.shop-retail'),
  }
}
export default strings
