import { Tabs } from 'antd'
import Point from './Point'
import Rank from './Rank'

const { TabPane } = Tabs
type Props = {
  point: any
  rank: any
  rank_point: any
}
export default function HistoryPoint({ point, rank, rank_point }: Props) {
  return (
    <Tabs>
      <TabPane tab={<p style={{ fontSize: '16px' }}>Điểm tích lũy</p>} key="1">
        <Point point={point} />
      </TabPane>
      <TabPane tab={<p style={{ fontSize: '16px' }}>Hạng tích lũy</p>} key="2">
        <Rank point={rank_point} rank={rank} />
      </TabPane>
    </Tabs>
  )
}
