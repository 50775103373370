import { Col, PageHeader, Space } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import R from 'assets'

type Props = { CreateShop: any }

export default function Header({ CreateShop }: Props) {
  return (
    <PageHeader
      title="CH xăng dầu -  Đối tác"
      extra={[
        <Space>
          <Col>
            <ButtonAdd
              onClickButton={CreateShop}
              text={R.strings().action_create}
            />
          </Col>
        </Space>,
      ]}
    />
  )
}
