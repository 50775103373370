import { PageHeader, Row, Spin, Table } from 'antd'
import ButtonHistoryChange from 'common/components/Buttons/ButtonHistoryChange'
import ButtonMoveInventory from 'common/components/Buttons/ButtonMoveInventory'
import openNotificationWithIcon from 'common/components/Notification'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import React from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import useDebounce from '../report/sale/hook/useDebounce'
import AddInventoryModal from './components/InventoryDetail/AddInventoryModal'
import InventoryDetailFilter from './components/InventoryDetail/InventoryDetailFilter'
import { getDetailWarehouse } from './components/service'
import { IPaging } from './InventoryList'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import ButtonExportExcelV2 from 'common/components/Buttons/ButtonExportExcelV2'
export interface IInventoryDetail {
  id: number
  code: string
  name: string
  remain: number
  receipt: number
  issue: number
  stock: number
  dimish: number
}

const InventoryDetail = () => {
  const column = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'id',
      render: (text: any, record: any, index: any) =>
        page === 1 ? ++index : (page - 1) * 12 + ++index,
    },
    {
      title: <b>Mã hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: <b>Tên hàng</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <b>Đơn vị tính</b>,
      dataIndex: 'product_unit_name',
      key: 'product_unit_name',
    },
    {
      title: <b>Tồn đầu</b>,
      dataIndex: 'remain',
      key: 'remain',
      render: (value: any) => (
        <>{value == 0 ? '0.00' : value ? formatPrice(value) : ''}</>
      ),
    },
    {
      title: <b>Đầu kỳ</b>,
      dataIndex: 'old_stock',
      key: 'old_stock',
      render: (value: any) => <>{!value ? '0.00' : formatPrice(value)}</>,
    },
    {
      title: <b>Nhập kho</b>,
      dataIndex: 'receipt',
      key: 'receipt',
      render: (value: any) => <>{!value ? '0.00' : formatPrice(value)}</>,
    },
    {
      title: <b>Xuất kho</b>,
      dataIndex: 'issue',
      key: 'issue',
      render: (value: any) => <>{!value ? '0.00' : formatPrice(value)}</>,
    },
    {
      title: <b>Hao hụt</b>,
      dataIndex: 'diminish',
      key: 'diminish',
      render: (value: any) => <>{!value ? '0.00' : formatPrice(value)}</>,
    },
    {
      title: <b>Cuối kỳ</b>,
      dataIndex: 'stock',
      key: 'stock',
      render: (value: any) => <>{!value ? '0.00' : formatPrice(value)}</>,
    },
  ]

  const location: any = useLocation()
  const [storeName, setStoreName] = React.useState<string>('')
  const [isOpenAddModal, setIsOpenAddModal] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [search, setSearch] = React.useState<string>(
    location?.state?.search ? location?.state?.search : ''
  )
  // const [currentPage, setCurrentPage] = React.useState<number>(
  //   location?.state?.currentPage ? location?.state?.currentPage : 1
  // )
  const [products, setProducts] = React.useState<IInventoryDetail[]>([])
  // const [paging, setPaging] = React.useState<IPaging>({
  //   total: 0,
  //   current: currentPage,
  //   pageSize: 12,
  // })

  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(1)

  const [
    isLoadingBtnExportData,
    setLoadingBtnExportData,
  ] = React.useState<boolean>(false)

  const [categoryId, setCategoryId] = React.useState<any>(
    location?.state?.categoryId ? location?.state?.categoryId : null
  )
  const [params, setParams] = React.useState<any>({
    search: '',
    from_date: undefined,
    to_date: moment().format('YYYY-MM-DD HH:mm:ss'),
  })

  const onCancelAddModal = () => {
    setIsOpenAddModal(false)
  }

  const onOpenAddModal = () => {
    setIsOpenAddModal(true)
  }

  const getDetail = async () => {
    try {
      setIsLoading(true)
      const res = await getDetailWarehouse(
        location?.state?.id,
        categoryId
          ? {
              ...params,
              page,
              category_id: categoryId,
            }
          : {
              ...params,
              page,
            }
      )
      if (res.status) {
        const data = res.data.map((product: any) => ({
          ...product,
          id: product.product_id,
          categoryId: product.category_id,
          code: product.product_code,
          name: product.product_name,
          remain: product.remain,
          receipt: product.receipt,
          issue: product.issue,
          stock: product.stock,
          diminish: product.diminish,
          category_name: product.category_name,
        }))
        setProducts(data)
        setTotal(res.meta.pagination.totalItems)
      } else {
        openNotificationWithIcon(
          'error',
          'Thông báo',
          'Đã có lỗi xảy ra! Xin vui lòng thử lại'
        )
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const searchDebounce = useDebounce(search, 300)

  React.useEffect(() => {
    setStoreName(location.state.name)
  }, [])

  React.useEffect(() => {
    getDetail()
  }, [params, categoryId, page])

  React.useEffect(() => {
    setParams({
      ...params,
      search: searchDebounce,
      page: 1,
    })
  }, [searchDebounce, JSON.stringify(params)])

  // React.useEffect(() => {
  //   setParams({
  //     ...params,
  //     page: currentPage,
  //   })
  // }, [currentPage, JSON.stringify(params)])

  React.useEffect(() => {
    if (location.state?.fromDate && location.state?.toDate) {
      if (
        moment(location?.state.fromDate).format('YYYY-MM-DD HH:mm') ===
        moment(location?.state.toDate).format('YYYY-MM-DD HH:mm')
      ) {
        setParams({
          ...params,
          from_date: location?.state?.fromDate
            ? moment(location?.state.fromDate)
                ?.seconds(0)
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
          to_date: location?.state.toDate
            ? moment(location?.state.toDate)
                ?.seconds(0)
                .add(59, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
        })
      } else {
        setParams({
          ...params,
          from_date: location?.state?.fromDate
            ? moment(location?.state.fromDate)
                ?.seconds(0)
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
          to_date: location?.state.toDate
            ? moment(location?.state.toDate)
                ?.seconds(0)
                .add(59, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
        })
      }
      // setParams({
      //   ...params,
      //   from_date: location?.state?.fromDate,
      //   to_date:
      //     location?.state?.toDate || moment().format('YYYY-MM-DD HH:mm:ss'),
      // })
    }
  }, [location.state])

  const onExportData = async () => {
    try {
      const workbook = new Excel.Workbook()

      setLoadingBtnExportData(true)
      const worksheet = workbook.addWorksheet('Tồn kho')

      // columns
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10 },
        { header: 'Mã hàng', key: 'code', width: 20 },
        { header: 'Tên hàng', key: 'name', width: 20 },
        { header: 'ĐVT', key: 'unit', width: 20 },
        { header: 'Tồn đầu', key: 'remain', width: 20 },
        { header: 'Đầu kỳ', key: 'debt_start', width: 20 },
        { header: 'Nhập kho', key: 'warehouse', width: 20 },
        { header: 'Xuất kho', key: 'export', width: 20 },
        { header: 'Hao hụt', key: 'loss', width: 20 },
        { header: 'Cuối kỳ', key: 'debt_end', width: 20 },
      ]

      worksheet.insertRow(1, [
        'TỔNG HỢP TỒN KHO ' +
          (storeName ? storeName : location.state?.inventoryDetailName),
      ])
      worksheet.getRow(1).font = { size: 18, color: { argb: '00000' } }
      worksheet.mergeCells('A1', 'I1')

      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
      }
      worksheet.getCell('A1').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      worksheet.insertRow(2, [
        params.from_date
          ? `Thời gian thực hiện: Từ ${moment(params.from_date)?.format(
              'HH:mm DD/MM/YYYY'
            )} đến ${moment(params.to_date)?.format('HH:mm DD/MM/YYYY')}`
          : 'Thời gian thực hiện: -',
      ])
      worksheet.mergeCells('A2', 'I2')
      //Todo:  styl;e từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }

      // todo: boder style
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      // todo: boder style
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      const dataListDebt = await getDetailWarehouse(
        location?.state?.id,
        categoryId
          ? {
              ...params,
              category_id: categoryId,
              limit: 999,
            }
          : { ...params, limit: 999 }
      )

      dataListDebt?.data?.forEach((o: any, i: number) => {
        worksheet.addRow({
          id: i + 1,
          code: o?.product_code,
          name: o?.product_name,
          unit: o?.product_unit_name,
          debt_start: o?.old_stock ? (+o?.old_stock).toFixed(2) : '0.00',
          warehouse: o?.receipt ? (+o?.receipt).toFixed(2) : '0.00',
          export: o?.issue ? (+o?.issue).toFixed(2) : '0.00',
          loss: o?.diminish ? (+o?.diminish).toFixed(2) : '0.00',
          debt_end: o?.stock ? Number(o?.stock).toFixed(2) : '0.00',
          remain: +o?.remain ? (+o?.remain).toFixed(2) : '0.00',
        })
      })

      worksheet.eachRow({ includeEmpty: true }, function (row: any, rowNumber) {
        if (row?._number === 3) {
          row.eachCell({ includeEmpty: true }, function (cell: any) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '87CEEB' },
            }
          })
        }

        row.eachCell({ includeEmpty: true }, function (cell: any) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(
        new Blob([buf]),
        `Tổng hợp tồn kho ${
          storeName ? storeName : location.state?.inventoryDetailName
        }.xlsx`
      )
    } catch (error) {
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <Container
      header={
        <PageHeader
          title={`Chi tiết kho ${
            storeName ? storeName : location.state?.inventoryDetailName
          }`}
          extra={[
            <ButtonMoveInventory
              text="Cập nhật tồn đầu"
              onClickButton={() => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.UPDATE_SURVIVE_HEAD}/${
                    location.state?.id || location?.state?.inventoryDetailId
                  }`,
                  state: {
                    inventoryDetailId:
                      location.state.id || location.state.inventoryDetailId,
                    inventoryDetailName:
                      location.state.name ||
                      location.state?.inventoryDetailName,
                    search: search,
                    currentPage: page,
                    currentPageList: location?.state?.currentPageList,
                    id: location?.state.id,
                    categoryId,
                    fromDate: params?.from_date,
                    // toDate: params?.to_date,
                  },
                })
              }}
            />,
            <ButtonHistoryChange
              text="Lịch sử kho"
              onClickButton={() => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.HISTORY_INVENTORY}/${
                    location.state.id || location.state.inventoryDetailId
                  }`,
                  state: {
                    inventoryDetailId:
                      location.state.id || location.state?.inventoryDetailId,
                    inventoryDetailName:
                      location.state.name ||
                      location.state?.inventoryDetailName,
                    search: search,
                    currentPage: page,
                    currentPageList: location?.state?.currentPageList,
                    id: location?.state.id,
                    categoryId,
                    fromDate: params.from_date,
                    toDate: params.to_date.toString(),
                  },
                })
              }}
            />,
            // <ButtonAdd text="Bổ sung tồn kho" onClickButton={onOpenAddModal} />,

            // <ButtonMoveInventory
            //   text="Chuyển kho"
            //   onClickButton={() => {
            //     history.push({
            //       pathname: `${ADMIN_ROUTER_PATH.CHANGE_WAREHOUSE}/${
            //         location.state.id || location.state.inventoryDetailId
            //       }`,
            //       state: {
            //         inventoryDetailId:
            //           location.state.id || location.state.inventoryDetailId,
            //         inventoryDetailName:
            //           location.state.name || location.state.inventoryDetailName,
            //       },
            //     })
            //   }}
            // />,
            <ButtonMoveInventory
              text="Cập nhật kho"
              onClickButton={() => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.UPDATE_INVENTORY}/${
                    location.state?.id || location?.state?.inventoryDetailId
                  }`,
                  state: {
                    inventoryDetailId:
                      location.state.id || location.state.inventoryDetailId,
                    inventoryDetailName:
                      location.state.name ||
                      location.state?.inventoryDetailName,
                    search: search,
                    currentPage: page,
                    currentPageList: location?.state?.currentPageList,
                    id: location?.state.id,
                    categoryId,
                    fromDate: params?.from_date,
                    // toDate: params?.to_date,
                  },
                })
              }}
            />,
          ]}
          onBack={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.INVENTORY}`,
              state: {
                currentPageList: location?.state?.currentPageList,
                search: location?.state?.listSearch,
                startDate: location?.state?.startDate,
                endDate: location?.state?.endDate,
              },
            })
          }}
        />
      }
      filterComponent={
        <Row justify="space-between">
          <div style={{ flex: 1 }}>
            <InventoryDetailFilter
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              setSearch={setSearch}
              search={search}
              params={params}
              setParams={setParams}
            />
          </div>
          <div
            style={{ width: '200px', display: 'flex', justifyContent: 'end' }}
          >
            {/* <ButtonExport
            sheetName={['Debts']}
            fileName={'Danh sách công nợ khách hàng'}
            loading={isLoadingBtnExportData}
            onClick={fn => onExportData(fn)}
            sheets={{
              Debts: ButtonExport.getSheets(dataExport),
            }}
          /> */}
            <ButtonExportExcelV2
              key="export_excel"
              loading={isLoadingBtnExportData}
              onClick={onExportData}
            />
          </div>
        </Row>
      }
      contentComponent={
        <Spin spinning={isLoading}>
          <div>
            {isOpenAddModal && (
              <AddInventoryModal
                isOpenAddModal={isOpenAddModal}
                onCancelAddModal={onCancelAddModal}
                setIsLoading={setIsLoading}
                getDetail={getDetail}
                petrolStoreId={location?.state.id}
              />
            )}
            <p>
              Kết quả lọc: <b>{total}</b>
            </p>
            <Table
              bordered
              columns={column}
              dataSource={products}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              onRow={record => {
                return {
                  onClick: () =>
                    history.push({
                      pathname: `${
                        ADMIN_ROUTER_PATH.HISTORY_INVENTORY_DETAIL
                      }/${
                        location.state.id || location.state.inventoryDetailId
                      }`,
                      state: {
                        inventoryDetailId:
                          location.state.id || location.state.inventoryDetailId,
                        inventoryDetailName:
                          location.state.name ||
                          location.state?.inventoryDetailName,
                        search: search,
                        currentPage: page,
                        currentPageList: location?.state?.currentPageList,
                        id: location?.state.id,
                        categoryId,
                        product_name: record?.name,
                        product_id: record?.id,
                        fromDate: params.from_date,
                        toDate: params.to_date.toString(),
                      },
                    }),
                }
              }}
              pagination={{
                pageSize: 12,
                total: total,
                showSizeChanger: false,
                onChange: _page => {
                  setPage(_page)
                  // setCurrentPage(page)
                },
              }}
            />
          </div>
        </Spin>
      }
    />
  )
}

export default InventoryDetail
