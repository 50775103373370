import moment from 'moment'
import { Table, Tag } from 'antd'
import { formatPrice } from 'utils/ruleForm'
import Filter from './components/Filter'
import HeaderBanner from './components/Header'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useOrder } from './hook/useOrder'
import Container from 'common/container/Container'

export default function InsuranceScreen() {
  const {
    history,
    isLoadingTable,
    setIsLoadingTable,
    onDeleteProduct,
    param,
  } = useOrder()
  const data = [
    {
      id: '1',
      code: 'ABC123',
      customer: 'Hải âu hấp hối',
      receiver: 'Người nhận 1',
      store: 'Cửa hàng số 1',
      product_numb: '10',
      price: '1000000',
      payment: 1,
      status: 1,
      status_payment: 1,
      create_at: '11/11/2021',
    },
    {
      id: '2',
      code: 'BCD234',
      customer: 'Sà lách xạ hương',
      receiver: 'Người nhận 2',
      store: 'Cửa hàng số 2',
      product_numb: '10',
      price: '1000000',
      payment: 0,
      status: 0,
      status_payment: 0,
      create_at: '11/11/2021',
    },
    {
      id: '3',
      code: 'CDE345',
      customer: 'Núi và Mây Mưa',
      receiver: 'Người nhận 3',
      store: 'Cửa hàng số 3',
      product_numb: '10',
      price: '1000000',
      payment: 1,
      status: 3,
      status_payment: 1,
      create_at: '11/11/2021',
    },
  ]

  const column = [
    {
      width: '55px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      width: '70px',
      title: <b>Mã đơn hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: <b>Khách hàng</b>,
      dataIndex: 'customer',
      key: 'customer',
    },
    {
      title: <b>Người nhận</b>,
      dataIndex: 'receiver',
      key: 'receiver',
    },
    {
      title: <b>Cửa hàng</b>,
      dataIndex: 'store',
      key: 'store',
    },
    {
      title: <b>Số SP</b>,
      width: '50px',
      dataIndex: 'product_numb',
      key: 'product_numb',
    },
    {
      title: <b>Tổng tiền</b>,
      dataIndex: 'price',
      key: 'price',
      render: (value: any) => <>{value == 0 ? '0' : formatPrice(value)}</>,
    },
    {
      width: '118px',
      title: <b>TT Đơn hàng</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        if (value === 1)
          return (
            <Tag style={{ fontSize: 12 }} color="blue">
              Đang thực hiện
            </Tag>
          )
        if (value === 3)
          return (
            <Tag style={{ fontSize: 12 }} color="green">
              Đã thực hiện
            </Tag>
          )
        if (value === 0)
          return (
            <Tag style={{ fontSize: 12 }} color="volcano">
              Chờ xác nhận
            </Tag>
          )
      },
    },
    {
      width: '115px',
      title: <b>Phương thức thanh toán</b>,
      dataIndex: 'payment',
      key: 'payment',
      render: (value: any) => {
        if (value === 1)
          return (
            <Tag style={{ fontSize: 12 }} color="blue">
              Chuyển khoản
            </Tag>
          )
        if (value === 0)
          return (
            <Tag style={{ fontSize: 12 }} color="green">
              Tiền mặt
            </Tag>
          )
      },
    },
    {
      width: '128px',
      title: <b>TT Thanh toán</b>,
      dataIndex: 'status_payment',
      key: 'status_payment',
      render: (value: any) => {
        // return <td>{value === 1 ? 'Đang hoạt động' : 'Ngưng hoạt động'}</td>
        if (value === 1)
          return (
            <Tag style={{ fontSize: 12 }} color="green">
              Đã thanh toán
            </Tag>
          )
        if (value === 0)
          return (
            <Tag style={{ fontSize: 12 }} color="volcano">
              Chưa thanh toán
            </Tag>
          )
      },
    },
    {
      width: '90px',
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('DD/MM/YYYY')}</>
      },
    },
  ]
  return (
    <Container
      header={<HeaderBanner />}
      filterComponent={<Filter />}
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>10</b>
          </p>
          <Table
            size="middle"
            loading={isLoadingTable}
            bordered
            columns={column}
            dataSource={data}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
              y: 'calc(100vh - 430px)',
            }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.id}`,
                  state: record,
                })
              },
              // onMouseEnter: event => {
              //   setItemSelected({ id: record.id, rowIndex: Number(rowIndex) })
              // },
            })}
            pagination={
              {
                // ...paging,
                // showSizeChanger: false,
                // onChange: async (page, pageSize) => {
                //   setParams({ ...params, page })
                // },
              }
            }
          />
        </div>
      }
    />
  )
}
