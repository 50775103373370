import moment from 'moment'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Cards } from './components/Cards'
import { Charts } from './components/Charts'
import { Header } from './components/Header'
import { getDashBoard } from './DashBoardApi'

type Params = {
  search: undefined | string
  from_date?: string
  to_date?: string
}
export default function DashBoardScreen() {
  const [loading, setLoading] = useState(false)
  const [dashBoard, setDashBoard] = useState()
  const [params, setParams] = useState<Params>({
    search: undefined,
    from_date: moment().startOf('month').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  })
  const userState = useSelector((state: any) => state.authReducer)?.userInfo
  const getDataDashBoard = async () => {
    try {
      setLoading(true)
      if (userState?.role_ids[0] === 21 || 22) {
        const res = await getDashBoard(params)
        setDashBoard(res)
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    getDataDashBoard()
  }, [params])
  return (
    <div>
      {/* {userState?.role_ids[0] === 22 && ( */}
      <div>
        <Header setParams={setParams} />
        <Cards data={dashBoard} />
        <Charts params={params} datas={dashBoard} />
      </div>
      {/* )} */}
    </div>
  )
}
