import { ApiClient } from 'services/ApiService'

export const getListProduct = (payload: any) =>
  ApiClient.get('/admin/product', payload)

export const requestAllCategory = (payload: any) =>
  ApiClient.get('/admin/category', payload)

export const requestDetailProduct = (payload: any) =>
  ApiClient.get('/admin/product/detail', payload)

export const requestListPetrolStore = (payload: any) =>
  ApiClient.get('/admin/petrol-store/active', payload)

export const createProduct = (payload: any) =>
  ApiClient.post(`/admin/product`, payload)

export const updateProduct = (product_id: number, payload: any) =>
  ApiClient.put(`/admin/product/${product_id}`, payload)

export const deleteProduct = (product_id: any) =>
  ApiClient.delete(`/admin/product/${product_id}`)

export const changeStatusproduct = (product_id: any) =>
  ApiClient.patch(`/admin/product/${product_id}`)

export const requestUploadImageproduct = (payload: any) =>
  ApiClient.post(`/files/upload/single/${payload.type}`, payload.data)

export const getUnits = () => ApiClient.get(`/admin/config-product-unit`)
