import Icon from '@ant-design/icons'
import { DatePicker, Row, Select, Input, Col } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import moment from 'moment'
import React, { useState } from 'react'
import { IS_ACTIVE } from 'utils/constants'

const { RangePicker } = DatePicker
const { Search } = Input
type Props = {
  data: any
  onDateSubmit: (from_date: any, to_date: any) => void
  onSearchSubmit: (searchKey: string) => void
  onTypeSubmit: (statusKey: any) => void
  onStatusNews: (roleKey: any) => void
  onStatus: (roleKey: any) => void
}
export const Filter = ({
  data,
  onSearchSubmit,
  onTypeSubmit,
  onStatusNews,
  onStatus,
  onDateSubmit,
}: Props) => {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)

  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={6}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Tiêu đề banner"
          addonAfter={<Icon type="close-circle-o" />}
          value={data.search}
          onChange={(e: any) => {
            onSearchSubmit(e.target.value)
          }}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="Loại banner"
          onChange={value => {
            onTypeSubmit(value)
          }}
          options={[
            { value: 1, text: 'Banner' },
            { value: 0, text: 'Tin tức' },
          ]}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="Trạng thái"
          onChange={value => {
            onStatusNews(value)
          }}
          options={[
            { value: 1, text: 'Đăng bài' },
            { value: 0, text: 'Lưu nháp' },
          ]}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="Trạng thái hoạt động"
          onChange={value => {
            onStatus(value)
          }}
          options={[
            { value: IS_ACTIVE.ACTIVE, text: 'Hoạt động' },
            { value: IS_ACTIVE.INACTIVE, text: 'Tạm dừng' },
          ]}
        />
      </Col>

      <Col span={6}>
        <RangePicker
          placeholder={['Từ ngày', 'Đến ngày']}
          onChange={(dates: any, dateString: any) => {
            onDateSubmit(dateString[0], dateString[1])
          }}
        />
      </Col>
    </Row>
  )
}
