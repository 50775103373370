import { PageHeader } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import { useState } from 'react'
// import { AddEditAccount } from './AddEditAccount'

export const Header = () => {
  const [showAddCategory, setShowAddCategory] = useState(false)

  return (
    <>
      <PageHeader
        // title="Yêu cầu cứu trợ, thay dầu"
        title="Trạm sạc điện"
        // extra={[]}
      />
      {/* <AddEditAccount
        visible={showAddCategory}
        isLoading={false}
        onCancel={() => {
          setShowAddCategory(false)
        }}
      /> */}
    </>
  )
}
