import { Divider, Table, DatePicker, Tag } from 'antd'
import Column from 'antd/lib/table/Column'
import {} from '../SaleProductApi'
import { formatPrice } from 'utils/ruleForm'
import { useDetailProduct } from '../hook/useDetailProduct'
import moment from 'moment'
const { RangePicker } = DatePicker

export default function DetailPrice() {
  const { dataSource, isLoading } = useDetailProduct()

  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin giá bán khuyến mại</b>
      <Divider dashed style={{ backgroundColor: '#BFBFBF' }} />
      <Table
        style={{ marginTop: '20px' }}
        bordered
        scroll={{
          x: 800,
          y: 'calc(100vh-300px)',
          scrollToFirstRowOnChange: true,
        }}
        loading={isLoading.loadingTable}
        dataSource={dataSource}
        pagination={false}
      >
        <Column
          width="70px"
          title={<b style={{ fontSize: '16px' }}>STT</b>}
          dataIndex="stt"
          key="stt"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{index_col + 1}</p>
          )}
        />
        <Column
          width="190px"
          title={<b style={{ fontSize: '16px' }}>Cửa hàng</b>}
          dataIndex="name"
          key="name"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="200px"
          title={<b style={{ fontSize: '16px' }}>Địa điểm</b>}
          dataIndex="address"
          key="price"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="140px"
          title={<b style={{ fontSize: '16px' }}>Giá gốc</b>}
          dataIndex="price_root"
          key="price_root"
          render={(value_col: any, record: any, index_col: number) => (
            <p> {value_col ? formatPrice(value_col) : '0'} đ</p>
          )}
        />
        <Column
          width="140px"
          title={<b style={{ fontSize: '16px' }}>Chiết khấu</b>}
          dataIndex="discount"
          key="discount"
          render={(value_col: any, record: any, index_col: number) => {
            return <p>{value_col ? formatPrice(value_col) : '0'} đ</p>
          }}
        />
        <Column
          width="140px"
          title={<b style={{ fontSize: '16px' }}>Giá khuyến mãi</b>}
          dataIndex="price"
          key="price"
          render={(value_col: any, record: any, index_col: number) => {
            return <p>{value_col ? formatPrice(value_col) : '0'} đ</p>
          }}
        />
        <Column
          width="130px"
          title={<b style={{ fontSize: '16px' }}>Trạng thái áp dụng</b>}
          dataIndex="status"
          key="status"
          render={(value: any) => (
            <Tag color={value === 1 ? 'green' : 'volcano'}>
              {value === 1 ? 'Đang áp dụng' : 'Không áp dụng'}
            </Tag>
          )}
        />
        <Column
          width="330px"
          title={<b style={{ fontSize: '16px' }}>Thời gian khuyến mãi</b>}
          dataIndex="promotion_time"
          key="promotion_time"
          render={(value_col: any, record: any, index_col: number) => {
            return (
              <RangePicker
                disabled
                format="HH:mm DD/MM/YYYY"
                showTime={{ format: 'HH:mm' }}
                placeholder={['Từ ngày', 'Đến ngày']}
                value={[moment(value_col[0]), moment(value_col[1])]}
              />
            )
          }}
        />
      </Table>
    </div>
  )
}
