import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { TParamsProduct, TRadiusSelect } from '../Product'
import { getListProduct, requestAllCategory } from '../ProductApi'

export const useProduct = () => {
  const location: any = useLocation()
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const [productData, setProductData] = useState({})
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [listCategory, setListCategory] = useState<Array<TRadiusSelect>>([])
  const [params, setParams] = useState<TParamsProduct>({
    search: undefined,
    status: undefined,
    category_id: undefined,
    page: 1,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })
  useEffect(() => {
    getListCategory()
    if (location.state) {
      setParams({
        ...params,
        search: location.state?.search,
        category_id: location.state?.category_id,
        status: location.state?.status,
        page: location.state?.page,
      })
    }
  }, [])

  useEffect(() => {
    let run = setTimeout(() => {
      getDataProduct()
    }, 300)
    return () => clearTimeout(run)
  }, [params])

  const getListCategory = async () => {
    const res: any = await requestAllCategory({ limit: 999 })
    const list_data = res?.data?.map((item: any) => {
      return { value: item.id, text: item.name }
    })
    setListCategory(list_data)
  }

  const getDataProduct = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getListProduct(params)

      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          category: item.product_category.name,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          priority: item.stt,
          unit: item?.product_unit?.name,
        }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setDataSource(tableData)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  return {
    productData,
    paging,
    isLoading,
    dataSource,
    params,
    listCategory,
    setParams,
    setDataSource,
    setProductData,
    setPaging,
    setIsLoading,
  }
}
