import { Descriptions, Divider, Timeline } from 'antd'
import styles from '../styles/style.module.css'

type Props = {
  data: any
}
export default function Insurance({ data }: Props) {
  return (
    <Descriptions
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <b>Thông tin bảo hiểm xe</b>
          <div className={styles.divider}></div>
        </div>
      }
      // column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      column={1}
    >
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Chủ sở hữu</span>}
      >
        {data.owner}
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}>Hãng xe</span>}
      >
        {data.vehicle_brand}
      </Descriptions.Item>
      {/* <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Phương tiện</span>}
      >
        Ô tô
      </Descriptions.Item> */}
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Biển số xe</span>}
      >
        {data.number_plate}
      </Descriptions.Item>
    </Descriptions>
  )
}
