import { Descriptions, Tag } from 'antd'
import styles from '../styles/style.module.css'

type Props = {
  data: any
}
export default function ReceiverInfor({ data }: Props) {
  return (
    <Descriptions
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <b>Thông tin Người nhận hàng</b>
          <div className={styles.divider}></div>
        </div>
      }
      column={1}
    >
      <Descriptions.Item style={{ paddingLeft: '15px' }}>
        {data.f_user_address == null ? (
          <Tag color="volcano">Nhận tại cửa hàng</Tag>
        ) : (
          <Tag color="blue">Nhận hàng tại địa chỉ</Tag>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          data.f_user_address == null ? (
            <span style={{ paddingLeft: '15px' }}> Tên cửa hàng</span>
          ) : (
            <span style={{ paddingLeft: '15px' }}> Tên người nhận</span>
          )
        }
      >
        {data.f_user_address == null ? data.shop : data.nameReceiver}
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Số điện thoại</span>}
      >
        {data.f_user_address == null ? data.phoneShop : data.phoneReceiver}
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Địa chỉ chi tiết</span>}
      >
        {data.user_address_id === null
          ? data.addressShop
          : data.addressReceiver}
      </Descriptions.Item>
    </Descriptions>
  )
}
