import { Input, InputNumber, Tooltip } from 'antd'

type TooltipProps = {
  title: string
  placement?: any
  onChange?: any
  placeholder?: string
  trigger?: any
  value?: any
  style?: any
}
export default function InputTooltip({
  title,
  onChange,
  placeholder,
  trigger,
  placement,
  value,
  style,
}: TooltipProps) {
  return (
    <Tooltip trigger={trigger} title={title} placement={placement}>
      <InputNumber
        onChange={onChange}
        placeholder={placeholder}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value: any) => (value ? value.replace(/[^0-9]/g, '') : '')}
        value={value}
        style={style ? { width: '100%', ...style } : { width: '100%' }}
      />
    </Tooltip>
  )
}
