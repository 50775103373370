import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Cards } from './components/Cards'
import { Charts } from './components/Charts'
import { Header } from './components/Header'
import { getDashBoardPetrolStore } from './DashBoardApi'

type Params = {
  from_date?: string
  to_date?: string
}
const DashBoardScreen = () => {
  const [loading, setLoading] = useState(false)
  const [dashBoard, setDashBoard] = useState()
  const [params, setParams] = useState<Params>({
    from_date: undefined,
    to_date: undefined,
  })
  const userState = useSelector((state: any) => state.authReducer)?.userInfo
  const getDataDashBoard = async () => {
    try {
      setLoading(true)
      const res = await getDashBoardPetrolStore(params)
      setDashBoard(res)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
    }
  }
  useLayoutEffect(() => {
    getDataDashBoard()
  }, [params])

  return (
    <div>
      {userState?.role_ids[0] === 23 || userState?.role_ids[0] === 28 ? (
        <div>
          <Header setParams={setParams} />
          <Cards data={dashBoard} />
          <Charts params={params} datas={dashBoard} />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default DashBoardScreen
