import {
  CloseCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Modal, Row, Space, Upload } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styles from 'common/components/styles/WhiteBox.module.css'
import { notificationError } from 'utils/notification'
import { uploadImageToServer } from 'utils/uploadImageToServer'
import { useParams } from 'react-router'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { useAccount } from '../hook/useAccount'
import {
  ACCOUNT_R0LE,
  WEB_PETROL_ROLES,
  WEB_PETROL_ROLES_ADMIN,
} from 'utils/constants'
import { useSelector } from 'react-redux'

type Props = {
  visible: boolean
  onCancel?: any
  data?: any
  onCreateNewAccount?: any
  onUpdateAccount?: any
  isLoading: boolean
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    notificationError('Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!')
  }
  const isLt2M = file.size / 1024 / 1024 < 3
  if (!isLt2M) {
    notificationError('Cho phép ảnh có dung lượng tối đa là 3MB')
  }
  return isJpgOrPng && isLt2M
}

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      name: null,
      phone_number: null,
      password: null,
      email: null,
      role_ids: undefined,
      avatar: null,
    }
  } else {
    return {
      ...data,
      email: data.email,
      avatar: data.avatar,
      name: data.name,
      role_ids: data.role_ids,
    }
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export const AddEditAccount = ({
  visible,
  onCancel,
  data,
  onCreateNewAccount,
  onUpdateAccount,
}: Props) => {
  const userState = useSelector((state: any) => state.authReducer)?.userInfo

  const { isLoading, setIsLoading } = useAccount()
  type imagesUpload = {
    loading: boolean
    imageUrl: any
  }
  const [upload, setUpload] = useState<imagesUpload>({
    loading: false,
    imageUrl: '',
  })

  const [form] = Form.useForm()

  const initialValues = convertDataToFrom(data)
  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setUpload({
          imageUrl: imageUrl,
          loading: false,
        })
      )
    }
  }
  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const onFinish = async (values: any, onCancel: any) => {
    setIsLoading(true)
    try {
      //Upload image lên Server
      if (values.avatar?.fileList && values.avatar) {
        var resUploadImage: any
        var last_element = []

        if (values.avatar.fileList.length > 1) {
          last_element = [values.avatar.fileList.slice(-1)[0]]
        } else {
          last_element = values.avatar.fileList
        }
        resUploadImage = await uploadImageToServer(last_element)
      }

      delete values.confirm
      let newData
      if (!data) {
        newData = {
          ...values,
          name: values.name.trim(),
          email:
            values?.email?.trim() === '' ? undefined : values?.email?.trim(),
          avatar: resUploadImage?.location,
        }
        onCreateNewAccount(newData, form.resetFields, setUpload)
      } else {
        //check anh thay doi
        if (upload.imageUrl === data.avatar) {
          newData = {
            name: values.name.trim(),
            email: values?.email?.trim() === '' ? null : values?.email?.trim(),
            id: data.id,
            role_ids: values.role_ids,
          }
        } else {
          if (upload.imageUrl === '') {
            newData = {
              name: values.name.trim(),
              email:
                values?.email?.trim() === '' ? null : values?.email?.trim(),
              id: data.id,
              avatar: '',
              role_ids: values.role_ids,
            }
          } else {
            newData = {
              name: values.name.trim(),
              email:
                values?.email?.trim() === '' ? null : values?.email?.trim(),
              id: data.id,
              avatar: resUploadImage?.location,
              role_ids: values.role_ids,
            }
          }
        }
        onUpdateAccount(newData, form.resetFields)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (data) {
      setUpload({
        ...upload,
        imageUrl: data.avatar,
      })
    }
  }, [data])
  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      maskClosable={false}
      footer={null}
      title={data ? 'Cập nhật' : 'Tạo tài khoản'}
      visible={visible}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        labelAlign="left"
        onFinish={(values: any) => onFinish(values, onCancel)}
        initialValues={initialValues}
        scrollToFirstError
      >
        {upload.imageUrl === '' || upload.imageUrl === null ? (
          <div style={{ height: 38 }}></div>
        ) : (
          <Button
            style={{
              paddingLeft: 270,
            }}
            type="text"
            icon={<CloseCircleOutlined />}
            onClick={() => {
              setUpload({
                imageUrl: '',
                loading: false,
              })
            }}
          />
        )}
        <Form.Item label="Ảnh đại diện" name="avatar">
          <Upload
            name="avatar_url"
            listType="picture-card"
            className="avatar_url-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {upload.imageUrl ? (
              <img
                src={upload.imageUrl}
                alt="avatar_url"
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  height: '100%',
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="name"
          label="Họ tên"
          rules={[
            {
              type: 'string',
              message: 'Nhập tài khoản',
            },
            {
              required: true,
              message: 'Vui lòng nhập tài khoản!',
            },
            {
              whitespace: true,
              message: 'Vui lòng nhập không nhập khoảng trắng trước Họ tên!',
            },
            {
              min: 6,
              max: 55,
              message: 'Vui lòng nhập từ 6 đến 55 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập tài khoản" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Số điện thoại"
          rules={[
            {
              // type: 'number',
              message: 'Nhập số điện thoại',
            },
            {
              required: true,
              message: 'Vui lòng nhập số điện thoại!',
            },
            {
              min: 10,
              max: 10,
              message: 'Vui lòng nhập 10 số!',
            },
          ]}
        >
          <Input
            style={{
              width: 315,
              borderRadius: 5,
            }}
            disabled={data}
            placeholder="Nhập số điện thoại"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'Định dạng email không hợp lệ!',
            },
            {
              min: 6,
              max: 100,
              message: 'Vui lòng nhập từ 6 đến 100 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>
        <Form.Item
          label="Loại tài khoản"
          name="role_ids"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn loại tài khoản!',
            },
          ]}
        >
          <RadiusSelection
            onChange={(value: number) => {}}
            options={
              userState?.role_ids[0] == ACCOUNT_R0LE[2]?.id
                ? WEB_PETROL_ROLES
                : WEB_PETROL_ROLES_ADMIN
            }
            placeholder="Chọn loại tài khoản"
            // defaultValue={data?.role_ids}
          />
        </Form.Item>

        {!data && (
          <Form.Item
            name="password"
            label="Mật khẩu"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
              {
                min: 6,
                max: 55,
                message: 'Vui lòng nhập từ 6 đến 55 ký tự!',
              },
            ]}
            hasFeedback
          >
            <Input.Password
              className={styles.input}
              placeholder="Nhập mật khẩu"
            />
          </Form.Item>
        )}
        {!data && (
          <Form.Item
            name="confirm"
            label="Xác nhận mật khẩu"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Nhập lại mật khẩu!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Mật khẩu không khớp!'))
                },
              }),
            ]}
          >
            <Input.Password
              className={styles.input}
              placeholder="Nhập lại mật khẩu"
            />
          </Form.Item>
        )}

        <Form.Item {...tailFormItemLayout}>
          <ButtonBottomModal
            isLoadingButton={isLoading}
            onCancel={() => {
              onCancel()
              form.resetFields()
            }}
            text={data ? 'Cập nhật' : 'Tạo tài khoản'}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
