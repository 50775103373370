import { ApiClient } from 'services/ApiService'

export const getListNotify = (payload: any) =>
  ApiClient.get(`/admin/notification`, payload)
export const deleteNotify = (payload: any) =>
  ApiClient.delete(`admin/notification/${payload.id}`)
export const creatNotify = (payload: any) =>
  ApiClient.post(`admin/notification`, payload)
export const updateNotify = (payload: any) =>
  ApiClient.put(`admin/notification/${payload.id}`, payload.data)
