import { Descriptions, Divider, Table, Tag } from 'antd'
import moment from 'moment'
import styles from 'common/components/styles/WhiteBox.module.css'
import { formatPrice } from 'utils/ruleForm'

const column = [
  {
    width: '70px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Tên người nhận hàng</b>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: <b>Tỉnh</b>,
    dataIndex: 'province_name',
    key: 'province_name',
  },
  {
    title: <b>Quận/ Huyện</b>,
    dataIndex: 'district_name',
    key: 'district_name',
  },
  {
    title: <b>Phường/ Xã</b>,
    dataIndex: 'ward_name',
    key: 'ward_name',
  },
  {
    title: <b>Địa chỉ chi tiết</b>,
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: <b>Ngày tạo</b>,
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]
export default function InforUser({ dataSource }: any) {
  return (
    <div>
      <div>
        <Descriptions
          column={2}
          title={<b>Thông tin cá nhân</b>}
          style={{ backgroundColor: 'white' }}
        >
          <Descriptions.Item
            label={<p className={styles.description_label}>Họ tên</p>}
          >
            <p>
              {dataSource.dataInfor?.name ? dataSource.dataInfor?.name : '---'}
            </p>
          </Descriptions.Item>
          <Descriptions.Item
            label={<p className={styles.description_label}>Hạng tích lũy</p>}
          >
            <p style={{ color: 'orange' }}>{dataSource.dataInfor?.rank}</p>
          </Descriptions.Item>
          <Descriptions.Item
            label={<p className={styles.description_label}>Loại khách hàng</p>}
          >
            <p
              style={{
                color: 'red',
              }}
            >
              {dataSource.dataInfor?.enterprise?.enterprise_name
                ? 'Doanh nghiệp'
                : 'Cá nhân'}
            </p>
          </Descriptions.Item>
          <Descriptions.Item
            label={<p className={styles.description_label}>Điểm tích lũy</p>}
          >
            <p style={{ color: 'orange' }}>
              {dataSource.dataInfor?.point !== 0
                ? formatPrice(dataSource.dataInfor?.point)
                : '0'}{' '}
              điểm
            </p>
          </Descriptions.Item>
          <Descriptions.Item
            label={<p className={styles.description_label}>Số điện thoại</p>}
          >
            <p>{dataSource.dataInfor.phone}</p>
          </Descriptions.Item>
          <Descriptions.Item
            label={<p className={styles.description_label}>Hạn mức nợ công</p>}
          >
            <p style={{ color: 'red' }}>
              {dataSource?.dataInfor?.debt_limit
                ? formatPrice(dataSource?.dataInfor?.debt_limit)
                : '---'}
            </p>
          </Descriptions.Item>
          <Descriptions.Item
            label={<p className={styles.description_label}>Email</p>}
          >
            <p>{dataSource.dataInfor.email}</p>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <p className={styles.description_label}>
                Thời gian công nợ đơn hàng
              </p>
            }
          >
            <p style={{ color: 'red' }}>
              {dataSource?.dataInfor?.debt_period
                ? dataSource?.dataInfor?.debt_period + ' ngày'
                : '---'}
            </p>
          </Descriptions.Item>

          <Descriptions.Item
            label={<p className={styles.description_label}>Ngày sinh</p>}
          >
            <p>
              {dataSource.dataInfor?.date_of_birth
                ? moment(dataSource.dataInfor?.date_of_birth).format(
                    'DD/MM/YYYY'
                  )
                : '---'}
            </p>
          </Descriptions.Item>
          <Descriptions.Item
            label={<p className={styles.description_label}>Trạng thái</p>}
          >
            <p>
              {dataSource.dataInfor.status ? (
                <Tag color="green">Đang hoạt động</Tag>
              ) : (
                <Tag color="volcano">Ngưng hoạt động</Tag>
              )}
            </p>
          </Descriptions.Item>
        </Descriptions>
        {dataSource.dataInfor?.enterprise?.enterprise_name ? (
          <>
            <Divider style={{ height: '2px', backgroundColor: 'lightgray' }} />
            <Descriptions
              column={2}
              title={<b>Thông tin doanh nghiệp</b>}
              style={{ backgroundColor: 'white' }}
            >
              <Descriptions.Item
                label={<p className={styles.description_label}>Tên công ty</p>}
              >
                <p>
                  {dataSource.dataInfor?.enterprise?.enterprise_name
                    ? dataSource.dataInfor?.enterprise?.enterprise_name
                    : '---'}
                </p>
              </Descriptions.Item>
              <Descriptions.Item
                label={<p className={styles.description_label}>Mã số thuế</p>}
              >
                <p>
                  {dataSource.dataInfor?.enterprise?.enterprise_tax
                    ? dataSource.dataInfor?.enterprise?.enterprise_tax
                    : '---'}
                </p>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <p className={styles.description_label}>Địa chỉ mã số thuế</p>
                }
              >
                <p>
                  {dataSource.dataInfor?.enterprise?.enterprise_address_tax
                    ? dataSource.dataInfor?.enterprise?.enterprise_address_tax
                    : '---'}
                </p>
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : (
          <></>
        )}
      </div>
      <Divider style={{ height: '2px', backgroundColor: 'lightgray' }} />
      <div>
        <Table
          title={() => <b style={{ fontSize: 16 }}>Thông tin nhận hàng</b>}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            y: 'calc(100vh - 430px)',
          }}
          dataSource={dataSource.dataTable}
          columns={column}
          bordered
        />
      </div>
    </div>
  )
}
