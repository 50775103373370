import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Image,
  Modal,
  Popconfirm,
  Row,
  Tabs,
} from 'antd'
import DocxViewer from 'common/components/DocxViewer'
import styles from 'common/components/styles/WhiteBox.module.css'
import { uniqueId } from 'lodash'
import moment from 'moment'
import React from 'react'
import { IDocument } from 'react-doc-viewer'
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon'
import { isImage } from 'utils'
type Props = {
  data: any
  getData: any
  onAccept: (id: any) => void
  onOpenModal: () => void
}

const { TabPane } = Tabs

export const RenderViewerFile = ({ item }: { item: any }) => {
  const [docLink, setDocLink] = React.useState<IDocument[]>([])
  const [visibleViewDoc, setVisibleViewDoc] = React.useState(false)

  return (
    <>
      <div style={{ flex: 1 }}>
        <Row
          style={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: '10px',
            padding: '20px',
          }}
          justify="center"
        >
          <div
            onClick={() => {
              window.open(item.media_url, '_blank')
            }}
            style={{ width: '60px', marginRight: '20px' }}
          >
            <FileIcon
              extension={item.media_url.slice(
                item.media_url.lastIndexOf('.') + 1
              )}
              {...defaultStyles[
                item.media_url.slice(
                  item.media_url.lastIndexOf('.') + 1
                ) as DefaultExtensionType
              ]}
            />
          </div>
        </Row>
      </div>
      <Modal
        centered
        bodyStyle={{ height: '98vh', padding: '0 10px 0' }}
        width="100vw"
        visible={visibleViewDoc}
        onCancel={() => {
          setVisibleViewDoc(false)
        }}
        destroyOnClose
        footer={null}
        closable={false}
      >
        <DocxViewer url={docLink} />

        {/* <Row justify="center">
          <ButtonActionDocxViewer
            docLink={docLink}
            setVisibleViewDoc={setVisibleViewDoc}
          />
        </Row> */}
      </Modal>
    </>
  )
}

export const RenderChatImage = ({ item }: { item: any }) => {
  return (
    <div>
      <div>
        {item.media_url.split(',').length > 1 ? (
          <Row>
            {item.media_url.split(',').map((image: string, index: number) => (
              <div
                key={uniqueId()}
                style={{
                  margin: '4px',
                  padding: '4px',
                  border: '2px dashed #666',
                  maxWidth: '160px',
                  borderRadius: '10px',
                }}
              >
                <Image
                  key={index}
                  style={{ borderRadius: '10px' }}
                  height={100}
                  src={image}
                />
              </div>
            ))}
          </Row>
        ) : (
          <Image
            style={{
              borderRadius: '10px',
              maxHeight: '340px',
              height: '100%',
              width: '100%',
            }}
            src={item.media_url.split(',')[0]}
          />
        )}
      </div>
    </div>
  )
}

const ContentView = (data: any) => {
  return (
    <>
      <Descriptions
        size="default"
        column={2}
        title={
          <p style={{ textDecorationLine: 'underline' }}>
            Thông tin khách hàng
          </p>
        }
      >
        <Descriptions.Item label="Tên khách hàng">
          {data.nameCUstomer}
        </Descriptions.Item>
        <Descriptions.Item label="Số điện thoại">
          {data.phoneCUstomer}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {data.emailCUstomer}
        </Descriptions.Item>
        <Descriptions.Item label="Thời gian gửi">
          {moment(data.create_at).format('HH:mm DD/MM/YYYY')}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions
        size="default"
        column={2}
        title={
          <p style={{ textDecorationLine: 'underline' }}>Thông tin cửa hàng</p>
        }
      >
        <Descriptions.Item label="Tên cửa hàng">{data.name}</Descriptions.Item>
        <Descriptions.Item label=" Mã số thuế">{data.tax}</Descriptions.Item>
        <Descriptions.Item label="Số điện thoại">
          {data.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Tên người đại diện">
          {data.representative}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
        <Descriptions.Item
          label="Địa chỉ chi tiết"
          style={{ paddingRight: 50 }}
        >
          {data?.location_address}{' '}
          {data?.ward?.name ? ', ' + data?.ward?.name : ''}{' '}
          {data?.district?.name ? ', ' + data?.district?.name : ''}{' '}
          {data?.province?.name ? ', ' + data?.province?.name : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Địa chỉ google maps">
          {data?.address}
        </Descriptions.Item>
        <Descriptions.Item label="Tên công ty">
          {data?.company_name}
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={[24, 0]}>
        {data?.partner_medias?.map((partner: any) => {
          if (isImage(partner?.media_url)) {
            return (
              <Col span={4}>
                <RenderChatImage key={partner.id} item={partner} />
              </Col>
            )
          }
          return (
            <Col span={4}>
              <RenderViewerFile key={partner.id} item={partner} />
            </Col>
          )
        })}
      </Row>
    </>
  )
}

function PartnerDetailScreen({ data, getData, onAccept, onOpenModal }: Props) {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Chi tiết đăng ký" key="1">
          <Card
            className={styles.expand}
            actions={[
              <Popconfirm
                title="Đồng ý phản hồi yêu cầu của người liên hệ ?"
                disabled={data.approve_status !== 2 ? true : false}
                onConfirm={async () => {
                  try {
                    onAccept(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <CheckCircleOutlined /> Phê duyệt
                  </>
                }
                cancelText={
                  <>
                    <LeftCircleOutlined /> Đóng
                  </>
                }
                cancelButtonProps={{
                  type: 'default',
                  style: {
                    // color: '#1890ff',
                    borderColor: 'black',
                    fontWeight: 'bold',
                    borderRadius: '3px',
                  },
                }}
                okButtonProps={{
                  type: 'default',
                  style: {
                    color: '#1890ff',
                    borderColor: '#1890ff',
                    fontWeight: 'bold',
                    borderRadius: '3px',
                  },
                }}
              >
                <Button
                  type="text"
                  size="large"
                  style={
                    data.approve_status !== 2
                      ? {
                          color: '#1890ff',
                          fontWeight: 'bold',
                          display: 'none',
                        }
                      : {
                          color: '#1890ff',
                          fontWeight: 'bold',
                        }
                  }
                  icon={<CheckCircleOutlined />}
                  disabled={data.approve_status !== 2 ? true : false}
                >
                  Phê duyệt
                </Button>
              </Popconfirm>,

              <Button
                type="text"
                size="large"
                icon={<CloseCircleOutlined />}
                style={
                  data.approve_status !== 2
                    ? {
                        color: 'tomato',
                        fontWeight: 'bold',
                        display: 'none',
                      }
                    : {
                        color: 'tomato',
                        fontWeight: 'bold',
                      }
                }
                disabled={data.approve_status !== 2 ? true : false}
                onClick={onOpenModal}
              >
                Từ chối
              </Button>,
            ]}
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default PartnerDetailScreen
