import { ADMIN_ROUTER_PATH, ENTERPRISE_ROUTER_PATH } from 'common/config'
import React from 'react'
import { AccountsScreen } from 'features/admin/account'
import { ConfigScreen } from 'features/admin/config'
import {
  CustomerScreen,
  CustomerDetailScreen,
} from 'features/admin/customer/customers'
import { EnterpriseScreen } from 'features/admin/customer/enterprise'
import { DashBoardScreen } from 'features/admin/dashboard'
import { OrderScreen, OrderDetailScreen } from 'features/admin/order/orders'
import {
  InsuranceScreen,
  InsuranceDetailScreen,
} from 'features/admin/order/insurance'
import { NewsScreen, AddNewsScreen } from 'features/admin/news'
import {
  ProductScreen,
  AddEditProductScreen,
  ProductDetailScreen,
} from 'features/admin/product/product'
import { CategoryProductScreen } from 'features/admin/product/category'
import {
  SaleProductScreen,
  AddEditSaleProductScreen,
  SaleProductDetailScreen,
} from 'features/admin/product/sale'
import { ShopReportScreen } from 'features/admin/report/shop'

import { SaleReportScreen, DetailSaleReport } from 'features/admin/report/sale'
import {
  DebtCustomerScreen,
  DetailDebtCustomerScreen,
} from 'features/admin/debt/customers'
import {
  DebtShopsScreen,
  DetailDebtShopScreen,
} from 'features/admin/debt/shops'
import { RequestScreen } from 'features/admin/request'
import { ShopScreen, ShopDetailScreen } from 'features/admin/shop/shops'
import { PartnerScreen } from 'features/admin/shop/partner'
import { NotificationScreen } from 'features/admin/notification'

import { EAccountsScreen } from 'features/enterprise/account'
import {
  EProductScreen,
  EProductDetailScreen,
} from 'features/enterprise/product/products'
import {
  ESaleProductScreen,
  ESaleProductDetailScreen,
} from 'features/enterprise/product/sale'
import { EDashBoardScreen } from 'features/enterprise/dashboard'
import {
  ECustomerScreen,
  ECustomerDetailScreen,
} from 'features/enterprise/customer/customers'
import { EEnterpriseScreen } from 'features/enterprise/customer/enterprise'
import { EOrderScreen, EOrderDetailScreen } from 'features/enterprise/order'
import { ECategoryScreen } from 'features/enterprise/product/category'
import { EShopReportScreen } from 'features/enterprise/report/shop'
import {
  EDebtReportScreen,
  EDebtReportDetailScreen,
} from 'features/enterprise/report/debt'
import {
  ESaleReportScreen,
  EDetailReportScreen,
} from 'features/enterprise/report/sale'
import PrintOrder from 'features/admin/order/orders/components/PrintOrder'
import PointReport from 'features/admin/report/points/PointReport'
import DetailPointReport from 'features/admin/report/points/DetailPointReport'
import InventoryList from 'features/admin/inventory/InventoryList'
import InventoryDetail from 'features/admin/inventory/InventoryDetail'
import ChangeWarehouse from 'features/admin/inventory/components/InventoryDetail/ChangeWarehouse'
import UpdateInventory from 'features/admin/inventory/components/InventoryDetail/UpdateInventory'
import HistoryInventory from 'features/admin/inventory/components/InventoryDetail/HistoryInventory'
import UpdateSurviveHead from 'features/admin/inventory/components/InventoryDetail/Update.Survive.Head'
import HistoryInventoryDetail from 'features/admin/inventory/components/InventoryDetail/HistoryInventoryDetail'
interface RouterProps {
  path: string
  component: React.FC | React.Component | any
  param?: any
  exact: boolean
}

const adminRouter: Array<RouterProps> = [
  {
    path: ADMIN_ROUTER_PATH.DASH_BOARD,
    component: DashBoardScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.SHOP,
    component: ShopScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.BECOME_PARTNER,
    component: PartnerScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.SHOP_DETAIL + '/:id',
    component: ShopDetailScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.INVENTORY,
    component: InventoryList,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.INVENTORY_DETAIL + '/:id',
    component: InventoryDetail,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.CHANGE_WAREHOUSE + '/:id',
    component: ChangeWarehouse,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.UPDATE_INVENTORY + '/:id',
    component: UpdateInventory,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.UPDATE_SURVIVE_HEAD + '/:id',
    component: UpdateSurviveHead,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.HISTORY_INVENTORY + '/:id',
    component: HistoryInventory,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.HISTORY_INVENTORY_DETAIL + '/:id',
    component: HistoryInventoryDetail,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.PRODUCT,
    component: ProductScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.EDIT_PRODUCT + '/:id',
    component: AddEditProductScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ADD_PRODUCT,
    component: AddEditProductScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.PRODUCT_DETAIL + '/:id',
    component: ProductDetailScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.CATEGORY_PRODUCT,
    component: CategoryProductScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.EDIT_SALE_PRODUCT + '/:id',
    component: AddEditSaleProductScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ADD_SALE_PRODUCT,
    component: AddEditSaleProductScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.SALE_PRODUCT,
    component: SaleProductScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.SALE_PRODUCT_DETAIL + '/:id',
    component: SaleProductDetailScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.CUSTOMER,
    component: CustomerScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.CUSTOMER_DETAIL + '/:id',
    component: CustomerDetailScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ENTERPRISE_CUSTOMER,
    component: EnterpriseScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ORDER,
    component: OrderScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ORDER_PRINT,
    component: PrintOrder,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ORDER_DETAIL + '/:id',
    component: OrderDetailScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ORDER_INSURANCE,
    component: InsuranceScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ORDER_DETAIL_INSURANCE + '/:id',
    component: InsuranceDetailScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.DEBT_CUSTOMERS,
    component: DebtCustomerScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.DEBT_CUSTOMERS_DETAIL + '/:id',
    component: DetailDebtCustomerScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.DEBT_SHOPS,
    component: DebtShopsScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.DEBT_SHOPS_DETAIL + '/:id',
    component: DetailDebtShopScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.REQUEST,
    component: RequestScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.NEWS,
    component: NewsScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ADD_NEWS,
    component: AddNewsScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.EDIT_NEWS + '/:id',
    component: AddNewsScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.SALE_REPORT,
    component: SaleReportScreen,
    exact: true,
  },
  {
    path:
      ADMIN_ROUTER_PATH.SALE_REPORT_DETAIL +
      '/:petrol_store_id' +
      '/:product_id',
    component: DetailSaleReport,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.POINT_REPORT,
    component: PointReport,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.POINT_REPORT_DETAIL + '/:id',
    component: DetailPointReport,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.SHOP_REPORT,
    component: ShopReportScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.CONFIG,
    component: ConfigScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.NOTIFICATION,
    component: NotificationScreen,
    exact: true,
  },
  {
    path: ADMIN_ROUTER_PATH.ACCOUNTS,
    component: AccountsScreen,
    exact: true,
  },
]

const enterpriseRouter: Array<RouterProps> = [
  {
    path: ENTERPRISE_ROUTER_PATH.DASH_BOARD,
    component: EDashBoardScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.PRODUCT,
    component: EProductScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.PRODUCT_DETAIL + '/:id',
    component: EProductDetailScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.CUSTOMER,
    component: ECustomerScreen,
    exact: true,
  },
  // {
  //   path: ENTERPRISE_ROUTER_PATH.ENTERPRISE_CUSTOMER,
  //   component: EEnterpriseScreen,
  //   exact: true,
  // },
  {
    path: ENTERPRISE_ROUTER_PATH.CUSTOMER_DETAIL + '/:id',
    component: ECustomerDetailScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.ORDER,
    component: EOrderScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.ORDER_DETAIL + '/:id',
    component: EOrderDetailScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.ACCOUNTS,
    component: EAccountsScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.CATEGORY_PRODUCT,
    component: ECategoryScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.SALE_PRODUCT,
    component: ESaleProductScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.SALE_PRODUCT_DETAIL + '/:id',
    component: ESaleProductDetailScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.DEBT_REPORT,
    component: EDebtReportScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.DEBT_REPORT_DETAIL + '/:id',
    component: EDebtReportDetailScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.SALE_REPORT,
    component: ESaleReportScreen,
    exact: true,
  },
  {
    path:
      ENTERPRISE_ROUTER_PATH.SALE_REPORT_DETAIL +
      '/:petrol_store_id' +
      '/:product_id',
    component: EDetailReportScreen,
    exact: true,
  },
  {
    path: ENTERPRISE_ROUTER_PATH.SHOP_REPORT,
    component: EShopReportScreen,
    exact: true,
  },
]

export default adminRouter.concat(enterpriseRouter)
