import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Modal, Divider, Table, Empty, Button, Row } from 'antd'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import { useAddEditProduct } from '../hook/useAddEditProduct'

type Props = {
  visible: boolean
  onCancel: () => void
  onSave: (listStoreSelected: Array<any>) => void
  paramsChooseShop: any
  paging: any
  listPetrolStore: any
  setParamsChooseShop: any
  storesSelected: any
}

const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    width: 70,
  },
  {
    title: 'Tên cửa hàng',
    dataIndex: 'name',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
  },
  {
    title: 'Mã số thuế',
    dataIndex: 'tax',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'location_address',
    render: (data: any, value: any) => {
      return (
        <div>
          {(data ? data + ',' : '') +
            (value?.ward?.name ? value?.ward?.name + ',' : '') +
            (value?.district?.name ? value?.district?.name + ',' : '') +
            (value?.province?.name || '-')}
        </div>
      )
    },
  },
]

export default function ChooseShopModal({
  visible,
  onCancel,
  onSave,
  paramsChooseShop,
  paging,
  listPetrolStore,
  setParamsChooseShop,
  storesSelected,
}: Props) {
  const { isLoading, rowSelection, storesChecked } = useAddEditProduct()
  return (
    <Modal
      title={<b>Thêm cửa hàng xăng dầu</b>}
      visible={visible}
      maskClosable={false}
      footer={null}
      width={800}
      onCancel={() => {
        onCancel()
      }}
      destroyOnClose
    >
      <TypingAutoSearch
        onSearchSubmit={(searchKey: string) => {
          setTimeout(() => {
            setParamsChooseShop({
              ...paramsChooseShop,
              search: searchKey === '' ? undefined : searchKey.trim(),
              page: 1,
            })
          }, 300)
        }}
        isSearchLoading={isLoading.loadingSearch}
        placeholder="Tên công ty, số điện thoại, mã số thuế"
      />
      <Divider
        dashed
        style={{ backgroundColor: '#BFBFBF', margin: '15px 0px' }}
      />
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 'calc(100vh - 300px)',
        }}
        bordered
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={listPetrolStore}
        loading={isLoading.loadingTableStore}
        columns={columns}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu.</span>}
            />
          ),
        }}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            setParamsChooseShop({ ...paramsChooseShop, page })
          },
        }}
      />
      <Row gutter={[16, 16]} justify="end">
        <Button
          danger
          type="primary"
          style={{ marginRight: '10px' }}
          icon={<CloseCircleOutlined />}
          onClick={() => {
            onCancel()
          }}
        >
          Đóng
        </Button>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          onClick={() => {
            onSave(storesSelected.concat(storesChecked))
          }}
        >
          Lưu
        </Button>
      </Row>
    </Modal>
  )
}
