import { message, Table, Tag } from 'antd'
import { Filter } from './components/Filter'
import { Header } from './components/Header'
import DetailAccount from './components/DetailAccount'
import { useAccount } from './hook/useAccount'
import Container from 'common/container/Container'
import { AddEditAccount } from './components/AddEditAccount'
import { changeStatusAccount, deleteAccount, resetPassword } from './AccountApi'
import styles from 'common/components/styles/WhiteBox.module.css'
import { notificationSuccess } from 'utils/notification'

const columns = [
  {
    width: 70,
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Họ tên</b>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: <b>Email</b>,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <b>Loại tài khoản</b>,
    dataIndex: 'role_names',
    key: 'role_names',
    render: (value: Array<string>) => <td>{value.join(', ')}</td>,
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    key: 'status',
    render: (value: any) => (
      <Tag color={value === 1 ? 'green' : 'volcano'}>
        {value === 1 ? 'HOẠT ĐỘNG' : 'TẠM DỪNG'}
      </Tag>
    ),
  },
]

const AccountsScreen = () => {
  const {
    isLoading,
    currentSelected,
    params,
    paging,
    listAccount,
    setCurrentSelected,
    setParams,
    getData,
    showAddAccount,
    setShowAddAccount,
    createAcc,
  } = useAccount()

  return (
    <Container
      header={<Header setIsCreate={setShowAddAccount} />}
      filterComponent={
        <Filter
          data={params}
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search:
                searchKey.trim() === '' ? undefined : searchKey.trimStart(),
              page: 1,
            })
          }}
          onStatusSubmit={(statusKey: string) => {
            setParams({ ...params, status: statusKey, page: 1 })
          }}
          onRoleSubmit={(roleKey: string) => {
            setParams({ ...params, role_id: roleKey, page: 1 })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            bordered
            dataSource={listAccount}
            loading={isLoading}
            columns={columns}
            expandedRowKeys={[currentSelected.id]}
            onRow={(row: any) => ({
              onClick: () => {
                if (currentSelected !== row) setCurrentSelected(row)
                else setCurrentSelected({ id: -1 })
              },
            })}
            expandable={{
              expandedRowRender: (record: any) => (
                <DetailAccount
                  onDeleteAccount={async (id: any) => {
                    try {
                      const res = await deleteAccount({ id: [id] })
                      notificationSuccess(res.message)
                      getData()
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                  onResetPassword={async (id: any) => {
                    try {
                      const res = await resetPassword({ id: [id] })
                      notificationSuccess(res.message)
                      getData()
                    } catch (error) {
                      // message.error(error)
                      console.log(error)
                    }
                  }}
                  onChangeStatus={async (id: any) => {
                    try {
                      const res = await changeStatusAccount({ id: [id] })
                      notificationSuccess(res.message)
                      getData()
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                  data={record}
                  getData={getData}
                />
              ),
            }}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
          {showAddAccount && (
            <AddEditAccount
              visible={showAddAccount}
              isLoading={isLoading}
              onCancel={() => {
                setShowAddAccount(false)
              }}
              onCreateNewAccount={createAcc}
            />
          )}
        </div>
      }
    />
  )
}

export default AccountsScreen
