import { Col, PageHeader, Row } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'

type HeaderProps = {
  setIsCreate: any
}

export const Header = ({ setIsCreate }: HeaderProps) => {
  return (
    <>
      <PageHeader
        title="Tài khoản "
        style={{ backgroundColor: 'white', margin: '5px 10px 10px' }}
        extra={[
          <Row gutter={[16, 16]}>
            <Col span={3}>
              <ButtonAdd
                text="Tạo tài khoản"
                onClickButton={() => setIsCreate(true)}
              />
            </Col>
          </Row>,
        ]}
      />
    </>
  )
}
