import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { ParamsType } from '../interface'
import {
  getDistrict,
  getListPetrolStore,
  getProvince,
  getWard,
} from '../ShopApi'

export const useShop = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const history = useHistory()
  const location: any = useLocation()
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const [idProvinces, setIdProvinces] = useState()
  const [idDistrict, setIdDistrict] = useState()
  const [dataSource, setDataSource] = useState<Array<any>>([])

  const [params, setParams] = useState<ParamsType>({
    search: undefined,
    status: undefined,
    type_petrol_store_id: undefined,
    page: 1,
    array_petrol_store_cancle: [],
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 24,
  })
  useEffect(() => {
    if (location.state) {
      setParams({
        ...params,
        search: location.state.search,
        status: location.state.status,
        type_petrol_store_id: location.state.type_petrol_store_id,
        page: location.state.page,
      })
    }
  }, [])
  useEffect(() => {
    let run = setTimeout(() => {
      getData()
    }, 300)
    return () => clearTimeout(run)
  }, [params])

  const CreateShop = () => {
    setIsModalVisible(true)
  }
  const getData = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getListPetrolStore(params)
      const tableData = res.data.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          type_petrol_store_name: item.type_petrol_store.name,
        }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setDataSource(tableData)
    } catch (error) {
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  return {
    history,
    isModalVisible,
    dataSource,
    params,
    isLoading,
    paging,
    setParams,
    setIsModalVisible,
    CreateShop,
    setDataSource,
    getData,
  }
}
