import Icon from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import { useState } from 'react'
import { IS_ACTIVE } from 'utils/constants'
import { useDetailShop } from '../hook/useDetailShop'

type Props = {
  onSearchSubmit: (search: string) => void
  onCategorySubmit: (category_id: number) => void
  onStatusSubmit: (status: number) => void
}

export default function FilterProductTab({
  onSearchSubmit,
  onCategorySubmit,
  onStatusSubmit,
}: Props) {
  const { listCategory } = useDetailShop()
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              onSearchSubmit(searchKey.trim())
            }}
            isSearchLoading={isSearchLoading}
            placeholder="Tên hoặc mã sản phẩm"
          />
        </Col>
        <Col span={6}>
          <RadiusSelection
            placeholder="DM sản phẩm"
            onChange={(value: number) => {
              onCategorySubmit(value)
            }}
            options={listCategory}
          />
        </Col>
        <Col span={6}>
          <RadiusSelection
            placeholder="Trạng thái"
            onChange={(value: number) => {
              onStatusSubmit(value)
            }}
            options={[
              { value: IS_ACTIVE.ACTIVE, text: 'Hoạt động' },
              { value: IS_ACTIVE.INACTIVE, text: 'Ngừng hoạt động' },
            ]}
          />
        </Col>
      </Row>
    </div>
  )
}
