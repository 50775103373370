import { useEffect, useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import {
  createCategory,
  getListCategory,
  updateCategory,
} from '../CategoryProductApi'

type Params = {
  search: undefined | string
  page: number
  status: undefined | number
  from_date: undefined | string
  to_date: undefined | string
  limit: number
}
export const useCategoryProduct = () => {
  const [category, setCategory] = useState<any>([])
  const [isLoading, setIsLoading] = useState({
    loadingTable: false,
    loadingSearch: false,
    loadingAddEdit: false,
  })
  const [showAddCategory, setShowAddCategory] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [params, setParams] = useState<Params>({
    search: undefined,
    page: 1,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    limit: 12,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })

  const [showEditCategory, setShowEditCategory] = useState(false)

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setIsLoading({ ...isLoading, loadingTable: true })

    try {
      const res = await getListCategory(params)
      const tableData = res.data.map((item: any) => {
        return { ...item, key: item.id }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setCategory(tableData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false, loadingSearch: false })
    }
  }

  const createCategorys = async (
    data: any,
    resetFields: any,
    setUpload: any
  ) => {
    try {
      setIsLoading({ ...isLoading, loadingAddEdit: true })
      const res = await createCategory(data)
      if (res.code === 201) {
        setShowAddCategory(false)
        resetFields()
        const item = {
          id: res.data.id,
          name: res.data.name,
          status: res.data.status,
          create_at: res.data.create_at,
          icon_url: res.data.icon_url,
          key: res.data.id,
          description: res.data.description,
        }
        const newList = category.splice(3, 0, item)
        setCategory([...category])
        notificationSuccess('Thêm danh mục thành công')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading({ ...isLoading, loadingAddEdit: false })
      setUpload({
        loading: false,
        imageUrl: '',
      })
    }
  }

  const updateCategorys = async (data: any, resetFields: any) => {
    try {
      let dataPush = {
        id: data.id,
        data: {
          name: data.name,
          order: data.order,
          icon_url: data.icon_url,
          description: data.description,
        },
      }
      const res = await updateCategory(dataPush)
      if (res.code === 200) {
        setShowEditCategory(false)
        window.location.reload()
        notificationSuccess(`Cập nhật thành công`)
        resetFields()
      }
    } catch (error) {
      console.log(error)
    } finally {
      getData()
      console.log('jhdg', getData)
    }
  }

  return {
    category,
    isLoading,
    showAddCategory,
    currentSelected,
    params,
    paging,
    setIsLoading,
    setPaging,
    setParams,
    setCurrentSelected,
    setShowAddCategory,
    setCategory,
    getData,
    createCategorys,
    updateCategorys,
    setShowEditCategory,
    showEditCategory,
  }
}
