import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  getDetailPetrolStore,
  getOrderPetrolStore,
  getProductPetrolStore,
  listRates,
  requestListCategory,
} from '../ShopApi'

type Params = {
  search: undefined | string
  page: number
  status: undefined | number
  order_status: undefined | string
  from_date: undefined | string
  to_date: undefined | string
  category_id?: number
  limit: number
  id_product?: number
}

type TRadiusSelect = {
  text: string
  value: number
}

type TDetailShop = {
  name?: string
  phone?: string
  email?: string
  status?: number
  order_status?: string
  tax?: string
  representative?: string
  type_petrol_store_id?: number
  location_lat?: number
  location_long?: number
  location_address?: string
  address?: string
  avatar?: string
}

export const useDetailShop = () => {
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const history = useHistory()
  const param: any = useParams()
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const [listCategory, setListCategory] = useState<Array<TRadiusSelect>>([])
  const [pagingProduct, setPagingProduct] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })
  const [pagingOrder, setPagingOrder] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })
  const [pagingRates, setPagingRates] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })
  const [paramsProduct, setParamsProduct] = useState<Params>({
    search: undefined,
    order_status: undefined,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    category_id: undefined,
    id_product: undefined,
    page: 1,
    limit: 12,
  })
  const [paramsOrder, setParamsOrder] = useState<Params>({
    search: undefined,
    status: undefined,
    order_status: undefined,
    from_date: undefined,
    to_date: undefined,
    category_id: undefined,
    page: 1,
    limit: 12,
  })
  const [dataSource, setDataSource] = useState<TDetailShop>({
    name: undefined,
    phone: undefined,
    email: undefined,
    status: undefined,
    tax: undefined,
    representative: undefined,
    type_petrol_store_id: undefined,
    location_lat: undefined,
    location_long: undefined,
    location_address: undefined,
    address: undefined,
    avatar: undefined,
  })
  const [product, setProduct] = useState<Array<any>>([])
  const [order, setOrder] = useState<Array<any>>([])
  const [rates, setRates] = useState()
  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })

  const [paramsRates, setParamsRates] = useState<Params>({
    search: undefined,
    status: undefined,
    order_status: undefined,
    from_date: undefined,
    to_date: undefined,
    category_id: undefined,
    page: 1,
    limit: 12,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    getListCategory()
  }, [])

  useEffect(() => {
    getProduct()
  }, [paramsProduct])

  useEffect(() => {
    getOrder()
  }, [paramsOrder])

  useEffect(() => {
    getData()
    // getPlaces()
  }, [param])

  useEffect(() => {
    getRates()
  }, [paramsRates])

  const getData = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getDetailPetrolStore(param.id)
      // console.log('res', res)
      setDataSource(res.data)
    } catch (error) {
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  const getListCategory = async () => {
    const res: any = await requestListCategory({})
    const list_data = res?.data?.map((item: any) => {
      return { value: item.id, text: item.name }
    })
    setListCategory(list_data)
  }

  const getProduct = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getProductPetrolStore(param.id, paramsProduct)
      const tableData = res.data.map((item: any, index: number) => {
        return {
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          code: item.code,
          name: item.name,
          category: item.product_category.name,
          status: item.status,
          product_stock_status: item.product_stock_status,
        }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPagingProduct(formattedPaging)
      setProduct(tableData)
    } catch (error) {
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  const getOrder = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getOrderPetrolStore(param.id, paramsOrder)
      const tableData = res.data.map((item: any, index: number) => {
        return {
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          code: item.code,
          name: item.user.name,
          phone: item.user.phone,
          total_amount: item.total_amount,
          total_price: item.total_price,
          create_at: item.create_at,
          status: item.status,
        }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPagingOrder(formattedPaging)
      setOrder(tableData)
    } catch (error) {
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  const getRates = async () => {
    try {
      const res = await listRates(param.id, paramsRates)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          id: item.id,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          name: item?.users?.name,
          phone: item?.users?.phone,
          email: item?.users?.email,
          star: item?.star,
          status: item?.status,
          description: item?.description,
          update_at: item?.update_at,
          review_media: item?.review_media,
        }
      })
      setRates(tableData)
      setPagingRates({
        ...pagingRates,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  return {
    history,
    isModalVisible,
    param,
    isLoading,
    dataSource,
    setDataSource,
    setIsModalVisible,
    setProduct,
    product,
    setParamsProduct,
    paramsProduct,
    pagingProduct,
    pagingOrder,
    setOrder,
    order,
    listCategory,
    paramsOrder,
    setParamsOrder,
    currentSelected,
    setCurrentSelected,
    rates,
    setPagingRates,
    pagingRates,
    setParamsRates,
    paramsRates,
    getRates,
  }
}
