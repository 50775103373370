import { Col, Row, DatePicker, Input } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import Icon from '@ant-design/icons'
const { RangePicker } = DatePicker
type Props = {
  // onSearchSubmit: (searchKey: string) => void
  isSearchLoading: boolean
}

export default function FilterDetail({ isSearchLoading }: Props) {
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={6}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Mã đơn hàng, tên khách hàng, SĐT"
          addonAfter={<Icon type="close-circle-o" />}
          // value={data.search}
          onChange={(e: any) => {
            // onSearchSubmit(e.target.value)
          }}
        />
      </Col>

      <Col span={4}>
        <RadiusSelection
          placeholder="TT đơn hàng"
          options={[
            { value: 0, text: 'Chờ xác nhận' },
            { value: 1, text: 'Đang thực hiện' },
            { value: 2, text: 'Đã hủy' },
          ]}
          onChange={() => {}}
        />
      </Col>
      <Col span={4}>
        <RadiusSelection
          placeholder="PT thanh toán"
          options={[
            { value: 0, text: 'Chuyển khoản' },
            { value: 1, text: 'Tiền mặt' },
          ]}
          onChange={() => {}}
        />
      </Col>
      <Col span={4}>
        <RadiusSelection
          placeholder="TT thanh toán"
          options={[
            { value: 0, text: 'Chờ thanh toán' },
            { value: 1, text: 'Đã thanh toán' },
          ]}
          onChange={() => {}}
        />
      </Col>
      <Col span={6}>
        <RangePicker
          style={{ width: '100%' }}
          placeholder={['Từ ngày', 'Đến ngày']}
          format="DD/MM/YYYY"
          onChange={(dates, dateStrings) => {
            // onTimeSubmit(dateStrings[0], dateStrings[1])
          }}
        />
      </Col>
    </Row>
  )
}
