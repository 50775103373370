import { Table } from 'antd'
import { formatPrice } from 'utils/ruleForm'
import styles from '../styles/style.module.css'

type Props = {
  data: any
}
export default function ProductList({ data }: Props) {
  //đơn hàng có hóa đơn
  const columns = [
    {
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: <b>Tên sản phẩm</b>,
      dataIndex: 'product',
      key: 'product',
      render: (value: any) => <>{value.name}</>,
    },
    {
      title: <b>Số lượng</b>,
      render: () => (
        <>
          {data.detailOrder.order_receipt_gasoline
            ? formatPrice(
                Number(
                  +(
                    data.detailOrder.order_payment.total_price /
                    data.detailOrder.order_receipt_gasoline.price
                  )
                ).toFixed(2)
              )
            : (data.detailOrder.total_price + data.detailOrder.points_used) /
                data.detailOrder.order_items[0].price_reality ===
              0
            ? 'Chưa xác định'
            : Number(
                (data.detailOrder.total_price + data.detailOrder.points_used) /
                  data.detailOrder.order_items[0].price_reality
              ).toFixed(2)}
        </>
      ),
    },
    {
      title: <b>Giá bán</b>,
      render: () => (
        <>
          {data.detailOrder.order_receipt_gasoline != null
            ? formatPrice(data.detailOrder.order_receipt_gasoline.price)
            : data.detailOrder.order_items[0].price_reality == 0
            ? '0đ'
            : formatPrice(data.detailOrder.order_items[0].price_reality) + 'đ'}
        </>
      ),
    },
    // {
    //   title: <b>Thành tiền</b>,
    //   render: () => (
    //     <>
    //       {data.detailOrder.order_receipt_gasoline.total_price_reality == 0
    //         ? '0đ'
    //         : formatPrice(
    //             data.detailOrder.order_receipt_gasoline.total_price_reality
    //           ) + 'đ'}
    //     </>
    //   ),
    // },
    {
      title: <b>Thành tiền</b>,
      render: () => (
        <>
          {data.detailOrder.total_price + data.detailOrder.points_used === 0
            ? 'Chưa xác định'
            : formatPrice(
                data.detailOrder.total_price + data.detailOrder.points_used
              ) + 'đ'}
        </>
      ),
    },
  ]
  //đơn hàng không có hóa đơn
  const column = [
    {
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: <b>Tên sản phẩm</b>,
      dataIndex: 'product',
      key: 'product',
      render: (value: any) => <>{value.name}</>,
    },
    {
      title: <b>Số lượng</b>,
      dataIndex: 'total_quantity',
      key: 'total_quantity',
      render: (value: any) => (
        <>{value == 0 ? 'Đầy bình' : formatPrice((+value).toFixed(2))}</>
      ),
    },
    {
      title: <b>Giá bán</b>,
      dataIndex: 'product_price',
      key: 'product_price',
      render: (value: any) => (
        <>{value == 0 ? 'Chưa xác định' : formatPrice(value)} đ</>
      ),
    },
    {
      title: <b>Đơn vị tính</b>,
      dataIndex: 'product',
      key: 'product',
      render: (value: any) => <>{value?.unit || '--'}</>,
    },
    {
      title: <b>Thành tiền</b>,
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: any) => <>{formatPrice(value || 0) + 'đ'} </>,
    },
  ]

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className={styles.title}>
        <b style={{ fontSize: 16 }}>Danh sách sản phẩm</b>
        <div className={styles.divider}></div>
      </div>
      <div style={{ padding: '15px 15px' }}>
        <Table
          dataSource={data.order_items}
          columns={column}
          // columns={column}
          bordered
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            // y: 'calc(100vh - 300px)',
          }}
          pagination={false}
        />
      </div>
    </div>
  )
}
