import { PageHeader, Table, Tag } from 'antd'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import ButtonExportExcelV2 from 'common/components/Buttons/ButtonExportExcelV2'
import { ENTERPRISE_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import {
  getAllReportProduct,
  getReportTotal,
} from 'features/admin/report/sale/SaleReportApi'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { formatPrice } from 'utils/ruleForm'
import Filter from './component/Filter'
import { getReportSale } from './ReportSaleProductApi'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
type TParams = {
  page: number
  search?: string
  category_id?: number
  petrol_store_id?: number
  from_date?: string | Date
  to_date?: string | Date
}
const column = [
  // {
  //   width: '50px',
  //   title: <b>STT</b>,
  //   dataIndex: 'stt',
  //   key: 'stt',
  // },
  // {
  //   title: <b>Tên sản phẩm</b>,
  //   dataIndex: 'name',
  //   key: 'name',
  // },
  // {
  //   title: <b>Nhóm sản phẩm</b>,
  //   dataIndex: 'product_category',
  //   key: 'product_category',
  // },
  // // {
  // //   title: <b>Gian hàng</b>,
  // //   dataIndex: 'petrol_store',
  // //   key: 'petrol_store',
  // // },
  // {
  //   title: <b>Tồn đầu</b>,
  //   dataIndex: 'survive_head',
  //   key: 'survive_head',
  //   render: (value: number) => (
  //     <>
  //       {!value
  //         ? '0'
  //         : formatPrice(
  //             Number.isInteger(value) ? value : Number(value).toFixed(3)
  //           )}
  //     </>
  //   ),
  // },
  // {
  //   title: <b>Xuất kho</b>,
  //   dataIndex: 'export_stock',
  //   key: 'export_stock',
  //   render: (value: number) => (
  //     <>
  //       {!value
  //         ? '0'
  //         : formatPrice(
  //             Number.isInteger(value) ? value : Number(value).toFixed(3)
  //           )}
  //     </>
  //   ),
  // },
  // {
  //   title: <b>Tồn cuối</b>,
  //   dataIndex: 'ending_stock',
  //   key: 'ending_stock',
  //   render: (value: number) => (
  //     <>
  //       {!value
  //         ? '0'
  //         : formatPrice(
  //             Number.isInteger(value) ? value : Number(value).toFixed(3)
  //           )}
  //     </>
  //   ),
  // },
  // {
  //   width: '70px',
  //   title: <b>Số đơn hàng</b>,
  //   dataIndex: 'total_order',
  //   key: 'total_order',
  //   render: (value: any) => <>{!value ? '0' : formatPrice(value)}</>,
  // },
  // {
  //   width: '200px',
  //   title: <b>Tổng tiền thực tế</b>,
  //   dataIndex: 'total_price',
  //   key: 'total_price',
  //   render: (value: any) => (
  //     <>{!value ? '0' : formatPrice(Number(value).toFixed(0))} đ</>
  //   ),
  // },
  {
    width: '50px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Gian hàng</b>,
    dataIndex: 'petrol_store',
    key: 'petrol_store',
  },
  {
    title: <b>Danh mục</b>,
    dataIndex: 'product_category',
    key: 'product_category',
  },
  {
    title: <b>Tên sản phẩm</b>,
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: <b>ĐVT</b>,
    dataIndex: 'product',
    key: 'product_unit',
    render: (value: any, row: any) => row?.product?.product_unit?.name,
  },
  {
    title: <b>Số lượng đặt</b>,
    dataIndex: 'survive_head',
    key: 'survive_head',
    render: (value: number, row: any, index: number) =>
      index > 0 ? (
        (+value).toFixed(2)
      ) : (
        <Tag color="processing">{(+value).toFixed(2)}</Tag>
      ),
  },
  {
    title: <b>Đã hoàn thành</b>,
    dataIndex: 'export_stock',
    key: 'export_stock',
    render: (value: number, row: any, index: number) =>
      index > 0 ? (
        (+value).toFixed(2)
      ) : (
        <Tag color="processing">{(+value).toFixed(2)}</Tag>
      ),
  },
  {
    title: <b>Số lượng tồn</b>,
    dataIndex: 'ending_stock',
    key: 'ending_stock',
    render: (value: number, row: any, index: number) =>
      index > 0 ? (
        (+value).toFixed(2)
      ) : (
        <Tag color="processing">{(+value).toFixed(2)}</Tag>
      ),
  },

  {
    width: 120,
    title: <b>Thành tiền</b>,
    dataIndex: 'total_price',
    key: 'total_price',
    render: (value: any, row: any, index: number) => (
      <>
        {!value ? (
          '0'
        ) : index > 0 ? (
          formatPrice(Number(value).toFixed(0))
        ) : (
          <Tag color="processing">
            {formatPrice(Number(value).toFixed(0))} đ
          </Tag>
        )}
      </>
    ),
  },
  {
    width: '70px',
    title: <b>Tổng đơn hàng</b>,
    dataIndex: 'total_order',
    key: 'total_order',
    render: (value: number, row: any, index: number) =>
      index > 0 ? value || '0' : <Tag color="processing">{value}</Tag>,
  },
]

const ShopReportScreen = () => {
  const history = useHistory()
  const location: any = useLocation()
  const userState = useSelector((state: any) => state.authReducer)?.userInfo
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 13,
  })
  const [dataSource, setDataSource] = useState<any>([])
  const [params, setParams] = useState<TParams>({
    page: 1,
    search: undefined,
    category_id: undefined,
    petrol_store_id: undefined,
    from_date: undefined,
    to_date: undefined,
  })
  const [firstRecord, setFirstRecord] = useState<any>({})

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingBtnExportData, setLoadingBtnExportData] = useState<boolean>(
    false
  )

  useEffect(() => {
    if (userState?.petrol_store_id) {
      setParams({ ...params, petrol_store_id: userState?.petrol_store_id })
    }
  }, [userState?.petrol_store_id])

  useEffect(() => {
    if (location?.state) {
      if (
        moment(location?.state.from_date).format('YYYY-MM-DD HH:mm') ===
        moment(location?.state.to_date).format('YYYY-MM-DD HH:mm')
      ) {
        setParams({
          ...params,
          from_date: location?.state?.from_date
            ? moment(location?.state.from_date)
                ?.seconds(0)
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
          to_date: location?.state.to_date
            ? moment(location?.state.to_date)
                ?.seconds(0)
                .add(59, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
        })
      } else {
        setParams({
          ...params,
          from_date: location?.state?.from_date
            ? moment(location?.state.from_date)
                ?.seconds(0)
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
          to_date: location?.state.to_date
            ? moment(location?.state.to_date)
                ?.seconds(0)
                .add(59, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss')
            : '',
        })
      }
    }
  }, [location?.state])

  useEffect(() => {
    if (params.petrol_store_id) {
      getDataSource()
    }
    getTotalReport()
  }, [params])

  const getTotalReport = async () => {
    try {
      const res = await getReportTotal({
        ...params,
        petrol_store_id: userState?.petrol_store_id,
      })

      setFirstRecord({
        id: Math.random().toString(),
        total_order: res.data?.report_product?.total_order,
        // total_amount: res.data?.report_product?.total_amount,
        total_price: res.data?.report_product?.total_price,
        survive_head: res.data?.report_product?.total_amount,
        export_stock: res.data?.report_product?.export_stock,
        ending_stock: res.data?.report_product?.ending_stock,
      })
    } catch (err) {
      console.log(
        '🚀 ~ file: SaleReportScreen.tsx:347 ~ getTotalReport ~ err',
        err
      )
    }
  }

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      // let countOrderQuantity = 0
      // let countFinish = 0
      // let countQuantityStock = 0
      // let countIntoMoney = 0
      // let countTotalOrder = 0

      const res = await getReportSale(params)
      const dataTable = res.data?.map((items: any, indexs: number) => {
        // countOrderQuantity += items?.survive_head
        // countFinish += items?.export_stock
        // countQuantityStock += items?.ending_stock
        // countIntoMoney += items?.total_price
        // countTotalOrder += items?.total_order
        return {
          id: items.id,
          key: items.id,
          name: items.product.name,
          product_category: items.product?.product_category?.name,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            indexs +
            1,
          petrol_store: items.petrol_store.name,
          petrol_store_id: items.petrol_store_id,
          product_id: items.product_id,
          total_order: items.total_order,
          total_amount: items.total_amount,
          total_price: items.total_price,
          survive_head: items.survive_head,
          export_stock: items.export_stock,
          ending_stock: items.ending_stock,
          product: items?.product,
        }
      })

      setDataSource(dataTable)
      setPaging({
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: 13,
      })
    } catch (error: any) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  // export excel
  const onExportData = async () => {
    try {
      const workbook = new Excel.Workbook()

      setLoadingBtnExportData(true)
      const worksheet = workbook.addWorksheet('Báo cáo bán hàng')

      // columns
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10 },
        { header: 'Gian hàng', key: 'petrol_store_name', width: 32 },
        { header: 'Danh mục', key: 'product_category_name', width: 20 },
        { header: 'Sản phẩm', key: 'product_name', width: 60 },
        { header: 'Đơn vị tính', key: 'unit', width: 15 },
        { header: 'Số lượng đặt', key: 'survive_head', width: 20 },
        { header: 'Đã hoàn thành', key: 'export_stock', width: 20 },
        { header: 'Đang thực hiện', key: 'ending_stock', width: 20 },
        { header: 'Thành tiền', key: 'total_price', width: 20 },
        { header: 'Tổng đơn đặt hàng', key: 'total_order', width: 20 },
      ]

      worksheet.insertRow(1, ['BÁO CÁO BÁN HÀNG'])
      worksheet.mergeCells('A1', 'J1')
      worksheet.insertRow(2, [
        params.from_date
          ? `Thời gian thực hiện: Từ ngày ${moment(params.from_date)?.format(
              'HH:mm DD/MM/YYYY'
            )} đến ngày ${moment(params.to_date)?.format('HH:mm DD/MM/YYYY')}`
          : 'Thời gian thực hiện: -',
      ])
      worksheet.mergeCells('A2', 'J2')

      //Todo:  style báo cáo bán hnagf
      worksheet.getRow(1).font = { size: 18, color: { argb: '00000' } }
      worksheet.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      //Todo:  styl;e từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }

      // todo: boder style
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      // end
      const dataListReportNotPagging = await getReportSale({
        ...params,
        limit: 999,
      })

      dataListReportNotPagging?.data.forEach((o: any, i: number) => {
        worksheet.addRow({
          id: i + 1,
          petrol_store_name: o.petrol_store?.name,
          product_category_name: o?.product?.product_category?.name,
          product_name: o.product?.name,
          survive_head: o?.survive_head,
          export_stock: o?.export_stock,
          ending_stock: o?.ending_stock,
          total_price: formatPrice(Number(o?.total_price).toFixed(0)),
          total_order: o?.total_order,
          unit: o?.product?.product_unit?.name,
        })
      })

      worksheet.insertRow(dataListReportNotPagging?.data.length + 4, ['TỔNG'])
      worksheet.mergeCells(
        `A${dataListReportNotPagging?.data.length + 4}`,
        `E${dataListReportNotPagging?.data.length + 4}`
      )

      //Todo:  style báo cáo bán hnagf
      worksheet.getRow(dataListReportNotPagging?.data.length + 4).font = {
        size: 13,
        color: { argb: '00000' },
        bold: true,
      }
      worksheet.getRow(dataListReportNotPagging?.data.length + 4).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      worksheet.getCell(
        `A${dataListReportNotPagging?.data.length + 4}`
      ).border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell(`F${dataListReportNotPagging?.data.length + 4}`).value =
        firstRecord?.survive_head
      worksheet.getCell(`G${dataListReportNotPagging?.data.length + 4}`).value =
        firstRecord?.export_stock
      worksheet.getCell(`H${dataListReportNotPagging?.data.length + 4}`).value =
        firstRecord?.ending_stock
      worksheet.getCell(`I${dataListReportNotPagging?.data.length + 4}`).value =
        firstRecord?.total_price
      worksheet.getCell(`J${dataListReportNotPagging?.data.length + 4}`).value =
        firstRecord?.total_order

      worksheet.eachRow({ includeEmpty: true }, function (row: any, rowNumber) {
        if (row?._number === 3) {
          row.eachCell({ includeEmpty: true }, function (cell: any) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '87CEEB' },
            }
          })
        }

        row.eachCell({ includeEmpty: true }, function (cell: any) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(new Blob([buf]), `Báo cáo bán hàng.xlsx`)
      // setDataExport(exportData)
      // fn()
    } catch (error) {
      console.log(
        '🚀 ~ file: SaleReportScreen.tsx ~ line 177 ~ onExportData ~ error',
        error
      )
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <Container
      header={
        <PageHeader
          title="Báo cáo bán hàng"
          extra={[
            // <ButtonExport
            //   sheetName={['SalesReport']}
            //   fileName="Báo cáo bán hàng"
            //   onClick={fn => onExportData(fn)}
            //   loading={isLoadingBtnExportData}
            //   sheets={{
            //     SalesReport: ButtonExport.getSheets(dataExport),
            //   }}
            // />,
            <ButtonExportExcelV2
              key="export_excel"
              loading={isLoadingBtnExportData}
              onClick={onExportData}
            />,
          ]}
        />
      }
      filterComponent={
        <Filter
          data={params}
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search:
                searchKey.trim() === '' ? undefined : searchKey.trimStart(),
              page: 1,
            })
          }}
          onCategoryIdSubmit={(category_id: number) => {
            setParams({
              ...params,
              category_id: category_id,
              page: 1,
            })
          }}
          onTimeSubmit={(
            from_date: string | undefined,
            to_date: string | undefined
          ) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
          onSelectPetrolStore={(petrol_store_id: number) => {
            setParams({
              ...params,
              petrol_store_id: petrol_store_id,
              page: 1,
            })
          }}
          params={params}
          setParams={setParams}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc:<b> {paging.total}</b>
          </p>
          <Table
            bordered
            columns={column}
            dataSource={[firstRecord, ...dataSource]}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            loading={isLoading}
            onRow={(record, index: any) => ({
              onClick: () => {
                if (!index && index < 1) return

                history.push({
                  pathname: `${ENTERPRISE_ROUTER_PATH.SALE_REPORT_DETAIL}/${record.petrol_store_id}/${record.product_id}`,
                  state: {
                    page: paging.current,
                    search: params.search,
                    category_id: params.category_id,
                    petrol_store_id: params.petrol_store_id,
                    from_date: params.from_date,
                    to_date: params.to_date,
                    product_name: record.name,
                    petrol_store_name: record.petrol_store,
                  },
                })
                setParams({
                  page: 1,
                  search: '',
                  category_id: undefined,
                  petrol_store_id: undefined,
                  from_date: undefined,
                  to_date: undefined,
                })
              },
            })}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}

export default ShopReportScreen
