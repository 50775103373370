import { message } from 'antd'
import { useEffect, useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import {
  createConfigPartner,
  getConfigPartner,
  updateConfigPartner,
} from '../ConfigApi'

export const useConfigPartner = () => {
  // const updatePartner = async (data: any, resetFields: any) => {
  //   try {
  //     let dataPush = {
  //       id: data.id,
  //       data: {
  //         name: data.name,
  //         icon_url: data.icon_url,
  //       },
  //     }
  //     const res = await updateConfigPartner(dataPush)
  //     notificationSuccess(res.message)
  //     setShowEditPartner(false)
  //     getData()
  //     resetFields()
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return {
    // isLoading,
    // currentSelected,
    // params,
    // paging,
    // listConfigPartner,
    // setPaging,
    // setIsLoading,
    // setCurrentSelected,
    // setParams,
    // getData,
    // setListConfigPartner,
    // setShowAddConfigPartner,
    // showAddConfigPartner,
    // createPartner,
    // showEditPartner,
    // setShowEditPartner,
    // updatePartner,
  }
}
