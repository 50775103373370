import { Col, DatePicker, PageHeader, Row, Table, Tag } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import { RadiusSelection } from 'common/components/Inputs'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import styles from 'common/components/styles/WhiteBox.module.css'
import Container from 'common/container/Container'
import moment from 'moment'
import 'moment/locale/vi'
import { IS_ACTIVE } from 'utils/constants'
import { notificationSuccess } from 'utils/notification'
import { changeStatusCategory, deleteCategory } from './CategoryProductApi'
import { AddEditCategory } from './components/AddEditCategory'
import CategoryDetail from './components/CategoryDetail'
import { useCategoryProduct } from './hook/useCategoryProduct'

const { RangePicker } = DatePicker

const Category = () => {
  const {
    category,
    isLoading,
    showAddCategory,
    currentSelected,
    params,
    paging,
    setIsLoading,
    setParams,
    setCurrentSelected,
    setShowAddCategory,
    getData,
    createCategorys,
  } = useCategoryProduct()

  const contentView = () => {
    const columns = [
      {
        title: <b>Tên danh mục</b>,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: <b>Trạng thái</b>,
        dataIndex: 'status',
        key: 'status',
        render: (value: any) => (
          <Tag color={value === 1 ? 'green' : 'volcano'}>
            {value === 1 ? 'HOẠT ĐỘNG' : 'TẠM DỪNG'}
          </Tag>
        ),
      },
      {
        title: <b>Ngày tạo</b>,
        dataIndex: 'create_at',
        key: 'create_at',
        render: (data: any) => <div>{moment(data).format('DD/MM/YYYY')}</div>,
      },
    ]

    function handleChangeStatus(value: any) {
      setParams({
        ...params,
        status: value,
        page: 1,
      })
    }

    const handleChangeTime = (dates: any, dateStrings: any[]) => {
      setParams({
        ...params,
        to_date: dateStrings[1],
        from_date: dateStrings[0],
        page: 1,
      })
    }

    const onSearchSubmit = (searchKey: string) => {
      setIsLoading({ ...isLoading, loadingSearch: true })

      setParams({ ...params, search: searchKey, page: 1 })
    }

    return (
      <>
        <Container
          header={
            <PageHeader
              title="Danh mục sản phẩm"
              extra={[
                <Row gutter={[16, 16]}>
                  <Col span={3}>
                    <ButtonAdd
                      onClickButton={() => setShowAddCategory(true)}
                      text="Thêm mới"
                      loading={isLoading.loadingAddEdit}
                    />
                  </Col>
                </Row>,
              ]}
            />
          }
          filterComponent={
            <Row gutter={[16, 16]} justify="start">
              <Col span={10}>
                <TypingAutoSearch
                  onSearchSubmit={(searchKey: string) => {
                    onSearchSubmit(searchKey.trim())
                  }}
                  isSearchLoading={isLoading.loadingSearch}
                  placeholder="Tên danh mục "
                />
              </Col>

              <Col span={5}>
                <RadiusSelection
                  placeholder="Trạng thái"
                  onChange={handleChangeStatus}
                  options={[
                    { value: IS_ACTIVE.ACTIVE, text: 'Đang hoạt động' },
                    { value: IS_ACTIVE.INACTIVE, text: 'Ngừng hoạt động' },
                  ]}
                />
              </Col>

              <Col span={8}>
                <RangePicker
                  style={{ width: '100%' }}
                  onChange={(dates, dateStrings) => {
                    handleChangeTime(dates, dateStrings)
                  }}
                  placeholder={['Từ ngày', 'Đến ngày']}
                />
              </Col>
            </Row>
          }
          contentComponent={
            <div>
              <p style={{ margin: '10px' }}>
                Kết quả lọc: <b>{paging.total}</b>
              </p>
              <Table
                className={styles.table_hover}
                scroll={{
                  x: 800,
                  scrollToFirstRowOnChange: true,
                }}
                bordered
                dataSource={category}
                loading={isLoading.loadingTable}
                columns={columns}
                expandedRowKeys={[currentSelected.id]}
                onRow={(row: any) => ({
                  onClick: () => {
                    if (currentSelected !== row) setCurrentSelected(row)
                    else setCurrentSelected({ id: -1 })
                  },
                })}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <CategoryDetail
                      onChangeStatusCategory={async () => {
                        const res = await changeStatusCategory(record.id)
                        if (res.code === 200) {
                          notificationSuccess(
                            `Cập nhật thành công danh mục: ${record.name}`
                          )
                          getData()
                        }
                      }}
                      onDeleteCategory={async (id: number) => {
                        try {
                          const res = await deleteCategory(id)
                          if (res.code === 200) {
                            notificationSuccess(
                              `Đã xoá danh mục: ${record.name}`
                            )
                            getData()
                          }
                        } catch (error) {
                          console.log(error)
                        }
                      }}
                      data={record}
                      getData={getData}
                    />
                  ),
                }}
                pagination={{
                  ...paging,
                  showSizeChanger: false,
                  onChange: async (page, pageSize) => {
                    setParams({ ...params, page })
                  },
                }}
              />
            </div>
          }
        />

        <AddEditCategory
          visible={showAddCategory}
          onCancel={() => {
            setShowAddCategory(false)
            getData()
          }}
          onCreateNewCategory={(
            newData: any,
            resetFields: any,
            setUpload: any
          ) => {
            createCategorys(newData, resetFields, setUpload)
          }}
          isLoading={isLoading.loadingAddEdit}
        />
      </>
    )
  }
  return contentView()
}
export default Category
