import { RedoOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, Row } from 'antd'
import { IProductInventory } from 'features/admin/inventory/Inventory'
import { isNull } from 'lodash'
import React, { memo } from 'react'

interface IInputNumberRemain {
  // hien tai
  record: IProductInventory
  onUpdateRemain: (record: IProductInventory, value: number) => void
  copiedListProduct: IProductInventory[]
  callback: boolean
}

const InputNumberRemain = (props: IInputNumberRemain) => {
  const { record, onUpdateRemain, copiedListProduct, callback } = props

  const [inputRemain, setInputRemain] = React.useState<number | undefined>()

  const onCheckDisabled = () => {
    const targetRecord = copiedListProduct.find(
      (product: IProductInventory) => product.id === record.id
    )
    if (targetRecord && targetRecord.beginInventory >= record.beginInventory) {
      return true
    }
    return false
  }

  React.useEffect(() => {
    setInputRemain(undefined)
  }, [callback])

  React.useEffect(() => {
    setInputRemain(record.updateRemainPetrol)
  }, [record.updateRemainPetrol])

  return (
    <Row align="middle" style={{ flexFlow: 'row' }}>
      {/* <Button
        disabled={isEmpty}
        onClick={() => {
          if (inputRemain) {
            onUpdateLoss(record, inputRemain)
            setInputRemain(undefined)
            setIsEmpty(true)
          }
        }}
      >
        <PlusOutlined />
      </Button> */}
      <Form.Item
        rules={[
          {
            validator: (_: any, value: any) => {
              if (isNull(value)) {
                return Promise.resolve()
              }

              if (Number(value) <= 0) {
                return Promise.reject(new Error('Tồn đầu phải lớn hơn 0!'))
              }

              return Promise.resolve()
            },
          },
        ]}
        name={`remain_${record.id}`}
        style={{ flexWrap: 'nowrap', marginBottom: 0 }}
      >
        <InputNumber
          id={`remain_${record.id}`}
          placeholder="Nhập tồn đầu"
          style={{ width: '100%' }}
          type="number"
          // max={record?.beginInventory}
          value={inputRemain}
          onChange={(value: any) => {
            setInputRemain(value)
            if (!value) {
              onUpdateRemain(record, 0)
            } else {
              onUpdateRemain(record, value)
            }
          }}
        />
      </Form.Item>
      <Button
        style={{ marginLeft: 10 }}
        onClick={() => {
          if (inputRemain) {
            onUpdateRemain(record, record?.remain_cur || 0)
            setInputRemain(record?.remain_cur)
          }
        }}
      >
        <RedoOutlined />
      </Button>
      {/* <Input
        autoComplete="off"
        placeholder="Nhập hao hụt"
        id={`loss_${record.id}`}
        disabled={  record.categoryId !== 1}
        value={formatPrice(inputRemain)}
        onChange={(e: any) => {
          const value = enterNumbersOnly(e.target.value)
          if (!/^\d+$/.test(value)) {
            setIsEmpty(true)
            onUpdateLoss(record, 0)
          } else {
            onUpdateLoss(record, Number(value))
            setIsEmpty(false)
            setInputRemain(Number(value))
          }
        }}
      /> */}
    </Row>
  )
}

export default memo(InputNumberRemain)
