import { Col, Input, Row, DatePicker } from 'antd'
import Icon from '@ant-design/icons'
import { IS_ACTIVE } from 'utils/constants'
import { RadiusSelection } from 'common/components/Inputs'
import { useSaleProduct } from '../hook/useSaleProduct'
import moment from 'moment'

const { RangePicker } = DatePicker
type Props = {
  onSearchSubmit: (searchKey: string) => void
  onCategorySubmit: (category_id: number) => void
  onStatusSubmit: (status: number) => void
  onChangeTime: (from_date?: string | Date, to_date?: string | Date) => void
}

export default function Filter({
  onSearchSubmit,
  onCategorySubmit,
  onStatusSubmit,
  onChangeTime,
}: Props) {
  const { listCategory } = useSaleProduct()
  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <Input.Search
            style={{ width: '100%' }}
            allowClear
            placeholder="Nhập tên hoặc mã sản phẩm"
            addonAfter={<Icon type="close-circle-o" />}
            onChange={e => {
              setTimeout(() => {
                onSearchSubmit(e.target.value)
              }, 300)
            }}
          />
        </Col>
        <Col span={4}>
          <RadiusSelection
            placeholder="DM  sản phẩm"
            onChange={(value: any) => {
              onCategorySubmit(value)
            }}
            options={listCategory}
            showSearch={true}
          />
        </Col>
        <Col span={4}>
          <RadiusSelection
            placeholder="Trạng thái"
            onChange={(value: any) => {
              onStatusSubmit(value)
            }}
            options={[
              { value: IS_ACTIVE.ACTIVE, text: 'Đang khuyến mãi' },
              { value: IS_ACTIVE.INACTIVE, text: 'Không khuyến mãi' },
            ]}
          />
        </Col>
        <Col span={6}>
          <RangePicker
            placeholder={['Từ ngày', 'Đến ngày']}
            onChange={(dates, dateStrings) => {
              onChangeTime(dateStrings[0], dateStrings[1])
            }}
          />
        </Col>
      </Row>
    </div>
  )
}
