import Container from 'common/container/Container'
import FilterDetail from './components/FilterDetail'
import { Table, Tag } from 'antd'
import { useState } from 'react'
import HeaderDetail from './components/HeaderDetail'
import { formatPrice } from 'utils/ruleForm'
import { useDetailDebt } from './hook/useDetailDebt'
import moment from 'moment'

export default function DetailDebtShopScreen() {
  const [isLoading, setIsLoading] = useState({
    isLoadingTable: false,
    isLoadingSearch: false,
  })
  const { dataSource } = useDetailDebt()

  const column = [
    {
      width: '60px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      width: '130px',
      title: <b>Mã đơn</b>,
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      width: '130px',
      title: <b>Khách hàng</b>,
      dataIndex: 'customer',
      key: 'customer',
    },
    {
      width: '90px',
      title: <b>Số SP</b>,
      dataIndex: 'count_product',
      key: 'count_product',
      render: (value: number) => <p>{value ? formatPrice(value) : 0}</p>,
    },
    {
      width: '150px',
      title: <b>Tổng tiền</b>,
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: number) => <p>{value ? formatPrice(value) : 0} đ</p>,
    },
    {
      width: '120px',
      title: <b>TT đơn hàng</b>,
      dataIndex: 'status_order',
      key: 'status_order',
      render: (value: number) => {
        switch (value) {
          case 0:
            return <Tag color="blue">Chờ xác nhận</Tag>
          case 1:
            return <Tag color="blue">Đang thực hiện</Tag>
          case 2:
            return <Tag color="volcano">Đã hủy</Tag>
          case 3:
            return <Tag color="green">Hoàn thành</Tag>
        }
      },
    },
    {
      width: '100px',
      title: <b>Phương thức thanh toán</b>,
      dataIndex: 'payment_methods',
      key: 'payment_methods ',
    },
    {
      width: '120px',
      title: <b>TT thanh toán</b>,
      dataIndex: 'status_pay',
      key: 'status_pay',
      render: (value: number) => {
        switch (value) {
          case 0:
            return <Tag color="blue">Chờ thanh toán</Tag>
          case 1:
            return <Tag color="green">Đã thanh toán</Tag>
        }
      },
    },
    {
      width: '100px',
      title: <b>Ngày tạo </b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('DD/MM/YYYY')}</>
      },
    },
  ]
  return (
    <Container
      header={<HeaderDetail title={dataSource?.name} />}
      filterComponent={
        <FilterDetail isSearchLoading={isLoading.isLoadingSearch} />
      }
      contentComponent={
        <div>
          <Table
            size="middle"
            loading={isLoading.isLoadingTable}
            bordered
            columns={column}
            dataSource={dataSource.dataTable}
            scroll={{
              x: 800,
            }}
            // pagination={{
            //   ...paging,
            //   showSizeChanger: false,
            //   onChange: async (page, pageSize) => {
            //     setParams({ ...params, page })
            //   },
            // }}
          />
        </div>
      }
    />
  )
}
