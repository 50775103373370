import {
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import { Table, Divider, Checkbox, Popconfirm, Input } from 'antd'
import Column from 'antd/lib/table/Column'
import { useEffect, useState } from 'react'
import { enterNumbersOnly, formatPrice } from 'utils/ruleForm'
import { getUnits, requestListPetrolStore } from '../ProductApi'
import { notificationError } from 'utils/notification'
import ChooseShopModal from './ChooseShopModal'
import { ButtonAdd, ButtonFixed } from 'common/components/Buttons'
import styles from '../style/Product.module.css'
import { TParamsChooseShop } from '../Product'
import { useAddEditProduct } from '../hook/useAddEditProduct'
import { useSelector } from 'react-redux'
import { getProductInfor } from '../ProductSlice'
type Props = {
  id?: number
  storesSelected: any
  arrayDeletePetrol: any
  setStoresSelected: any
  setArrayDeletePetrol: any
}

export default function PriceInformationProduct({
  id,
  storesSelected,
  arrayDeletePetrol,
  setStoresSelected,
  setArrayDeletePetrol,
}: Props) {
  const { setStoresChecked, isLoading } = useAddEditProduct()
  const productInfor = useSelector(getProductInfor)
  const [valuePrice, setValuePrice] = useState<number>(0)
  const [visibleChooseShop, setVisibleChooseShop] = useState(false)
  const [listPetrolStore, setListPetrolStore] = useState<Array<any>>([])
  const [currentUnit, setCurrentUnit] = useState<string>('')
  const [isLoadings, setIsLoadings] = useState({
    loadingSearch: false,
    loadingTableStore: false,
  })
  const [paramsChooseShop, setParamsChooseShop] = useState<TParamsChooseShop>({
    search: undefined,
    page: 1,
    array_petrol_store_cancle: [],
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const getCurrentUnit = async () => {
    const resUnit = await getUnits()
    const targetUnit = resUnit.data.filter(
      (item: any) => item.id === productInfor?.unit
    )
    setCurrentUnit(targetUnit[0].name)
  }

  useEffect(() => {
    if (productInfor?.unit) {
      getCurrentUnit()
    } else {
      setCurrentUnit('')
    }
  }, [productInfor?.unit])

  useEffect(() => {
    const list_store_cancel = storesSelected.map(
      (item: any) => item.petrol_store_id
    )
    setParamsChooseShop({
      ...paramsChooseShop,
      array_petrol_store_cancle: list_store_cancel,
      page: 1,
    })
  }, [storesSelected.length])

  useEffect(() => {
    getListShop()
  }, [paramsChooseShop])

  const getListShop = async () => {
    try {
      setIsLoadings({
        ...isLoadings,
        loadingTableStore: true,
        loadingSearch: true,
      })
      const res = await requestListPetrolStore(paramsChooseShop)

      const tableData = res.data.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            index +
            1,
          type_petrol_store_name: item.type_petrol_store.name,
        }
      })
      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setListPetrolStore(tableData)
    } catch (error: any) {
      console.log(error)
    } finally {
      setIsLoadings({
        ...isLoadings,
        loadingTableStore: false,
        loadingSearch: false,
      })
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <b style={{ fontSize: '20px' }} tabIndex={-1} id="add_store">
          Thông tin giá bán
        </b>
        <ButtonAdd
          onClickButton={() => {
            setVisibleChooseShop(true)
          }}
          text="Thêm cửa hàng"
        />
      </div>

      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <b style={{ fontSize: '16px' }}>Giá bán:</b>
        <Input
          placeholder="Nhập giá bán"
          style={{ width: '300px', marginLeft: '30px' }}
          value={valuePrice ? formatPrice(valuePrice) : ''}
          onChange={(e: any) => {
            let value = enterNumbersOnly(e.target.value)
            if (parseInt(value) > 1000000000) {
              notificationError('Giá sản phẩm không vượt quá 1,000,000,000')
            } else {
              setValuePrice(parseInt(value))
            }
          }}
        />
        <ButtonFixed
          style={{
            marginLeft: '30px',
            borderRadius: '5px',
          }}
          text="Áp dụng cho tất cả"
          icon={<CopyOutlined />}
          disabled={storesSelected.length < 1 ? true : false}
          onClickButton={() => {
            const listPrice = storesSelected.map(
              (item: any, index: number) => ({
                ...item,
                price: valuePrice,
              })
            )
            setStoresSelected([...listPrice])
          }}
        />
      </div>
      <Table
        style={{ marginTop: '20px', cursor: 'pointer' }}
        bordered
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        loading={isLoading.loadingTable}
        dataSource={storesSelected}
        pagination={false}
      >
        <Column
          width="40px"
          align="center"
          title={<b style={{ fontSize: '16px' }}>STT</b>}
          dataIndex="stt"
          key="stt"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{index_col + 1}</p>
          )}
        />
        <Column
          width="200px"
          title={<b style={{ fontSize: '16px' }}>Cửa hàng</b>}
          dataIndex="name"
          key="name"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="200px"
          title={<b style={{ fontSize: '16px' }}>Địa điểm</b>}
          dataIndex="location_address"
          key="location_address"
          render={(value_col: any, record: any, index_col: number) => (
            <p>{value_col}</p>
          )}
        />
        <Column
          width="100px"
          title={<b style={{ fontSize: '16px' }}>Giá</b>}
          dataIndex="price"
          key="price"
          render={(value_col: any, record: any, index_col: number) => (
            <Input
              id={`price-product-${index_col}`}
              style={{ border: '0px solid #fff', width: '100%' }}
              value={value_col ? formatPrice(value_col) : 0}
              onChange={(e: any) => {
                let value = enterNumbersOnly(e.target.value)
                if (parseInt(value) > 1000000000) {
                  notificationError('Giá sản phẩm không vượt quá 1,000,000,000')
                } else {
                  const arr = [...storesSelected]
                  const new_data_record: any = {
                    ...record,
                    price: parseInt(value === '' ? 0 : value),
                  }
                  arr[index_col] = new_data_record
                  setStoresSelected(arr)
                }
              }}
            />
          )}
        />
        {/* <Column
          width="80px"
          title={<b style={{ fontSize: '16px' }}>Đơn vị tính</b>}
          dataIndex="unit"
          key="unit"
          align="center"
          render={(value_col: any, record: any, index_col: number) => {
            return <>{currentUnit}</>
          }}
        /> */}
        <Column
          width="100px"
          title={<b style={{ fontSize: '16px' }}>Giá khuyến mại</b>}
          dataIndex="sale_price"
          key="sale_price"
          render={(value_col: any, record: any, index_col: number) => (
            <>{value_col ? formatPrice(value_col) + ' đ' : '---'}</>
          )}
        />
        <Column
          width="45px"
          title={<b style={{ fontSize: '16px' }}>Áp dụng</b>}
          dataIndex="status"
          key="status"
          align="center"
          render={(value: any, record: any, index: number) => (
            <Checkbox
              key={index}
              checked={record?.status ? true : false}
              onChange={e => {
                const arr = [...storesSelected]
                const new_data_record = {
                  ...record,
                  status: e.target.checked === true ? 1 : 0,
                }
                arr[index] = new_data_record
                setStoresSelected(arr)
              }}
            />
          )}
        />
        <Column
          width="45px"
          title={<b style={{ fontSize: '16px' }}>Xóa</b>}
          dataIndex="delete"
          key="delete"
          align="center"
          render={(value: any, record: any, index: number) => (
            <Popconfirm
              placement="bottomLeft"
              title="Bạn có chắc chắn muốn xóa?"
              style={{ margin: 'auto' }}
              onConfirm={() => {
                const arr = [...storesSelected]
                arr.splice(index, 1)
                setStoresSelected(arr)
                setArrayDeletePetrol([...arrayDeletePetrol, record.id])
              }}
              okText={
                <>
                  <CloseCircleOutlined /> Xóa
                </>
              }
              cancelText={
                <>
                  <LeftCircleOutlined /> Quay lại
                </>
              }
              okButtonProps={{
                type: 'ghost',
                danger: true,
              }}
              cancelButtonProps={{
                style: {
                  minWidth: '70px',
                  fontWeight: 'bold',
                  borderColor: 'black',
                  color: 'black',
                },
              }}
            >
              <DeleteOutlined className={styles.icon_delete} />
            </Popconfirm>
          )}
        />
      </Table>
      <ChooseShopModal
        visible={visibleChooseShop}
        storesSelected={storesSelected}
        onCancel={() => {
          setVisibleChooseShop(false)
        }}
        onSave={(listStoreSelected: Array<any>) => {
          setStoresSelected(listStoreSelected)
          setStoresChecked([])

          const list_store_cancel = storesSelected.map(
            (item: any) => item.petrol_store_id
          )
          setParamsChooseShop({
            ...paramsChooseShop,
            array_petrol_store_cancle: list_store_cancel,
            page: 1,
          })
          setVisibleChooseShop(false)
        }}
        paramsChooseShop={paramsChooseShop}
        paging={paging}
        listPetrolStore={listPetrolStore}
        setParamsChooseShop={setParamsChooseShop}
      />
    </div>
  )
}
