import { Col } from 'antd'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import { useState } from 'react'

export default function Filter() {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  return (
    <Col span={10}>
      <TypingAutoSearch
        onSearchSubmit={(searchKey: string) => {
          // onSearchSubmit(searchKey.trim())
        }}
        isSearchLoading={isSearchLoading}
        placeholder="Tên hoặc số điện thoại"
      />
    </Col>
  )
}
