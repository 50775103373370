import { Col, DatePicker, Row } from 'antd'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import moment from 'moment'
import { useState } from 'react'

export default function FilterDetail() {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <TypingAutoSearch
          onSearchSubmit={(searchKey: string) => {
            // onSearchSubmit(searchKey.trim())
          }}
          isSearchLoading={isSearchLoading}
          placeholder="Tên hoặc số điện thoại"
        />
      </Col>
      <Col span={6}>
        <DatePicker
          placeholder="Hạn thanh toán"
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
        />
      </Col>
      <Col span={6}>
        <DatePicker
          style={{ width: '100%' }}
          placeholder="Ngày tạo"
          format="DD/MM/YYYY"
        />
      </Col>
    </Row>
  )
}
