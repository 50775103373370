import { ApiClient } from 'services/ApiService'

interface PayloadLogin {
  username: string
  password: string
}

export const requestLogin = (payload: PayloadLogin) =>
  ApiClient.post('manager/auth/login', payload)
export const requestLogout = () => ApiClient.put('/manager/auth/logout')
export const requestGetUserInfo = () => ApiClient.get('/user/manager/info')
export const roleAdmin = () => ApiClient.get('/role/admin')
export const rolePetrolStore = () => ApiClient.get('/role/petrol-store')
