import { Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { notificationError, notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { useOrder } from '../hook/useOrder'
import { billFullTank } from '../OrderApi'

type Props = {
  visible: boolean
  onCancel: any
  data?: any
  setIsLoading: any
  isLoading: any
  detailOrder: any
  setActualPayment: any
  handleCallbackDetail?: any
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export default function AddActualPayment({
  visible,
  onCancel,
  data,
  isLoading,
  setIsLoading,
  detailOrder,
  setActualPayment,
  handleCallbackDetail,
}: Props) {
  const { getData } = useOrder()
  const [form] = Form.useForm()
  const param = useParams()

  const addBill = async (data: any, resetFields: any) => {
    setIsLoading(true)
    try {
      const res = await billFullTank(data)
      console.log('Data: ', data)
      if (res.code === 201) {
        notificationSuccess(`Thêm mới thành công!`)
        getData()
      }
    } catch (error) {
      notificationError(`Đã xảy ra lỗi, vui lòng thử lại sau!`)
      console.log(error)
    } finally {
      // window.location.reload()
      handleCallbackDetail()
      setIsLoading(false)
      setActualPayment(false)
    }
  }
  const onFinish = async (values: any) => {
    try {
      if (+values?.total_price_reality > +detailOrder?.total_price) {
        notificationError(`Tổng tiền thực tế không được lớn hơn tổng tiền!`)
        return
      }
      delete values.confirm
      let newData

      newData = {
        ...values,
        total_price_reality: Number(values.total_price_reality),
        total_price: detailOrder.total_price,
        amount: detailOrder.detailOrder.total_amount,
        order_id: detailOrder.id,
      }
      addBill(newData, form.resetFields)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  useEffect(() => {
    if (detailOrder?.total_price_reality) {
      form.setFieldsValue({
        total_price_reality: detailOrder?.total_price_reality || 0,
      })
    }
  }, [detailOrder?.total_price_reality])

  return (
    <Modal
      onCancel={() => {
        getData()
        onCancel()
      }}
      title="Thông tin tiền đổ xăng thực tế"
      visible={visible}
      maskClosable={false}
      footer={null}
    >
      <Form
        style={{ padding: 0 }}
        {...formItemLayout}
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        // initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          label="Tổng tiền thực tế"
          name="total_price_reality"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                const reg = /^\d+$/
                if (reg.test(value) && value > 0) {
                  return Promise.resolve()
                }
                return Promise.reject()
              },
              message: 'Vui lòng nhập số tiền đúng định dạng!',
            },
          ]}
        >
          <InputNumber
            max={detailOrder.total_price}
            style={{ width: '100%' }}
            placeholder="Số tiền"
            formatter={(value: any) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          />
        </Form.Item>
        <Form.Item label="Tiền đặt hàng">
          <Input
            disabled
            placeholder={
              formatPrice(
                detailOrder.detailOrder.total_price +
                  detailOrder.detailOrder.points_used
              ) + 'đ'
            }
          />
        </Form.Item>
        <Form.Item label="Tên sản phẩm">
          <Input disabled placeholder={detailOrder.productName} />
        </Form.Item>
        <Form.Item label="Tên cửa hàng">
          <Input disabled placeholder={detailOrder.shop} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <ButtonBottomModal
            isLoadingButton={isLoading}
            onCancel={() => {
              onCancel()
              getData()
            }}
            text="Ok"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
