import { Divider, Descriptions, Typography } from 'antd'
import { useState } from 'react'
import { formatPrice } from 'utils/ruleForm'

const { Paragraph } = Typography
export default function DetailGeneral({ productData }: any) {
  const [ellipsis, setEllipsis] = useState(true)
  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin chung</b>
      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <Descriptions column={2}>
        <Descriptions.Item label="Mã sản phẩm">
          {productData.code}
        </Descriptions.Item>
        <Descriptions.Item label="Giá niêm yết sản phẩm">
          {productData.price !== 0 ? formatPrice(productData.price) : '0'} đ
        </Descriptions.Item>
        <Descriptions.Item label="Danh mục">
          {productData.category_name}
        </Descriptions.Item>

        <Descriptions.Item label="Trạng thái">
          {productData.status ? 'Đang hoạt động' : 'Tạm dừng'}
        </Descriptions.Item>
        <Descriptions.Item label="Tên sản phẩm">
          {productData.name}
        </Descriptions.Item>

        {productData.status_home ? (
          <Descriptions.Item label="Số sản phẩm lấy tại nhà">
            {productData.quantity_home !== 0
              ? formatPrice(productData.quantity_home)
              : '0'}{' '}
            sản phẩm
          </Descriptions.Item>
        ) : (
          <></>
        )}
        <Descriptions.Item label="Thứ tự ưu tiên">
          {productData.stt}
        </Descriptions.Item>
        <Descriptions.Item label="Đơn vị tính">
          {productData?.unit}
        </Descriptions.Item>
        {
          // nếu là xăng dầu và mua xăng giữ hàng
          productData.save_status && (
            <Descriptions.Item label="Thời gian giữ hàng">
              {productData.expire_time} Ngày
            </Descriptions.Item>
          )
        }
        {
          // nếu là xăng dầu và mua xăng giữ hàng
          productData.save_status && (
            <Descriptions.Item
              span={3}
              label="Số lượng được phép đăng ký giữ xăng:"
            >
              {productData.min_quantity} {productData.unit || '--'} -{' '}
              {productData.max_quantity} {productData.unit || '--'}
            </Descriptions.Item>
          )
        }
        <Descriptions.Item span={3} label="Mô tả sản phẩm">
          <Paragraph
            ellipsis={
              ellipsis
                ? {
                    expandable: true,
                    symbol: (
                      <b style={{ fontSize: '17px' }}>[Xem mô tả sản phẩm]</b>
                    ),
                  }
                : false
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: productData.description as string,
              }}
            />
          </Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}
