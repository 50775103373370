import { PageHeader } from 'antd'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { ADMIN_ROUTER_PATH } from 'common/config'
import DetailGeneral from './components/DetailGeneral'
import DetailPrice from './components/DetailPrice'
import { deleteSaleProduct } from './SaleProductApi'
import styles from 'common/components/styles/WhiteBox.module.css'
import { ButtonDelete, ButtonEdit } from 'common/components/Buttons'
import { notificationError, notificationSuccess } from 'utils/notification'
import { clearDataSaleProduct } from './SaleProductSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

export default function SaleProductDetailScreen() {
  const param: { id: any } = useParams()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState({ loadingDelete: false })

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PageHeader
          title="Chi tiết sản phẩm khuyến mại"
          onBack={() => {
            dispatch(clearDataSaleProduct())
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.SALE_PRODUCT}`,
              state: location.state,
            })
          }}
          extra={[
            <ButtonEdit
              onClickButton={() => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.EDIT_SALE_PRODUCT}/${param.id}`,
                })
              }}
              text="Sửa"
            />,
            <ButtonDelete
              text="Xóa"
              title="Bạn chắc chắn muốn xóa sản phẩm này"
              onConfirm={() => {
                try {
                  setIsLoading({ ...isLoading, loadingDelete: true })
                  deleteSaleProduct(param.id)
                  notificationSuccess('Xóa sản phẩm thành công')
                  history.goBack()
                } catch (error: any) {
                  notificationError(error.message)
                } finally {
                  setIsLoading({ ...isLoading, loadingDelete: false })
                }
              }}
            />,
          ]}
        />
      </div>
      <div className={styles.content}>
        <DetailGeneral />
      </div>
      <div className={styles.content}>
        <DetailPrice />
      </div>
    </div>
  )
}
