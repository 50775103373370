import { PageHeader, Spin, Table } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import openNotificationWithIcon from 'common/components/Notification'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import React from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import useDebounce from '../report/sale/hook/useDebounce'
import AddProductModal from './components/InventoryList/AddProductModal'
import InventoryFilter from './components/InventoryList/InventoryFilter'
import { getListWarehouses } from './components/service'

export interface IPaging {
  total: number
  current: number
  pageSize: number
}

export interface IWarehouse {
  id: number
  name: string
  quantity: number
}

const InventoryList = () => {
  const columns = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'stt',
      render: (text: any, record: any, index: any) => (
        <td id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
    },
    {
      title: <b>Tên cửa hàng</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <b>Số lượng sản phẩm</b>,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    },
    // {
    //   title: <b>Tổng tồn dầu</b>,
    //   dataIndex: 'totalPetrol',
    //   key: 'totalPetrol',
    //   render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    // },
    // {
    //   title: <b>Tổng nhập kho</b>,
    //   dataIndex: 'totalImport',
    //   key: 'totalImport',
    //   render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    // },
    // {
    //   title: <b>Tổng xuất kho</b>,
    //   dataIndex: 'totalExport',
    //   key: 'totalExport',
    //   render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    // },
    // {
    //   title: <b>Tổng cuối kỳ</b>,
    //   dataIndex: 'totalPeriod',
    //   key: 'totalPeriod',
    //   render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
    // },
  ]

  const location: any = useLocation()
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false)
  const [warehouses, setWarehouses] = React.useState<IWarehouse[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [currentPage, setCurrentPage] = React.useState<number>(
    location?.state?.currentPageList ? location?.state?.currentPageList : 1
  )
  const [search, setSearch] = React.useState<string>(
    location?.state?.search ? location?.state?.search : ''
  )
  const [params, setParams] = React.useState<any>({
    page: currentPage,
    search: search === '' ? undefined : search,
    from_date: '',
    to_date: '',
  })
  const [paging, setPaging] = React.useState<IPaging>({
    total: 0,
    current: currentPage,
    pageSize: 12,
  })

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const getWarehouses = async () => {
    try {
      setIsLoading(true)
      const res = await getListWarehouses(params)
      if (res.status) {
        const data = res.data.map((warehouse: any, index: number) => {
          return {
            id: warehouse.id,
            name: warehouse.name,
            quantity: warehouse.sum_product,
          }
        })
        const page = {
          total: res.meta.pagination.totalItems,
          current: res.meta.pagination.page,
          pageSize: res.meta.pagination.limit,
        }
        setCurrentPage(res.meta.pagination.page)
        setWarehouses(data)
        setPaging(page)
      } else {
        openNotificationWithIcon(
          'error',
          'Thông báo',
          'Đã có lỗi xảy ra! Xin vui lòng thử lại'
        )
      }
    } catch (error) {
      console.log('error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const searchDebounce = useDebounce(search, 300)

  React.useEffect(() => {
    getWarehouses()
  }, [params])

  React.useEffect(() => {
    setParams({
      ...params,
      search: searchDebounce,
      page: 1,
    })
  }, [searchDebounce])

  React.useEffect(() => {
    setParams({
      ...params,
      page: currentPage,
    })
  }, [currentPage, JSON.stringify(params)])

  React.useEffect(() => {
    setParams({
      ...params,
      from_date: location?.state?.startDate,
      to_date: location?.state?.endDate,
    })
  }, [location?.state])

  return (
    <Container
      header={
        <PageHeader
          title="Quản lý kho"
          extra={
            [
              // <ButtonAdd
              //   text="Thêm sản phẩm mới cho kho"
              //   onClickButton={showModal}
              // />,
            ]
          }
        />
      }
      filterComponent={
        <InventoryFilter
          params={params}
          setSearch={setSearch}
          search={search}
          onDateSubmit={(from_date: string, to_date: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
            })
          }}
        />
      }
      contentComponent={
        <Spin spinning={isLoading}>
          <div>
            <p>
              Kết quả lọc: <b>{paging.total}</b>
            </p>
            <Table
              bordered
              columns={columns}
              dataSource={warehouses}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              onRow={record => ({
                onClick: () => {
                  history.push({
                    pathname: `${ADMIN_ROUTER_PATH.INVENTORY_DETAIL}/${record.id}`,
                    state: {
                      id: record.id,
                      name: record.name,
                      currentPageList: currentPage,
                      listSearch: search,
                      startDate: params.from_date,
                      endDate: params.to_date,
                    },
                  })
                },
              })}
              pagination={{
                ...paging,
                showSizeChanger: false,
                onChange: async page => {
                  setPaging({ ...paging, current: page })
                  setCurrentPage(page)
                },
              }}
            />
            {isModalVisible && (
              <AddProductModal
                isModalVisible={isModalVisible}
                handleCancel={handleCancel}
                setIsLoading={setIsLoading}
                getWarehouses={getWarehouses}
                warehouses={warehouses}
              />
            )}
          </div>
        </Spin>
      }
    />
  )
}

export default InventoryList
