import { Input, Modal, Table, Tag } from 'antd'
import styles from 'common/components/styles/WhiteBox.module.css'
import Container from 'common/container/Container'
import moment from 'moment'
import { useState } from 'react'
import { PARTNER_REQUEST_STATUS } from 'utils/constants'
import { notificationSuccess, notificationError } from 'utils/notification'
import { PartnerDetailScreen } from '.'
import Filter from './components/Filter'
import Header from './components/Header'
import { useBecomePartner } from './hook/useBecomePartner'
import { acceptPartner, rejectPartner } from './ParnertApi'

const { TextArea } = Input
const columns = [
  {
    width: 70,
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: 'Tên cửa hàng',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Mã số thuế',
    dataIndex: 'tax',
    key: 'tax',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Tên người đại diện',
    dataIndex: 'representative',
    key: 'representative',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'approve_status',
    key: 'approve_status',
    render: (value: number) => {
      switch (value) {
        case PARTNER_REQUEST_STATUS.PENDING:
          return <Tag color="blue">Chờ phê duyệt</Tag>
        case PARTNER_REQUEST_STATUS.ACCEPT:
          return <Tag color="green">Phê duyệt</Tag>
        case PARTNER_REQUEST_STATUS.REJECT:
          return <Tag color="volcano">Từ chối</Tag>
      }
    },
  },
  {
    title: 'Ngày tạo ',
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]

export default function PartnerScreen() {
  const [visibleReason, setVisibleReason] = useState<boolean>(false)
  const [reason, setReason] = useState<string>('')
  const {
    paging,
    params,
    currentSelected,
    dataSource,
    setParams,
    getData,
    setCurrentSelected,
  } = useBecomePartner()

  return (
    <Container
      header={<Header />}
      filterComponent={
        <Filter
          onSearchSubmit={(searchKey: string) => {
            setTimeout(() => {
              setParams({
                ...params,
                search: searchKey.trim() === '' ? undefined : searchKey.trim(),
                page: 1,
              })
            }, 400)
          }}
          onStatusSubmit={(statusKey: number) => {
            setParams({
              ...params,
              approve_status: statusKey,
              page: 1,
            })
          }}
          onTimeSubmit={(from_date?: string, to_date?: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            bordered
            dataSource={dataSource}
            // loading={isSearchLoading}
            columns={columns}
            expandedRowKeys={[currentSelected.id]}
            onRow={(row: any) => ({
              onClick: () => {
                if (currentSelected.id !== row.id) setCurrentSelected(row)
                else setCurrentSelected({ id: -1 })
              },
            })}
            expandable={{
              expandedRowRender: (record: any) => (
                <PartnerDetailScreen
                  onAccept={async (id: any) => {
                    try {
                      const res = await acceptPartner(id)
                      notificationSuccess(res.message)
                      getData()
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                  data={record}
                  getData={getData}
                  onOpenModal={() => {
                    setVisibleReason(true)
                  }}
                />
              ),
            }}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
          <Modal
            title="Lý do từ chối"
            visible={visibleReason}
            onCancel={() => {
              setVisibleReason(false)
            }}
            okText="Từ chối"
            onOk={async () => {
              try {
                if (reason.trim()) {
                  const res = await rejectPartner(currentSelected.id, {
                    reason: reason,
                  })
                  notificationSuccess(res.message)
                  getData()
                } else {
                  notificationError('Vui lòng nhập lý do!')
                }
              } catch (error) {
                console.log(error)
              } finally {
                setVisibleReason(false)
              }
            }}
          >
            <TextArea
              placeholder="Nhập lý do từ chối"
              onChange={(e: any) => {
                setReason(e.target.value)
              }}
            />
          </Modal>
        </div>
      }
    />
  )
}
