import { Col, DatePicker, Row, Table } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { getListRankCustomer } from '../CustomerApi'

type Props = {
  point: any
  rank: any
}
const { RangePicker } = DatePicker
type TParamHistoryPoint = {
  page: number
  action?: number
  from_date?: string | Date
  to_date?: string | Date
  type?: number
}
const column = [
  {
    width: '70px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    width: '170px',
    title: <b>Mã đơn hàng</b>,
    dataIndex: 'data',
    key: 'data',
    render: (value: any) => <>{value.order_code}</>,
  },
  {
    title: <b>Nội dung</b>,
    dataIndex: 'type_rank_transaction_history',
    key: 'type_rank_transaction_history',
    render: (value: any) => <>{value.title}</>,
  },
  {
    title: <b>Số điểm</b>,
    dataIndex: 'point',
    key: 'point',
    render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
  },
  // {
  //   width: '100px',
  //   title: <b>Số dư</b>,
  //   dataIndex: 'current_point',
  //   key: 'current_point',
  //   render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)}</>,
  // },
  {
    width: '150px',
    title: <b>Ngày tạo</b>,
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
    },
  },
]
export default function Rank({ point, rank }: Props) {
  const param: any = useParams()
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [params, setParams] = useState<TParamHistoryPoint>({
    page: 1,
    action: undefined,
    from_date: undefined,
    to_date: undefined,
    type: undefined,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      setIsLoading(true)
      const res = await getListRankCustomer(param.id, params)
      const dataTable = res.data.map((item: any, index: number) => ({
        ...item,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
      }))
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
      setDataSource(dataTable)
    } catch (error: any) {
      notificationError('Hệ thống gặp sự cố. Vui lòng thử lại!!')
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div>
      <Row style={{ paddingBottom: '15px' }} justify="start" gutter={[16, 16]}>
        <Col>
          <RadiusSelection
            placeholder="Tác vụ"
            style={{ minWidth: '220px' }}
            onChange={(value: number) => {
              setParams({
                ...params,
                action: value,
                page: 1,
              })
            }}
            options={[
              { value: 1, text: 'Cộng điểm' },
              { value: 0, text: 'Trừ điểm' },
            ]}
          />
        </Col>
        <Col>
          <RangePicker
            placeholder={['Từ ngày', 'đến ngày']}
            onChange={(value, dateString) => {
              setParams({
                ...params,
                to_date: dateString[1],
                from_date: dateString[0],
                page: 1,
              })
            }}
          />
        </Col>
      </Row>
      <div style={{ fontSize: '18px', marginBottom: '10px' }}>
        <Row justify="space-between">
          <span>Hạng hiện tại: {rank}</span>
          <span>
            Điểm hiện tại:
            {point === 0 ? '0' : formatPrice(point)}
          </span>
        </Row>
      </div>
      <Table
        dataSource={dataSource}
        columns={column}
        loading={isLoading}
        bordered
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            setParams({ ...params, page })
          },
        }}
      />
    </div>
  )
}
