import { Row, Col, DatePicker } from 'antd'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import { useState } from 'react'

const { RangePicker } = DatePicker

export default function Filter() {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  return (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <TypingAutoSearch
          onSearchSubmit={(searchKey: string) => {}}
          isSearchLoading={isSearchLoading}
          placeholder="Tên gian hàng"
        />
      </Col>
      <Col span={6}>
        <RangePicker
          placeholder={['Từ ngày', 'Đến ngày']}
          onChange={(dates, dateStrings) => {
            // onTimeSubmit(dateStrings[0], dateStrings[1])
          }}
        />
      </Col>
    </Row>
  )
}
