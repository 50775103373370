import { Col, PageHeader, Row } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import { useState } from 'react'
import { useAccount } from '../hook/useAccount'
import { AddEditAccount } from './AddEditAccount'

type HeaderProps = {
  setIsCreate: any
}

export const Header = ({ setIsCreate }: HeaderProps) => {
  return (
    <>
      <PageHeader
        title="Tài khoản "
        extra={[
          <Row gutter={[16, 16]}>
            <Col span={3}>
              <ButtonAdd
                text="Tạo tài khoản"
                onClickButton={() => setIsCreate(true)}
              />
            </Col>
          </Row>,
        ]}
      />
    </>
  )
}
