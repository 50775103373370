import Icon from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import { IS_ACTIVE } from 'utils/constants'
type FilterType = {
  data: any
  onSearchSubmit: (searchKey: string) => void
  onTypeShopSubmit: (type_shop_id: number) => void
  onStatusSubmit: (statusKey: number) => void
}
export default function Filter({
  data,
  onSearchSubmit,
  onTypeShopSubmit,
  onStatusSubmit,
}: FilterType) {
  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[16, 16]} justify="start">
        <Col span={8}>
          <Input.Search
            allowClear
            placeholder="Nhập tên công ty, số điện thoại, mã số thuế"
            addonAfter={<Icon type="close-circle-o" />}
            value={data.search}
            onChange={e => {
              onSearchSubmit(e.target.value)
            }}
          />
        </Col>

        <Col span={8}>
          <RadiusSelection
            style={{ width: '100%' }}
            placeholder="Loại cửa hàng"
            onChange={(value: number) => {
              onTypeShopSubmit(value)
            }}
            value={data.type_petrol_store_id}
            options={[
              { value: 7, text: 'Cửa hàng xăng/dầu' },
              { value: 8, text: 'Cửa hàng đối tác' },
            ]}
          />
        </Col>
        <Col span={8}>
          <RadiusSelection
            placeholder="Trạng thái"
            onChange={(value: number) => {
              onStatusSubmit(value)
            }}
            value={data.status}
            options={[
              { value: IS_ACTIVE.ACTIVE, text: 'Đang hoạt động' },
              { value: IS_ACTIVE.INACTIVE, text: 'Ngừng hoạt động' },
            ]}
          />
        </Col>
      </Row>
    </div>
  )
}
