import { Col, Input, Row } from 'antd'

import Icon from '@ant-design/icons'
type Props = {
  // onSearchSubmit: (searchKey: string) => void
  isSearchLoading: boolean
}

export default function Filter({ isSearchLoading }: Props) {
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={10}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Tên đối tác, SĐT, Mã số thuế, Email"
          addonAfter={<Icon type="close-circle-o" />}
          // value={data.search}
          onChange={(e: any) => {
            // onSearchSubmit(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}
