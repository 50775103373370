import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { notificationError } from 'utils/notification'
import { requestDetailProduct } from '../SaleProductApi'
import { TGeneralInforProduct } from '../SaleProduct'

export const useDetailProduct = () => {
  const param: any = useParams()
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const [params, setParams] = useState({ search: '', page: 1 })
  const [selectedRowList, setSelectedRowList] = useState<Array<any>>([])
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })
  const [productData, setProductData] = useState<TGeneralInforProduct>({
    name: '',
    category_name: '',
    description: '',
    unit: '',
  })
  const [dataSource, setDataSource] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
    if (param.id) {
      getDetailPertrolStore()
    }
  }, [param])

  useEffect(() => {
    setTimeout(() => {}, 300)
  }, [params])

  const getDetailPertrolStore = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await requestDetailProduct(parseInt(param.id))

      setProductData({
        ...productData,
        name: res.data.name,
        unit: res.data.unit,
        category_name: res.data.product_category.name,
        description: res.data.promotional_product.description,
      })
      const dataTable = res.data.promotional_product.store_promotional_products.map(
        (item: any) => {
          return {
            name: item?.petrol_store?.name,
            address:
              (item?.petrol_store?.location_address
                ? item?.petrol_store?.location_address + ', '
                : '') +
              (item?.petrol_store?.ward?.name
                ? item?.petrol_store?.ward?.name + ', '
                : '') +
              (item?.petrol_store?.district?.name
                ? item?.petrol_store?.district?.name + ', '
                : '') +
              (item?.petrol_store?.province?.name
                ? item?.petrol_store?.province?.name
                : ''),
            price: item?.price,
            check_sale: item?.check_sale,
            price_root: item?.base_price,
            status: item?.status,
            promotion_time: [item.start_at, item.finish_at],
            pertrol_store_id: item?.id,
            discount: item?.discount,
          }
        }
      )
      setDataSource(dataTable)
    } catch (error: any) {
      notificationError(`Đã xảy ra lỗi, xin vui lòng thử lại`)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      const selectedRowID = selectedRows.map((item: any) => {
        return item
      })
      setSelectedRowList(selectedRowID)
    },
    getCheckboxProps: (record: any) => ({
      id: record.id,
    }),
  }
  return {
    isSearchLoading,
    isLoading,
    params,
    selectedRowList,
    paging,
    dataSource,
    rowSelection,
    productData,
    setProductData,
    setParams,
  }
}
