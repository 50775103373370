export const RESTART_ON_REMOUNT = '@saga-injector/restart-on-remount'

export const BANNER_STATUS_POST = {
  STATUS_POST: 1,
  STATUS_DRAFT: 0,
}
export const TYPE_BANNER_STATUS = {
  BANNER: 1,
  NEWS: 0,
}
export const TYPE_DEBT = {
  INCREASE_DEBT: 1, // Tăng dư nợ
  COMPLETE_ORDER_DEBT: 2, // Đã thanh toán
  CANCEL_ORDER_DEBT: 3,
}
export const TYPE_PAYMENT = {
  VNPAY: 1, // VnPay
  BANK_TRANSFER: 2, // Chuyển khoản
  CANCEL_ORDER_DEBT: 3,
}
export const API_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  NOTFOUND: 404,
  BADREQUEST: 400,
  SERVER: 500,
  UNAUTHORIZED: 401,
  CONFLICT: 409,
  FORBIDDEN: 403,
}
export const CONFIG_TYPE = {
  ATTENDANCE: 1,
  ORDER_PROMOTION: 2,
  DAILY_TURN: 3,
  REFERRAL_APP: 4,
  REFERRAL_CODE: 5,
  REFERRAL_MEMBER: 6,
}

export const REQUEST_STATUS = {
  PENDING: 0, // Chờ phản hồi
  SUCCESS: 1, // Đồng ý
  REFUSE: 2, // Từ chối
}
export const PARTNER_REQUEST_STATUS = {
  PENDING: 2, // Đang yêu cầu
  ACCEPT: 1, // Chấp nhận
  REJECT: 0, // Từ chối
}
export const ORDER_STATUS = {
  PENDING: 1, // chờ xác nhận
  CONFIRMED: 2, // đang thực hiện
  SHIP: 3, // đang vận chuyển
  SUCCCESS: 4, // hoàn thành
  CANCELED: 5, // huỷ
  DENY: 6, // từ chối
}
export const GENDER = {
  MALE: 0,
  FEMALE: 1,
}
// export const REG_PHONE = /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/
export const REG_PHONE = /(84|0[3|5|7|8|9])+([0-9]{8})\b/

export const IS_ACTIVE = {
  ACTIVE: 1, // Hoạt động
  INACTIVE: 0, // Ngưng hoạt động
}
export const ENTERPRISE_REQUEST_STATUS = {
  PENDING: 2, // Chờ phê duyệt
  ACCEPT: 1, // Chấp nhận
  REJECT: 0, // Từ chối
}
export const MEDIA_NAME = {
  IMAGE: 'image',
  VIDEO: 'video',
}

export const ACCOUNT_R0LE = [
  { id: 21, name: 'Admin' }, // admin web quản trị
  { id: 22, name: 'Nhân viên kinh doanh' }, // nhân viên kinh doanh web quản trị
  { id: 23, name: 'Admin' }, // supper admin cửa hàng xăng dầu
  { id: 24, name: 'Nhân viên kinh doanh' }, // nhân viên kinh doanh cửa hàng xăng dầu
  { id: 26, name: 'Kế toán' }, // kế toán web quản trị
  { id: 27, name: 'Admin' }, // kế toán web quản trị
  { id: 28, name: 'Admin' }, // admin cửa hàng xăng dầu
]

export const WEB_ADMIN_ROLES = [
  // { value: 21, text: 'Admin' },
  { value: 22, text: 'Nhân viên kinh doanh' },
  { value: 26, text: 'Kế toán' },
  { value: 27, text: 'Admin' },
]

export const WEB_ADMIN_ROLES_ADMIN = [
  { value: 22, text: 'Nhân viên kinh doanh' },
  { value: 26, text: 'Kế toán' },
]

export const WEB_PETROL_ROLES = [
  { value: 28, text: 'Admin' },
  { value: 24, text: 'Nhân viên kinh doanh' },
]

export const WEB_PETROL_ROLES_ADMIN = [
  { value: 24, text: 'Nhân viên kinh doanh' },
]

export const PROVINCES = [
  {
    id: 201,
    name: 'Hà Nội',
    code: '4',
  },
  {
    id: 202,
    name: 'Hồ Chí Minh',
    code: '8',
  },
  {
    id: 203,
    name: 'Đà Nẵng',
    code: '511',
  },
  {
    id: 204,
    name: 'Đồng Nai',
    code: '61',
  },
  {
    id: 205,
    name: 'Bình Dương',
    code: '065',
  },
  {
    id: 206,
    name: 'Bà Rịa - Vũng Tàu',
    code: '64',
  },
  {
    id: 207,
    name: 'Gia Lai',
    code: '59',
  },
  {
    id: 208,
    name: 'Khánh Hòa',
    code: '58',
  },
  {
    id: 209,
    name: 'Lâm Đồng',
    code: '63',
  },
  {
    id: 210,
    name: 'Đắk Lắk',
    code: '500',
  },
  {
    id: 211,
    name: 'Long An',
    code: '72',
  },
  {
    id: 212,
    name: 'Tiền Giang',
    code: '73',
  },
  {
    id: 213,
    name: 'Bến Tre',
    code: '75',
  },
  {
    id: 214,
    name: 'Trà Vinh',
    code: '74',
  },
  {
    id: 215,
    name: 'Vĩnh Long',
    code: '70',
  },
  {
    id: 216,
    name: 'Đồng Tháp',
    code: '67',
  },
  {
    id: 217,
    name: 'An Giang',
    code: '76',
  },
  {
    id: 218,
    name: 'Sóc Trăng',
    code: '79',
  },
  {
    id: 219,
    name: 'Kiên Giang',
    code: '77',
  },
  {
    id: 220,
    name: 'Cần Thơ',
    code: '710',
  },
  {
    id: 221,
    name: 'Vĩnh Phúc',
    code: '211',
  },
  {
    id: 223,
    name: 'Thừa Thiên - Huế',
    code: '54',
  },
  {
    id: 224,
    name: 'Hải Phòng',
    code: '31',
  },
  {
    id: 225,
    name: 'Hải Dương',
    code: '320',
  },
  {
    id: 226,
    name: 'Thái Bình',
    code: '36',
  },
  {
    id: 227,
    name: 'Hà Giang',
    code: '219',
  },
  {
    id: 228,
    name: 'Tuyên Quang',
    code: '27',
  },
  {
    id: 229,
    name: 'Phú Thọ',
    code: '210',
  },
  {
    id: 230,
    name: 'Quảng Ninh',
    code: '33',
  },
  {
    id: 231,
    name: 'Nam Định',
    code: '350',
  },
  {
    id: 232,
    name: 'Hà Nam',
    code: '351',
  },
  {
    id: 233,
    name: 'Ninh Bình',
    code: '30',
  },
  {
    id: 234,
    name: 'Thanh Hóa',
    code: '37',
  },
  {
    id: 235,
    name: 'Nghệ An',
    code: '38',
  },
  {
    id: 236,
    name: 'Hà Tĩnh',
    code: '39',
  },
  {
    id: 237,
    name: 'Quảng Bình',
    code: '52',
  },
  {
    id: 238,
    name: 'Quảng Trị',
    code: '53',
  },
  {
    id: 239,
    name: 'Bình Phước',
    code: '651',
  },
  {
    id: 240,
    name: 'Tây Ninh',
    code: '66',
  },
  {
    id: 241,
    name: 'Đắk Nông',
    code: '501',
  },
  {
    id: 242,
    name: 'Quảng Ngãi',
    code: '55',
  },
  {
    id: 243,
    name: 'Quảng Nam',
    code: '510',
  },
  {
    id: 244,
    name: 'Thái Nguyên',
    code: '280',
  },
  {
    id: 245,
    name: 'Bắc Kạn',
    code: '281',
  },
  {
    id: 246,
    name: 'Cao Bằng',
    code: '26',
  },
  {
    id: 247,
    name: 'Lạng Sơn',
    code: '25',
  },
  {
    id: 248,
    name: 'Bắc Giang',
    code: '240',
  },
  {
    id: 249,
    name: 'Bắc Ninh',
    code: '241',
  },
  {
    id: 250,
    name: 'Hậu Giang',
    code: '711',
  },
  {
    id: 252,
    name: 'Cà Mau',
    code: '780',
  },
  {
    id: 253,
    name: 'Bạc Liêu',
    code: '781',
  },
  {
    id: 258,
    name: 'Bình Thuận',
    code: '62',
  },
  {
    id: 259,
    name: 'Kon Tum',
    code: '60',
  },
  {
    id: 260,
    name: 'Phú Yên',
    code: '57',
  },
  {
    id: 261,
    name: 'Ninh Thuận',
    code: '68',
  },
  {
    id: 262,
    name: 'Bình Định',
    code: '56',
  },
  {
    id: 263,
    name: 'Yên Bái',
    code: '29',
  },
  {
    id: 264,
    name: 'Lai Châu',
    code: '231',
  },
  {
    id: 265,
    name: 'Điện Biên',
    code: '230',
  },
  {
    id: 266,
    name: 'Sơn La',
    code: '22',
  },
  {
    id: 267,
    name: 'Hòa Bình',
    code: '218',
  },
  {
    id: 268,
    name: 'Hưng Yên',
    code: '321',
  },
  {
    id: 269,
    name: 'Lào Cai',
    code: '20',
  },
]
