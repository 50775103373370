import Filter from './components/Filter'
import { Input, Modal, PageHeader, Table, Tag } from 'antd'
import moment from 'moment'
import { useEnterprise } from './hook/useEnterprise'
import EnterpriseDetail from './components/EnterpriseDetail'
import Container from 'common/container/Container'
import styles from 'common/components/styles/WhiteBox.module.css'
import { ENTERPRISE_REQUEST_STATUS } from 'utils/constants'
import { notificationError, notificationSuccess } from 'utils/notification'
import { acceptEnterprise, rejectEnterprise } from './EnterpriseApi'
import { useState } from 'react'

const { TextArea } = Input
const column = [
  {
    width: '65px',
    title: <b>STT</b>,
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: <b>Tên doanh nghiệp/ khách hàng</b>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: <b>Mã số thuế</b>,
    dataIndex: 'tax_code',
    key: 'tax_code',
  },
  {
    title: <b>Email</b>,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <b>Địa chỉ mã số thuế</b>,
    dataIndex: 'tax_address',
    key: 'tax_address',
  },
  {
    title: <b>Ngày tạo</b>,
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'approve_status',
    key: 'approve_status',
    render: (value: any) => {
      switch (value) {
        case ENTERPRISE_REQUEST_STATUS.PENDING:
          return <Tag color="blue">Chờ phê duyệt</Tag>
        case ENTERPRISE_REQUEST_STATUS.ACCEPT:
          return <Tag color="green">Phê duyệt</Tag>
        case ENTERPRISE_REQUEST_STATUS.REJECT:
          return <Tag color="volcano">Từ chối</Tag>
      }
    },
  },
]

export default function EnterpriseScreen() {
  const [visibleReason, setVisibleReason] = useState<boolean>(false)
  const [reason, setReason] = useState<string>('')

  const {
    isLoading,
    currentSelected,
    params,
    paging,
    setCurrentSelected,
    setParams,
    dataSource,
    setDataSource,
    getData,
  } = useEnterprise()

  return (
    <Container
      header={<PageHeader title="Yêu cầu trở thành Doanh nghiệp" />}
      filterComponent={
        <Filter
          onSearchSubmit={(searchKey: string) => {
            setTimeout(() => {
              setParams({
                ...params,
                search: searchKey.trim() === '' ? undefined : searchKey.trim(),
                page: 1,
              })
            }, 400)
          }}
          onStatusSubmit={(statusKey: number) => {
            setParams({
              ...params,
              approve_status: statusKey,
              page: 1,
            })
          }}
          onTimeSubmit={(from_date: string | Date, to_date: string | Date) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{paging.total}</b>
          </p>
          <Table
            className={styles.table_hover}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            bordered
            dataSource={dataSource}
            loading={isLoading.loadingTable}
            columns={column}
            expandedRowKeys={[currentSelected.id]}
            onRow={(row: any) => ({
              onClick: () => {
                if (currentSelected.id !== row.id) setCurrentSelected(row)
                else setCurrentSelected({ id: -1 })
              },
            })}
            expandable={{
              expandedRowRender: (record: any, index: number) => (
                <EnterpriseDetail
                  onAccept={async (id: number) => {
                    try {
                      await acceptEnterprise(id)
                      notificationSuccess(
                        `${record.name} đã trở thành doanh nghiệp `
                      )
                      const record_value = [...dataSource]
                      record_value[index].approve_status =
                        ENTERPRISE_REQUEST_STATUS.ACCEPT
                      setDataSource(record_value)
                    } catch (error: any) {
                      notificationError(error.message)
                    }
                  }}
                  onRefuse={async (id: number) => {}}
                  onOpenModal={() => {
                    setVisibleReason(true)
                  }}
                  data={record}
                />
              ),
            }}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
          <Modal
            title="Lý do từ chối"
            visible={visibleReason}
            onCancel={() => {
              setVisibleReason(false)
            }}
            okText="Từ chối"
            onOk={async () => {
              if (reason) {
                try {
                  await rejectEnterprise(currentSelected.id, { reason: reason })
                  notificationSuccess(
                    `Đã từ chối yêu cầu trở thành doanh nghiệp`
                  )
                  getData()
                } catch (error: any) {
                  notificationError(error.message)
                } finally {
                  setVisibleReason(false)
                }
              } else {
                notificationError('Vui lòng nhập lý do!')
              }
            }}
          >
            <TextArea
              placeholder="Nhập lý do từ chối"
              onChange={(e: any) => {
                setReason(e.target.value)
              }}
            />
          </Modal>
        </div>
      }
    />
  )
}
