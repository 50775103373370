import { Descriptions, Tabs, Card, Image, Row, Col } from 'antd'
import {
  ButtonActive,
  ButtonDelete,
  ButtonEdit,
} from 'common/components/Buttons'
import moment from 'moment'
import { AddEditPartner } from './AddEditPartner'
import { useConfigPartner } from '../hook/useConfigPartner'

type Props = {
  data: any
  getData: any
  onDeletePartner: any
  onChangeStatus: any
  onUpdatePartner: any
  showEditPartner: any
  setShowEditPartner: any
  setIsLoading: any
  isLoading: any
}

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const ContentView = (data: any) => {
  return (
    <Row gutter={[16, 16]}>
      <Col sm={8}>
        <Descriptions size="default" column={3}>
          <Descriptions.Item label="Ảnh đối tác">
            <Image
              style={{ objectFit: 'cover', width: '100px', height: '100px' }}
              src={data.icon_url}
            />
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col sm={16}>
        <Descriptions size="default" column={2}>
          <Descriptions.Item label="Tên đối tác">{data.name}</Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {data.status ? 'Hoạt động' : 'Tạm dừng'}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày tạo">
            {moment(data.create_at).format('DD/MM/YYYY')}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

function DetailPartner({
  data,
  getData,
  onDeletePartner,
  onChangeStatus,
  onUpdatePartner,
  setShowEditPartner,
  showEditPartner,
  setIsLoading,
  isLoading,
}: Props) {
  const {} = useConfigPartner()
  return (
    <div>
      {showEditPartner && (
        <AddEditPartner
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          getData={getData}
          data={data}
          visible={showEditPartner}
          onCancel={() => {
            setShowEditPartner(false)
            getData()
          }}
          onUpdatePartner={(newData: any, resetFields: any) => {
            onUpdatePartner(newData, resetFields)
            setIsLoading(true)
            getData()
          }}
        />
      )}
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        style={{ padding: '0px 10px' }}
      >
        <TabPane tab="Chi tiết đối tác" key="1">
          <Card
            actions={[
              <ButtonActive
                typeButton="text"
                title={
                  data.status
                    ? 'Bạn chắc chắn muốn ngừng hoạt động đối tác này?'
                    : 'Bạn chắc chắn muốn hoạt động đối tác này?'
                }
                isActive={data.status}
                onConfirm={async () => {
                  try {
                    onChangeStatus(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
              />,
              <ButtonEdit
                typeButton="text"
                text="Chỉnh sửa"
                onClickButton={() => {
                  setShowEditPartner(true)
                }}
                styleButton
              />,
              <ButtonDelete
                typeButton="text"
                title="Bạn chắc chắn muốn xóa đối tác này?"
                text="Xóa đối tác"
                onConfirm={async () => {
                  try {
                    onDeletePartner(data.id)
                    getData()
                  } catch (error) {
                  } finally {
                  }
                }}
              />,
            ]}
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default DetailPartner
