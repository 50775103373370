import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Form } from 'antd'
import InputTooltip from 'common/components/Inputs/InputTooltip'
import { useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { updateConfigSystem } from '../ConfigApi'

type Props = {
  listConfigSystem: any
  setListConfigSystem: any
  getDataSystems: any
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}
function Referral({
  listConfigSystem,
  setListConfigSystem,
  getDataSystems,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState()
  const [form] = Form.useForm()
  const onFinish = async (values: any) => {
    setLoading(true)
    try {
      delete values.confirm
      let newData

      newData = {
        ...values,
        id: 2,
        point: Number(values.point).toFixed(3),
      }
      updateConfigSystem(newData)
      notificationSuccess('Cập nhật thành công')
      getDataSystems()
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  return (
    <>
      <div
        style={{
          borderWidth: '1px',
          borderColor: 'black',
          borderStyle: 'solid',
          padding: 20,
          height: 235,
        }}
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          form={form}
          onFinish={(values: any) => onFinish(values)}
          scrollToFirstError
        >
          <Form.Item
            {...tailFormItemLayout}
            label={<span style={{ fontSize: 18 }}>Giới thiệu</span>}
          >
            <Button
              loading={loading}
              type="primary"
              style={{
                float: 'right',
                minWidth: '70px',
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
              htmlType="submit"
            >
              <CheckCircleOutlined />
              Lưu
            </Button>
          </Form.Item>
          <Divider />
          <Form.Item
            label={<span style={{ fontSize: 16 }}>Giới thiệu ứng dụng</span>}
            name="point"
            rules={[
              {
                validator: (_, value) => {
                  const reg = /^\d+$/
                  if (reg.test(value) && value >= 0 && value <= 100000000000) {
                    return Promise.resolve()
                  }
                  return Promise.reject()
                },
                message: 'Vui lòng nhập số tiền từ 1 đến 100,000,000,000',
              },
            ]}
          >
            {/* <Input
              value={
                listConfigSystem?.data
                  ? formatPrice(listConfigSystem?.data?.[1].value)
                  : 0
              }
              placeholder={
                listConfigSystem?.data?.[1].value
                  ? formatPrice(listConfigSystem?.data?.[1].value)
                  : 0
              }
              style={{ width: 150, float: 'right' }}
            /> */}
            <InputTooltip
              title={formatPrice(title)}
              value={
                listConfigSystem?.data
                  ? formatPrice(listConfigSystem?.data?.[1].value)
                  : 0
              }
              onChange={(e: any) => {
                setTitle(e)
              }}
              placeholder={
                listConfigSystem?.data?.[1].value
                  ? formatPrice(listConfigSystem?.data?.[1].value)
                  : 0
              }
              style={{ width: 150, float: 'right' }}
            />
          </Form.Item>
          <span style={{ color: '#990000', fontSize: 'small', float: 'right' }}>
            * Với đơn hàng đầu tiên của người được giới thiệu.
          </span>
        </Form>
      </div>
    </>
  )
}
export default Referral
