import { Table, Tag } from 'antd'
import React from 'react'
import { IPaging, ItemRefuelHistory } from '../OrderDetailScreen'
import styles from '../styles/style.module.css'

interface IHistoryRefueling {
  refuelList: ItemRefuelHistory[]
  paging: IPaging
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const HistoryRefueling = (props: IHistoryRefueling) => {
  const { refuelList, paging, setCurrentPage } = props

  const column = [
    {
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
      render: (text: string, record: any, index: number) => {
        return (
          <td id={record.id}>{(paging.page - 1) * paging.limit + index + 1}</td>
        )
      },
    },
    {
      title: <b>Tên sản phẩm</b>,
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: number) => {
        if (value === 1) {
          return <td style={{ color: 'green' }}>Đổ xăng</td>
        } else {
          return <td style={{ color: 'red' }}>Quá hạn</td>
        }
      },
    },
    {
      title: <b>Số lượng đổ</b>,
      dataIndex: 'refueledLit',
      key: 'refueledLit',
      render: (value: number) => {
        return <Tag color={'red'}>- {value}</Tag>
      },
    },
    {
      title: <b>Số dư</b>,
      dataIndex: 'remainedLit',
      key: 'remainedLit',
    },
    {
      title: <b>Thời gian</b>,
      dataIndex: 'date',
      key: 'date',
    },
  ]

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className={styles.title}>
        <b style={{ fontSize: 16 }}>Lịch sử đổ xăng</b>
        <div className={styles.divider}></div>
      </div>
      <div style={{ padding: '15px 15px' }}>
        <Table
          dataSource={refuelList}
          columns={column}
          bordered
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            // y: 'calc(100vh - 300px)',
          }}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: (page: number) => {
              setCurrentPage(page)
            },
          }}
        />
      </div>
    </div>
  )
}

export default HistoryRefueling
