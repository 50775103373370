import { ADMIN_ROUTER_PATH } from 'common/config'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { notificationError, notificationSuccess } from 'utils/notification'
import { TDataPush, TGeneralInforProduct } from '../Product'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import {
  createProduct,
  requestDetailProduct,
  updateProduct,
} from '../ProductApi'
import {
  setCodeProduct,
  setNameProduct,
  setCategoryProduct,
  setDescriptionProduct,
  setListedPriceProduct,
  getProductInfor,
  getOriginStoreSelected,
  setOriginStoresSelected,
  clearDataProduct,
  setStatusHome,
  setQuantityHome,
  setPriority,
  setUnitProduct,
  setMinQuantityProduct,
  setMaxQuantityProduct,
  setExpireTimeProduct,
  setSaveStatus,
} from '../ProductSlice'

export const useAddEditProduct = () => {
  const location: any = useLocation()
  const productInfor = useSelector(getProductInfor)
  const orginStoresSelected = useSelector(getOriginStoreSelected)
  const dispatch = useDispatch()
  const param: any = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState({
    loadingSearch: false,
    loadingTable: false,
    loadingTableStore: false,
    loadingUpdate: false,
  })
  const [arrayDeletePetrol, setArrayDeletePetrol] = useState<Array<number>>([])
  const [storesSelected, setStoresSelected] = useState<Array<any>>([])
  const [storesChecked, setStoresChecked] = useState<Array<any>>([])
  const [listImages, setListImages] = useState<Array<any>>(
    Array.from(Array(5).keys()).map(i => {
      return {
        id: i,
        fileList: [],
        buffer: null,
        url: '',
      }
    })
  )

  useEffect(() => {
    if (param.id) {
      getDataProduct()
    }
    dispatch(clearDataProduct())
  }, [])

  // Lấy thông tin sản phẩm khi sửa
  const getDataProduct = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await requestDetailProduct({ product_id: param.id })

      dispatch(setNameProduct(res.data.name))
      dispatch(setCodeProduct(res.data.code))
      dispatch(setListedPriceProduct(res.data.price))
      dispatch(setCategoryProduct(res.data.product_category.id))
      dispatch(setDescriptionProduct(res.data.description))
      dispatch(setPriority(res.data.stt))
      dispatch(setStatusHome(res.data.status_home))
      dispatch(setQuantityHome(res.data.quantity_home))

      // dispatch(setUnitProduct(res.data.unit))
      dispatch(setUnitProduct(res.data.product_unit_id))
      dispatch(setMinQuantityProduct(res.data.min_quantity))
      dispatch(setMaxQuantityProduct(res.data.max_quantity))
      dispatch(setExpireTimeProduct(res.data.expire_time))
      dispatch(setSaveStatus(res.data.save_status ? true : false))

      const images = listImages.map((item: any, index: number) => {
        if (res.data.product_medias[index]?.media_url) {
          return {
            id: item.id,
            fileList: [{ url: res.data.product_medias[index]?.media_url }],
            buffer: null,
            url: res.data.product_medias[index]?.media_url,
          }
        }
        return {
          id: index,
          fileList: [],
          buffer: null,
          url: '',
        }
      })
      setListImages(images)

      const dataTable = res.data.product_stocks.map((item: any) => {
        return {
          id: item.id,
          name: item?.petrol_store?.name,
          address: item?.petrol_store?.address,
          location_address:
            item?.petrol_store?.location_address +
            ', ' +
            item?.petrol_store?.ward?.name +
            ', ' +
            item?.petrol_store?.district?.name +
            ', ' +
            item?.petrol_store?.province?.name,
          price: item?.price,
          sale_price: item?.sale_price,
          status: item?.status,
          petrol_store_id: item?.petrol_store_id,
          unit: 'lit hihi',
        }
      })
      setStoresSelected(dataTable)
      dispatch(setOriginStoresSelected(dataTable))
    } catch (error: any) {
      notificationError(error?.message ? error.message : 'Hệ thống gặp sự cố')
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      const listStore = selectedRows.map((item: any) => {
        return {
          id: item?.id,
          petrol_store_id: item?.id,
          name: item?.name,
          location_address:
            (item?.location_address ? item?.location_address + ',' : '') +
            (item?.district?.name ? item?.district?.name + ',' : '') +
            item?.province?.name,
          price: 0,
          status: 1,
        }
      })
      setStoresChecked(listStore)
    },
  }

  const validateBeforeConfirm = () => {
    console.log(
      'productInfor.description?.trim(): ',
      productInfor.description?.trim().length
    )

    if (productInfor.code?.trim() === '') {
      notificationError('Vui lòng nhập mã sản phẩm')
      document.getElementById('code_product')?.focus()
      return false
    }

    if (productInfor.code?.length > 55 || productInfor.code?.length < 6) {
      notificationError('Độ dài mã sản phẩm 6-55 ký tự')
      document.getElementById('code_product')?.focus()
      return false
    }
    if (productInfor.name?.trim() === '') {
      notificationError('Vui lòng nhập tên sản phẩm')
      document.getElementById('name_product')?.focus()
      return false
    }
    if (productInfor.name?.length > 55 || productInfor.name?.length < 6) {
      notificationError('Độ dài tên sản phẩm 6-55 ký tự')
      document.getElementById('name_product')?.focus()
      return false
    }
    if (!productInfor.category_id) {
      notificationError('Vui lòng chọn danh mục sản phẩm')
      document.getElementById('category_id')?.focus()
      return false
    }
    if (productInfor.price < 1 || productInfor.price > 1000000000) {
      notificationError('Vui lòng nhập giá niêm yết từ 1 - 1,000,000,000')
      document.getElementById('listed_price_product')?.focus()

      return false
    }
    if (!productInfor.stt) {
      notificationError('Vui lòng nhập thứ tự ưu tiên')
      document.getElementById('priority')?.focus()

      return false
    }
    if (productInfor.description?.trim().length <= 7) {
      notificationError('Vui lòng nhập mô tả sản phẩm')
      document.getElementById('description_product')?.focus()
      return false
    }

    if (productInfor.isAllSpace) {
      notificationError('Mô tả sản phẩm đang là khoảng trắng! ')
      document.getElementById('description_product')?.focus()
      return false
    }
    if (!productInfor.quantity_home && productInfor.status_home === 1) {
      notificationError('Vui lòng nhập số lượng sản phẩm lấy tại nhà')
      document.getElementById('description_product')?.focus()
      return false
    }

    if (!productInfor.unit && productInfor.category_id !== 3) {
      console.log('productInfor.category_id : ', productInfor.category_id)
      notificationError('Vui lòng chọn đơn vị tính')
      return false
    }

    if (productInfor.save_status && !productInfor.min_quantity) {
      notificationError('Vui lòng nhập số lượng tối thiểu được phép giữ xăng')
      document.getElementById('min_quantity')?.focus()
      return false
    }
    if (productInfor.save_status && !productInfor.max_quantity) {
      notificationError('Vui lòng nhập số lượng tối đa được phép giữ xăng')
      document.getElementById('max_quantity')?.focus()
      return false
    }

    if (
      productInfor.save_status &&
      productInfor.min_quantity &&
      productInfor.max_quantity &&
      productInfor.max_quantity < productInfor.min_quantity
    ) {
      notificationError('Số lượng tối thiếu phải nhỏ hơn số lượng tối da')
      document.getElementById('min_quantity')?.focus()
      return false
    }

    if (productInfor.save_status && !productInfor.expire_time) {
      notificationError('Vui lòng nhập thời gian giữ hàng')
      document.getElementById('expire_time')?.focus()
      return false
    }

    if (listImages[0].url === '') {
      notificationError('Vui lòng chọn ảnh bìa sản phẩm')
      document.getElementById('image_product')?.focus()
      return false
    }

    for (let i = 0; i < storesSelected.length; i++) {
      if (storesSelected[i].price === 0 && !storesSelected[i].price) {
        notificationError('Vui lòng nhập giá sản phẩm')
        document.getElementById(`price-product-${i}`)?.focus()
        return false
      }
      if (
        storesSelected[i].sale_price &&
        storesSelected[i].price <= storesSelected[i].sale_price
      ) {
        notificationError('Vui lòng nhập giá lớn hơn giá khuyến mại')
        document.getElementById(`price-product-${i}`)?.focus()
        return false
      }
    }
    return true
  }

  const onSave = async () => {
    if (validateBeforeConfirm() && !isLoading.loadingUpdate) {
      setIsLoading({ ...isLoading, loadingUpdate: true })
      const images: any = []
      listImages
        .filter(img => img?.url)
        .map((item: any) => {
          if (item?.url) {
            const newUrl = item.url.slice(item.url.indexOf('upload'))
            images.push(newUrl)
          }
        })

      try {
        const dataPush: TDataPush = {
          name: productInfor.name.trim(),
          code: productInfor.code.trim(),
          price: productInfor.price,
          description: productInfor.description,
          stt: productInfor.stt,
          status_home: productInfor.status_home,
          category_id: productInfor.category_id,
          quantity_home: productInfor.quantity_home,
          array_images: images,
        }

        if (productInfor.category_id !== 3) {
          dataPush.product_unit_id = productInfor.unit
        }

        // nếu là xăng dầu
        if (productInfor.save_status) {
          dataPush.save_status = 1
          dataPush.min_quantity = productInfor.min_quantity
          dataPush.max_quantity = productInfor.max_quantity
          dataPush.expire_time = productInfor.expire_time
        } else {
          dataPush.save_status = 0
          dataPush.min_quantity = 0
          dataPush.max_quantity = 0
          dataPush.expire_time = 0
        }

        if (!param.id) {
          // THÊM SẢN PHẨM
          dataPush.array_pertrol_store = storesSelected.map((item: any) => ({
            petrol_store_id: item.petrol_store_id,
            price: item.price,
            status: item.status,
          }))

          const res = await createProduct(dataPush)
          if (res.status) {
            notificationSuccess(res.message)
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.PRODUCT}`,
              state: location.state,
            })
          } else {
            notificationError('Đã có lỗi xảy ra! Xin vui lòng thử lại!')
          }
        } else {
          // SỬA SẢN PHẨM
          dataPush.array_pertrol_store_update = []
          dataPush.array_pertrol_store = []
          dataPush.array_pertrol_store_id_delete = arrayDeletePetrol // Danh sách cửa hàng xóa
          // array_update: lấy danh sách những cửa hàng được thêm và sửa data
          const array_update = storesSelected
            .filter((item: any) => !orginStoresSelected.includes(item))
            .map((store: any) => ({
              id: store.id,
              petrol_store_id: store.petrol_store_id,
              status: store.status,
              price: store.price,
            }))
          // Danh sách những cửa hàng sửa
          for (let index = 0; index < orginStoresSelected.length; index++) {
            array_update.filter(itemX => {
              if (
                itemX.petrol_store_id ===
                  orginStoresSelected[index].petrol_store_id &&
                !orginStoresSelected.includes(itemX)
              )
                dataPush.array_pertrol_store_update?.push(itemX)
            })
          }
          // Danh sách những cửa hàng thêm mới
          dataPush.array_pertrol_store = array_update
            .filter(
              (item: any) =>
                !dataPush.array_pertrol_store_update?.includes(item)
            )
            .map((store: any) => ({
              petrol_store_id: store.petrol_store_id,
              status: store.status,
              price: store.price,
            }))
          if (!dataPush.quantity_home) {
            dataPush.quantity_home = 0
          }
          const res = await updateProduct(param.id, dataPush)
          notificationSuccess(res.message)
          history.push({
            pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${param.id}`,
            state: location.state,
          })
        }
      } catch (error: any) {
        notificationError(error.message)
      } finally {
        setIsLoading({ ...isLoading, loadingUpdate: false })
        // dispatch(clearDataProduct())
      }
    }
  }
  return {
    isLoading,
    storesSelected,
    rowSelection,
    listImages,
    storesChecked,
    arrayDeletePetrol,
    setStoresChecked,
    setStoresSelected,
    setListImages,
    onSave,
    setArrayDeletePetrol,
  }
}
