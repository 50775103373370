import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { Button, PageHeader, Popconfirm } from 'antd'
import { ENTERPRISE_ROUTER_PATH } from 'common/config'
import { useRef } from 'react'
import { useHistory } from 'react-router'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import { ORDER_STATUS } from 'utils/constants'
import { useOrder } from '../hook/useOrder'
import AddActualPayment from './AddActualPayment'
import AddBillFullTank from './AddBillFullTank'
import DenyStatus from './DenyStatus'
import PrintOrder from './PrintOrder'

type Props = {
  data: any
  setDenyStatus: any
  isLoading: any
  denyStatus: any
  setIsLoading: any
  onChangeStatus: any
  onCompleteStatus: any
  onDenyStatus: any
  detailOrder: any
  setActualPayment: any
  actualPayment: any
  fullTank: any
  setFullTank: any
  showModal: any
  curentRemainLit: number
  handleCallbackDetail?: any
}

export default function Header({
  data,
  setDenyStatus,
  isLoading,
  denyStatus,
  setIsLoading,
  onChangeStatus,
  onCompleteStatus,
  onDenyStatus,
  detailOrder,
  fullTank,
  setActualPayment,
  actualPayment,
  setFullTank,
  showModal,
  curentRemainLit,
  handleCallbackDetail,
}: Props) {
  const { getData } = useOrder()
  const saveStatus = detailOrder.saveStatus
  const convertStatus = (value: any) => {
    switch (value) {
      case ORDER_STATUS.PENDING:
        return (
          <>
            {/* {data.order_gasoline !== null &&
            data.total_amount != 0 &&
            saveStatus !== 1 &&
            data.type_payment_id != 4 ? (
              <Button
                disabled
                style={{
                  borderRadius: '3px',
                  color: 'gray',
                  borderColor: 'gray',
                }}
                onClick={() => {}}
              >
                <b>
                  <PlusCircleOutlined /> Bổ sung tiền đổ thực tế
                </b>
              </Button>
            ) : (
              <></>
            )} */}
            <Button
              type="primary"
              danger
              style={{
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
              onClick={() => {
                setDenyStatus(true)
              }}
            >
              <CloseCircleOutlined />
              &nbsp;Từ chối
            </Button>
            <DenyStatus
              onDenyStatus={onDenyStatus}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              isLoadingButton={isLoading}
              visible={denyStatus}
              onCancel={() => {
                setDenyStatus(false)
              }}
            />

            <Popconfirm
              placement="bottomLeft"
              title="Bạn chắc chắn muốn xác nhận đơn hàng này?"
              onConfirm={async () => {
                setIsLoading(true)
                onChangeStatus()
                setIsLoading(false)
              }}
              okText={
                <>
                  <CheckCircleOutlined /> Xác nhận
                </>
              }
              cancelText={
                <>
                  <CloseCircleOutlined /> Hủy
                </>
              }
              okButtonProps={{
                type: 'default',
                style: {
                  borderRadius: '3px',
                  borderColor: '#1890ff',
                  color: '#1890ff',
                  fontWeight: 'bold',
                },
              }}
              cancelButtonProps={{
                danger: true,
                style: { borderRadius: '3px', fontWeight: 'bold' },
              }}
            >
              <Button
                loading={isLoading}
                type="primary"
                icon={<CheckCircleOutlined />}
                style={{ fontWeight: 'bold', borderRadius: '3px' }}
              >
                Xác nhận
              </Button>
            </Popconfirm>
          </>
        )
      case ORDER_STATUS.CONFIRMED:
        return (
          <>
            {data.order_gasoline !== null &&
            data.total_amount != 0 &&
            saveStatus !== 1 &&
            data.type_payment_id != 4 ? (
              <Button
                style={{
                  borderRadius: '3px',
                  color: '#1890ff',
                  borderColor: '#1890ff',
                }}
                onClick={() => {
                  setActualPayment(true)
                }}
              >
                <b>
                  <PlusCircleOutlined /> Bổ sung tiền đổ thực tế
                </b>
              </Button>
            ) : (
              <></>
            )}
            <Button
              style={
                data?.is_full_fuel != 1
                  ? {
                      display: 'none',
                    }
                  : {
                      borderRadius: '3px',
                      color: '#1890ff',
                      borderColor: '#1890ff',
                    }
              }
              onClick={() => {
                setFullTank(true)
              }}
            >
              <b>
                <PlusCircleOutlined /> Thêm thông tin HĐ
              </b>
            </Button>
            {saveStatus === 1 && curentRemainLit !== 0 && (
              <Button
                style={{
                  borderRadius: '3px',
                  color: 'orange',
                  borderColor: 'orange',
                }}
                onClick={() => {
                  showModal(true)
                }}
              >
                <b>
                  <PlusCircleOutlined /> Bổ sung lịch sử đổ xăng
                </b>
              </Button>
            )}
            <AddActualPayment
              setActualPayment={setActualPayment}
              detailOrder={detailOrder}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              visible={actualPayment}
              onCancel={() => {
                setActualPayment(false)
              }}
              handleCallbackDetail={handleCallbackDetail}
            />
            <AddBillFullTank
              detailOrder={detailOrder}
              setFullTank={setFullTank}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              visible={fullTank}
              onCancel={() => {
                setFullTank(false)
              }}
            />
            {saveStatus !== 1 && (
              <Button
                type="primary"
                danger
                style={{
                  fontWeight: 'bold',
                  borderRadius: '3px',
                }}
                onClick={() => {
                  setDenyStatus(true)
                }}
              >
                <CloseCircleOutlined />
                &nbsp;Từ chối
              </Button>
            )}

            <DenyStatus
              onDenyStatus={onDenyStatus}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              isLoadingButton={isLoading}
              visible={denyStatus}
              onCancel={() => {
                setDenyStatus(false)
              }}
            />

            {saveStatus !== 1 && (
              <Popconfirm
                placement="bottomLeft"
                title="Bạn chắc chắn muốn hoàn thành đơn hàng này?"
                onConfirm={async () => {
                  setIsLoading(true)
                  onCompleteStatus()
                  setIsLoading(false)
                }}
                okText={
                  <>
                    <CheckCircleOutlined /> Hoàn thành
                  </>
                }
                cancelText={
                  <>
                    <CloseCircleOutlined /> Hủy
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    borderColor: '#1890ff',
                    color: '#1890ff',
                    fontWeight: 'bold',
                  },
                }}
                cancelButtonProps={{
                  danger: true,
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
              >
                <Button
                  loading={isLoading}
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  style={{ fontWeight: 'bold', borderRadius: '3px' }}
                >
                  Hoàn thành
                </Button>
              </Popconfirm>
            )}
          </>
        )
      case ORDER_STATUS.SUCCCESS:
        return <></>
      case ORDER_STATUS.CANCELED:
        return <></>
      case ORDER_STATUS.DENY:
        return <></>
    }
  }
  const convertStatuss = (value: any) => {
    switch (value) {
      case ORDER_STATUS.CONFIRMED:
        return (
          <>
            {data.order_gasoline !== null &&
            data.total_amount != 0 &&
            data.type_payment_id != 4 ? (
              <Button
                style={{
                  borderRadius: '3px',
                  color: '#1890ff',
                  borderColor: '#1890ff',
                }}
                onClick={() => {
                  setActualPayment(true)
                }}
              >
                <b>
                  <PlusCircleOutlined /> Bổ sung tiền đổ thực tế
                </b>
              </Button>
            ) : (
              <></>
            )}
            <Button
              type="primary"
              danger
              style={{
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
              onClick={() => {
                setDenyStatus(true)
              }}
            >
              <CloseCircleOutlined />
              &nbsp;Hủy
            </Button>
            <AddActualPayment
              setActualPayment={setActualPayment}
              detailOrder={detailOrder}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              visible={actualPayment}
              onCancel={() => {
                setActualPayment(false)
                getData()
              }}
            />
            <DenyStatus
              onDenyStatus={onDenyStatus}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              isLoadingButton={isLoading}
              visible={denyStatus}
              onCancel={() => {
                setDenyStatus(false)
              }}
            />

            <Popconfirm
              placement="bottomLeft"
              title="Bạn chắc chắn muốn hoàn thành đơn hàng này?"
              onConfirm={async () => {
                setIsLoading(true)
                onCompleteStatus()
                setIsLoading(false)
              }}
              okText={
                <>
                  <CheckCircleOutlined /> Hoàn thành
                </>
              }
              cancelText={
                <>
                  <CloseCircleOutlined /> Hủy
                </>
              }
              okButtonProps={{
                type: 'default',
                style: {
                  borderRadius: '3px',
                  borderColor: '#1890ff',
                  color: '#1890ff',
                  fontWeight: 'bold',
                },
              }}
              cancelButtonProps={{
                danger: true,
                style: { borderRadius: '3px', fontWeight: 'bold' },
              }}
            >
              <Button
                loading={isLoading}
                type="primary"
                icon={<CheckCircleOutlined />}
                style={{ fontWeight: 'bold', borderRadius: '3px' }}
              >
                Hoàn thành
              </Button>
            </Popconfirm>
          </>
        )
      case ORDER_STATUS.SUCCCESS:
        return <></>
      case ORDER_STATUS.CANCELED:
        return <></>
      case ORDER_STATUS.DENY:
        return <></>
    }
  }
  const history = useHistory()
  const componentRef = useRef<any>()
  const reactToPrintFunc = useReactToPrint({
    content: () => componentRef.current,
  })
  return (
    <div>
      <PageHeader
        onBack={() => {
          history.push({
            pathname: `${ENTERPRISE_ROUTER_PATH.ORDER}`,
          })
        }}
        title="Đơn hàng chi tiết"
        extra={
          data.type_payment_id === 4 || 3
            ? [
                convertStatus(data.status),
                <>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button
                          loading={isLoading}
                          type="primary"
                          style={{ borderRadius: '3px' }}
                          onClick={() => {
                            setTimeout(() => {
                              setIsLoading(true)
                            }, 3000)
                          }}
                        >
                          <PrinterOutlined />
                          <b> In hóa đơn</b>
                        </Button>
                      )
                    }}
                    content={() => componentRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <PrintOrder ref={componentRef} detailOrder={detailOrder} />
                  </div>
                </>,
              ]
            : [
                convertStatuss(data.status),
                <>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button
                          loading={isLoading}
                          type="primary"
                          style={{ borderRadius: '3px' }}
                          onClick={() => {
                            setTimeout(() => {
                              setIsLoading(true)
                            }, 3000)
                          }}
                        >
                          <PrinterOutlined />
                          <b> In hóa đơn</b>
                        </Button>
                      )
                    }}
                    content={() => componentRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <PrintOrder ref={componentRef} detailOrder={detailOrder} />
                  </div>
                </>,
              ]
        }
      />
    </div>
  )
}
