import { ApiClient } from 'services/ApiService'

export const getAccounts = (payload: any) =>
  ApiClient.get('/admin/user', payload)
export const createAccount = (payload: any) =>
  ApiClient.post('/admin/user', payload)
export const updateAccount = (payload: any) => {
  return ApiClient.put(`/admin/user/${payload.id}`, payload.data)
}
export const changeStatusAccount = (payload: any) =>
  ApiClient.patch(`/admin/user/${payload.id}/status`, payload.status)
export const deleteAccount = (payload: any) =>
  ApiClient.delete(`admin/user/${payload.id}`)
export const resetPassword = (payload: any) =>
  ApiClient.put(
    `admin/user/${payload.id}/reset-password`,
    payload.resetPassword
  )
export const requestUploadImageAccount = (payload: any) =>
  ApiClient.post(`/file/upload/${payload.name}/single`, payload.data)
