import { PageHeader } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import { AddNotification } from './AddNotification'

type Props = {
  setAddNotify: any
  // createNotify: any
  // getData: any
  // addNotify: any
}
export default function HeaderNotify({
  setAddNotify,
}: // createNotify,
// getData,
// addNotify,
Props) {
  return (
    <div>
      <PageHeader
        title="Gửi thông báo"
        extra={[
          <ButtonAdd
            text="Thêm mới"
            onClickButton={() => {
              setAddNotify(true)
            }}
          />,
        ]}
      />
    </div>
  )
}
