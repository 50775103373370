import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import R from 'assets'
import LoadingProgress from 'common/components/LoadingProgress'
import {
  ADMIN_ROUTER_PATH,
  ENTERPRISE_ROUTER_PATH,
  SESSION_KEY,
} from 'common/config'
import { setUserInfo } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ACCOUNT_R0LE, REG_PHONE } from 'utils/constants'
import history from 'utils/history'
import { requestGetUserInfo, requestLogin } from './AuthApi'
import './authStyle.css'

function Login(props: any) {
  const [isLoading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const resLogin = await requestLogin({
        username: values.username.trim(),
        password: values.password.trim(),
      })
      Cookie.set(SESSION_KEY.SESSION, resLogin.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      // dispatch(getUserInfoAction())
      const res = await requestGetUserInfo()
      dispatch(setUserInfo(res.data))
      routerLoginSuccess(res?.data?.role_ids[0])
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const routerLoginSuccess = (role_ids: number) => {
    switch (role_ids) {
      case ACCOUNT_R0LE[0].id:
        history.push(ADMIN_ROUTER_PATH.DASH_BOARD)
        return
      case ACCOUNT_R0LE[1].id:
        history.push(ADMIN_ROUTER_PATH.CATEGORY_PRODUCT)
        return
      case ACCOUNT_R0LE[2].id:
        history.push(ENTERPRISE_ROUTER_PATH.DASH_BOARD)
        return
      case ACCOUNT_R0LE[3].id:
        history.push(ENTERPRISE_ROUTER_PATH.CUSTOMER)
        return
      case ACCOUNT_R0LE[4].id:
        history.push(ADMIN_ROUTER_PATH.CATEGORY_PRODUCT)
        return
      case ACCOUNT_R0LE[5].id:
        history.push(ADMIN_ROUTER_PATH.DASH_BOARD)
        return
      case ACCOUNT_R0LE[6].id:
        history.push(ENTERPRISE_ROUTER_PATH.DASH_BOARD)
        return
    }
  }

  return (
    <div className="login">
      <div className="login_image">
        <img
          src={R.images.img_background}
          alt="logo"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'blur(1px)',
            backgroundColor: 'white',
          }}
        />
      </div>
      <div
        className="login_image"
        style={{ backgroundColor: 'black', opacity: 0.5 }}
      ></div>

      <div className="container_login">
        <img
          alt=""
          src={R.images.img_logo}
          style={{
            width: '80%',
            height: 'auto',
            marginTop: '20px',
            marginBottom: '30px',
          }}
        />
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true, username: null, password: null }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              { required: true, message: 'Vui lòng nhập số điện thoại' },
              {
                pattern: REG_PHONE,
                max: 10,
                message: 'Số điện thoại không hợp lệ',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Số điện thoại"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: R.strings().please_enter_pass },
              { max: 55, min: 6, message: 'Độ dài mật khẩu từ 6 đến 55 ký tự' },
              {
                whitespace: true,
                message: 'Không được phép nhập khoảng trắng',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={R.strings().password}
            />
          </Form.Item>
          {/* <Form.Item
            style={{
              textAlign: 'right',
            }}
          >
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>{R.strings().remember_password}</Checkbox>
            </Form.Item>
          </Form.Item> */}

          <Form.Item
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: '5px' }}
            >
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
      {isLoading && <LoadingProgress />}
    </div>
  )
}

export default Login
