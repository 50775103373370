import moment from 'moment'
import { Button, Popconfirm, Spin, Table, Tag } from 'antd'
import HeaderBanner from './components/Header'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useNews } from './hook/useNews'
import Container from 'common/container/Container'
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import {
  BANNER_STATUS_POST,
  IS_ACTIVE,
  TYPE_BANNER_STATUS,
} from 'utils/constants'
import { deleteNews } from './NewApi'
import { notificationSuccess } from 'utils/notification'
import { useAddEdit } from './hook/useAddEdit'
import { useParams } from 'react-router'
import { Filter } from './components/Filter'

export default function Banner() {
  const {
    isLoading,
    history,
    listNew,
    getData,
    setParams,
    params,
    pagingtion,
  } = useNews()
  const param: { value: any } = useParams()

  const column = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: <b>Tiêu đề banner</b>,
      dataIndex: 'title_banner',
      key: 'title_banner',
    },
    {
      title: <b>Loại banner</b>,
      dataIndex: 'type_banner',
      key: 'type_banner',
      render: (value: number) => {
        switch (value) {
          case TYPE_BANNER_STATUS.BANNER:
            return <span>Banner</span>
          case TYPE_BANNER_STATUS.NEWS:
            return <span>Tin tức</span>
        }
      },
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'banner_status',
      key: 'order',
      render: (value: number) => {
        switch (value) {
          case BANNER_STATUS_POST.STATUS_POST:
            return <span>Đăng bài</span>
          case BANNER_STATUS_POST.STATUS_DRAFT:
            return <span>Lưu nháp</span>
        }
      },
    },
    {
      title: <b>Trạng thái hoạt động</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: number) => {
        switch (value) {
          case IS_ACTIVE.ACTIVE:
            return <Tag color="green">Đang hoạt động</Tag>
          case IS_ACTIVE.INACTIVE:
            return <Tag color="volcano">Ngưng hoạt động</Tag>
        }
      },
    },
    {
      title: <b>Ngày tạo</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
      },
    },
    {
      title: <b>Thao tác</b>,
      width: '120px',
      dataIndex: 'id',
      key: 'id',
      render: (value: number) => {
        return (
          <td>
            <Button
              type="text"
              style={{ fontSize: 16, color: '#1890ff' }}
              onClick={() => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.EDIT_NEWS}/${value}`,
                })
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              placement="topRight"
              title="Bạn có chắc chắn muốn xóa banner, tin tức?"
              onConfirm={async () => {
                try {
                  const res = await deleteNews(value)
                  notificationSuccess(res.message)
                  getData()
                } catch (error) {
                  // message.error(error)
                  console.log(error)
                }
              }}
              okText={
                <span>
                  <CloseCircleOutlined />
                  &nbsp;Xóa
                </span>
              }
              cancelText={
                <span>
                  <LeftCircleOutlined />
                  &nbsp;Trở lại
                </span>
              }
              okButtonProps={{
                danger: true,
                type: 'default',
                style: { borderRadius: '3px', fontWeight: 'bold' },
              }}
              cancelButtonProps={{
                style: {
                  borderRadius: '3px',
                  borderColor: '#1890ff',
                  color: '#1890ff',
                  fontWeight: 'bold',
                },
              }}
            >
              <Button
                type="text"
                style={{ fontSize: 16, color: 'red' }}
                onClick={() => {}}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </td>
        )
      },
    },
  ]
  return (
    <Container
      header={<HeaderBanner />}
      filterComponent={
        <Filter
          data={param}
          onSearchSubmit={(searchKey: string) => {
            setParams({
              ...params,
              search:
                searchKey.trim() === '' ? undefined : searchKey.trimStart(),
              page: 1,
            })
          }}
          onTypeSubmit={(statusKey: string) => {
            setParams({ ...params, type_banner: statusKey, page: 1 })
          }}
          onStatus={(statusKey: string) => {
            setParams({ ...params, status: statusKey, page: 1 })
          }}
          onStatusNews={(statusNewsKey: string) => {
            setParams({ ...params, banner_status: statusNewsKey, page: 1 })
          }}
          onDateSubmit={(from_date: any, to_date: any) => {
            console.log('from_date', from_date)
            if (from_date != null && to_date != null) {
              setParams({
                ...params,
                from_date: from_date,
                to_date: to_date,
                page: 1,
              })
            } else {
              setParams({
                ...params,
                page: 1,
              })
            }
          }}
        />
      }
      contentComponent={
        <div>
          <p>
            Kết quả lọc: <b>{pagingtion.total}</b>
          </p>

          <Table
            loading={isLoading}
            bordered
            columns={column}
            dataSource={listNew}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            pagination={{
              ...pagingtion,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParams({ ...params, page })
              },
            }}
          />
        </div>
      }
    />
  )
}
