import {
  AppstoreOutlined,
  AreaChartOutlined,
  BellOutlined,
  CheckOutlined,
  CloseOutlined,
  CodeSandboxOutlined,
  DollarCircleOutlined,
  DropboxOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FontSizeOutlined,
  HomeOutlined,
  LoadingOutlined,
  LogoutOutlined,
  RedoOutlined,
  ShopOutlined,
  TeamOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Badge,
  Button,
  Dropdown,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd'
import R from 'assets'
import styles from 'common/components/styles/WhiteBox.module.css'
import {
  ADMIN_ROUTER_PATH,
  ENTERPRISE_ROUTER_PATH,
  SESSION_ID,
} from 'common/config'
import { requestLogout } from 'features/auth/AuthApi'
import { logoutAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import moment from 'moment'
import React, { Key, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { WebSocket } from 'services/WebSocket'
import { notificationNote } from 'utils/notification'
import { injectObject2String } from 'utils/ruleForm'
import ChangePass from './Dropdown/ChangePass'
import { PersonInfor } from './Dropdown/PersonInfor'
import { NotificationIcon } from './Icons'
import {
  getNotifyAdmin,
  getNotifyPetrol,
  readAllNotify,
  readAllNotifyPetrol,
  readNotify,
  readNotifyPetrol,
} from './NotifyApi'
import { ACCOUNT_R0LE } from 'utils/constants'

const { SubMenu } = Menu
function handleGetCurrentRouter() {
  return window.location.pathname
}

export default function Header(props: any) {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const cookie = Cookie.get(SESSION_ID)
  const userState = useSelector((state: any) => state.authReducer)?.userInfo
  const authState = useSelector((state: any) => state.authReducer)
  const [openKeys, setOpenKeys] = useState<Array<any>>([])
  const [visible, setVisible] = useState(false)
  const [seeAll, setSeeAll] = useState<boolean>(true)
  const [dotNotify, setDotNotify] = useState(false)
  const [openModal, setOpenModal] = useState({
    personalInfor: false,
    changePassword: false,
  })
  const [listNotify, setListNotify] = useState<Array<any>>([])
  const [loadMoreNotification, setLoadMoreNotification] = useState<boolean>(
    false
  )
  const [showNotify, setShowNotify] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [param, setParam] = useState({
    page: 1,
  })
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    moreNotification()
  }, [param])

  React.useEffect(() => {
    if (location?.pathname === '/') {
      history.push('/dash-board')
    }
  }, [])

  useEffect(() => {
    getData()
    WebSocket.init(cookie)
    if (userState) {
      if (
        [
          ACCOUNT_R0LE[0].id,
          ACCOUNT_R0LE[1].id,
          ACCOUNT_R0LE[4].id,
          ACCOUNT_R0LE[5].id,
        ].includes(userState?.role_ids[0])
      ) {
        // console.log('Thông báo admin: ', WebSocket.socketClient)
        WebSocket.socketClient.on(`admin_notification`, async (datas: any) => {
          // console.log('Thông báo admin: ', datas)
          datas.create_at = new Date()
          datas.is_read = 0
          const listIdNotification = listNotify.map(item => {
            return item.id
          })
          if (Object.values(listIdNotification).includes(datas.data.id)) {
          } else {
            setListNotify(prevState => {
              return [datas.data, ...prevState]
            })
          }
          setDotNotify(true)
          setSeeAll(true)
          notificationNote(
            injectObject2String(
              datas.data?.type_notification?.content,
              datas.data?.data
            )
          )
        })
      } else {
        // console.log('thong bao petrol')
        // console.log('socketClient: ', WebSocket.socketClient)
        WebSocket.socketClient.on(`petrol_notification`, async (datas: any) => {
          if (datas.data.petrol_store_id === userState.petrol_store_id) {
            // console.log('Thông báo cửa hàng xăng dầu: ', datas)
            datas.create_at = new Date()
            datas.is_read = 0
            const listIdNotification = listNotify.map(item => {
              return item.id
            })
            if (Object.values(listIdNotification).includes(datas.data.id)) {
            } else {
              setListNotify(prevState => {
                return [datas.data, ...prevState]
              })
            }
            setDotNotify(true)
            setSeeAll(true)
            notificationNote(
              injectObject2String(
                datas.data?.type_notification?.content,
                datas.data?.data
              )
            )
          }
        })
      }
    }
  }, [userState?.role_ids[0]])

  function handleClick(e: any) {
    if (e.key === 'logout') {
      //handle logout
      //call api logout, reset reducer
      dispatch(logoutAction())
    } else if (e.key === 'PersonInfors') {
      PersonInfors()
    } else if (e.key === 'ChangePassword') {
      ChangePassword()
    } else {
      history.push(e.key)
    }
  }
  const ChangePassword = () => {
    if (!openModal.personalInfor) {
      setOpenModal({ ...openModal, changePassword: true })
    }
  }
  const PersonInfors = () => {
    if (!openModal.changePassword) {
      setOpenModal({ ...openModal, personalInfor: true })
    }
  }
  const onClick = () => {
    setVisible(!visible)
    setDotNotify(false)
  }

  const rootSubmenuKeys = Object.values(ADMIN_ROUTER_PATH)

  const handleChangeMenu = (keys: Key[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey as string) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const DecentralizationForMenu = (role_id: number) => {
    switch (role_id) {
      case ACCOUNT_R0LE[0].id:
        return <AdminMenu />
      case ACCOUNT_R0LE[1].id:
        return <BusinessManMenu />
      case ACCOUNT_R0LE[2].id:
        return <AdminPetrolMenu />
      case ACCOUNT_R0LE[3].id:
        return <BusinessManPetrolMenu />
      case ACCOUNT_R0LE[4].id:
        return <AccountantMenu />
      case ACCOUNT_R0LE[5].id:
        return <AdminMenu />
      case ACCOUNT_R0LE[6].id:
        return <AdminPetrolMenu />
    }
  }

  const AdminMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={e => handleChangeMenu(e)}
        mode="inline"
        selectedKeys={[handleGetCurrentRouter()]}
      >
        <Menu.Item
          key={ADMIN_ROUTER_PATH.DASH_BOARD}
          icon={<HomeOutlined />}
          children={R.strings().title_header_dashboard}
        />
        <SubMenu
          key="shop"
          icon={<ShopOutlined />}
          title={R.strings().title_header_shop}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.SHOP}>
            {R.strings().title_header_shop_list}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.BECOME_PARTNER}>
            {R.strings().title_header_request_become_partner}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="product"
          icon={<DropboxOutlined />}
          title={R.strings().title_header_product}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.CATEGORY_PRODUCT}>
            {R.strings().title_header_category_product}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.PRODUCT}>
            {R.strings().title_header_product}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.SALE_PRODUCT}>
            {R.strings().title_header_sale_product}
          </Menu.Item>
        </SubMenu>
        {/* Tồn kho */}

        <Menu.Item
          key={ADMIN_ROUTER_PATH.INVENTORY}
          icon={<CodeSandboxOutlined />}
        >
          {R.strings().title_header_inventory}
        </Menu.Item>

        <SubMenu
          key="customer"
          icon={<TeamOutlined />}
          title={R.strings().title_header_customer}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.CUSTOMER}>
            {R.strings().title_header_customer}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.ENTERPRISE_CUSTOMER}>
            {R.strings().title_header_enterprise_customer}
          </Menu.Item>
        </SubMenu>
        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER} icon={<FileTextOutlined />}>
          {R.strings().title_header_order}
        </Menu.Item>
        <SubMenu
          key="debt"
          icon={<DollarCircleOutlined />}
          title={R.strings().title_header_debt}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.DEBT_CUSTOMERS}>
            {R.strings().title_header_customer_enterprise}
          </Menu.Item>
        </SubMenu>
        <Menu.Item key={ADMIN_ROUTER_PATH.REQUEST} icon={<ToolOutlined />}>
          Trạm sạc điện
        </Menu.Item>
        <Menu.Item key={ADMIN_ROUTER_PATH.NEWS} icon={<FontSizeOutlined />}>
          {R.strings().title_header_news}
        </Menu.Item>
        <SubMenu
          key="report"
          icon={<AreaChartOutlined />}
          title={R.strings().title_header_report}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.SALE_REPORT}>
            {R.strings().title_header_sale_report}
          </Menu.Item>
          {/* <Menu.Item key={ADMIN_ROUTER_PATH.SHOP_REPORT}>
            {R.strings().title_header_shop_report}
          </Menu.Item> */}
          <Menu.Item key={ADMIN_ROUTER_PATH.POINT_REPORT}>
            {R.strings().title_header_point_report}
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key={ADMIN_ROUTER_PATH.NOTIFICATION}
          icon={<NotificationIcon />}
        >
          {R.strings().title_header_send_notification}
        </Menu.Item>
        <Menu.Item key={ADMIN_ROUTER_PATH.CONFIG} icon={<AppstoreOutlined />}>
          {R.strings().title_header_config}
        </Menu.Item>
        <Menu.Item key={ADMIN_ROUTER_PATH.ACCOUNTS} icon={<UserOutlined />}>
          {R.strings().title_header_account}
        </Menu.Item>
      </Menu>
    )
  }
  const BusinessManMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={e => handleChangeMenu(e)}
        mode="inline"
        selectedKeys={[handleGetCurrentRouter()]}
      >
        <SubMenu
          key="shop"
          icon={<ShopOutlined />}
          title={R.strings().title_header_shop}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.SHOP}>
            {R.strings().title_header_shop_list}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.BECOME_PARTNER}>
            {R.strings().title_header_request_become_partner}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="product"
          icon={<DropboxOutlined />}
          title={R.strings().title_header_product}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.CATEGORY_PRODUCT}>
            {R.strings().title_header_category_product}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.PRODUCT}>
            {R.strings().title_header_product}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.SALE_PRODUCT}>
            {R.strings().title_header_sale_product}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="customer"
          icon={<TeamOutlined />}
          title={R.strings().title_header_customer}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.CUSTOMER}>
            {R.strings().title_header_customer}
          </Menu.Item>
        </SubMenu>
        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER} icon={<FileTextOutlined />}>
          {R.strings().title_header_order}
        </Menu.Item>
        <SubMenu
          key="debt"
          icon={<DollarCircleOutlined />}
          title={R.strings().title_header_debt}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.DEBT_CUSTOMERS}>
            {R.strings().title_header_customer_enterprise}
          </Menu.Item>
        </SubMenu>
        <Menu.Item key={ADMIN_ROUTER_PATH.NEWS} icon={<FontSizeOutlined />}>
          {R.strings().title_header_news}
        </Menu.Item>
        <Menu.Item
          key={ADMIN_ROUTER_PATH.NOTIFICATION}
          icon={<NotificationIcon />}
        >
          {R.strings().title_header_send_notification}
        </Menu.Item>
      </Menu>
    )
  }
  const AdminPetrolMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={e => handleChangeMenu(e)}
        mode="inline"
        selectedKeys={[handleGetCurrentRouter()]}
      >
        <Menu.Item
          key={ENTERPRISE_ROUTER_PATH.DASH_BOARD}
          icon={<HomeOutlined />}
          children={R.strings().title_header_dashboard}
        />
        <SubMenu
          key="product"
          icon={<DropboxOutlined />}
          title={R.strings().title_header_product}
        >
          <Menu.Item key={ENTERPRISE_ROUTER_PATH.CATEGORY_PRODUCT}>
            {R.strings().title_header_category_product}
          </Menu.Item>
          <Menu.Item key={ENTERPRISE_ROUTER_PATH.PRODUCT}>
            {R.strings().title_header_product}
          </Menu.Item>
          <Menu.Item key={ENTERPRISE_ROUTER_PATH.SALE_PRODUCT}>
            {R.strings().title_header_sale_product}
          </Menu.Item>
        </SubMenu>
        {/* <SubMenu
          key="customer"
          icon={<TeamOutlined />}
          title={R.strings().title_header_customer}
        > */}
        <Menu.Item
          icon={<TeamOutlined />}
          key={ENTERPRISE_ROUTER_PATH.CUSTOMER}
        >
          {R.strings().title_header_customer}
        </Menu.Item>
        {/* <Menu.Item key={ENTERPRISE_ROUTER_PATH.ENTERPRISE_CUSTOMER}>
            {R.strings().title_header_enterprise_customer}
          </Menu.Item> */}
        {/* </SubMenu> */}
        <Menu.Item
          key={ENTERPRISE_ROUTER_PATH.ORDER}
          icon={<FileTextOutlined />}
        >
          {R.strings().title_header_order}
        </Menu.Item>
        <SubMenu
          key="report"
          icon={<AreaChartOutlined />}
          title={R.strings().title_header_report}
        >
          <Menu.Item key={ENTERPRISE_ROUTER_PATH.SALE_REPORT}>
            {R.strings().title_header_sale_report}
          </Menu.Item>
          {/* <Menu.Item key={ENTERPRISE_ROUTER_PATH.SHOP_REPORT}>
            {R.strings().title_header_shop_report}
          </Menu.Item> */}
        </SubMenu>
        <Menu.Item
          key={ENTERPRISE_ROUTER_PATH.ACCOUNTS}
          icon={<UserOutlined />}
        >
          {R.strings().title_header_account}
        </Menu.Item>
      </Menu>
    )
  }
  const BusinessManPetrolMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={e => handleChangeMenu(e)}
        mode="inline"
        selectedKeys={[handleGetCurrentRouter()]}
      >
        <Menu.Item
          key={ENTERPRISE_ROUTER_PATH.CUSTOMER}
          icon={<UserOutlined />}
        >
          {R.strings().title_header_customer}
        </Menu.Item>
        <Menu.Item
          key={ENTERPRISE_ROUTER_PATH.ORDER}
          icon={<FileTextOutlined />}
        >
          {R.strings().title_header_order}
        </Menu.Item>
        <Menu.Item
          key={ENTERPRISE_ROUTER_PATH.SALE_REPORT}
          icon={<AreaChartOutlined />}
        >
          {R.strings().title_header_sale_report}
        </Menu.Item>
      </Menu>
    )
  }

  const AccountantMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={e => handleChangeMenu(e)}
        mode="inline"
        selectedKeys={[handleGetCurrentRouter()]}
      >
        <SubMenu
          key="product"
          icon={<DropboxOutlined />}
          title={R.strings().title_header_product}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.CATEGORY_PRODUCT}>
            {R.strings().title_header_category_product}
          </Menu.Item>
          <Menu.Item key={ADMIN_ROUTER_PATH.PRODUCT}>
            {R.strings().title_header_product}
          </Menu.Item>
        </SubMenu>
        <Menu.Item key={ADMIN_ROUTER_PATH.CUSTOMER} icon={<TeamOutlined />}>
          {R.strings().title_header_customer}
        </Menu.Item>
        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER} icon={<FileTextOutlined />}>
          {R.strings().title_header_order}
        </Menu.Item>
        <SubMenu
          key="debt"
          icon={<DollarCircleOutlined />}
          title={R.strings().title_header_debt}
        >
          <Menu.Item key={ADMIN_ROUTER_PATH.DEBT_CUSTOMERS}>
            {R.strings().title_header_customer_enterprise}
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key={ADMIN_ROUTER_PATH.SALE_REPORT}
          icon={<AreaChartOutlined />}
        >
          {R.strings().title_header_sale_report}
        </Menu.Item>
      </Menu>
    )
  }

  const getData = async () => {
    if (userState !== null) {
      try {
        if (
          [
            ACCOUNT_R0LE[0].id,
            ACCOUNT_R0LE[1].id,
            ACCOUNT_R0LE[4].id,
            ACCOUNT_R0LE[5].id,
          ].includes(userState?.role_ids[0])
        ) {
          const res = await getNotifyAdmin(param)
          setListNotify(res.data)
        } else {
          const res = await getNotifyPetrol(param)
          setListNotify(res.data)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const moreNotification = async () => {
    if (userState !== null) {
      try {
        if (
          [
            ACCOUNT_R0LE[0].id,
            ACCOUNT_R0LE[1].id,
            ACCOUNT_R0LE[4].id,
            ACCOUNT_R0LE[5].id,
          ].includes(userState?.role_ids[0])
        ) {
          const res = await getNotifyAdmin(param)
          const new_list = listNotify.concat(res.data)
          setListNotify(new_list)
        } else {
          const res = await getNotifyPetrol(param)
          const new_list = listNotify.concat(res.data)
          setListNotify(new_list)
        }
      } catch (error) {
      } finally {
        setLoadMoreNotification(false)
      }
    }
  }

  const convertIcon = (value: number) => {
    switch (value) {
      case 17:
      case 26:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#1890ff' }}
          >
            <FileTextOutlined />
          </div>
        )
      case 27:
      case 22:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: 'red' }}
          >
            <FieldTimeOutlined />
          </div>
        )
      case 28:
      case 23:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#EB852D' }}
          >
            <FieldTimeOutlined />
          </div>
        )
      case 29:
      case 24:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#EB852D' }}
          >
            <FieldTimeOutlined />
          </div>
        )
      case 30:
      case 25:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#54e854' }}
          >
            <FileDoneOutlined />
          </div>
        )
      case 31:
      case 32:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#54e854' }}
          >
            <FileDoneOutlined />
          </div>
        )
      case 18:
      case 19:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#1890ff' }}
          >
            <UsergroupAddOutlined />
          </div>
        )
      case 21:
      case 20:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#EB852D' }}
          >
            <ToolOutlined />
          </div>
        )
      default:
        return (
          <div
            className={styles.icon_notice}
            style={{ backgroundColor: '#1890ff' }}
          >
            <BellOutlined />
          </div>
        )
    }
  }

  const listNotifications = (
    <div className={styles.wrap_notification}>
      <div className={styles.title_notification}>
        <span>Thông báo</span>
        <Row>
          {seeAll ? (
            <Tooltip title="Đánh dấu đã đọc">
              <Button
                type="text"
                style={{ color: 'white' }}
                onClick={async () => {
                  setLoading(true)
                  try {
                    if (
                      [
                        ACCOUNT_R0LE[0].id,
                        ACCOUNT_R0LE[1].id,
                        ACCOUNT_R0LE[4].id,
                        ACCOUNT_R0LE[5].id,
                      ].includes(userState?.role_ids[0])
                    ) {
                      await readAllNotify()
                    } else {
                      await readAllNotifyPetrol()
                    }
                    getData()
                  } catch (error) {
                    console.log(error)
                  }
                  setLoading(false)
                  setSeeAll(false)
                }}
              >
                <CheckOutlined />
              </Button>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip title="Đóng">
            <Button
              type="text"
              style={{ color: 'white' }}
              onClick={() => setVisible(false)}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </Row>
      </div>

      <div className={styles.list_notification}>
        <Spin spinning={loading}>
          {listNotify?.length > 0 ? (
            listNotify.map((item: any) => {
              return (
                <Badge
                  dot={item?.is_read === 0 ? true : false}
                  style={{
                    width: 7,
                    height: 7,
                    marginTop: 20,
                    marginRight: 310,
                    position: 'absolute',
                  }}
                >
                  <Tooltip
                    style={{}}
                    title={
                      item?.type_notification.scope === 3 ? (
                        <b style={{}}>
                          {injectObject2String(
                            item?.type_notification?.content,
                            item.data
                          )}
                        </b>
                      ) : null
                    }
                    trigger="click"
                    overlayStyle={{ margin: '15px 0px 15px 10px' }}
                    placement="leftTop"
                    color={'#003a8c'}
                  >
                    <li
                      onClick={async () => {
                        setLoading(true)
                        try {
                          if (
                            [
                              ACCOUNT_R0LE[0].id,
                              ACCOUNT_R0LE[1].id,
                              ACCOUNT_R0LE[4].id,
                              ACCOUNT_R0LE[5].id,
                            ].includes(userState?.role_ids[0])
                          ) {
                            await readNotify(item.id)
                          } else {
                            await readNotifyPetrol(item.id)
                          }
                          getData()
                        } catch (error) {
                          console.log(error)
                        }
                        setLoading(false)
                        if (item.type_notification.scope === 3) {
                          setShowNotify(true)
                        }

                        if (
                          item.type_notification.scope === 17 ||
                          item.type_notification.scope === 25 ||
                          item.type_notification.scope === 26 ||
                          item.type_notification.scope === 30 ||
                          item.type_notification.scope === 31 ||
                          item.type_notification.scope === 32 ||
                          item.type_notification.scope === 23 ||
                          item.type_notification.scope === 39
                        ) {
                          if (
                            [
                              ACCOUNT_R0LE[0].id,
                              ACCOUNT_R0LE[1].id,
                              ACCOUNT_R0LE[4].id,
                              ACCOUNT_R0LE[5].id,
                            ].includes(userState?.role_ids[0])
                          ) {
                            history.replace({
                              pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${item.data?.order_id}`,
                            })
                          } else {
                            history.replace({
                              pathname: `${ENTERPRISE_ROUTER_PATH.ORDER_DETAIL}/${item.data?.order_id}`,
                            })
                          }
                        }

                        if (item.type_notification.scope === 18) {
                          history.push({
                            pathname: `${ADMIN_ROUTER_PATH.BECOME_PARTNER}`,
                            state: {
                              id: item.data.partner_id,
                            },
                          })
                        }
                        if (item.type_notification.scope === 19) {
                          history.push({
                            pathname: `${ADMIN_ROUTER_PATH.ENTERPRISE_CUSTOMER}`,
                            state: {
                              id: item.data.enterprise_id,
                            },
                          })
                        }
                        if (item.type_notification.scope === 20) {
                          history.push({
                            pathname: `${ADMIN_ROUTER_PATH.REQUEST}`,
                            state: {
                              id: item.data.relief_id,
                            },
                          })
                        }
                        if (item.type_notification.scope === 21) {
                          history.push({
                            pathname: `${ADMIN_ROUTER_PATH.REQUEST}`,
                            state: {},
                          })
                        }
                      }}
                    >
                      <div className={styles.left_notification}>
                        {convertIcon(item?.type_notification.scope)}
                      </div>
                      <div className={styles.contend_notification}>
                        {injectObject2String(
                          item?.type_notification?.title,
                          item?.data
                        )}

                        <div className={styles.time_notification}>
                          {moment(item?.create_at).format('HH:mm DD/MM/YYYY')}
                        </div>
                      </div>
                    </li>
                  </Tooltip>
                </Badge>
              )
            })
          ) : (
            <>Không có thông báo</>
          )}
        </Spin>
        <div
          style={{
            fontSize: '25px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loadMoreNotification ? <LoadingOutlined /> : <></>}
        </div>

        <div
          style={{
            marginTop: '5px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setLoadMoreNotification(true)
            let numPage = param.page + 1
            setParam({ ...param, page: numPage })
          }}
        >
          {!loadMoreNotification ? '[ Xem thêm ]' : ''}
        </div>
      </div>
    </div>
  )
  return (
    <Spin spinning={authState.dialogLoading}>
      <div>
        <Row
          style={{
            backgroundColor: 'white',
            paddingTop: 5,
            paddingLeft: 10,
            paddingRight: 10,
            width: '100vw',
          }}
          justify="space-between"
        >
          <img
            alt=""
            src={R.images.img_logo}
            style={{
              width: 80,
              height: 30,
              padding: 3,
              objectFit: 'contain',
              marginTop: '7px',
              marginBottom: '7px',
            }}
          />
          <Typography.Title
            children={
              userState?.df_type_user_id === 1
                ? 'Quản trị'
                : userState?.df_type_user_id === 2
                ? 'XĂNG DẦU CÔNG NGHỆ'
                : ''
            }
            style={{ margin: 0, fontSize: '20px', marginTop: 8 }}
          />

          <Row align="middle">
            <Badge dot={dotNotify}>
              <Dropdown
                visible={visible}
                overlay={listNotifications}
                trigger={['click']}
                placement="bottomRight"
              >
                <BellOutlined
                  className={styles.icon_notification}
                  onClick={onClick}
                />
              </Dropdown>
            </Badge>

            <Menu
              triggerSubMenuAction="click"
              onClick={handleClick}
              disabledOverflow
              mode="horizontal"
              selectedKeys={[handleGetCurrentRouter()]}
            >
              <SubMenu
                key="sub15"
                icon={<UserOutlined />}
                title={
                  userState
                    ? userState.petrol_store
                      ? userState.petrol_store.name
                      : userState.name
                    : 'Username'
                }
              >
                <Menu.Item key={'PersonInfors'}>
                  <UserOutlined style={{ marginRight: '10px' }} />
                  Thông tin cá nhân
                </Menu.Item>
                <Menu.Item key={'ChangePassword'}>
                  <RedoOutlined style={{ marginRight: '10px' }} />
                  Đổi mật khẩu
                </Menu.Item>
                <Menu.Item
                  key={'logout'}
                  onClick={async () => {
                    try {
                      await requestLogout()
                    } catch (error) {}
                  }}
                >
                  <LogoutOutlined style={{ marginRight: '10px' }} />
                  Đăng xuất
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Row>
        </Row>

        {DecentralizationForMenu(userState?.role_ids[0])}
      </div>
      <PersonInfor
        openModal={openModal}
        setOpenModal={setOpenModal}
        authState={authState}
        visible={openModal.personalInfor}
        isLoading={false}
        onCancel={() => {
          setOpenModal({ ...openModal, personalInfor: false })
        }}
      />
      <ChangePass
        openModal={openModal}
        setOpenModal={setOpenModal}
        visible={openModal.changePassword}
        onCancel={() => {
          setOpenModal({ ...openModal, changePassword: false })
        }}
      />
    </Spin>
  )
  //
}
