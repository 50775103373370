import { Tabs } from 'antd'
import Header from './components/Header'
import DisplayInforTab from './DisplayInforTab'
import SystemInforTab from './SystemInforTab'
import styles from 'common/components/styles/WhiteBox.module.css'
import RankInforTab from './RankInforTab'
import UnitTab from './components/UnitTab'

const { TabPane } = Tabs
// test
const ConfigScreen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header />
      </div>
      <Tabs
        size="small"
        style={{
          fontWeight: 'bold',
          padding: '10px 0px',
        }}
      >
        <TabPane
          tab={<p style={{ fontSize: '18px' }}>Thông số hệ thống</p>}
          key="1"
        >
          <SystemInforTab />
        </TabPane>
        <TabPane
          tab={<p style={{ fontSize: '18px' }}>Thông tin hiển thị </p>}
          key="2"
        >
          <DisplayInforTab />
        </TabPane>
        <TabPane
          tab={<p style={{ fontSize: '18px' }}>Thông tin hạng khách hàng </p>}
          key="3"
        >
          <RankInforTab />
        </TabPane>
        <TabPane tab={<p style={{ fontSize: '18px' }}>Đơn vị tính </p>} key="4">
          <UnitTab />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ConfigScreen
