import FilterProductTab from './FilterProductTab'
import { Table, Tag } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { IS_ACTIVE } from 'utils/constants'
import { useDetailShop } from '../hook/useDetailShop'
import history from 'utils/history'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useParams } from 'react-router-dom'

export default function ProductTab() {
  const params: { id: any } = useParams()
  const {
    paramsProduct,
    setParamsProduct,
    pagingProduct,
    product,
    isLoading,
  } = useDetailShop()
  const columns = [
    {
      width: 70,
      title: <b>STT</b>,
      dataIndex: 'stt',
    },
    {
      title: <b>Mã sản phẩm</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: <b>Tên sản phẩm</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <b>Danh mục</b>,
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      render: (value: number, record: any) => {
        const status_product_in_shop = record.status
          ? record.product_stock_status
            ? IS_ACTIVE.ACTIVE
            : IS_ACTIVE.INACTIVE
          : IS_ACTIVE.INACTIVE
        switch (status_product_in_shop) {
          case IS_ACTIVE.ACTIVE:
            return <Tag color="green">Hoạt động</Tag>
          case IS_ACTIVE.INACTIVE:
            return <Tag color="volcano">Ngưng hoạt động</Tag>
        }
      },
    },
    {
      title: <b>Chi tiết</b>,
      render: (record: any) => {
        return (
          <InfoCircleOutlined
            style={{ fontSize: '16px', color: 'darkblue' }}
            onClick={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${record.key}`,
                state: {
                  page: record.current,
                  search: record.search,
                  category_id: record.category_id,
                  status: record.status,
                  name: ADMIN_ROUTER_PATH.SHOP_DETAIL,
                  idShop: params.id,
                },
              })
            }}
          />
        )
      },
      width: 60,
    },
  ]
  return (
    <div>
      <FilterProductTab
        onSearchSubmit={(searchKey: string) => {
          setParamsProduct({
            ...paramsProduct,
            search: searchKey === '' ? undefined : searchKey,
            page: 1,
          })
        }}
        onCategorySubmit={(categoryKey: number) => {
          setParamsProduct({
            ...paramsProduct,
            category_id: categoryKey,
            page: 1,
          })
        }}
        onStatusSubmit={(statusKey: number) => {
          setParamsProduct({ ...paramsProduct, status: statusKey, page: 1 })
        }}
      />
      <div style={{ marginTop: '10px' }}>
        {/* <b style={{ fontSize: '16px' }}>Kết quả lọc: 2</b> */}
        <span>
          Kết quả lọc: <b>{pagingProduct.total}</b>
        </span>
        <Table
          // className={styles.table_hover}
          loading={isLoading.loadingTable}
          style={{ marginTop: '10px' }}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
          }}
          // onRow={record => ({
          //   onClick: () => {
          //     history.push({
          //       pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${record.key}`,
          //       state: {
          //         page: pagingProduct.current,
          //         search: paramsProduct.search,
          //         category_id: paramsProduct.category_id,
          //         status: paramsProduct.status,
          //       },
          //     })
          //   },
          // })}
          dataSource={product}
          bordered
          columns={columns}
          pagination={{
            ...pagingProduct,
            onChange: async (page, pageSize) => {
              setParamsProduct({ ...paramsProduct, page })
            },
          }}
        />
      </div>
    </div>
  )
}
