import Icon from '@ant-design/icons'
import { Col, Input, Row, Select } from 'antd'
import { ICategory } from '../../Inventory'

interface IUpdateInventoryFilter {
  categories: ICategory[]
  setCategoryId: React.Dispatch<React.SetStateAction<number>>
  setSearch: React.Dispatch<React.SetStateAction<string>>
}

const UpdateInventoryFilter = (props: IUpdateInventoryFilter) => {
  const { categories, setCategoryId, setSearch } = props
  return (
    <Row gutter={[16, 16]}>
      <Col span={7}>
        <Input.Search
          allowClear
          style={{ width: '100%' }}
          placeholder="Tên hoặc mã sản phẩm"
          addonAfter={<Icon type="close-circle-o" />}
          //  value={data?.search}
          onChange={(e: any) => {
            setSearch(e.target.value?.trim())
          }}
        />
      </Col>
      <Col span={6}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Danh mục sản phẩm"
          onChange={(value: number) => {
            if (!value) {
              setCategoryId(-1)
            } else setCategoryId(value)
          }}
        >
          {categories.map((category: ICategory) => (
            <Select.Option value={category.value}>
              {category.text}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  )
}

export default UpdateInventoryFilter
