import { Descriptions } from 'antd'
import styles from 'common/components/styles/WhiteBox.module.css'
import moment from 'moment'
import { formatPrice } from 'utils/ruleForm'

export default function SaleProductDetailScreen({ data }: any) {
  return (
    <div className={styles.expand}>
      <Descriptions
        title={<b>THÔNG TIN CHUNG</b>}
        className={styles.tab}
        column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item
          label={<span className={styles.description_label}>Mã sản phẩm</span>}
        >
          <span style={{ color: 'red', width: '130px' }}>{data.code}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Danh mục</span>}
        >
          <span>{data.product_category_name}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Tên sản phẩm</span>}
        >
          <span>{data.name}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <span className={styles.description_label}>
              Trạng thái sản phẩm
            </span>
          }
        >
          <>
            {data.status === 1 ? (
              <span style={{ color: 'green' }}>Đang hoạt động</span>
            ) : (
              <span style={{ color: 'red' }}>Ngưng hoạt động</span>
            )}
          </>
        </Descriptions.Item>
        <Descriptions.Item
          label={<span className={styles.description_label}>Đơn vị tính</span>}
        >
          <span>{data?.unit || '--'}</span>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title={<b>THÔNG TIN KHUYẾN MẠI</b>}
        className={styles.tab}
        column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item
          label={<span className={styles.description_label}>Giá gốc</span>}
        >
          <span style={{ color: 'red' }}>
            {data.price == null ? '0đ' : formatPrice(data?.origin_price) + 'đ'}
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <span className={styles.description_label}>Giá khuyến mại</span>
          }
        >
          <span style={{ color: 'red' }}>
            {data.price == null ? '0đ' : formatPrice(data?.price) + 'đ'}
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <span className={styles.description_label}>
              Thời gian bắt đầu khuyến mại
            </span>
          }
        >
          <span>{moment(data.start_at).format('HH:mm DD/MM/YYYY')}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <span className={styles.description_label}>
              Trạng thái khuyến mại
            </span>
          }
        >
          <>
            {data.check_sale !== 0 ? (
              <span style={{ color: 'green' }}>Đang khuyến mại</span>
            ) : (
              <span style={{ color: 'red' }}>Ngưng khuyến mại</span>
            )}
          </>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <span className={styles.description_label}>
              Thời gian kết thúc khuyễn mại
            </span>
          }
        >
          <span>{moment(data.finish_at).format('HH:mm DD/MM/YYYY')}</span>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}
