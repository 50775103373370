import React from 'react'

const useDeounce = (value: string, delay: number) => {
  const [debounceValue, setDebounceValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => setDebounceValue(value), delay)
    return () => clearTimeout(handler)
  }, [value])

  return debounceValue
}

export default useDeounce
