import { CheckCircleOutlined } from '@ant-design/icons'
import { PageHeader, Tabs } from 'antd'
import {
  ButtonActive,
  ButtonDelete,
  ButtonEdit,
} from 'common/components/Buttons'
import OrderTab from './components/OrderTab'
import ProductTab from './components/ProductTab'
import ShopTab from './components/ShopTab'
import styles from 'common/components/styles/WhiteBox.module.css'
import { changeStatusPetrolStore, deletePetrolStore } from './ShopApi'
import { useDetailShop } from './hook/useDetailShop'
import AddEditShop from './components/AddEditShop'
import { notificationSuccess } from 'utils/notification'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useState } from 'react'
import { useLocation } from 'react-router'

const { TabPane } = Tabs

export default function ShopDetail() {
  const {
    history,
    isModalVisible,
    param,
    dataSource,
    setDataSource,
    setIsModalVisible,
  } = useDetailShop()
  const [isLoading, setIsLoading] = useState({ loadingDelete: false })
  const location = useLocation()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PageHeader
          title={
            <div>
              <span>Chi tiết cửa hàng xăng dầu - đối tác</span>
              <br />
              {dataSource.name}
            </div>
          }
          onBack={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.SHOP}`,
              state: location.state,
            })
          }}
          extra={[
            <ButtonActive
              isActive={dataSource.status}
              onConfirm={async () => {
                try {
                  const res = await changeStatusPetrolStore(param.id)
                  if (res.code === 200) {
                    setDataSource({
                      ...dataSource,
                      status: dataSource.status ? 0 : 1,
                    })
                  }
                  notificationSuccess('Cập nhật trạng thái thành công')
                } catch (error) {
                  console.log(error)
                }
              }}
              title={
                dataSource?.status
                  ? 'Bạn thực sự muốn ngừng hoạt động cửa hàng xăng dầu này?'
                  : 'Bạn thực sự muốn cửa hàng xăng dầu này hoạt động?'
              }
              okText={
                <>
                  <CheckCircleOutlined /> Đồng ý
                </>
              }
            />,
            <ButtonEdit
              onClickButton={() => {
                setIsModalVisible(true)
              }}
              text="Sửa"
            />,
            <ButtonDelete
              text="Xóa"
              title="Bạn chắc chắn muốn xóa cửa hàng này?"
              isLoading={isLoading.loadingDelete}
              onConfirm={async () => {
                try {
                  setIsLoading({ ...isLoading, loadingDelete: true })
                  const res = await deletePetrolStore(param.id)
                  if (res.code === 404) {
                    history.push({
                      pathname: `${ADMIN_ROUTER_PATH.SHOP_DETAIL}/${param.id}`,
                    })
                  } else {
                    history.push({
                      pathname: `${ADMIN_ROUTER_PATH.SHOP}`,
                    })
                  }
                } catch (error) {
                } finally {
                  setIsLoading({ ...isLoading, loadingDelete: false })
                }
              }}
            />,
          ]}
        />
      </div>
      <div>
        <Tabs className={styles.content}>
          <TabPane tab={<b>Thông tin cửa hàng</b>} key="1">
            <ShopTab data={dataSource} />
          </TabPane>
          <TabPane tab={<b>Sản phẩm</b>} key="2">
            <ProductTab />
          </TabPane>
          <TabPane tab={<b>Đơn hàng</b>} key="3">
            <OrderTab />
          </TabPane>
        </Tabs>
      </div>
      <AddEditShop
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
          // getData()
        }}
        data={dataSource}
      />
    </div>
  )
}
