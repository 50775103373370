import { Table, Tag } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import moment from 'moment'
import { IS_ACTIVE } from 'utils/constants'
import { AddEditPartner } from './AddEditPartner'
import DetailPartner from './DetailPartner'
import styles from 'common/components/styles/WhiteBox.module.css'
import { useConfigPartner } from '../hook/useConfigPartner'
import { notificationError, notificationSuccess } from 'utils/notification'
import _ from 'lodash'
import {
  changeStatusConfigPartner,
  createConfigPartner,
  deleteConfigPartner,
  getConfigPartner,
  updateConfigPartner,
} from '../ConfigApi'
import { useEffect, useState } from 'react'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  from_date: undefined | string
  to_date: undefined | string
  role_id?: string
  limit: number
}
const columns = [
  {
    title: 'Tên đối tác ',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    // width: 120,
    render: (value: number) => {
      switch (value) {
        case IS_ACTIVE.ACTIVE:
          return <Tag color="green">Hoạt động</Tag>
        case IS_ACTIVE.INACTIVE:
          return <Tag color="volcano">Tạm dùng</Tag>
      }
    },
  },
  {
    title: 'Ngày tạo ',
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]
export default function PartnerInfor() {
  const updatePartner = async (data: any, resetFields: any) => {
    try {
      let dataPush = {
        id: data.id,
        data: {
          name: data.name,
          icon_url: data.icon_url,
        },
      }
      const res = await updateConfigPartner(dataPush)
      const itemUpdate = { ...res.data, key: res.data.id }
      const index = _.findIndex(listConfigPartner, { id: itemUpdate.id })
      listConfigPartner.splice(index, 1, itemUpdate)
      setListConfigPartner([...listConfigPartner])
      notificationSuccess(res.message)
      setShowEditPartner(false)
      getData()
      resetFields()
    } catch (error) {
      console.log(error)
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [showEditPartner, setShowEditPartner] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [listConfigPartner, setListConfigPartner] = useState<any>([])
  const [showAddConfigPartner, setShowAddConfigPartner] = useState(false)
  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    role_id: undefined,
    page: 1,
    // offset: 0,
    limit: 12,
  })

  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      const res = await getConfigPartner(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt: (paging.current - 1) * paging.pageSize + index + 1,
        }
      })
      setListConfigPartner(tableData)
      setPaging({
        ...paging,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const createPartner = async (data: any, resetFields: any, setUpload: any) => {
    try {
      const res = await createConfigPartner(data)
      setShowAddConfigPartner(false)
      resetFields()
      notificationSuccess(res.message)
      getData()
      // window.location.reload()
    } catch (error) {
      console.log(error)
      notificationError('Đã có lỗi xảy ra')
    } finally {
      setUpload({
        loading: false,
        imageUrl: '',
      })
    }
    getData()
  }

  const {} = useConfigPartner()
  return (
    <div className={styles.content}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: '18px' }}>Thông tin đối tác</p>
        <ButtonAdd
          onClickButton={() => {
            setShowAddConfigPartner(true)
          }}
          text="Thêm đối tác"
        />
      </div>
      <Table
        className={styles.table_hover}
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        bordered
        dataSource={listConfigPartner}
        loading={isLoading}
        columns={columns}
        expandedRowKeys={[currentSelected.id]}
        onRow={(row: any) => ({
          onClick: () => {
            if (currentSelected !== row) setCurrentSelected(row)
            else setCurrentSelected({ id: -1 })
          },
        })}
        expandable={{
          expandedRowRender: (record: any) => (
            <DetailPartner
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              onDeletePartner={async (id: any) => {
                try {
                  const res = await deleteConfigPartner({ id: [id] })
                  notificationSuccess(res.message)
                  getData()
                } catch (error) {
                  // notificationError(res.message)
                  console.log(error)
                }
              }}
              onChangeStatus={async (id: any) => {
                try {
                  const res = await changeStatusConfigPartner({ id: [id] })
                  notificationSuccess('Thay đổi trạng thái thành công')
                  getData()
                } catch (error) {
                  console.log(error)
                }
              }}
              onUpdatePartner={updatePartner}
              data={record}
              getData={getData}
              showEditPartner={showEditPartner}
              setShowEditPartner={setShowEditPartner}
            />
          ),
        }}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            setParams({ ...params, page })
          },
        }}
      />
      <AddEditPartner
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        getData={getData}
        visible={showAddConfigPartner}
        onCancel={() => {
          setShowAddConfigPartner(false)
        }}
        onCreatePartner={createPartner}
      />
    </div>
  )
}
