import { Descriptions, Tabs, Card, Col, Row, Image } from 'antd'
import {
  ButtonActive,
  ButtonDelete,
  ButtonEdit,
} from 'common/components/Buttons'
import moment from 'moment'
import { useConfigBank } from '../hook/useConfigBank'
import { AddEditBank } from './AddEditBank'

type Props = {
  data: any
  getData: any
  onDeleteBank: any
  onChangeStatus: any
  onUpdateBank: any
  setShowEditBank: any
  showEditBank: any
}

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const ContentView = (data: any) => {
  return (
    <Row gutter={[16, 16]}>
      <Col sm={8}>
        <Descriptions size="default" column={3}>
          <Descriptions.Item style={{ paddingLeft: '40px' }}>
            <Image
              style={{
                objectFit: 'cover',
                width: '120px',
                height: '120px',
              }}
              src={data.icon_url}
            />
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col sm={16}>
        <Descriptions size="default" column={2}>
          <Descriptions.Item label="Tên ngân hàng">
            {data.name}
          </Descriptions.Item>
          <Descriptions.Item label="Số tài khoản">
            {data.account_number}
          </Descriptions.Item>
          <Descriptions.Item label="Tên người sở hữu">
            {data.owner}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {data.status ? 'Hoạt động' : 'Tạm dừng'}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày tạo">
            {moment(data.create_at).format('DD/MM/YYYY')}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

function DetailBank({
  data,
  getData,
  onDeleteBank,
  onChangeStatus,
  onUpdateBank,
  setShowEditBank,
  showEditBank,
}: Props) {
  const {
    // showEditBank,
    // setShowEditBank,
    // updateBank,
    isLoading,
    setIsLoading,
  } = useConfigBank()
  return (
    <div>
      <Tabs
        size="small"
        defaultActiveKey="1"
        onChange={callback}
        style={{ padding: '0px 10px' }}
      >
        <TabPane tab="Chi tiết ngân hàng" key="1">
          <Card
            actions={[
              <ButtonActive
                typeButton="text"
                isActive={data.status}
                title={
                  data.status
                    ? 'Bạn chắc chắn muốn ngừng hoạt động ngân hàng này?'
                    : 'Bạn chắc chắn muốn hoạt động ngân hàng này?'
                }
                onConfirm={async () => {
                  try {
                    onChangeStatus(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
              />,
              <ButtonEdit
                typeButton="text"
                text="Chỉnh sửa"
                onClickButton={() => {
                  setShowEditBank(true)
                }}
                styleButton
              />,
              <ButtonDelete
                typeButton="text"
                title="Bạn chắc chắn muốn xóa ngân hàng này?"
                text="Xóa ngân hàng"
                onConfirm={async () => {
                  try {
                    onDeleteBank(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
              />,
            ]}
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
      <AddEditBank
        visible={showEditBank}
        isLoadingButton={isLoading}
        onCancel={() => {
          setShowEditBank(false)
        }}
        onUpdateBank={(newData: any, resetFields: any) => {
          onUpdateBank(newData, resetFields)
          setIsLoading(true)
          getData()
        }}
        data={data}
      />
    </div>
  )
}

export default DetailBank
