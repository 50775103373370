import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { Row } from 'antd'
import styles from './styles/Card.module.css'
import { formatPrice } from 'utils/ruleForm'
import moment from 'moment'

type Props = { datas: any; params: any }
Chart.register(...registerables)

export const Charts = ({ datas, params }: Props) => {
  console.log('🚀 ~ file: Charts.tsx:12 ~ Charts ~ params:', params)
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Hoàn thành',
        data: [],
        fill: false,
        tension: 0.3,
        borderColor: '#54e854',
        backgroundColor: '#54e854',
      },
      {
        label: 'Chờ xác nhận',
        data: [],
        fill: false,
        tension: 0.3,
        borderColor: 'orange',
        backgroundColor: 'orange',
      },
      {
        label: 'Đang thực hiện',
        data: [],
        fill: false,
        tension: 0.3,
        borderColor: '#1890ff',
        backgroundColor: '#1890ff',
      },
      {
        label: 'Từ chối/ Hủy',
        data: [],
        fill: false,
        tension: 0.3,
        borderColor: 'tomato',
        backgroundColor: 'tomato',
      },
    ],
  }
  return (
    <div
      style={{
        backgroundColor: 'white',
        marginTop: 10,
        marginInline: 10,
        padding: 10,
      }}
    >
      <b style={{ fontSize: 18 }}>
        Đơn hàng từ{' '}
        {params?.from_date
          ? moment(params?.from_date).format('DD/MM/YYYY')
          : moment().startOf('month').format('DD/MM/YYYY')}{' '}
        - {moment(params?.to_date).format('DD/MM/YYYY')}
      </b>
      <div
        style={{
          height: 1.5,
          width: 230,
          backgroundColor: 'black',
          marginBottom: 10,
        }}
      ></div>
      <Row justify="space-around" style={{ paddingBottom: 20 }}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>Hoàn thành</b>
          <div className={styles.cards} style={{ borderColor: '#54e854' }}>
            {datas?.data?.order.count_success === 0
              ? '0'
              : formatPrice(datas?.data?.order.count_success)}
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>Đang thực hiện</b>
          <div className={styles.cards} style={{ borderColor: '#1890ff' }}>
            {datas?.data?.order.count_pending === 0
              ? '0'
              : formatPrice(datas?.data?.order.count_pending)}
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>Chờ xác nhận</b>
          <div className={styles.cards} style={{ borderColor: 'orange' }}>
            {datas?.data?.order.count_confirmed === 0
              ? '0'
              : formatPrice(datas?.data?.order.count_confirmed)}
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>Từ chối/ Hủy</b>
          <div className={styles.cards} style={{ borderColor: 'tomato' }}>
            {datas?.data?.order.count_cancel === 0
              ? '0'
              : formatPrice(datas?.data?.order.count_cancel)}
          </div>
        </div>
      </Row>

      <Line
        height={100}
        data={!datas ? data : datas?.data?.chart}
        style={{ paddingInline: 50 }}
      />
    </div>
  )
}
