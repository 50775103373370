import { Descriptions, Table, Tag } from 'antd'
import styles from 'common/components/styles/WhiteBox.module.css'
import moment from 'moment'
import { notificationSuccess } from 'utils/notification'
import { useDetailShop } from '../hook/useDetailShop'
import { changeStatusRates, deleteRates } from '../ShopApi'
import RateDetail from './RateDetail'

type Props = {
  data: any
}

export default function ShopTab({ data }: Props) {
  const columns = [
    {
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: <b>Họ tên</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <b>Số điện thoại</b>,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: <b>Số sao đánh giá</b>,
      dataIndex: 'star',
      key: 'star',
    },
    {
      title: <b>Trạng thái đánh giá</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => (
        <Tag color={value === 1 ? 'green' : 'volcano'}>
          {value === 1 ? 'HIỂN THỊ' : 'TẠM ẨN'}
        </Tag>
      ),
    },
    {
      title: <b>Thời gian đánh giá</b>,
      dataIndex: 'update_at',
      key: 'update_at',
      render: (data: any) => <div>{moment(data).format('DD/MM/YYYY')}</div>,
    },
  ]
  const {
    isLoading,
    currentSelected,
    setCurrentSelected,
    rates,
    pagingRates,
    setParamsRates,
    paramsRates,
    getRates,
  } = useDetailShop()
  return (
    <>
      <div>
        <Descriptions title="Thông tin cá nhân" column={2}>
          <Descriptions.Item label="Tên cửa hàng" style={{ paddingRight: 50 }}>
            {data?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Mã số thuế">{data?.tax}</Descriptions.Item>
          <Descriptions.Item label="Số điện thoại">
            {data?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Tên người đại diện">
            {data?.representative}
          </Descriptions.Item>
          <Descriptions.Item label="Email" style={{ paddingRight: 50 }}>
            {data?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Loại cửa hàng">
            <p style={{ color: 'red', marginBottom: '0px' }}>
              {data?.type_petrol_store?.name}
            </p>
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {data?.status ? 'Đang hoạt động' : 'Ngừng hoạt động'}
          </Descriptions.Item>
          <Descriptions.Item label="Đánh giá">
            {Number(data.petrol_store_star).toFixed(1)} sao
          </Descriptions.Item>
          <Descriptions.Item
            label="Địa chỉ chi tiết"
            style={{ paddingRight: 50 }}
          >
            {data?.location_address}, {data?.ward?.name}, {data?.district?.name}
            , {data?.province?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Địa chỉ google maps">
            {data?.address}
          </Descriptions.Item>
          <Descriptions.Item label="Số điện thoại liên hệ">
            {data?.contact_phone}
          </Descriptions.Item>
          <Descriptions.Item label="Tên công ty">
            {data?.company_name}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div style={{ paddingTop: '25px' }}>
        <b style={{ fontSize: 16 }}>Đánh giá cửa hàng</b>
        <Table
          style={{ paddingTop: '20px' }}
          className={styles.table_hover}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
          }}
          bordered
          dataSource={rates}
          columns={columns}
          expandedRowKeys={[currentSelected.id]}
          onRow={(row: any) => ({
            onClick: () => {
              if (currentSelected.id !== row.id) {
                setCurrentSelected(row)
              } else setCurrentSelected({ id: -1 })
            },
          })}
          expandable={{
            expandedRowRender: (record: any) => (
              <RateDetail
                data={record}
                onChangeStatusRates={async (id: any) => {
                  try {
                    const res = await changeStatusRates(id)
                    notificationSuccess(res.message)
                    getRates()
                  } catch (error) {
                    console.log(error)
                  }
                }}
                onDeleteRates={async (id: any) => {
                  try {
                    const res = await deleteRates(id)
                    notificationSuccess(res.message)
                    getRates()
                  } catch (error) {
                    console.log(error)
                  }
                }}
                getData={getRates}
              />
            ),
          }}
          pagination={{
            ...pagingRates,
            onChange: async (page, pageSize) => {
              setParamsRates({ ...paramsRates, page })
            },
          }}
        />
      </div>
    </>
  )
}
