import { ApiClient } from 'services/ApiService'

export const getListProduct = (payload: any) =>
  ApiClient.get('/admin/promotional-product', payload)

export const requestAllCategory = (payload: any) =>
  ApiClient.get('/admin/category', payload)

export const requestListCategory = (payload: any) =>
  ApiClient.get('/admin/promotional-product/list-category', payload)

export const getListProductByCategory = (payload: any) =>
  ApiClient.get('/admin/promotional-product/list-product', payload)

export const requestDetailProduct = (id: any) =>
  ApiClient.get(`/admin/promotional-product/detail-product-promotion/${id}`)

export const requestListPetrolStore = (payload: any) =>
  ApiClient.get('/admin/promotional-product/list-petrol-stores', payload)

export const createSaleProduct = (payload: any) =>
  ApiClient.post(`/admin/promotional-product`, payload)

export const updateSaleProduct = (product_id: number, payload: any) =>
  ApiClient.put(`/admin/promotional-product/${product_id}`, payload)

export const deleteSaleProduct = (product_id: any) =>
  ApiClient.delete(`/admin/promotional-product/${product_id}`)

export const requestUploadImageproduct = (payload: any) =>
  ApiClient.post(`/files/upload/single/${payload.type}`, payload.data)
