import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  LeftCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
import { Button, Card, Descriptions, Popconfirm, Rate, Tabs } from 'antd'
import styles from 'common/components/styles/WhiteBox.module.css'
import moment from 'moment'
import { isImage } from 'utils'
import {
  RenderChatImage,
  RenderViewerFile,
} from '../../partner/PartnerDetailScreen'

type Props = {
  getData: any
  data: any
  onChangeStatusRates: any
  onDeleteRates: any
}
const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const ContentView = (data: any) => {
  return (
    <>
      <Descriptions title="Chi tiết đánh giá" column={3}>
        <Descriptions.Item label="Tên người dùng">
          {data.name}
        </Descriptions.Item>
        <Descriptions.Item label="Số điện thoại">
          {data.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Thời gian đánh giá">
          {moment(data.update_at).format('DD/MM/YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Đánh giá">
          <Rate disabled defaultValue={data.star} />
        </Descriptions.Item>
        <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
        <Descriptions.Item label="Trạng thái">
          {data.status == 1 ? 'Hiển thị' : 'Tạm ẩn'}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item label="Nội dung đánh giá">
          {data.description}
        </Descriptions.Item>
      </Descriptions>
      {data?.review_media &&
        data?.review_media.length > 0 &&
        data?.review_media?.map((media: any) => {
          if (isImage(media?.media_url)) {
            return (
              <Descriptions>
                <Descriptions.Item label="Media">
                  <RenderChatImage key={media.id} item={media} />
                </Descriptions.Item>
              </Descriptions>
            )
          }
          return (
            <Descriptions>
              <Descriptions.Item label="Media">
                <RenderViewerFile key={media.id} item={media} />
              </Descriptions.Item>
            </Descriptions>
          )
        })}
    </>
  )
}

function CategoryDetail({ data, onChangeStatusRates, onDeleteRates }: Props) {
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        style={{ padding: '0px 10px' }}
      >
        <TabPane tab="Thông tin danh mục" key="1">
          <Card
            className={styles.expand}
            actions={[
              <Popconfirm
                // disabled={data.is_default === 1 ? true : false}
                // title={
                //   data.status
                //     ? 'Bạn chắc chắn muốn ẩn đánh giá này?'
                //     : 'Bạn chắc chắn muốn hiện đánh giá này?'
                // }
                title={'Bạn có chắc chắn muốn ẩn đánh giá này?'}
                onConfirm={async () => {
                  try {
                    onChangeStatusRates(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <CheckCircleOutlined /> Đồng ý
                  </>
                }
                cancelText={
                  <>
                    <LeftCircleOutlined /> Trở lại
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    color: '#1890ff',
                    borderColor: '#1890ff',
                  },
                }}
                cancelButtonProps={{
                  danger: true,
                  // type: 'ghost',
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
              >
                <Button
                  type="text"
                  danger
                  style={
                    data.status
                      ? {
                          minWidth: '70px',
                          fontWeight: 'bold',
                          color: '#ff4d4f',
                        }
                      : {
                          minWidth: '70px',
                          fontWeight: 'bold',
                          color: '#1890ff',
                        }
                  }
                >
                  {data.status ? (
                    <span>
                      <PauseCircleOutlined />
                      &nbsp;Tạm ẩn
                    </span>
                  ) : (
                    <span>
                      <PlayCircleOutlined />
                      &nbsp;Hiển thị
                    </span>
                  )}
                </Button>
              </Popconfirm>,

              <Popconfirm
                title="Bạn chắc chắn muốn xoá đánh giá này?"
                onConfirm={async () => {
                  try {
                    onDeleteRates(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <CloseCircleOutlined /> Xóa
                  </>
                }
                cancelText={
                  <>
                    <LeftCircleOutlined /> Trở lại
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    color: '#1890ff',
                    borderColor: '#1890ff',
                  },
                }}
                cancelButtonProps={{
                  danger: true,
                  // type: 'ghost',
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
              >
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  style={{ fontWeight: 'bold', color: '#ff4d4f' }}
                  //   disabled={data.is_default === 1 ? true : false}
                >
                  Xóa đánh giá
                </Button>
              </Popconfirm>,
            ]}
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default CategoryDetail
