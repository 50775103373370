import { Table, Tag } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import moment from 'moment'
import { IS_ACTIVE } from 'utils/constants'
import { useConfigBank } from '../hook/useConfigBank'
import styles from 'common/components/styles/WhiteBox.module.css'
import { AddEditBank } from './AddEditBank'
import DetailBank from './DetailBank'
import {
  changeStatusConfigBank,
  deleteConfigBank,
  updateConfigBank,
} from '../ConfigApi'
import { notificationError, notificationSuccess } from 'utils/notification'
import { useState } from 'react'
import _ from 'lodash'

const columns = [
  {
    title: 'Tên ngân hàng ',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Số tài khoản',
    dataIndex: 'account_number',
    key: 'account_number',
  },
  {
    title: 'Tên người sở hữu',
    dataIndex: 'owner',
    key: 'owner',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (value: number) => {
      switch (value) {
        case IS_ACTIVE.ACTIVE:
          return <Tag color="green">Hoạt động</Tag>
        case IS_ACTIVE.INACTIVE:
          return <Tag color="volcano">Tạm dùng</Tag>
      }
    },
  },
  {
    title: 'Ngày tạo ',
    dataIndex: 'create_at',
    key: 'create_at',
    render: (value: any) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>
    },
  },
]

export default function BankInfors() {
  const [showEditBank, setShowEditBank] = useState(false)
  const {
    isLoading,
    currentSelected,
    listConfigBank,
    setListConfigBank,
    setCurrentSelected,
    getData,
    createBank,
    setShowAddConfigBank,
    showAddConfigBank,
    paging,
  } = useConfigBank()

  const updateBank = async (data: any, resetFields: any) => {
    try {
      let dataPush = {
        id: data.id,
        data: {
          name: data.name,
          icon_url: data.icon_url,
          account_number: data.account_number,
          owner: data.owner,
        },
      }
      const res = await updateConfigBank(dataPush)
      const itemUpdate = { ...res.data, key: res.data.id }
      const index = _.findIndex(listConfigBank, { id: itemUpdate.id })
      listConfigBank.splice(index, 1, itemUpdate)
      setListConfigBank([...listConfigBank])
      notificationSuccess(res.message)
      setShowEditBank(false)
      resetFields()
    } catch (error) {
      notificationError(`Đã có lỗi xảy ra`)
      console.log(error)
    }
  }
  return (
    <div className={styles.content}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: '18px' }}>Thông tin ngân hàng</p>

        <ButtonAdd
          onClickButton={() => {
            paging.total === 5
              ? notificationError(`Tối đa 5 ngân hàng`)
              : setShowAddConfigBank(true)
          }}
          text="Thêm ngân hàng"
        />

        <AddEditBank
          visible={showAddConfigBank}
          isLoadingButton={isLoading}
          onCancel={() => {
            setShowAddConfigBank(false)
          }}
          onCreateNewBank={createBank}
        />
      </div>
      <Table
        className={styles.table_hover}
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        bordered
        dataSource={listConfigBank}
        loading={isLoading}
        columns={columns}
        expandedRowKeys={[currentSelected.id]}
        onRow={(row: any) => ({
          onClick: () => {
            if (currentSelected !== row) setCurrentSelected(row)
            else setCurrentSelected({ id: -1 })
          },
        })}
        expandable={{
          expandedRowRender: (record: any, index: number) => (
            <DetailBank
              onDeleteBank={async (id: any) => {
                try {
                  const res = await deleteConfigBank({ id: [id] })
                  notificationSuccess(res.message)
                  getData()
                  console.log(getData)
                } catch (error) {
                  console.log(error)
                }
              }}
              onChangeStatus={async (id: any) => {
                try {
                  const res = await changeStatusConfigBank({ id: [id] })
                  notificationSuccess('Thay đổi trạng thái thành công')
                  getData()
                  console.log(getData)
                } catch (error) {
                  console.log(error)
                }
              }}
              data={record}
              getData={getData}
              onUpdateBank={updateBank}
              setShowEditBank={setShowEditBank}
              showEditBank={showEditBank}
            />
          ),
        }}
        pagination={false}
      />
    </div>
  )
}
