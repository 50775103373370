import { Descriptions, Timeline } from 'antd'
import moment from 'moment'
import styles from '../styles/style.module.css'

type Props = {
  data: any
}
export default function HistoryOrder({ data }: Props) {
  console.log('DATA: ', data)
  return (
    <Descriptions
      column={1}
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <b>Lịch sử đơn hàng</b>
          <div className={styles.divider}></div>
        </div>
      }
    >
      <Descriptions.Item>
        <Timeline mode="left" style={{ width: '100%', paddingTop: '15px' }}>
          {data?.history?.map((item: any) => {
            return (
              <Timeline.Item
                label={
                  <div className={styles.time_line}>
                    {item.type_order_state.name}
                  </div>
                }
              >
                <div className={styles.timeline_right}>
                  {moment(item.create_at).format('HH:mm DD/MM/YYYY')}
                </div>
              </Timeline.Item>
            )
          })}
        </Timeline>
      </Descriptions.Item>
      {data?.reason == '' ? (
        <></>
      ) : data?.reason == null ? (
        <></>
      ) : (
        <Descriptions.Item
          label="Lý do hủy/ từ chối"
          style={{ paddingInline: 20 }}
        >
          <span>{data?.reason}</span>
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}
