import { PageHeader } from 'antd'
import ButtonExportExcelV2 from 'common/components/Buttons/ButtonExportExcelV2'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { ORDER_STATUS } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { getDetailDebtCustomer } from '../DebtCustomerApi'
import { DEFINED_TYPE_DEBT } from '../DetailDebtCustomerScreen'

type Props = {
  title: string
  params: any
  customer_id: any
  debtStart?: any
}

const StatusOrder = (status: number) => {
  switch (status) {
    case ORDER_STATUS.PENDING:
      return 'Chờ xác nhận'
    case ORDER_STATUS.CONFIRMED:
      return 'Đang thực hiện'
    case ORDER_STATUS.SUCCCESS:
      return 'Hoàn thành'
    case ORDER_STATUS.CANCELED:
      return 'Hủy'
    case ORDER_STATUS.DENY:
      return 'Từ chối'
  }
}

export default function HeaderDetail({
  title,
  params,
  customer_id,
  debtStart,
}: Props) {
  const history = useHistory()
  const location: any = useLocation()

  const [isLoadingBtnExportData, setLoadingBtnExportData] = useState<boolean>(
    false
  )

  const onExportData = async () => {
    try {
      const workbook = new Excel.Workbook()

      setLoadingBtnExportData(true)
      const worksheet = workbook.addWorksheet('Chi tiết công nợ')

      // columns
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10 },
        { header: 'Ngày thay đổi', key: 'buy_date', width: 32 },
        { header: 'Người thay đổi', key: 'create_name', width: 32 },
        { header: 'Tác vụ', key: 'action', width: 20 },
        { header: 'Đơn hàng', key: 'order_name', width: 20 },
        { header: 'Sản phẩm', key: 'product_name', width: 40 },
        { header: 'ĐVT', key: 'product_unit', width: 20 },
        { header: 'Số lượng', key: 'quantity', width: 20 },
        { header: 'Đơn giá', key: 'price', width: 15 },
        { header: 'Phát sinh', key: 'incurred', width: 20 },
        { header: 'Số thanh toán', key: 'payment', width: 20 },
        { header: 'Số còn phải thu', key: 'income', width: 20 },
      ]

      worksheet.insertRow(1, [
        'TỔNG HỢP CÔNG NỢ PHẢI THU CỦA KHÁCH HÀNG ' + title,
      ])
      worksheet.mergeCells('A1', 'K1')
      worksheet.insertRow(2, [
        params.from_date
          ? `Thời gian công nợ: Từ ngày ${moment(params.from_date)?.format(
              'DD/MM/YYYY'
            )} đến ngày ${moment(params.to_date)?.format('DD/MM/YYYY')}`
          : 'Thời gian công nợ: -',
      ])
      worksheet.mergeCells('A2', 'K2')

      //Todo:  style báo cáo bán hnagf
      worksheet.getRow(1).font = { size: 18, color: { argb: '00000' } }
      worksheet.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      //Todo:  styl;e từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }

      //Todo:  style từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }

      // todo: boder style
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      // end
      const dataListReportNotPagging = await getDetailDebtCustomer(
        customer_id,
        {
          ...params,
          limit: 999,
        }
      )
      worksheet.insertRow(3, [
        'Số dư đầu kỳ: ' + formatPrice(debtStart || '0') + 'đ',
      ])
      worksheet.mergeCells('A3', 'J3')

      dataListReportNotPagging?.data?.[0]?.rows?.forEach(
        (o: any, i: number) => {
          worksheet.addRow({
            id: i + 1,
            buy_date: moment(o?.create_at).format('HH:mm DD/MM/YYYY'),
            action: o?.name,
            order_name: o?.code,
            quantity: +o?.amount,
            price: o.data_product?.['product.price'],
            product_name: o.data_product?.['product.name'],
            product_unit: o.unit,
            create_name: o?.create_name,
            incurred:
              o?.type_debt_id !== +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
                ? o?.total_price > 0
                  ? '+' + formatPrice(o?.total_price)
                  : formatPrice(o?.total_price)
                : '',
            payment:
              o?.type_debt_id === +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
                ? o?.total_price > 0
                  ? '+' + formatPrice(o?.total_price)
                  : formatPrice(o?.total_price)
                : '',
            income: o?.total_debt,
          })
        }
      )

      worksheet.eachRow({ includeEmpty: true }, function (row: any, rowNumber) {
        if (row?._number === 4) {
          row.eachCell({ includeEmpty: true }, function (cell: any) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '87CEEB' },
            }
          })
        }

        row.eachCell({ includeEmpty: true }, function (cell: any) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(new Blob([buf]), `Chi tiết công nợ ${title}.xlsx`)
      // setDataExport(exportData)
      // fn()
    } catch (error) {
      console.log(
        '🚀 ~ file: SaleReportScreen.tsx ~ line 177 ~ onExportData ~ error',
        error
      )
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <div>
      <PageHeader
        title={
          <>
            Chi tiết công nợ khách hàng
            <span style={{ color: '#B40000' }}> {title}</span>
          </>
        }
        onBack={() => {
          history.push({
            pathname: `${ADMIN_ROUTER_PATH.DEBT_CUSTOMERS}`,
            state: location.state,
          })
        }}
        extra={[
          <ButtonExportExcelV2
            key="export_excel"
            loading={isLoadingBtnExportData}
            onClick={onExportData}
          />,
        ]}
      />
    </div>
  )
}
