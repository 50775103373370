import { DeleteOutlined } from '@ant-design/icons'
import { InputNumber, PageHeader, Popconfirm, Row, Table } from 'antd'
import ButtonAdd from 'common/components/Buttons/ButtonAdd'
import SaveButton from 'common/components/Buttons/SaveButton'
import openNotificationWithIcon from 'common/components/Notification'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import { IPaging } from 'features/enterprise/order/OrderDetailScreen'
import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import ChanegWarehouseFilter from './ChangeWarehouseFilter'
// import ChangeWarehouseModal from './ChangeWarehouseModal'

export interface IFilter {
  date: string
  warehouse: number
  note: string | undefined
}
export interface IProduct {
  id: number
  code: string
  category: string
  name: string
  price?: number
  quantity?: number
  unit: string
}

export interface ILoading {
  loadingSearch: boolean
  loadingTable: boolean
}

export interface IFilter {
  date: string
  warehouse: number
  note: string | undefined
}

const ChangeWarehouse = () => {
  const dataModal = [
    {
      id: 1,
      key: 1,
      code: 'SP1',
      category: 'Xăng dầu',
      name: 'Xăng ERON E95',
      unit: 'Lít',
      price: 100,
    },
    {
      id: 2,
      key: 2,
      code: 'SP2',
      category: 'Xăng dầu',
      name: 'Xăng ERON E96',
      unit: 'Lít',
      price: 100,
    },
    {
      id: 3,
      key: 3,
      code: 'SP3',
      category: 'Xăng dầu',
      name: 'Xăng ERON E97',
      unit: 'Lít',
      price: 100,
    },
  ]

  const columns = [
    {
      width: '50px',
      title: <b>STT</b>,
      dataIndex: 'id',
      render: (text: any, record: any, index: any) => (
        <td id={record.id}>{(paging.page - 1) * paging.limit + index + 1}</td>
      ),
    },
    {
      title: <b>Mã sản phẩm</b>,
      dataIndex: 'code',
    },
    {
      title: <b>Danh mục sản phẩm</b>,
      dataIndex: 'category',
    },
    {
      title: <b>Tên sản phẩm</b>,
      dataIndex: 'name',
    },
    {
      title: <b>Đơn vị tính</b>,
      dataIndex: 'unit',
    },
    {
      title: <b>Số lượng chuyển</b>,
      dataIndex: 'quantity',
      render: (_: number, record: IProduct) => {
        return (
          <InputNumber
            id={`quantity_${record.id}`}
            defaultValue={1}
            min={1}
            // status="error"
            style={{ width: '100%', borderRadius: 5 }}
            placeholder="Nhập số lượng chuyển"
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(value: number) => {
              onUpdateAmount(record, value)
            }}
          />
        )
      },
    },
    {
      title: <b>Đơn giá</b>,
      dataIndex: 'price',
      render: (value: number, record: IProduct) => {
        return (
          <InputNumber
            id={`price_${record.id}`}
            defaultValue={1}
            min={1}
            style={{ width: '100%', borderRadius: 5 }}
            placeholder="Nhập đơn giá"
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(value: number) => {
              onUpdatePrice(record, value)
            }}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'action',
      render: (value: number, record: IProduct) => {
        return (
          <Popconfirm
            placement="bottomLeft"
            title="Bạn có chắc chắn muốn xóa?"
            style={{ margin: 'auto' }}
            onConfirm={() => {
              onDeleteProduct(record.id)
            }}
            okText={'Xoá'}
            cancelText={'Quay lại'}
            okButtonProps={{
              type: 'primary',
              danger: true,
            }}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        )
      },
    },
  ]

  // Danh sách trong modal được chọn
  const [listProductChecked, setListProductChecked] = React.useState<
    IProduct[]
  >([])

  // Danh sách sản phẩm khi chọn trong modal (gọi API lấy  DS)
  const [listProductModal, setListproductModal] = React.useState<IProduct[]>(
    dataModal
  )

  // Danh sách sản phẩm bên ngoài
  const [listProduct, setListProduct] = React.useState<IProduct[]>([])
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const location: any = useLocation()
  const [filterData, setFilterData] = React.useState<IFilter>({
    date: moment().format().slice(0, 10),
    warehouse: -1,
    note: '',
  })
  const [paging, setPaging] = React.useState<IPaging>({
    page: 1,
    totalItems: 10,
    limit: 12,
  })
  const [isLoading, setIsLoading] = React.useState<ILoading>({
    loadingSearch: false,
    loadingTable: false,
  })

  const openModal = () => setIsVisible(true)
  const closeModal = () => setIsVisible(false)

  const validateEmpty = () => {
    const data = listProduct.find(
      (product: IProduct) => !product.quantity || !product.price
    )
    if (data) {
      listProduct.forEach((product: IProduct) => {
        if (product.id === data.id) {
          if (!data.quantity) {
            document
              .getElementById(`quantity_${data.id}`)
              ?.parentElement?.parentElement?.classList.add(
                'ant-input-number-status-error'
              )
          } else if (!data.price) {
            document
              .getElementById(`price_${data.id}`)
              ?.parentElement?.parentElement?.classList.add(
                'ant-input-number-status-error'
              )
          }
        }
      })
      return true
    }
    return false
  }

  const validateData = (filterData: IFilter) => {
    if (!filterData.date) {
      openNotificationWithIcon(
        'error',
        'Thất bại',
        'Vui lòng chọn ngày chuyển kho!'
      )
      document.getElementById('choose_date')?.focus()
      return false
    } else if (filterData.warehouse === -1) {
      openNotificationWithIcon('error', 'Thất bại', 'Vui lòng chọn kho đến!')
      document.getElementById('choose_warehouse')?.focus()
      return false
    } else if (listProduct.length === 0) {
      openNotificationWithIcon(
        'error',
        'Thất bại',
        'Danh sách sản phẩm không thể trống!'
      )
      return false
    } else if (validateEmpty()) {
      openNotificationWithIcon(
        'error',
        'Thất bại',
        'Số lượng và đơn giá không thể trống!'
      )
      return false
    }
    openNotificationWithIcon(
      'success',
      'Thành công',
      'Thêm thông tin thành công!'
    )
    return true
  }

  const onSaveChoosingProducts = () => {
    setListProduct([...listProduct, ...listProductChecked])
    const checkedRowKeys = listProductChecked.map(
      (product: IProduct) => product.id
    )
    let listProductUnchecked: IProduct[] = listProductModal.filter(
      (product: IProduct) => !checkedRowKeys.includes(product.id)
    )
    setListproductModal(listProductUnchecked)
    setIsVisible(false)
  }

  const onDeleteProduct = (rowKey: number) => {
    const newData = listProduct.filter(
      (product: IProduct) => product.id !== rowKey
    )
    const removedProduct = listProduct.filter(
      (product: IProduct) => product.id === rowKey
    )
    setListproductModal(listProductModal.concat(removedProduct))
    setListProduct(newData)
  }

  const helpFoundIndex = (list: any[], id: number) => {
    return list.findIndex((item: any) => item.id === id)
  }

  const onUpdateAmount = (record: IProduct, value: number) => {
    const indexFounded = helpFoundIndex(listProduct, record?.id)
    if (indexFounded !== -1) {
      let newArray = [...listProduct]
      let newData = { ...listProduct[indexFounded] }
      newData.quantity = value
      newArray[indexFounded] = newData
      setListProduct(newArray)
    }
  }

  const onUpdatePrice = (record: IProduct, value: number) => {
    const indexFounded = helpFoundIndex(listProduct, record?.id)
    if (indexFounded !== -1) {
      let newArray = [...listProduct]
      let newData = { ...listProduct[indexFounded] }
      newData.price = value
      newArray[indexFounded] = newData
      setListProduct(newArray)
      console.log(newData)
    }
  }

  const handleSubmit = () => {
    if (validateData(filterData)) {
      console.log('filterData: ', filterData)
      console.log('listProduct: ', listProduct)
    } else {
      console.log('FAILED')
    }
  }

  return (
    <Container
      header={
        <PageHeader
          title={`Chuyển kho (${location.state.inventoryDetailName})`}
          extra={[<SaveButton onClickButton={handleSubmit} text="Lưu" />]}
          onBack={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.INVENTORY_DETAIL}/${location.state.inventoryDetailId}`,
              state: {
                inventoryDetailId: location.state.inventoryDetailId,
                inventoryDetailName: location.state.inventoryDetailName,
              },
            })
          }}
        />
      }
      filterComponent={
        <ChanegWarehouseFilter
          setFilterData={setFilterData}
          filterData={filterData}
        />
      }
      contentComponent={
        <div>
          <Row
            style={{
              width: '100%',
              marginBottom: 20,
            }}
            justify="end"
          >
            <ButtonAdd text="Thêm sản phẩm" onClickButton={openModal} />
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={listProduct}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            pagination={false}
          />
        </div>
      }
    />
  )
}

export default ChangeWarehouse
