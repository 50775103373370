import {
  CheckCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  Input,
  PageHeader,
  Form,
  Row,
  Col,
  Checkbox,
  Upload,
  Button,
} from 'antd'
import { ButtonActive } from 'common/components/Buttons'
import styles from 'common/components/styles/WhiteBox.module.css'
import style from './styles/news.module.css'
import { useEffect, useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { RadiusSelection } from 'common/components/Inputs'
import { uploadImageToServer } from 'utils/uploadImageToServer'
import { useNews } from './hook/useNews'
import { useAddEdit } from './hook/useAddEdit'
import { useDispatch } from 'react-redux'
// import { getNewstInfor } from './NewsSlice'
import EditorComponent from 'common/components/editor-hook/EditorComponent'
import { changeStatusNews, createNews, getListNews, updateNews } from './NewApi'
import { useHistory, useParams } from 'react-router-dom'
const { TextArea } = Input

function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    notificationError('Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    notificationError('Cho phép ảnh có dung lượng tối đa là 2MB')
  }
  return isJpgOrPng && isLt2M
}

function convertDataToFrom(value: any) {
  if (!value) {
    return {
      title_banner: null,
      type_banner: null,
      banner_status: null,
      status: null,
      order: null,
      description: null,
      expired_at: null,
      create_at: null,
      image_url: null,
      is_notify: null,
    }
  } else {
    return {
      ...value,
      title_banner: value.title_banner,
      type_banner: value.type_banner,
      status: value.status,
      img_url: value.status,
      description: value.description,
      is_notify: value.is_notify,
    }
  }
}

type Props = {
  visible: boolean
  onCancel?: any
  data?: any

  isLoading: boolean
}

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  banner_status: undefined | string
  from_date: undefined | Date
  to_date: undefined | Date
  limit: number
  type_banner: undefined | string
  is_notify: undefined | boolean
}

export default function AddNewsScreen({ onCancel, data }: Props) {
  const dispatch = useDispatch()
  // const NewsInfor = useSelector(getNewstInfor)
  const [description, setDescription] = useState<string>()
  const { dataSource, setDataSource, setIsLoading, isLoading } = useAddEdit()
  // const {
  //   param,
  //   history,
  //   getData,
  //   onUpdateNews,
  //   onCreateNews,
  //   onChangeStatus,
  // } = useNews()

  const { callback, setCallback } = useNews()

  const [upload, setUpload] = useState<{ loading: boolean; imageUrl: string }>({
    loading: false,
    imageUrl: '',
  })
  const [checkSend, setCheckSend] = useState(false)
  useEffect(() => {
    if (dataSource) {
      const check = dataSource?.is_notify ? true : false
      setNotifys(check)
    }
  }, [dataSource.is_notify])
  const [form] = Form.useForm()
  const initialValues = convertDataToFrom(dataSource)
  // form.setFieldsValue(initialValues)
  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setUpload({
          imageUrl: imageUrl,
          loading: false,
        })
      )
    }
  }

  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const [notifys, setNotifys] = useState(false)

  const onChangeNotify = (e: { target: { checked: any } }) => {
    setNotifys(!notifys)
  }

  const history = useHistory()
  const param: any = useParams()
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [listNew, setListNew] = useState<any>([])
  const [showAddNew, setShowAddNew] = useState(false)
  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    banner_status: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
    limit: 12,
    type_banner: undefined,
    is_notify: undefined,
  })

  const [pagingtion, setPagingtion] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      const res = await getListNews(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt:
            (res.meta?.pagination?.page - 1) * res.meta?.pagination?.limit +
            index +
            1,
        }
      })
      setListNew(tableData)
      setPagingtion({
        ...pagingtion,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const onCreateNews = async (data: any, resetFields: any) => {
    // setIsLoading(true)
    try {
      const res = await createNews(data)
      setTimeout(() => {
        notificationSuccess(res.message)
        resetFields()
        const new_list = listNew.unshift(res.data)
        setListNew(new_list)
        history.goBack()
        getData()
      }, 300)
    } catch (error) {
      console.log(error)
      notificationError(`Đã có lỗi xảy ra`)
    }
  }

  const onUpdateNews = async (data: any, resetFields: any) => {
    // setIsLoading(true)
    try {
      const res = await updateNews(data)
      notificationSuccess(res.message)
      resetFields()
      setCallback(!callback)
      history.goBack()
    } catch (error) {
      console.log(error)
      notificationError(`Đã có lỗi xảy ra`)
    }
  }

  const onChangeStatus = async (id: any) => {
    console.log('id: ', id)
    try {
      const res = await changeStatusNews({ id: [id] })
      notificationSuccess(res.message)
      getData()
    } catch (error) {
      console.log(error)
    }
  }

  const onFinish = async (values: any, onCancel: any) => {
    setIsLoading(true)
    try {
      //Upload image lên Server
      if (values.image_url.fileList) {
        var resUploadImage: any
        var last_element = []

        if (values.image_url.fileList.length > 1) {
          last_element = [values.image_url.fileList.slice(-1)[0]]
        } else {
          last_element = values.image_url.fileList
        }

        resUploadImage = await uploadImageToServer(last_element)
      }
      console.log(
        '🚀 ~ file: AddEdditNewsScreen.tsx:269 ~ onFinish ~ resUploadImage',
        resUploadImage
      )
      let newData
      if (!param.id) {
        newData = {
          ...values,
          type_banner: values.type_banner,
          banner_status: values.banner_status,
          description: values.description,
          image_url: resUploadImage?.location.slice(
            resUploadImage?.location.indexOf('upload')
          ),
          is_notify: notifys ? 1 : 0,
          order: 1,
        }
        // {
        //   values.banner_status == 0 && notifys == true
        //     ? notificationError(
        //         `Trạng thái lưu nháp không thể gửi thông báo cho KH`
        //       )
        //     : (
        //       onCreateNews(newData, form.resetFields)
        //       getData()
        //     )
        // }
        if (values.banner_status == 0 && notifys == true) {
          notificationError(
            `Trạng thái lưu nháp không thể gửi thông báo cho KH`
          )
        } else {
          onCreateNews(newData, form.resetFields)
          getData()
        }
      } else {
        if (dataSource?.image_url === values?.image_url) {
          // delete values.image_url
          newData = {
            data: {
              ...values,
              type_banner: values.type_banner,
              banner_status: values.banner_status,
              description: values.description,
              is_notify: notifys ? 1 : 0,
              image_url: resUploadImage
                ? resUploadImage?.location.slice(
                    resUploadImage?.location.indexOf('upload')
                  )
                : dataSource?.image_url?.slice(
                    dataSource?.image_url.indexOf('upload')
                  ),
              order: 1,
            },
            id: dataSource.id,
          }
        } else {
          newData = {
            data: {
              ...values,
              type_banner: values.type_banner,
              banner_status: values.banner_status,
              description: values.description,
              image_url: resUploadImage
                ? resUploadImage?.location.slice(
                    resUploadImage?.location.indexOf('upload')
                  )
                : dataSource?.image_url?.slice(
                    dataSource?.image_url?.indexOf('upload')
                  ),
              is_notify: notifys ? 1 : 0,
              order: 1,
            },
            id: dataSource.id,
          }
        }
        {
          values.banner_status == 0 && notifys == true
            ? notificationError(
                `Trạng thái lưu nháp không thể gửi thông báo cho KH`
              )
            : onUpdateNews(newData, form.resetFields)
        }
      }
    } catch (error: any) {
      console.log(error)
      notificationError('Đã xảy ra lỗi')
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 5000)
    }
  }

  useEffect(() => {
    if (dataSource) {
      setUpload({
        ...upload,
        imageUrl: (dataSource?.image_url as string) || '',
      })
      form.setFieldsValue(dataSource)
    }
  }, [dataSource])

  useEffect(() => {
    if (dataSource.banner_status === 0) {
      setCheckSend(true)
    } else {
      setCheckSend(false)
    }
  }, [dataSource])

  useEffect(() => {
    setTimeout(() => {
      setDescription('hi')
    }, 1000)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PageHeader
          title={!param.id ? 'Thêm mới Banner, Tin tức' : 'Sửa Banner, Tin tức'}
          onBack={() => {
            history.goBack()
          }}
          extra={[
            <>
              {param.id ? (
                <ButtonActive
                  isActive={dataSource.status}
                  onConfirm={() => {
                    try {
                      onChangeStatus(param.id)
                      setDataSource({
                        ...dataSource,
                        status: dataSource.status ? 0 : 1,
                      })
                    } catch (error) {
                      console.log(error)
                    } finally {
                    }
                  }}
                  title={
                    1
                      ? 'Bạn thực sự muốn ngừng hoạt động tin tức này?'
                      : 'Bạn thực sự muốn tin tức này hoạt động?'
                  }
                />
              ) : (
                ''
              )}
            </>,
          ]}
        />
      </div>
      <div className={styles.content}>
        <Form
          form={form}
          initialValues={initialValues}
          labelAlign="left"
          onFinish={(values: any) => onFinish(values, onCancel)}
        >
          <Row gutter={[16, 16]}>
            <Col span={14}>
              <Form.Item
                name="title_banner"
                label={<span className={style.form}>Tiêu đề banner</span>}
                rules={[
                  {
                    type: 'string',
                    message: 'Nhập tiêu đề',
                  },
                  {
                    required: true,
                    message: 'Vui lòng nhập tiêu đề!',
                  },
                  {
                    max: 255,
                    message: 'Vui lòng nhập không quá 255 ký tự!',
                  },
                ]}
                style={{ width: '80%' }}
              >
                <Input placeholder="Nhập tiêu đề" />
              </Form.Item>
              <Form.Item
                label={<span className={style.form}>Loại banner</span>}
                name="type_banner"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn loại banner!',
                  },
                ]}
                style={{ width: '80%' }}
              >
                <RadiusSelection
                  placeholder="Chọn loại banner"
                  options={[
                    { value: 1, text: 'Banner' },
                    { value: 0, text: 'Tin tức' },
                  ]}
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<span className={style.form}>Trạng thái banner</span>}
                name="banner_status"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn trạng thái!',
                  },
                ]}
                // style={{ width: '100%' }}
              >
                <RadiusSelection
                  placeholder="Chọn trạng thái"
                  options={[
                    { value: 1, text: 'Đăng bài' },
                    { value: 0, text: 'Lưu nháp' },
                  ]}
                  disabled={
                    param.id
                      ? dataSource.banner_status === 1
                        ? true
                        : false
                      : false
                  }
                  onChange={(value: number) => {
                    if (value === 0) {
                      setCheckSend(true)
                    } else {
                      setCheckSend(false)
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="is_notify">
            <Checkbox
              style={{ marginBottom: '20px' }}
              disabled={
                param.id ? (dataSource.is_notify === 1 ? true : false) : false
              }
              checked={notifys}
              onChange={onChangeNotify}
            >
              {<b style={{ fontSize: 18 }}>Gửi thông báo cho khách hàng</b>}
            </Checkbox>
          </Form.Item>
          <Form.Item
            label={<span className={style.form}>Ảnh banner</span>}
            name="image_url"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ảnh!',
              },
            ]}
          >
            <Upload
              listType="picture-card"
              accept="image/jpeg,image/png,image/jpg"
              name="image_url"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {upload.imageUrl ? (
                <img
                  src={upload.imageUrl}
                  alt="avatar"
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    height: '100%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label={<span className={style.form}>Nội dung</span>}
            name="description"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập nội dung!',
              },
            ]}
            // style={{ margin: 10 }}
          >
            <EditorComponent
              value={description}
              onChange={(value: string) => {
                form.setFieldsValue({
                  description: value,
                })
                console.log(value)
              }}
              editorStyle={{
                border: '1px solid #ACB0B0',
                borderRadius: '5px',
                overflow: 'hidden scroll',
                paddingInline: 10,
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              style={{
                float: 'right',
                minWidth: '70px',
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
              htmlType="submit"
            >
              <CheckCircleOutlined />
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
