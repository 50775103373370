import { ApiClient } from 'services/ApiService'
// Api khách hàng
export const getListCustomer = (payload: any) =>
  ApiClient.get('/admin/customer', payload)
export const getInforCustomer = (id: number) =>
  ApiClient.get(`/admin/customer/${id}/info`)
export const getListOrderCustomer = (id: number, payload: any) =>
  ApiClient.get(`/admin/customer/${id}/order`, payload)
export const getListDebtCustomer = (id: number, payload: any) =>
  ApiClient.get(`/admin/customer/${id}/debt`, payload)
export const getListPointHistoryCustomer = (id: number, payload: any) =>
  ApiClient.get(`/admin/customer/${id}/point-history`, payload)
export const getListReferralCustomer = (id: number, payload: any) =>
  ApiClient.get(`/admin/customer/${id}/referral`, payload)
export const changeStatusCustomer = (id: number) =>
  ApiClient.patch(`/admin/customer/${id}`)
export const getListRankCustomer = (id: number, payload: any) =>
  ApiClient.get(`/admin/customer/${id}/rank-history`, payload)

// Api áp hạn mức công nợ
export const imposeLimitDebt = (payload: any) =>
  ApiClient.post(`/enterprise/add-debt`, payload)
export const changeStatusDebt = (payload: any) =>
  ApiClient.post(`/enterprise/change-status-debt`, payload)

// Api lấy danh sách rank
export const requestRanks = () => ApiClient.get('/admin/config-ranks')
