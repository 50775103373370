import { Descriptions } from 'antd'
import { useOrder } from '../hook/useOrder'
import styles from '../styles/style.module.css'

type Props = {
  data: any
}
export default function CustomerInfor({ data }: Props) {
  return (
    <Descriptions
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <b>Thông tin KH</b>
          <div className={styles.divider}></div>
        </div>
      }
      column={1}
    >
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Tên khách hàng</span>}
      >
        {data.nameCustomer}
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Số điện thoại</span>}
      >
        {data.phoneCustomer}
      </Descriptions.Item>
    </Descriptions>
  )
}
