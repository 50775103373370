import { Button, Form, InputNumber, Modal, Row, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import CancelButton from 'common/components/Buttons/CancelButton'
import openNotificationWithIcon from 'common/components/Notification'
import {
  getListProduct,
  requestAllCategory,
} from 'features/admin/product/product/ProductApi'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { ICategory, IProductSelection } from '../../Inventory'
import { addANewProduct } from '../service'

interface IAddInventoryModal {
  isOpenAddModal: boolean
  onCancelAddModal: () => void
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  getDetail: () => Promise<void>
  petrolStoreId: number
}

const AddInventoryModal = (props: IAddInventoryModal) => {
  const {
    isOpenAddModal,
    onCancelAddModal,
    setIsLoading,
    getDetail,
    petrolStoreId,
  } = props
  const [categoryId, setCategoryId] = React.useState<number | undefined>(0)
  const [categories, setCategories] = React.useState<ICategory[]>([])
  const [productSelections, setProductSelections] = React.useState<
    IProductSelection[]
  >([])
  const [productId, setProductId] = React.useState<number | undefined>(-1)
  const [beginInventory, setBeginInventory] = React.useState<number>()
  const [loss, setLoss] = React.useState<number>()
  const [form] = useForm()

  const getListCategory = async () => {
    try {
      const res: any = await requestAllCategory({ limit: 999 })
      const data = res.data.map((item: any) => {
        return { value: item.id, text: item.name }
      })
      setCategories(data)
    } catch (error) {
      console.log('ERROR:', error)
    }
  }

  const getDataProduct = async () => {
    try {
      const res = await getListProduct({
        page: 1,
        category_id: categoryId,
      })
      console.log('RES: ', res)
      const tableData = res.data?.map((item: any) => {
        return {
          value: item.id,
          text: item.name,
        }
      })
      setProductSelections(tableData)
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  const onSubmitForm = async () => {
    try {
      setIsLoading(true)
      const payload = {
        category_id: categoryId,
        petrol_store_id: [petrolStoreId],
        product_id: productId,
        remain: beginInventory,
        diminish: loss,
        type: 1,
      }
      const res = await addANewProduct(payload)
      if (res.status) {
        openNotificationWithIcon(
          'success',
          'Thành công',
          'Thêm sản phẩm mới thành công!'
        )
        getDetail()
      } else {
        openNotificationWithIcon(
          'error',
          'Thất bại',
          'Thêm sản phẩm mới thất bại!'
        )
      }

      onCancelAddModal()
    } catch (error) {
      console.log('ERROR: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getListCategory()
  }, [])

  React.useEffect(() => {
    getDataProduct()
  }, [categoryId])

  return (
    <Modal
      title="Bổ sung tồn kho"
      centered
      visible={isOpenAddModal}
      onCancel={onCancelAddModal}
      footer={null}
      width="50%"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onSubmitForm}
        autoComplete="off"
      >
        <Form.Item
          label="Danh mục sản phẩm"
          name="productCategory"
          rules={[
            { required: true, message: 'Vui lòng chọn danh mục sản phẩm!' },
          ]}
        >
          <Select
            allowClear
            placeholder="Chọn danh mục sản phẩm"
            style={{ width: '100%' }}
            onChange={(value: number | undefined) => {
              setCategoryId(value)
              form.setFieldsValue({
                productName: undefined,
                storeName: undefined,
                loss: undefined,
              })
            }}
          >
            {categories.map((category: ICategory) => (
              <Select.Option value={category.value}>
                {category.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tên sản phẩm"
          name="productName"
          rules={[{ required: true, message: 'Vui lòng chọn sản phẩm!' }]}
        >
          <Select
            allowClear
            placeholder="Chọn sản phẩm"
            style={{ width: '100%' }}
            disabled={categoryId === -1 || !categoryId ? true : false}
            onChange={(value: number | undefined) => {
              setProductId(value)
            }}
          >
            {productSelections.map((category: ICategory) => (
              <Select.Option value={category.value}>
                {category.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tồn kho đầu kỳ"
          name="inventory"
          rules={[
            { required: true, message: 'Vui lòng nhập tồn kho đầu kỳ!' },
            {
              min: 1,
              type: 'number',
              message: `Vui lòng nhập tồn đầu lớn hơn 1`,
            },
          ]}
        >
          <InputNumber
            min={1}
            style={{ width: '100%' }}
            placeholder="Nhập tồn kho đầu kỳ"
            value={beginInventory}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(value: number) => {
              setBeginInventory(value)
            }}
            addonAfter={categoryId === 1 ? 'Lít' : ''}
          />
        </Form.Item>

        {categoryId === 1 && (
          <Form.Item
            label="Hao hụt"
            name="loss"
            rules={[
              { required: true, message: 'Vui lòng nhập hao hụt!' },
              {
                min: 1,
                max: beginInventory,
                type: 'number',
                message: `Vui lòng nhập hao hụt trong khoảng 1  - ${beginInventory}`,
              },
            ]}
          >
            <InputNumber
              min={1}
              style={{ width: '100%' }}
              placeholder="Nhập hao hụt cho sản phẩm"
              value={loss}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={(value: number | undefined) => {
                setLoss(value)
              }}
              addonAfter="Lít"
            />
          </Form.Item>
        )}
        <br />
        <Row justify="center">
          <CancelButton text="Huỷ" onClickButton={onCancelAddModal} />
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 10, borderRadius: 3 }}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddInventoryModal
