import { PageHeader } from 'antd'
import {
  ButtonActive,
  ButtonDelete,
  ButtonEdit,
} from 'common/components/Buttons'
import styles from 'common/components/styles/WhiteBox.module.css'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { notificationError, notificationSuccess } from 'utils/notification'
import DetailGeneral from './components/DetailGeneral'
import DetailImage from './components/DetailImage'
import DetailPrice from './components/DetailPrice'
import { useDetailProduct } from './hook/useDetailProduct'
import { changeStatusproduct, deleteProduct } from './ProductApi'
import { clearDataProduct } from './ProductSlice'

export default function ProductDetailScreen() {
  const param: { id: any } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const location: any = useLocation()
  const { productData, setProductData } = useDetailProduct()
  const [isLoading, setIsLoading] = useState({ loadingDelete: false })

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PageHeader
          title="Chi tiết sản phẩm"
          onBack={() => {
            dispatch(clearDataProduct())
            location.state?.name === ADMIN_ROUTER_PATH.PRODUCT
              ? history.push({
                  pathname: `${ADMIN_ROUTER_PATH.PRODUCT}`,
                  state: location.state,
                })
              : history.push({
                  pathname: `${ADMIN_ROUTER_PATH.SHOP_DETAIL}/${location.state?.idShop}`,
                  state: location.state,
                })
          }}
          extra={[
            <ButtonActive
              isActive={productData.status}
              onConfirm={async () => {
                try {
                  if (productData.category_status === 0) {
                    notificationError(
                      'Danh mục của sản phẩm đang ngưng hoạt động'
                    )
                  } else {
                    setProductData({
                      ...productData,
                      status: productData.status ? 0 : 1,
                    })

                    const res = await changeStatusproduct(param.id)
                    notificationSuccess(res.message)
                  }
                } catch (error: any) {
                  notificationError(error.message)
                }
              }}
              title={
                productData.status
                  ? 'Bạn thực sự muốn ngừng hoạt động sản phẩm này?'
                  : 'Bạn thực sự muốn sản phẩm này hoạt động?'
              }
            />,
            <ButtonEdit
              onClickButton={() => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.EDIT_PRODUCT}/${param.id}`,
                  state: location.state,
                })
              }}
              text="Sửa"
            />,
            <ButtonDelete
              text="Xóa"
              title="Bạn chắc chắn muốn xóa sản phẩm này"
              isLoading={isLoading.loadingDelete}
              onConfirm={async () => {
                setIsLoading({ ...isLoading, loadingDelete: true })
                try {
                  const res = await deleteProduct(param.id)
                  notificationSuccess(res.message)
                  history.replace(`${ADMIN_ROUTER_PATH.PRODUCT}`)
                } catch (error: any) {
                  notificationSuccess(error.message)
                } finally {
                  setIsLoading({ ...isLoading, loadingDelete: false })
                }
              }}
            />,
          ]}
        />
      </div>
      <div className={styles.content}>
        <DetailGeneral productData={productData} />
      </div>
      <div className={styles.content}>
        <DetailImage />
      </div>
      <div className={styles.content}>
        <DetailPrice />
      </div>
    </div>
  )
}
