import { Descriptions, Timeline } from 'antd'
import styles from '../../orders/styles/style.module.css'

export default function HistoryOrder() {
  return (
    <Descriptions
      title={
        <div className={styles.title}>
          <b>Lịch sử đơn hàng</b>
          {/* <Divider /> */}
        </div>
      }
    >
      <Descriptions.Item>
        <Timeline mode="left" style={{ width: '100%', paddingTop: '15px' }}>
          <Timeline.Item
            label={<div className={styles.timeline_left}>Đã thực hiện</div>}
          >
            <div className={styles.timeline_right}>20.10.2021 09:12:11</div>
          </Timeline.Item>
          <Timeline.Item
            label={<div className={styles.timeline_left}>Đang thực hiện</div>}
          >
            <div className={styles.timeline_right}>20.10.2021 09:12:11</div>
          </Timeline.Item>
          <Timeline.Item
            label={
              <div className={styles.timeline_left}>Xác nhận đơn hàng</div>
            }
          >
            <div className={styles.timeline_right}>20.10.2021 09:12:11</div>
          </Timeline.Item>
          <Timeline.Item
            label={
              <div className={styles.timeline_left}>Thời gian đặt hàng</div>
            }
          >
            <div className={styles.timeline_right}>20.10.2021 09:12:11</div>
          </Timeline.Item>
        </Timeline>
      </Descriptions.Item>
    </Descriptions>
  )
}
