import { useState } from 'react'
import { Input, Form, Button, Divider } from 'antd'
import { formatPrice } from 'utils/ruleForm'
import { CheckCircleOutlined } from '@ant-design/icons'
import { notificationSuccess } from 'utils/notification'
import { updateConfigSystem } from '../ConfigApi'
type Props = {
  listConfigSystem: any
  setListConfigSystem: any
  getDataSystems: any
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

function OrderPromotion({ listConfigSystem, getDataSystems }: Props) {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const onFinish = async (values: any) => {
    setLoading(true)
    try {
      delete values.confirm
      let newData

      newData = {
        ...values,
        id: 1,
        point: Number(values.point).toFixed(3),
      }
      updateConfigSystem(newData)
      notificationSuccess('Cập nhật thành công')
      getDataSystems()
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  return (
    <>
      <div
        style={{
          borderWidth: '1px',
          borderColor: 'black',
          borderStyle: 'solid',
          padding: 20,
          height: 235,
        }}
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          form={form}
          onFinish={(values: any) => onFinish(values)}
          scrollToFirstError
        >
          <Form.Item
            {...tailFormItemLayout}
            label={<span style={{ fontSize: 18 }}>Mua hàng</span>}
          >
            <Button
              loading={loading}
              type="primary"
              style={{
                float: 'right',
                minWidth: '70px',
                fontWeight: 'bold',
                borderRadius: '3px',
              }}
              htmlType="submit"
            >
              <CheckCircleOutlined />
              Lưu
            </Button>
          </Form.Item>
          <Divider />
          <Form.Item
            label={<span style={{ fontSize: 16 }}>Tích điểm (%)</span>}
            name="point"
            rules={[
              {
                message:
                  'Vui lòng nhập % điểm đúng định dạng từ 0 đến 100 (ví dụ: 1.234)',
                validator: (_, value) => {
                  const reg = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/
                  const array = value.split('.')
                  if (
                    reg.test(value) &&
                    value !== '' &&
                    value >= 0 &&
                    array[0] !== '' &&
                    array[1] !== '' &&
                    array[0] <= 100
                  ) {
                    if (
                      (array[1] && array[1].length > 3) ||
                      (array[1] && array[0].length > 2 && array[1].length > 0)
                    )
                      return Promise.reject()
                    return Promise.resolve()
                  }
                  return Promise.reject()
                },
              },
            ]}
          >
            <Input
              onChange={() => {}}
              value={
                listConfigSystem?.data
                  ? formatPrice(listConfigSystem?.data[0]?.value)
                  : 0
              }
              placeholder={listConfigSystem?.data?.[0].value}
              style={{ width: 150, float: 'right' }}
            />
          </Form.Item>
          <span style={{ color: '#990000', fontSize: 'small', float: 'right' }}>
            * Tính theo tổng giá trị thanh toán đơn hàng.
          </span>
        </Form>
      </div>
    </>
  )
}
export default OrderPromotion
