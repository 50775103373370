import { PageHeader, Spin, Table } from 'antd'
import openNotificationWithIcon from 'common/components/Notification'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import useDebounce from 'features/admin/report/sale/hook/useDebounce'
import React from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import { formatedDate } from 'utils/TimerHelper'
import { IPaging } from '../../InventoryList'
import { getHistoryChange } from '../service'
import HistoryFilter from './HistoryFilter'

interface IHistoryInventory {
  isModalVisible: boolean
  handleCancel: () => void
  storeId: number
}

interface IHistory {
  id: number
  changeTypeName: string
  productName: string
  changeDetail: any
  changedWarehouse: string
  receivedWarehouse: string
  changedDate: string
  user: string
}

interface IPram {
  search: string | undefined
  storage_change_type_id: number | undefined
  page: number
  product_id?: any
}

const HistoryInventoryDetail = (props: IHistoryInventory) => {
  const columns = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: any, index: any) => (
        <td id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
    },
    {
      title: <b>Thời gian thực</b>,
      dataIndex: 'realDate',
    },
    {
      title: <b>Thời gian chuyển đổi</b>,
      dataIndex: 'changedDate',
    },
    {
      title: <b>Tên sản phẩm</b>,
      dataIndex: 'productName',
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'changeTypeName',
    },
    {
      title: <b>Tồn đầu</b>,
      dataIndex: 'information',
      render: (value: any, row: any) =>
        [1].includes(row?.storage_change_type_id) ? row?.remain : '0.00',
    },
    // {
    //   title: <b>Đầu kỳ</b>,
    //   dataIndex: 'information',
    //   render: (value: any) => value?.old_stock,
    // },
    {
      title: <b>Nhập kho</b>,
      dataIndex: 'information',
      render: (value: any, row: any) =>
        row?.storage_change_type_id != 1 &&
        ![3, 4].includes(row?.storage_change_type_id)
          ? row?.amount
          : '0.00',
    },
    {
      title: <b>Xuất kho</b>,
      dataIndex: 'information',
      render: (value: any, row: any) =>
        [3, 4].includes(row?.storage_change_type_id) ? row?.amount : '0.00',
    },
    {
      title: <b>Hao hụt</b>,
      dataIndex: 'information',
      render: (value: any, row: any) =>
        Number(
          ((+value?.cur_diminish || 0) - (+value?.old_diminish || 0)).toFixed(2)
        ) || '0.00',
    },
    // {
    //   title: <b>Cuối kỳ</b>,
    //   dataIndex: 'information',
    //   render: (value: any) => value?.cur_stock || '0.00',
    // },
    // {
    //   title: <b>Thông tin thay đổi</b>,
    //   dataIndex: 'changeDetail',
    //   render: (_: any, record: any) => {
    //     return (
    //       <td>
    //         {record.information?.old_stock !== undefined && (
    //           <p>
    //             Tồn kho:{' '}
    //             {record?.information?.old_stock !== 0
    //               ? record?.information?.old_stock
    //               : '0.00'}
    //             <ArrowRightOutlined />{' '}
    //             <span style={{ color: 'green' }}>
    //               {formatPrice(record?.information?.cur_stock.toFixed(2)) ||
    //                 '0.00'}
    //               {/* {typeof } */}
    //             </span>{' '}
    //           </p>
    //         )}
    //         {record.information?.old_diminish !== undefined && (
    //           <p>
    //             Hao hụt:{' '}
    //             {Number(formatPrice(record?.information?.old_diminish)).toFixed(
    //               2
    //             ) || '0.00'}{' '}
    //             <ArrowRightOutlined />{' '}
    //             <span style={{ color: 'red' }}>
    //               {Number(
    //                 formatPrice(record?.information?.cur_diminish)
    //               ).toFixed(2) || '0.00'}
    //             </span>
    //           </p>
    //         )}
    //       </td>
    //     )
    //   },
    // },
    // {
    //   title: <b>Kho thay đổi</b>,
    //   dataIndex: 'changedWarehouse',
    // },
    // {
    //   title: <b>Kho nhận</b>,
    //   dataIndex: 'receivedWarehouse',
    // },

    // {
    //   title: <b>Người thay đổi</b>,
    //   dataIndex: 'user',
    // },
  ]

  const location: any = useLocation()

  const [listHistory, setListHistory] = React.useState<IHistory[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [search, setSearch] = React.useState<string>('')
  const [storageDesId, setStorageDesId] = React.useState<number | undefined>()
  const [storageSourceId, setStorageSourceId] = React.useState<
    number | undefined
  >()
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [storageChangeTypeId, setStorageChangeTypeId] = React.useState<
    number | undefined
  >()
  const [param, setParam] = React.useState<IPram>({
    search: search,
    storage_change_type_id: storageChangeTypeId,
    page: currentPage,
  })

  const [paging, setPaging] = React.useState<IPaging>({
    total: 0,
    current: currentPage,
    pageSize: 12,
  })

  const searchDebounce = useDebounce(search, 300)

  const getHistory = async () => {
    try {
      setIsLoading(true)
      const res = await getHistoryChange(location?.state?.id, param)
      if (res.status) {
        const data = res.data.map((item: any) => {
          return {
            ...item,
            changeTypeName: item.change_type_name,
            productName: item.product_name,
            changedWarehouse: item.storage_source,
            receivedWarehouse: item.storage_destination,
            information: item.information,
            changedDate: formatedDate(item.input_date),
            realDate: formatedDate(item.create_at),
            user: item.user_name,
            categoryId: item.category_id,
          }
        })
        setListHistory(data)
        const page = {
          total: res.meta.pagination.totalItems,
          current: res.meta.pagination.page,
          pageSize: res.meta.pagination.limit,
        }
        setCurrentPage(res.meta.pagination.page)
        setPaging(page)
      } else {
        openNotificationWithIcon(
          'error',
          'Thông báo',
          'Đã có lỗi xảy ra! Xin vui lòng thử lại'
        )
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (!param?.product_id) return
    getHistory()
  }, [param])

  React.useEffect(() => {
    setParam({
      ...param,
      search: searchDebounce === '' ? undefined : searchDebounce,
      storage_change_type_id: storageChangeTypeId,
      page: currentPage,
      product_id: location?.state?.product_id,
    })
  }, [
    searchDebounce,
    storageChangeTypeId,
    currentPage,
    location?.state?.product_id,
  ])

  return (
    <Container
      header={
        <PageHeader
          title={`Lịch sử thay đổi_${location.state.inventoryDetailName}`}
          onBack={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.INVENTORY_DETAIL}/${location?.state?.inventoryDetailId}`,
              state: {
                nventoryDetailId: location.state.inventoryDetailId,
                inventoryDetailName: location.state.inventoryDetailName,
                currentPage: location?.state?.currentPage,
                search: location?.state?.search,
                id: location?.state?.id,
                currentPageList: location?.state?.currentPageList,
                categoryId: location?.state?.categoryId,
                fromDate: location?.state?.fromDate,
                toDate: location?.state?.toDate,
              },
            })
          }}
        />
      }
      filterComponent={
        <HistoryFilter
          search={search}
          setSearch={setSearch}
          storageSourceId={storageSourceId}
          storageDesId={storageDesId}
          setStorageSourceId={setStorageSourceId}
          storageChangeTypeId={storageChangeTypeId}
          setStorageChangeTypeId={setStorageChangeTypeId}
          setStorageDesId={setStorageDesId}
        />
      }
      contentComponent={
        <Spin spinning={isLoading}>
          <div>
            <p>
              Kết quả lọc: <b>{paging.total}</b>
            </p>
            <Table
              bordered
              columns={columns}
              dataSource={listHistory}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              pagination={{
                ...paging,
                showSizeChanger: false,
                onChange: async page => {
                  setPaging({ ...paging, current: page })
                  setCurrentPage(page)
                },
              }}
            />
          </div>
        </Spin>
      }
    />
  )
}

export default HistoryInventoryDetail
