import BankInfors from './components/BankInfors'
import PartnerInfor from './components/PartnerInfor'

export default function DisplayInfor() {
  return (
    <>
      <BankInfors />
      <PartnerInfor />
    </>
  )
}
