import { Form, Input, Modal, Row, Col, AutoComplete } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import { useParams } from 'react-router'
import styles from '../styles/shop.module.css'
import RadiusSelection from 'common/components/Inputs/RadiusSelection'
import { EnvironmentOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import {
  createPetrolStore,
  getDistrict,
  getPlace,
  getPlaceAuto,
  getProvince,
  getWard,
  updatePetrolStore,
} from '../ShopApi'
import { notificationError, notificationSuccess } from 'utils/notification'
import { REG_PHONE } from 'utils/constants'
import { ApiClient } from 'services/ApiService'

type Props = { visible: boolean; onCancel: any; data?: any }

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export default function AddEditShop({ data, visible, onCancel }: Props) {
  const [form] = Form.useForm()
  const param: any = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const initialValues = convertDataToFrom(data)
  const [options, setOptions] = useState<Array<any>>([])
  const [placeId, setPlaceId] = useState<string>('')
  const [nameProvince, setNameProvince] = useState<string>('')
  const [nameDistrict, setNameDistrict] = useState<string>('')
  const [nameWard, setNameWard] = useState<string>('')
  const [nameStreet, setNameStreet] = useState<string>('')

  useEffect(() => {
    if (data?.district_id) {
      getWards({ district_id: data.district_id })
    }
  }, [data?.district_id])
  useEffect(() => {
    if (data?.province_id) {
      getDistricts({ province_id: data.province_id })
    }
  }, [data?.province_id])
  useEffect(() => {
    getProvinces()
  }, [])

  const [province, setProvince] = useState<Array<any>>([])
  const [district, setDistrict] = useState<Array<any>>([])
  const [ward, setWard] = useState<Array<any>>([])

  const getProvinces = async () => {
    try {
      const res = await getProvince()
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setProvince(data)
    } catch (error) {
    } finally {
    }
  }

  const getDistricts = async (payload: any) => {
    try {
      const res = await getDistrict(payload)
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setDistrict(data)
    } catch (error) {
    } finally {
    }
  }

  const getWards = async (payload: any) => {
    try {
      const res = await getWard(payload)
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setWard(data)
    } catch (error) {
    } finally {
    }
  }

  function convertDataToFrom(data: any) {
    if (!data) {
      return {
        name: null,
        email: null,
        phone: null,
        status: null,
        address_google_map: null,
        province_id: null,
        district_id: null,
        ward_id: null,
        location_address: null,
      }
    } else {
      return {
        ...data,
        name: data.name,
        email: data.email,
        phone: data.phone,
        status: data.status,
        address_google_map: data.address,
        location_address: data.location_address,
      }
    }
  }

  const onFinish = async (values: any) => {
    setIsLoading(true)
    try {
      let res_place_id
      if (placeId !== '') {
        res_place_id = await getPlace({ placeId: placeId })
      }

      values.address =
        placeId === '' ? data.address : res_place_id?.data?.address
      values.location_lat =
        placeId === '' ? data.location_lat : res_place_id?.data?.lat
      values.location_long =
        placeId === '' ? data.location_long : res_place_id?.data?.long
      values.representative = values.representative ? values.representative : ''
      // values.location_address = nameStreet

      delete values.confirm
      delete values.address_google_map
      if (param.id) {
        const dataPush = {
          id: param.id,
          data: values,
        }
        const res = await updatePetrolStore(dataPush)
        if (res.code === 201) {
          notificationSuccess('Sửa cửa hàng thành công')
          form.resetFields()
          onCancel()
          window.location.reload()
        }
      } else {
        const res = await createPetrolStore(values)
        if (res.code === 201) {
          notificationSuccess('Tạo cửa hàng thành công')
          form.resetFields()
          onCancel()
          window.location.reload()
        }
      }
    } catch (error: any) {
      notificationError('Đã có lỗi xảy ra, vui lòng thử lại')
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onSelect = (data: string, option: any) => {
    setPlaceId(option.place_id)
  }

  const getPlaceAuto = (payload: any) =>
    ApiClient.get('/google-address/place-auto', payload)

  const onChange = async (data: string) => {
    try {
      const res = await getPlaceAuto({ address: data })
      const list_option = res.data?.predictions?.map((item: any) => ({
        value: item.description,
        place_id: item.place_id,
      }))
      setOptions(list_option)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      title={
        param.id
          ? 'Sửa cửa hàng xăng dầu -  đối tác'
          : 'Thêm Cửa hàng xăng dầu - đối tác'
      }
      visible={visible}
      maskClosable={false}
      footer={null}
      width={700}
      onCancel={() => {
        form.resetFields()
        onCancel()
        setNameStreet('')
        setNameWard('')
        setNameDistrict('')
        setNameProvince('')
      }}
    >
      <Form
        layout="vertical"
        {...formItemLayout}
        labelAlign="left"
        initialValues={initialValues}
        form={form}
        onFinish={(values: any) => onFinish(values)}
        scrollToFirstError
      >
        <Row justify="space-between">
          <Col style={{ width: '45%' }}>
            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              label="Tên cửa hàng"
              name="name"
              rules={[
                {
                  type: 'string',
                  message: 'Nhập tên cửa hàng',
                },
                {
                  required: true,
                  message: 'Vui lòng nhập tên cửa hàng!',
                },
                {
                  min: 1,
                  max: 56,
                  message: 'Vui lòng nhập từ 1 đến 56 ký tự!',
                },
              ]}
            >
              <Input placeholder="Nhập tên cửa hàng" />
            </Form.Item>
            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              label="Tên người đại diện"
              name="representative"
              rules={[
                {
                  type: 'string',
                  message: 'Nhập tên người đại diện',
                },
                {
                  min: 1,
                  max: 56,
                  message: 'Vui lòng nhập từ 1 đến 56 ký tự!',
                },
              ]}
            >
              <Input
                className={styles.input_form}
                placeholder="Nhập tên người đại diện"
              />
            </Form.Item>

            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              label="Tên công ty"
              name="company_name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên công ty!',
                },
              ]}
            >
              <Input
                // className={styles.input_form}
                // type="number"
                placeholder="Nhập tên công ty"
              />
            </Form.Item>
            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              label="Mã số thuế"
              name="tax"
              rules={[
                {
                  min: 10,
                  max: 13,
                  message: 'Vui lòng nhập từ 10 đến 13 chữ số!',
                },
                {
                  required: true,
                  message: 'Vui lòng nhập mã số thuế!',
                },
                {
                  required: true,
                  validator: (_, value) => {
                    const reg = /^\d+$/
                    if (reg.test(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject()
                  },
                },
              ]}
            >
              <Input
                // className={styles.input_form}
                // type="number"
                placeholder="Nhập mã số thuế"
              />
            </Form.Item>

            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              label="Số điện thoại liên hệ"
              name="contact_phone"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập số điện thoại!',
                },
                {
                  pattern: REG_PHONE,
                  min: 10,
                  max: 10,
                  message: 'Vui lòng nhập điện thoại hợp lệ và gồm 10 số!',
                },
              ]}
            >
              <Input
                // className={styles.input_form}
                // type="number"
                placeholder="Nhập số điện thoại liên hệ"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              name="phone"
              label="Số điện thoại"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập số điện thoại!',
                },
                {
                  pattern: REG_PHONE,
                  min: 10,
                  max: 10,
                  message: 'Vui lòng nhập điện thoại hợp lệ và gồm 10 số!',
                },
              ]}
            >
              <Input
                disabled={data ? true : false}
                placeholder="Nhập số điện thoại"
              />
            </Form.Item>
            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'Định dạng email không hợp lệ!',
                },
                {
                  required: true,
                  message: 'Vui lòng nhập email!',
                },
                {
                  min: 1,
                  max: 100,
                  message: 'Vui lòng nhập từ 1 đến 100 ký tự!',
                },
              ]}
            >
              <Input
                autoComplete="off"
                // className={styles.input_form}
                placeholder="Nhập email"
              />
            </Form.Item>
            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              label="Loại cửa hàng"
              name="type_petrol_store_id"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn loại cửa hàng',
                },
              ]}
            >
              <RadiusSelection
                placeholder="Loại cửa hàng"
                options={[
                  { value: 7, text: 'Cửa hàng xăng dầu' },
                  { value: 8, text: 'Đối tác' },
                ]}
                onChange={() => {}}
                value={data?.status}
              />
            </Form.Item>
            <Form.Item
              className={styles.item_form}
              labelAlign="right"
              label={
                <span>
                  Địa chỉ Google Maps&nbsp;
                  <EnvironmentOutlined />
                </span>
              }
              name="address_google_map"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn địa chỉ google map',
                },
                {
                  max: 255,
                  message: 'Vui lòng không nhập quá 255 ký tự',
                },
              ]}
            >
              <AutoComplete
                className={styles.auto_complete}
                // filterOption={(inputValue, option) =>
                //   option!.value
                //     .toUpperCase()
                //     .indexOf(inputValue.toUpperCase()) !== -1
                // }
                // value={}
                options={options}
                placeholder="Chọn địa chỉ google maps"
                onSelect={onSelect}
                onChange={(value: string) => {
                  setTimeout(() => {
                    onChange(value)
                  }, 300)
                }}
              />
            </Form.Item>
            {param.id ? (
              <Form.Item
                className={styles.item_form}
                labelAlign="right"
                name="status"
                label="Trạng thái"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn trạng thái!',
                  },
                ]}
              >
                <RadiusSelection
                  placeholder="Trạng thái hoạt động"
                  options={[
                    { value: 0, text: 'Ngừng hoạt động' },
                    { value: 1, text: 'Đang hoạt động' },
                  ]}
                  onChange={() => {}}
                  value={data?.status}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  className={styles.item_form}
                  labelAlign="right"
                  name="password"
                  label="Mật khẩu"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mật khẩu!',
                    },
                    {
                      min: 6,
                      max: 55,
                      message: 'Vui lòng nhập từ 6 đến 55 ký tự!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    autoComplete="off"
                    className={styles.input_pass}
                    placeholder="Nhập mật khẩu"
                  />
                </Form.Item>

                <Form.Item
                  className={styles.item_form}
                  labelAlign="right"
                  name="confirm"
                  label="Nhập lại mật khẩu"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Nhập lại mật khẩu!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error('Mật khẩu không khớp!'))
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    autoComplete="off"
                    className={styles.input_pass}
                    placeholder="Nhập lại mật khẩu"
                  />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Form.Item
            style={{ width: 1000, marginBottom: 0, padding: 0 }}
            labelAlign="right"
            // label="Chi tiết địa chỉ"
            labelCol={{ flex: '20px' }}
            label="Địa chỉ chi tiết"
            required
          >
            <span
              style={{
                fontSize: 14,
                fontWeight: 600,
                color: '#002766',
                paddingLeft: '1.5%',
              }}
            >
              {(nameStreet
                ? nameStreet + ', '
                : !data?.location_address
                ? ''
                : data?.location_address + ', ') +
                (nameWard
                  ? nameWard + ', '
                  : !data?.ward?.name
                  ? ''
                  : data?.ward?.name + ', ') +
                (nameDistrict
                  ? nameDistrict + ', '
                  : !data?.district?.name
                  ? ''
                  : data?.district?.name + ', ') +
                (nameProvince
                  ? nameProvince + '. '
                  : !data?.province?.name
                  ? ''
                  : data?.province?.name + '. ')}
            </span>
            <Input.Group compact style={{ width: 1000, paddingTop: 10 }}>
              <Form.Item
                style={{ width: 165, paddingRight: 5 }}
                name="province_id"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn tỉnh/thành',
                  },
                ]}
              >
                <RadiusSelection
                  showArrow={false}
                  placeholder="Tỉnh/thành"
                  options={province}
                  onChange={(value: number, text: any) => {
                    if (value) {
                      getDistricts({ province_id: value })
                    }
                    setNameProvince(text?.children)
                    if (nameProvince != text?.children) {
                      setNameDistrict('')
                      setNameWard('')
                      setNameStreet('')
                    } else {
                      setNameDistrict(nameDistrict)
                      setNameWard(nameWard)
                      setNameStreet(nameStreet)
                    }
                    form.setFieldsValue({ district_id: 'Quận /huyện' })
                    form.setFieldsValue({ ward_id: 'Phường /xã' })
                    form.setFieldsValue({ location_address: '' })
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ width: 165, paddingRight: 5 }}
                name="district_id"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn quận huyện',
                  },
                ]}
              >
                <RadiusSelection
                  showArrow={false}
                  placeholder="Quận/huyện"
                  options={district}
                  onChange={(value: number, text: any) => {
                    if (value) {
                      const res = getWards({ district_id: value })
                    }
                    setNameDistrict(text?.children)
                    // form.setFieldsValue({
                    //   location_address: `${nameProvince}, ${text.children}`,
                    // })
                    form.setFieldsValue({ ward_id: 'Phường /xã' })
                    form.setFieldsValue({ location_address: '' })
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ width: 165, paddingRight: 5 }}
                name="ward_id"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn phường/xã',
                  },
                ]}
              >
                <RadiusSelection
                  showArrow={false}
                  placeholder="Phường/xã"
                  options={ward}
                  onChange={(value: number, text: any) => {
                    setNameWard(text?.children)
                    form.setFieldsValue({ location_address: '' })
                  }}
                  // value={data?.status}
                />
              </Form.Item>
              <Form.Item
                style={{ width: 165, paddingRight: 5 }}
                name="location_address"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập số nhà, tên đường',
                  },
                ]}
              >
                <Input
                  style={{ borderRadius: 5 }}
                  allowClear
                  placeholder="Số nhà, tên đường"
                  onChange={(e: any) => {
                    setNameStreet(e.target.value)
                    // form.setFieldsValue({ location_address: '' })
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          {/* <Form.Item
            style={{ width: 650, marginTop: 0 }}
            // name="location_address"
          >
            <span style={{ fontSize: 16, fontWeight: 500 }}>
              {(nameStreet ? nameStreet : data?.location_address) +
                ', ' +
                (nameWard ? nameWard : data?.ward?.name) +
                ', ' +
                (nameDistrict ? nameDistrict : data?.district?.name) +
                ', ' +
                (nameProvince ? nameProvince : data?.province?.name) +
                '.'}
            </span>
          </Form.Item> */}
        </Row>

        <Form.Item {...tailFormItemLayout}>
          <ButtonBottomModal
            isLoadingButton={isLoading}
            onCancel={() => {
              onCancel()
              setNameStreet('')
              setNameWard('')
              setNameDistrict('')
              setNameProvince('')
              form.resetFields()
            }}
            text="Lưu"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
