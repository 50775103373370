import { CheckCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, Modal, Row, Select } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'

type Props = {
  visible: boolean
  onCancel: any
  setShowAddDebt?: any
  data?: any
  isLoadingButton: boolean
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      name: null,
      address: null,
    }
  } else {
    return {
      ...data,
    }
  }
}

export default function ChangePayment({ visible, onCancel, data }: Props) {
  const [form] = Form.useForm()
  const initialValues = convertDataToFrom(data)
  const { Option } = Select

  const onFinish = (values: any) => {
    // console.log('Success:', values)
    // if (!data) {
    //   const newData = {
    //     long: 1,
    //     lat: 1,
    //     df_ward_id: 0,
    //     df_district_id: 0,
    //     df_province_id: 0,
    //     address: values.address.trim(),
    //     name: values.name.trim(),
    //   }
    //   onCreateStock && onCreateStock(newData)
    // } else {
    //   const newData = {
    //     long: 1,
    //     lat: 1,
    //     df_ward_id: 0,
    //     df_district_id: 0,
    //     df_province_id: 0,
    //     address: values.address.trim(),
    //     name: values.name.trim(),
    //     id: data.id,
    //   }
    //   onUpdateStock(newData, form.resetFields)
    // }
  }

  return (
    <Modal
      title={
        <div
          style={{
            color: 'steelblue',
          }}
        >
          Trạng thái thanh toán
        </div>
      }
      visible={visible}
      maskClosable={false}
      footer={
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <ButtonBottomModal
              // isLoadingButton={isLoadingButton}
              onCancel={onCancel}
              text="Lưu"
            />
          </Col>
        </Row>
      }
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
    >
      <Form
        // style={{ width: '600px' }}
        {...formItemLayout}
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          label={<span>Trạng thái</span>}
          name="limit"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn trạng thái!',
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Trạng thái thanh toán"
            onChange={(value: any) => {
              // onStatusSubmit(value)
            }}
            // style={{ minWidth: '150px' }}
          >
            <Option value={1}>Chưa thanh toán</Option>
            <Option value={2}>Đã thanh toán</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
