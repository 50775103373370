import { ApiClient } from 'services/ApiService'

export const getListCategory = (payload: any) =>
  ApiClient.get('/admin/category', payload)

export const createCategory = (payload: any) =>
  ApiClient.post(`/admin/category`, payload)

export const updateCategory = (payload: any) =>
  ApiClient.put(`/admin/category/${payload.id}`, payload.data)

export const deleteCategory = (category_id: any) =>
  ApiClient.delete(`/admin/category/${category_id}`)

export const changeStatusCategory = (category_id: any) =>
  ApiClient.patch(`/admin/category/${category_id}/status`)

export const requestUploadImageCategory = (payload: any) =>
  ApiClient.post(`/files/upload/single/${payload.type}`, payload.data)
