import { DollarOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Col, DatePicker, Row, Table, Tag, Tooltip } from 'antd'
import ButtonExport from 'common/components/Buttons/ButtonExport'
import ButtonExportExcelV2 from 'common/components/Buttons/ButtonExportExcelV2'
import { RadiusSelection } from 'common/components/Inputs'
import { ENTERPRISE_ROUTER_PATH } from 'common/config'
import { DEFINED_TYPE_DEBT } from 'features/admin/debt/customers/DetailDebtCustomerScreen'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { ORDER_STATUS, TYPE_DEBT } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { getListDebtCustomer } from '../CustomerApi'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
const { RangePicker } = DatePicker
type TParamDebt = {
  page: number
  action?: number
  payment_date?: string | Date
  from_date?: string | Date
  to_date?: string | Date
  payment_to_date?: string | Date
  payment_from_date?: string | Date
}
const StatusOrder = (status: number) => {
  switch (status) {
    case ORDER_STATUS.PENDING:
      return 'Chờ xác nhận'
    case ORDER_STATUS.CONFIRMED:
      return 'Đang thực hiện'
    case ORDER_STATUS.SUCCCESS:
      return 'Hoàn thành'
    case ORDER_STATUS.CANCELED:
      return 'Hủy'
    case ORDER_STATUS.DENY:
      return 'Từ chối'
  }
}
export default function Debt({ infoCustomer }: { infoCustomer: any }) {
  const param: any = useParams()
  const history = useHistory()
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalDebt, setTotalDebt] = useState<number>(0)
  const [params, setParams] = useState<TParamDebt>({
    page: 1,
    action: 0,
    payment_date: undefined,
    payment_to_date: undefined,
    payment_from_date: undefined,
    from_date: undefined,
    to_date: undefined,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      setIsLoading(true)
      const res = await getListDebtCustomer(param.id, params)
      const dataTable = res.data[0]?.rows?.map((item: any, index: number) => ({
        ...item,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
      }))
      setPaging({
        ...paging,
        current: res.meta.pagination.page,
        total: res.meta.pagination.totalItems,
        pageSize: res.meta.pagination.limit,
      })
      setTotalDebt(res.data[0]?.debtEnterprise)
      setDataSource(dataTable)
    } catch (error: any) {
      notificationError('Hệ thống gặp sự cố. Vui lòng thử lại!!')
    } finally {
      setIsLoading(false)
    }
  }
  const column = [
    // {
    //   width: 70,
    //   title: <b>STT</b>,
    //   dataIndex: 'stt',
    //   key: 'stt',
    // },
    // {
    //   width: 100,
    //   title: <b>Mã đơn hàng</b>,
    //   dataIndex: 'code',
    //   key: 'code',
    // },
    // {
    //   width: 100,
    //   title: <b>Tác vụ</b>,
    //   dataIndex: 'name',
    //   key: 'name',
    // },
    // {
    //   width: 110,
    //   title: <b>Số tiền</b>,
    //   dataIndex: 'total_price',
    //   key: 'total_price',
    //   render: (value: any) => (
    //     <>
    //       {value >= 0
    //         ? value === 0
    //           ? '0'
    //           : '+' + formatPrice(value)
    //         : formatPrice(value)}
    //       đ
    //     </>
    //   ),
    // },
    // {
    //   width: 110,
    //   title: <b>Nợ lũy kế</b>,
    //   dataIndex: 'total_debt',
    //   key: 'total_debt',
    //   render: (value: any) => <>{value === 0 ? '0' : formatPrice(value)} đ</>,
    // },
    // {
    //   width: 90,
    //   title: <b>Ngày tạo</b>,
    //   dataIndex: 'create_at',
    //   key: 'create_at',
    //   render: (value: any) => {
    //     return <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
    //   },
    // },
    // {
    //   title: <b>Hạn thanh toán</b>,
    //   dataIndex: 'payment_time',
    //   key: 'payment_time',
    //   render: (value: any) => (
    //     <>{value == null ? '' : moment(value).format('HH:mm DD/MM/YYYY')}</>
    //   ),
    // },
    {
      width: 70,
      title: <b>STT</b>,
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      width: 90,
      title: <b>Ngày thay đổi</b>,
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => {
        return <>{moment(value).format('HH:mm DD/MM/YYYY')}</>
      },
    },
    {
      width: 120,
      title: <b>Người thay đổi</b>,
      dataIndex: 'create_name',
      key: 'create_name',
      render: (value: any) => value,
    },
    {
      width: 120,
      title: <b>Tác vụ</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: 100,
      title: <b>Đơn hàng</b>,
      dataIndex: 'code',
      key: 'code',
    },
    {
      width: 180,
      title: <b>Sản phẩm</b>,
      dataIndex: 'product_name',
      key: 'product_name',
      render: (value: any) => <b>{value}</b>,
    },
    {
      width: 100,
      title: <b>ĐVT</b>,
      dataIndex: 'unit',
      key: 'data_product_unit',
      render: (value: any) => <b>{value}</b>,
    },
    {
      width: 100,
      title: <b>Số lượng</b>,
      dataIndex: 'amount',
      key: 'data_product_quantity',
      render: (value: any) => <b>{+value}</b>,
    },
    {
      width: 110,
      title: <b>Đơn giá</b>,
      dataIndex: 'price',
      key: 'data_product_price',
      render: (value: any) => <b>{value ? formatPrice(value) : 0}</b>,
    },
    {
      width: 100,
      title: <b>Phát sinh</b>,
      dataIndex: 'total_price',
      key: 'total_price2',
      render: (value: any, row: any) => (
        <b>
          {row?.type_debt_id !== +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
            ? value > 0
              ? '+' + formatPrice(value)
              : formatPrice(value)
            : ''}
        </b>
      ),
    },
    {
      width: 100,
      title: <b>Số thanh toán</b>,
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: any, row: any) => (
        <b>
          {row?.type_debt_id === +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
            ? value > 0
              ? '+' + formatPrice(value)
              : formatPrice(value)
            : ''}
        </b>
      ),
    },
    {
      width: 100,
      title: <b>Số còn phải thu</b>,
      dataIndex: 'total_debt',
      key: 'total_debt',
      render: (value: any) => <b>{value ? formatPrice(value) : 0}</b>,
    },
    // {
    //   width: 120,
    //   title: <b>TT Đơn hàng</b>,
    //   dataIndex: 'order_status',
    //   key: 'order_status',
    //   render: (value: any) => {
    //     switch (value) {
    //       case ORDER_STATUS.PENDING:
    //         return <Tag color="blue">Chờ xác nhận</Tag>
    //       case ORDER_STATUS.CONFIRMED:
    //         return <Tag color="blue">Đang thực hiện</Tag>
    //       case ORDER_STATUS.SUCCCESS:
    //         return <Tag color="green">Hoàn thành</Tag>
    //       case ORDER_STATUS.CANCELED:
    //         return <Tag color="volcano">Hủy</Tag>
    //       case ORDER_STATUS.DENY:
    //         return <Tag color="volcano">Từ chối</Tag>
    //     }
    //   },
    // },
    // {
    //   width: 120,
    //   title: <b>Trạng thái công nợ</b>,
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (value: number, record: any) => {
    //     return !record.payment_time ? (
    //       <Tag color="blue">Chờ xác nhận</Tag>
    //     ) : record.is_paid ? (
    //       <Tag color="green">Đã thanh toán</Tag>
    //     ) : moment().isBefore(record.payment_time) ? (
    //       <Tag color="blue">Chờ thanh toán</Tag>
    //     ) : (
    //       <Tag color="volcano">Quá hạn</Tag>
    //     )
    //   },
    // },
    {
      title: <b>Chi tiết</b>,
      width: 40,
      render: (value: any, record: any) => (
        <InfoCircleOutlined
          style={{ fontSize: '18px' }}
          onClick={() => {
            history.push({
              pathname: `${ENTERPRISE_ROUTER_PATH.ORDER_DETAIL}/${record.order_id}`,
              state: {
                name: ENTERPRISE_ROUTER_PATH.CUSTOMER_DETAIL,
                idCustomer: param.id,
              },
            })
          }}
        />
      ),
    },
  ]

  const location: any = useLocation()

  const [isLoadingBtnExportData, setLoadingBtnExportData] = useState<boolean>(
    false
  )

  const onExportData = async () => {
    try {
      const workbook = new Excel.Workbook()

      setLoadingBtnExportData(true)
      const worksheet = workbook.addWorksheet('Chi tiết công nợ')

      // columns
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10 },
        { header: 'Ngày thay đổi', key: 'buy_date', width: 32 },
        { header: 'Người thay đổi', key: 'create_name', width: 32 },
        { header: 'Tác vụ', key: 'action', width: 20 },
        { header: 'Đơn hàng', key: 'order_name', width: 20 },
        { header: 'Sản phẩm', key: 'product_name', width: 40 },
        { header: 'ĐVT', key: 'product_unit', width: 20 },
        { header: 'Số lượng', key: 'quantity', width: 20 },
        { header: 'Đơn giá', key: 'price', width: 15 },
        { header: 'Phát sinh', key: 'incurred', width: 20 },
        { header: 'Số thanh toán', key: 'payment', width: 20 },
        { header: 'Số còn phải thu', key: 'income', width: 20 },
      ]

      worksheet.insertRow(1, [
        'TỔNG HỢP CÔNG NỢ PHẢI THU CỦA KHÁCH HÀNG ' +
          infoCustomer?.dataInfor?.name,
      ])
      worksheet.mergeCells('A1', 'J1')
      worksheet.insertRow(2, [
        params.from_date
          ? `Thời gian công nợ: Từ ngày ${moment(params.from_date)?.format(
              'DD/MM/YYYY'
            )} đến ngày ${moment(params.to_date)?.format('DD/MM/YYYY')}`
          : 'Thời gian công nợ: -',
      ])
      worksheet.mergeCells('A2', 'J2')

      //Todo:  style báo cáo bán hnagf
      worksheet.getRow(1).font = { size: 18, color: { argb: '00000' } }
      worksheet.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }

      //Todo:  styl;e từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }

      //Todo:  style từ ngày đến ngày
      worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' },
      }
      worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getCell('A2').font = { size: 18, color: { argb: '00000' } }

      // todo: boder style
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin', color: { argb: 'cccccc' } },
        left: { style: 'thin', color: { argb: 'cccccc' } },
        bottom: { style: 'thin', color: { argb: 'cccccc' } },
        right: { style: 'thin', color: { argb: 'cccccc' } },
      }

      // end
      const dataListReportNotPagging = await getListDebtCustomer(param.id, {
        ...params,
        limit: 999,
      })
      worksheet.insertRow(3, [
        // 'Số dư đầu kỳ: ' + formatPrice(location?.state?.debt_limit),
        'Số dư đầu kỳ: ' + formatPrice(totalDebt || '0') + 'đ',
      ])
      worksheet.mergeCells('A3', 'J3')

      dataListReportNotPagging?.data?.[0]?.rows?.forEach(
        (o: any, i: number) => {
          worksheet.addRow({
            id: i + 1,
            buy_date: o?.create_at
              ? moment(o?.create_at).format('HH:mm DD/MM/YYYY')
              : '',
            action: o?.name,
            order_name: o?.code,
            quantity: +o?.amount,
            price: o?.price,
            product_name: o.product_name,
            product_unit: o.unit,
            create_name: o?.create_name,
            incurred:
              o?.type_debt_id !== +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
                ? o?.total_price > 0
                  ? '+' + formatPrice(o?.total_price)
                  : formatPrice(o?.total_price)
                : '',
            payment:
              o?.type_debt_id === +DEFINED_TYPE_DEBT.PAYMENT_NUMBER
                ? o?.total_price > 0
                  ? '+' + formatPrice(o?.total_price)
                  : formatPrice(o?.total_price)
                : '',
            income: o?.total_debt,
          })
        }
      )

      worksheet.eachRow({ includeEmpty: true }, function (row: any, rowNumber) {
        if (row?._number === 4) {
          row.eachCell({ includeEmpty: true }, function (cell: any) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '87CEEB' },
            }
          })
        }

        row.eachCell({ includeEmpty: true }, function (cell: any) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(new Blob([buf]), `Chi tiết công nợ ${location?.state?.name}.xlsx`)
      // saveAs(new Blob([buf]), `Chi tiết công nợ ${title}.xlsx`)
      // setDataExport(exportData)
      // fn()
    } catch (error) {
      console.log(
        '🚀 ~ file: SaleReportScreen.tsx ~ line 177 ~ onExportData ~ error',
        error
      )
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <div>
      <Row
        style={{
          width: '100%',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
        }}
      >
        <Row
          style={{ paddingBottom: '15px', width: '100%' }}
          justify="start"
          gutter={[16, 16]}
        >
          <Col span={8}>
            <RadiusSelection
              placeholder="Tác vụ"
              onChange={(value: number) => {
                setParams({
                  ...params,
                  action: value,
                  page: 1,
                })
              }}
              options={[
                { value: TYPE_DEBT.COMPLETE_ORDER_DEBT, text: 'KH thanh toán' },
                { value: TYPE_DEBT.INCREASE_DEBT, text: 'Tăng dư nợ' },
                { value: TYPE_DEBT.CANCEL_ORDER_DEBT, text: 'Hủy đơn' },
              ]}
            />
          </Col>
          <Tooltip title="Hạn thanh toán" placement="topLeft">
            <Col span={8}>
              <RangePicker
                style={{ width: '100%' }}
                placeholder={['Từ ngày', 'Đến ngày']}
                onChange={(value, dateString) => {
                  setParams({
                    ...params,
                    payment_to_date: value
                      ? moment(value?.[1]).format('YYYY-MM-DD')
                      : undefined,
                    payment_from_date: value
                      ? moment(value?.[0]).format('YYYY-MM-DD')
                      : undefined,
                    page: 1,
                  })
                }}
              />
            </Col>
          </Tooltip>
          <Tooltip title="Ngày tạo" placement="topLeft">
            <Col span={8}>
              <RangePicker
                style={{ width: '100%' }}
                placeholder={['Từ ngày', 'Đến ngày']}
                onChange={(value, dateString) => {
                  setParams({
                    ...params,
                    to_date: value
                      ? moment(value?.[1]).format('YYYY-MM-DD')
                      : undefined,
                    from_date: value
                      ? moment(value?.[0]).format('YYYY-MM-DD')
                      : undefined,
                    page: 1,
                  })
                }}
              />
            </Col>
          </Tooltip>
        </Row>
        <div style={{ width: '300px', display: 'flex', justifyContent: 'end' }}>
          <ButtonExportExcelV2
            key="export_excel"
            loading={isLoadingBtnExportData}
            onClick={onExportData}
          />
        </div>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '18px', marginBottom: '10px' }}>
          {/* <DollarOutlined /> Tổng nợ lũy kế hiện tại :{' '}
          {totalDebt ? formatPrice(totalDebt) : '0'} đ */}
          <DollarOutlined /> Số dư đầu kỳ :{' '}
          {totalDebt ? formatPrice(totalDebt) : '0'} đ
        </div>
        {/* <Checkbox>
          <span style={{ fontSize: '18px', fontWeight: 500 }}>
            Áp dụng công nợ
          </span>
        </Checkbox> */}
      </div>

      <Table
        dataSource={dataSource}
        columns={column}
        loading={isLoading}
        bordered
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            setParams({ ...params, page })
          },
        }}
      />
    </div>
  )
}
