import { changeStatusNews, getDetailBanner } from '../NewApi'
import { notificationSuccess } from 'utils/notification'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { createSlice } from '@reduxjs/toolkit'

type Params = {
  title_banner?: string
  type_banner?: number
  banner_status?: number
  status: undefined | number
  image_url?: string
  description?: string
  id?: number
  is_notify?: number
}

export const useAddEdit = () => {
  const [isLoading, setIsLoading] = useState(false)
  const param: { id: any } = useParams()
  const [dataSource, setDataSource] = useState<Params>({
    id: undefined,
    title_banner: undefined,
    type_banner: undefined,
    banner_status: undefined,
    image_url: undefined,
    description: undefined,
    status: undefined,
    is_notify: undefined,
  })

  let initialState: any = {
    productInfor: {
      code: '',
      name: '',
      category_id: null,
      price: 0,
      description: '',
    },
  }

  const getAddEdit = async () => {
    try {
      setIsLoading(true)
      const res = await getDetailBanner(param.id)
      setDataSource(res.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (param?.id) getAddEdit()
  }, [param?.id])
  return {
    getAddEdit,
    dataSource,
    param,
    setDataSource,
    setIsLoading,
    isLoading,
  }
}
