import { Row, Col, DatePicker, Input } from 'antd'
import { useState } from 'react'
import Icon from '@ant-design/icons'

const { RangePicker } = DatePicker

export default function Filter() {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  return (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Nhập tên gian hàng"
          addonAfter={<Icon type="close-circle-o" />}
          // value={data.search}
          onChange={(e: any) => {
            // onSearchSubmit(e.target.value)
          }}
        />
      </Col>
      <Col span={6}>
        <RangePicker
          placeholder={['Từ ngày', 'Đến ngày']}
          onChange={(dates, dateStrings) => {
            // onTimeSubmit(dateStrings[0], dateStrings[1])
          }}
        />
      </Col>
    </Row>
  )
}
