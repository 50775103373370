import Icon from '@ant-design/icons'
import { Col, Input, Row, Select } from 'antd'
import { useLocation } from 'react-router-dom'

interface IHistoryFilter {
  search: string
  storageChangeTypeId: number | undefined
  storageSourceId: number | undefined
  storageDesId: number | undefined
  setSearch: React.Dispatch<React.SetStateAction<string>>
  setStorageChangeTypeId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  setStorageSourceId: React.Dispatch<React.SetStateAction<number | undefined>>
  setStorageDesId: React.Dispatch<React.SetStateAction<number | undefined>>
}

const HistoryFilter = (props: IHistoryFilter) => {
  const {
    search,
    storageDesId,
    storageChangeTypeId,
    storageSourceId,
    setSearch,
    setStorageChangeTypeId,
    setStorageSourceId,
    setStorageDesId,
  } = props

  const location: any = useLocation()

  return (
    <Row gutter={[16, 16]}>
      {!location?.state?.product_name && (
        <Col span={12}>
          <Input.Search
            allowClear
            style={{ width: '100%' }}
            placeholder="Tên sản phẩm"
            addonAfter={<Icon type="close-circle-o" />}
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value)
            }}
          />
        </Col>
      )}

      <Col span={12}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Loại thay đổi"
          value={storageChangeTypeId}
          onChange={(value: number) => {
            setStorageChangeTypeId(value)
          }}
        >
          <Select.Option value={1}>Cập nhật tồn đầu</Select.Option>
          <Select.Option value={2}>Cập nhật kho</Select.Option>
          <Select.Option value={3}>Đơn hàng hoàn thành</Select.Option>
          <Select.Option value={4}>Mua xăng giữ hàng - Đổ xăng</Select.Option>
          <Select.Option value={5}>Mua xăng giữ hàng - Quá hạn</Select.Option>
        </Select>
      </Col>
      {/* <Col span={6}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Kho thay đổi (kho chuyển)"
          value={storageSourceId}
          onChange={(value: number) => {
            setStorageSourceId(value)
          }}
        >
          <Select.Option value="0">Kho 1</Select.Option>
          <Select.Option value="1">Kho 2</Select.Option>
        </Select>
      </Col>
      <Col span={6}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Kho nhận"
          value={storageDesId}
          onChange={(value: number) => {
            setStorageDesId(value)
          }}
        >
          <Select.Option value="0">Kho 1</Select.Option>
          <Select.Option value="1">Kho 2</Select.Option>
        </Select>
      </Col> */}
    </Row>
  )
}

export default HistoryFilter
