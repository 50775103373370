import { useEffect, useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { createAccount, getAccounts, updateAccount } from '../AccountApi'

type Params = {
  search: undefined | string
  page: number
  status: undefined | string
  from_date: undefined | string
  to_date: undefined | string
  role_id?: string
  limit: number
}
export const useAccount = () => {
  const [showEditAccount, setShowEditAccount] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [listAccount, setListAccount] = useState<any>([])
  const [showAddAccount, setShowAddAccount] = useState(false)
  const [params, setParams] = useState<Params>({
    search: undefined,
    status: undefined,
    from_date: undefined,
    to_date: undefined,
    role_id: undefined,
    page: 1,
    limit: 12,
  })

  const [paging, setPaging] = useState({
    total: 5,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    try {
      const res = await getAccounts(params)
      const tableData = res.data?.map((item: any, index: number) => {
        return {
          ...item,
          key: item.id,
          stt: (paging.current - 1) * paging.pageSize + index + 1,
        }
      })
      setListAccount(tableData)
      setPaging({
        ...paging,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const createAcc = async (data: any, resetFields: any) => {
    // setIsLoading(true)
    try {
      const res = await createAccount(data)
      console.log(res)
      setShowAddAccount(false)
      resetFields()
    } catch (error) {
      console.log(error)
      notificationError(`Thông tin bạn nhập không chính xác`)
    } finally {
      // setIsLoading(false)
    }
    getData()
  }

  // const updateAcc = async (data: any, resetFields: any) => {
  //   try {
  //     let dataPush = {
  //       id: data.id,
  //       data: {
  //         name: data.name,
  //         email: data.email,
  //         avatar: data.avatar,
  //         role_ids: data.role_ids,
  //       },
  //     }
  //     await updateAccount(dataPush)
  //     notificationSuccess(`Cập nhật thành công`)
  //     setShowEditAccount(false)
  //     getData()
  //     resetFields()
  //     // window.location.reload()
  //   } catch (error) {
  //     notificationError(`Thông tin bạn nhập không chính xác`)
  //     console.log(error)
  //   } finally {
  //   }
  // }

  return {
    isLoading,
    currentSelected,
    params,
    paging,
    listAccount,
    setPaging,
    setIsLoading,
    setCurrentSelected,
    setParams,
    getData,
    setListAccount,
    setShowAddAccount,
    showAddAccount,
    createAcc,
    // updateAcc,
    showEditAccount,
    setShowEditAccount,
  }
}
