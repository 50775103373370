import { Button, Descriptions, Dropdown, Menu, Row, Space, Tag } from 'antd'
import { useState } from 'react'
import { formatPrice } from 'utils/ruleForm'
import styles from '../styles/style.module.css'
import ChangePayment from './ChangePayment'
import { ORDER_STATUS } from 'utils/constants'
import { changeStatusPayment } from '../OrderApi'

type Props = {
  data: any
}
export default function OrderInfor({ data }: Props) {
  console.log('🚀 ~ file: OrderInfor.tsx:13 ~ data:', data)
  const [changePayment, setChangePayment] = useState(false)
  const convertStatus = (status: number) => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return <Tag color="blue">Chờ xác nhận</Tag>
      case ORDER_STATUS.CONFIRMED:
        return <Tag color="blue">Đang thực hiện</Tag>
      // case ORDER_STATUS.SHIP:
      //   return <Tag color="green">Đang vận chuyển</Tag>
      case ORDER_STATUS.SUCCCESS:
        return <Tag color="green">Hoàn thành</Tag>
      case ORDER_STATUS.CANCELED:
        return <Tag color="volcano">Hủy</Tag>
      case ORDER_STATUS.DENY:
        return <Tag color="volcano">Từ chối</Tag>
    }
  }

  const showButtonChangePaymentStatus = (
    payment_status: number,
    order_status: number,
    payment: number
  ) => {
    if (payment_status) {
      return <></>
    } else {
      if (payment === 2 || payment === 3) {
        if (
          (payment === 2 &&
            (order_status === ORDER_STATUS.SUCCCESS ||
              order_status === ORDER_STATUS.CANCELED)) ||
          (payment === 3 && order_status === ORDER_STATUS.CANCELED)
        ) {
          return <></>
        } else {
          return (
            <Dropdown
              overlay={
                <Menu
                  onClick={async (e: any) => {
                    if (e.key === '1') {
                      const res = await changeStatusPayment(data.id)
                      if (res.code === 200) {
                        window.location.reload()
                      }
                    }
                  }}
                >
                  <Menu.Item key="1">
                    <b>Đã thanh toán</b>
                  </Menu.Item>
                  <Menu.Item key="0">
                    <b>Chưa thanh toán</b>
                  </Menu.Item>
                </Menu>
              }
              placement="topRight"
              trigger={['click']}
            >
              <Button type="link">
                <b>(Thay đổi)</b>
              </Button>
            </Dropdown>
          )
        }
      } else {
        return <></>
      }
    }
  }

  return (
    <Descriptions
      style={{ backgroundColor: 'white' }}
      title={
        <div className={styles.title}>
          <Row justify="space-between">
            <b> </b>
            {convertStatus(data.status)}
            <div className={styles.divider}></div>
          </Row>
        </div>
      }
      column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
    >
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Mã đơn hàng</span>}
      >
        {data.code}
      </Descriptions.Item>
      {/* {data.order_receipt_gasoline == null ||
      data.order_receipt_gasoline.total_price === 0 ? ( */}
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Số lượng sản phẩm</span>}
      >
        {data.total_amount == 0
          ? 'Đổ đầy bình'
          : formatPrice(data.total_amount)}
      </Descriptions.Item>
      {/* ) : (
        <Descriptions.Item
          label={<span style={{ paddingLeft: '15px' }}> Số lượng</span>}
        >
          {formatPrice(data.amount)}
        </Descriptions.Item>
      )} */}
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> TT thanh toán</span>}
      >
        {data?.detailOrder?.order_payment?.is_paid
          ? 'Đã thanh toán'
          : 'Chưa thanh toán'}

        {showButtonChangePaymentStatus(
          data?.detailOrder?.order_payment?.is_paid,
          data?.status,
          data?.detailOrder?.order_payment?.type_payment_id
        )}
        <ChangePayment
          isLoadingButton
          visible={changePayment}
          onCancel={() => {
            setChangePayment(false)
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span style={{ paddingLeft: '15px' }}> Hình thức thanh toán</span>
        }
      >
        {data.type_payment}
      </Descriptions.Item>
      {data.order_receipt_gasoline == null ||
      data.order_receipt_gasoline.total_price === 0 ? (
        <Descriptions.Item
          label={<span style={{ paddingLeft: '15px' }}> Tổng tiền</span>}
        >
          {data.points_used === 0 && data.total_price === 0
            ? 'Chưa xác định'
            : data.total_price + data.points_used === 0
            ? '0đ'
            : formatPrice(data.total_price + data.points_used) + 'đ'}
        </Descriptions.Item>
      ) : (
        <Descriptions.Item
          label={<span style={{ paddingLeft: '15px' }}> Tổng tiền</span>}
        >
          {data.points_used === 0 &&
          data.order_receipt_gasoline.total_price === 0
            ? 'Chưa xác định'
            : data.order_receipt_gasoline.total_price + data.points_used === 0
            ? '0đ'
            : formatPrice(
                data.order_receipt_gasoline.total_price + data.points_used
              ) + 'đ'}
        </Descriptions.Item>
      )}
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}>Điểm tích lũy</span>}
      >
        {data.points_received > 0
          ? formatPrice(data.points_received) + ' điểm'
          : '---'}
      </Descriptions.Item>
      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}>Điểm đã dùng</span>}
      >
        {data.points_used > 0 ? formatPrice(data.points_used) + ' điểm' : '---'}
      </Descriptions.Item>
      {data.order_receipt_gasoline == null ||
      data.order_receipt_gasoline.total_price === 0 ? (
        <Descriptions.Item
          label={
            <span style={{ paddingLeft: '15px' }}> Tổng tiền thanh toán</span>
          }
        >
          {data.total_price === 0 && data.points_used !== 0
            ? '0đ'
            : data.points_used === 0 && data.total_price === 0
            ? 'Chưa xác định'
            : formatPrice(data.total_price) + 'đ'}
        </Descriptions.Item>
      ) : (
        <Descriptions.Item
          label={
            <span style={{ paddingLeft: '15px' }}> Tổng tiền thanh toán</span>
          }
        >
          {data.total_price === 0 && data.total_price !== 0
            ? '0đ'
            : data.order_receipt_gasoline.total_price === 0
            ? '0đ'
            : data.points_used === 0 && data.total_price === 0
            ? 'Chưa xác định'
            : formatPrice(data.order_receipt_gasoline.total_price) + 'đ'}
        </Descriptions.Item>
      )}
      {data.order_receipt_gasoline != null ? (
        <Descriptions.Item
          label={
            <span style={{ paddingLeft: '15px' }}> Tiền KH đổ thực tế</span>
          }
        >
          {data.detailOrder.order_receipt_gasoline === null
            ? '---'
            : !data.total_price_reality
            ? '---'
            : data.total_price_reality === 0
            ? '---'
            : formatPrice(data.total_price_reality) + 'đ'}
        </Descriptions.Item>
      ) : (
        <></>
      )}
      {data.order_receipt_gasoline != null ? (
        <Descriptions.Item
          label={
            <span style={{ paddingLeft: '15px' }}>
              {data.total_price_reality - data.total_price_receipt < 0
                ? 'Tiền dư'
                : 'Tiền thiếu'}
            </span>
          }
        >
          {data.detailOrder.order_receipt_gasoline === null
            ? '---'
            : !(data.total_price_receipt - data.total_price_reality)
            ? '0 đ'
            : data.total_price_receipt - data.total_price_reality === 0
            ? '---'
            : data.total_price_reality === 0
            ? '---'
            : data.total_price_reality - data.total_price_receipt < 0
            ? formatPrice(
                data.total_price_receipt - data.total_price_reality + 'đ'
              )
            : formatPrice(data.total_price_reality - data.total_price_receipt) +
              'đ'}
        </Descriptions.Item>
      ) : (
        <></>
      )}

      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Phương tiện</span>}
      >
        {!data.type_vehicle ? '---' : data.type_vehicle}
      </Descriptions.Item>

      <Descriptions.Item
        label={<span style={{ paddingLeft: '15px' }}> Biển số</span>}
      >
        {!data.license_plate ? '---' : data.license_plate}
      </Descriptions.Item>
    </Descriptions>
  )
}
