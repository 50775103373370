import Icon from '@ant-design/icons'
import { Col, Input, Row, Select, DatePicker } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
import TypingAutoSearch from 'common/components/Inputs/TypingAutoSearch'
import { useState } from 'react'
import { ORDER_STATUS } from 'utils/constants'

const { RangePicker } = DatePicker
const { Option } = Select
type Props = {
  onSearchSubmit: (search: string) => void
  onDateSubmit: (from_date: string, to_date: string) => void
  onStatusSubmit: (order_status: string) => void
}

export default function FilterOrderTab({
  onSearchSubmit,
  onDateSubmit,
  onStatusSubmit,
}: Props) {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              onSearchSubmit(searchKey.trim())
            }}
            isSearchLoading={isSearchLoading}
            placeholder="Tên, SĐT hoặc mã đơn hàng"
          />
        </Col>
        <Col span={6}>
          <RadiusSelection
            placeholder="Trạng thái đơn hàng"
            onChange={(value: string) => {
              onStatusSubmit(value)
            }}
            options={[
              { value: ORDER_STATUS.PENDING, text: 'Chờ xác nhận' },
              { value: ORDER_STATUS.CONFIRMED, text: 'Đang thực hiện' },
              { value: ORDER_STATUS.SUCCCESS, text: 'Hoàn thành' },
              { value: ORDER_STATUS.CANCELED, text: 'Hủy' },
              { value: ORDER_STATUS.DENY, text: 'Từ chối' },
            ]}
          />
        </Col>
        <Col span={6}>
          <RangePicker
            // className="rangerpicker_topic"
            style={{ width: '100%' }}
            placeholder={['Từ ngày', 'đến ngày']}
            onChange={(value, dateString) => {
              onDateSubmit(dateString[0], dateString[1])
            }}
          />
        </Col>
      </Row>
    </div>
  )
}
