import Icon from '@ant-design/icons'
import { DatePicker, Row, Input, Col } from 'antd'
import { RadiusSelection } from 'common/components/Inputs'
const { RangePicker } = DatePicker
const { Search } = Input

type Props = {
  data: any
  onSearchSubmit: (searchKey: string) => void
  onTypeUser: (userKey: any) => void
  onDateSubmit: (from_date: string, to_date: string) => void
}
export default function Filter({
  data,
  onSearchSubmit,
  onTypeUser,
  onDateSubmit,
}: Props) {
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col span={10}>
        <Input.Search
          style={{ width: '100%' }}
          allowClear
          placeholder="Tiêu đề thông báo"
          addonAfter={<Icon type="close-circle-o" />}
          value={data.search}
          onChange={(e: any) => {
            onSearchSubmit(e.target.value)
          }}
        />
      </Col>

      <Col span={6}>
        <RadiusSelection
          placeholder="Loại tài khoản"
          onChange={value => {
            onTypeUser(value)
          }}
          options={[
            { value: 0, text: 'Khách hàng' },
            { value: 1, text: 'Nhà cung cấp' },
            { value: 2, text: 'Tất cả' },
          ]}
        />
      </Col>

      <Col span={6}>
        <RangePicker
          style={{ width: '100%' }}
          placeholder={['Từ ngày', 'đến ngày']}
          onChange={(value, dateString) => {
            onDateSubmit(dateString[0], dateString[1])
          }}
        />
      </Col>
    </Row>
  )
}
