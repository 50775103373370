import { message, PageHeader, Spin, Table, Tag } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'common/container/Container'
import React from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import DetailFilter from './components/DetailPointFilter'
import { IDetailParams, IDetailPoint } from './ReportPoint'
import { getPointDetail } from './ReportPointApi'

const DetailPointReport = () => {
  const location: any = useLocation()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [totalFilter, setTotalFilter] = React.useState<number>(1)
  const [actionType, setActionType] = React.useState<number>()
  const [fromDate, setFromDate] = React.useState<string>('')
  const [toDate, setToDate] = React.useState<string>('')
  const [listPointDetail, setListPointDetail] = React.useState<IDetailPoint[]>(
    []
  )
  const [params, setParams] = React.useState<IDetailParams>({})
  const [paging, setPaging] = React.useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const getDetail = async () => {
    try {
      setIsLoading(true)
      const res = await getPointDetail(location?.state?.pointId, params)
      if (res.status) {
        const data = res.data.map((item: any) => {
          const dateArray = item.create_at.substring(0, 10).split('-')
          const newDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
          return {
            id: item.id,
            orderCode: item.order_code,
            content: item.type_point_transaction_history.title,
            points: item.point,
            totalPointsLeft: item.current_point,
            date: newDate,
            detail: item.type_point_transaction_history,
          }
        })
        const newPaging = {
          total: res.meta.pagination.totalItems,
          current: res.meta.pagination.page,
          pageSize: res.meta.pagination.limit,
        }
        setListPointDetail(data)
        setPaging({ ...paging, ...newPaging })
        setTotalFilter(res.meta.pagination.totalItems)
      } else {
        message.error('Đã có lỗi xảy ra! Xin vui lòng thử lại!')
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getDetail()
  }, [params])

  React.useEffect(() => {
    if (!actionType && actionType !== 0 && !fromDate) {
      setParams({
        page: currentPage,
      })
    } else if (!fromDate) {
      setParams({
        page: currentPage,
        action: actionType,
      })
    } else if (!actionType && actionType !== 0) {
      setParams({
        page: currentPage,
        from_date: fromDate,
        to_date: toDate,
      })
    } else {
      setParams({
        page: currentPage,
        from_date: fromDate,
        to_date: toDate,
        action: actionType,
      })
    }
  }, [currentPage, actionType, fromDate, toDate])

  const column = [
    {
      width: '70px',
      title: <b>STT</b>,
      dataIndex: 'id',
      key: 'id',
      render: (text: string, record: any, index: number) => {
        return (
          <td id={record.id}>
            {(paging.current - 1) * paging.pageSize + index + 1}
          </td>
        )
      },
    },
    {
      title: <b>Mã đơn hàng</b>,
      dataIndex: 'orderCode',
      key: 'orderCode',
    },
    {
      title: <b>Nội dung</b>,
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: <b>Số điểm</b>,
      dataIndex: 'points',
      key: 'points',
      render: (text: string, record: any) => {
        if (record.detail.type === 1) {
          return <Tag color="green">+ {formatPrice(text)}</Tag>
        } else {
          return <Tag color="red">- {formatPrice(text)}</Tag>
        }
      },
    },
    {
      title: <b>Số dư</b>,
      dataIndex: 'totalPointsLeft',
      key: 'totalPointsLeft',
      render: (text: string) => {
        return <td>{formatPrice(text)}</td>
      },
    },
    {
      title: <b>Ngày</b>,
      dataIndex: 'date',
      key: 'date',
    },
  ]

  return (
    <Spin spinning={isLoading}>
      <Container
        header={
          <PageHeader
            title="Chi tiết báo cáo tích điểm"
            onBack={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.POINT_REPORT}`,
                state: {
                  currentParam: location?.state?.currentParam,
                },
              })
            }}
          />
        }
        filterComponent={
          <DetailFilter
            setActionType={setActionType}
            setFromDate={setFromDate}
            setToDate={setToDate}
            id={location?.state?.pointId}
          />
        }
        contentComponent={
          <div>
            <p>
              Kết quả lọc: <b>{totalFilter}</b>
            </p>
            <Table
              bordered
              columns={column}
              dataSource={listPointDetail}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
              }}
              pagination={{
                ...paging,
                showSizeChanger: false,
                onChange: (page: number) => {
                  setCurrentPage(page)
                },
              }}
            />
          </div>
        }
      />
    </Spin>
  )
}

export default DetailPointReport
