import AuthReducer from './auth/AuthSlice'
import ProductReducer from './admin/product/product/ProductSlice'
import SaleProductReducer from './admin/product/sale/SaleProductSlice'

const rootReducer = {
  authReducer: AuthReducer,
  productReducer: ProductReducer,
  saleProductReducer: SaleProductReducer,
}

export default rootReducer
