import { Row, Input, Divider, Col } from 'antd'
import {
  getListProductByCategory,
  requestListCategory,
} from '../SaleProductApi'
import styles from '../../product/style/Product.module.css'
import { RadiusSelection } from 'common/components/Inputs'
import {
  setCategoryId,
  setDescriptionProduct,
  setProductId,
} from '../SaleProductSlice'
import { useDispatch } from 'react-redux'
import { TRadiusSelect } from '../SaleProduct'
import { useEffect, useState } from 'react'
import { notificationError } from 'utils/notification'

const { TextArea } = Input
type Props = {
  id?: number
  saleProductInfor: any
  listProduct: Array<any>
}

export default function GeneralInformationProduct({
  id,
  saleProductInfor,
  listProduct,
}: Props) {
  const [listCategory, setListCategory] = useState<Array<TRadiusSelect>>([])
  const dispatch = useDispatch()

  useEffect(() => {
    getListCategory()
  }, [])

  const getListCategory = async () => {
    try {
      const res = await requestListCategory({})
      const list_data = res.data.map((item: any) => {
        return { value: item.id, text: item.name }
      })
      setListCategory(list_data)
    } catch (error: any) {
      notificationError(error?.message)
    }
  }
  return (
    <div>
      <b style={{ fontSize: '20px' }}>Thông tin chung</b>
      <Divider style={{ backgroundColor: '#BFBFBF' }} />
      <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
        <Col
          span={12}
          style={{ display: 'flex', justifyContent: 'flex-start' }}
        >
          <div style={{ marginRight: '10px' }}>
            <b>Danh mục </b>
            <b style={{ color: 'red' }}>&#42;</b>
          </div>
          <RadiusSelection
            placeholder="Chọn danh mục"
            style={{ width: '70%' }}
            value={saleProductInfor?.category_id}
            showSearch={true}
            onChange={(value: number) => {
              dispatch(setCategoryId(value))
              dispatch(setProductId(null))
            }}
            options={listCategory}
            disabled={id ? true : false}
          />
        </Col>
        <Col
          span={12}
          style={{ display: 'flex', justifyContent: 'flex-start' }}
        >
          <div style={{ marginRight: '10px' }}>
            <b>Tên sản phẩm: </b>
            <b style={{ color: 'red' }}>&#42;</b>
          </div>
          <RadiusSelection
            placeholder="Chọn sản phẩm"
            onChange={(value: number) => {
              dispatch(setProductId(value))
            }}
            style={{ width: '70%' }}
            value={
              id ? saleProductInfor?.product_name : saleProductInfor?.product_id
            }
            showSearch={true}
            options={listProduct}
            disabled={id ? true : false}
            emtpyText="Danh mục không còn sản phẩm để tạo khuyến mại"
          />
        </Col>
      </Row>
      <b className={styles.form}>Ghi chú </b>
      <TextArea
        style={{ marginBottom: '15px' }}
        id="description_product"
        allowClear
        placeholder="Nhập ghi chú"
        autoSize={{ minRows: 5, maxRows: 7 }}
        value={saleProductInfor?.description}
        onChange={(e: any) => {
          dispatch(setDescriptionProduct(e.target.value))
        }}
      />
    </div>
  )
}
