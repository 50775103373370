import { useEffect, useState } from 'react'
import { notificationError } from 'utils/notification'
import { getListCategory, getListSaleProduct } from '../SaleProductApi'

type TParam = {
  search: undefined | string
  status: undefined | number
  category_id: undefined | number
  from_date: undefined | string | Date
  to_date: undefined | string | Date
  page: number
}
export const useSaleProduct = () => {
  const [isLoading, setIsLoading] = useState({
    loadingTable: false,
    loadingSearch: false,
  })
  const [params, setParams] = useState<TParam>({
    search: undefined,
    status: undefined,
    category_id: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
  })
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })
  const [listCategory, setListCategory] = useState([])

  useEffect(() => {
    requestListCategory()
  }, [])
  useEffect(() => {
    getDataProduct()
  }, [params])

  const getDataProduct = async () => {
    setIsLoading({ ...isLoading, loadingTable: true, loadingSearch: true })
    try {
      const res = await getListSaleProduct(params)

      const tableData = res.data?.map((item: any, index: number) => ({
        ...item,
        key: item.id,
        stt:
          (res.meta.pagination.page - 1) * res.meta.pagination.limit +
          index +
          1,
        name: item.promotional_product.product.name,
        code: item.promotional_product.product.code,
        product_category_name:
          item.promotional_product.product?.product_category?.name,
        sale_status: item.check_status && item.check_sale ? 1 : 0,
      }))

      const formattedPaging = {
        total: res.meta.pagination.totalItems,
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
      }
      setPaging(formattedPaging)
      setDataSource(tableData)
    } catch (error: any) {
      notificationError(error?.message)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false, loadingSearch: false })
    }
  }
  const requestListCategory = async () => {
    const res = await getListCategory()
    const data = res.data?.map((item: any) => ({
      value: item.id,
      text: item.name,
    }))
    setListCategory(data)
  }
  return {
    isLoading,
    dataSource,
    paging,
    params,
    listCategory,
    setPaging,
    setIsLoading,
    setParams,
  }
}
