import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftCircleOutlined,
  SyncOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Descriptions,
  Tabs,
  Popconfirm,
  Card,
  Image,
  Row,
  Col,
  Avatar,
} from 'antd'
import { ButtonActive } from 'common/components/Buttons'
import moment from 'moment'
import { useState } from 'react'
import { AddEditAccount } from './AddEditAccount'
import { useAccount } from '../hook/useAccount'
import { notificationError, notificationSuccess } from 'utils/notification'
import { updateAccount } from '../AccountApi'

type Props = {
  data: any
  getData: any
  onDeleteAccount: any
  onChangeStatus: any
  onResetPassword: any
}

const { TabPane } = Tabs
const ContentView = (data: any) => {
  return (
    <Row gutter={[16, 16]}>
      <Col sm={6}>
        <Descriptions size="default">
          <Descriptions.Item label="">
            {data.avatar === null || data.avatar === '' ? (
              <Avatar
                shape="square"
                size={120}
                style={{
                  padding: '10px 0 0 0',
                }}
                icon={<UserOutlined style={{ fontSize: 90 }} />}
              />
            ) : (
              <Image
                style={{
                  objectFit: 'cover',
                  width: '120px',
                  height: '120px',
                }}
                src={data.avatar}
              />
            )}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col sm={18}>
        <Descriptions
          size="default"
          column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Tên người dùng">
            {data.name}
          </Descriptions.Item>
          <Descriptions.Item label="Số điện thoại">
            {data.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
          <Descriptions.Item label="Loại tài khoản">
            {data.role_names.join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {data.status ? 'Đang hoạt động' : 'Ngừng hoạt động'}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày tạo">
            {moment(data.create_at).format('DD/MM/YYYY')}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

function DetailAccount({
  data,
  getData,
  onDeleteAccount,
  onChangeStatus,
  onResetPassword,
}: Props) {
  const [loading, setLoading] = useState<boolean>(true)
  const { showEditAccount, setShowEditAccount } = useAccount()
  const updateAcc = async (data: any, resetFields: any) => {
    try {
      let dataPush = {
        id: data.id,
        data: {
          name: data.name,
          email: data.email,
          avatar: data.avatar,
          role_ids: data.role_ids,
        },
      }
      await updateAccount(dataPush)
      notificationSuccess(`Cập nhật thành công`)
      setShowEditAccount(false)
      getData()
      resetFields()
      // window.location.reload()
    } catch (error) {
      notificationError(`Thông tin bạn nhập không chính xác`)
      console.log(error)
    } finally {
    }
  }
  return (
    <div>
      {showEditAccount && (
        <AddEditAccount
          data={data}
          visible={showEditAccount}
          isLoading={false}
          onCancel={() => {
            setShowEditAccount(false)
            getData()
          }}
          onUpdateAccount={(newData: any, resetFields: any) => {
            // console.log(newData)
            updateAcc(newData, resetFields)
            setLoading(true)
            getData()
          }}
        />
      )}
      <Tabs
        defaultActiveKey="1"
        // onChange={callback}
        // style={{ padding: '0px 10px' }}
      >
        <TabPane tab="Thông tin tài khoản" key="1">
          <Card
            className="expand"
            actions={[
              <ButtonActive
                typeButton="text"
                title={
                  data.status
                    ? 'Bạn chắc chắn muốn ngừng hoạt động tài khoản này?'
                    : 'Bạn chắc chắn muốn hoạt động tài khoản này?'
                }
                isActive={data.status}
                onConfirm={async () => {
                  try {
                    onChangeStatus(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
              />,

              <Popconfirm
                title="Reset mật khẩu?"
                onConfirm={async () => {
                  try {
                    onResetPassword(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <SyncOutlined /> Reset
                  </>
                }
                cancelText={
                  <>
                    <CloseCircleOutlined /> Hủy
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    color: '#1890ff',
                    borderColor: '#1890ff',
                  },
                }}
                cancelButtonProps={{
                  danger: true,
                  // type: 'ghost',
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
              >
                <Button
                  type="text"
                  icon={<SyncOutlined />}
                  style={{ fontWeight: 'bold' }}
                >
                  Reset mật khẩu
                </Button>
              </Popconfirm>,
              <Button
                type="text"
                icon={<EditOutlined />}
                style={{ fontWeight: 'bold', color: '#1890ff' }}
                onClick={() => {
                  setShowEditAccount(true)
                }}
              >
                Chỉnh sửa
              </Button>,

              <Popconfirm
                title="Bạn chắc chắn muốn xoá tài khoản này??"
                onConfirm={async () => {
                  try {
                    onDeleteAccount(data.id)
                  } catch (error) {
                  } finally {
                  }
                }}
                okText={
                  <>
                    <CloseCircleOutlined /> Xóa
                  </>
                }
                cancelText={
                  <>
                    <LeftCircleOutlined /> Trở lại
                  </>
                }
                okButtonProps={{
                  type: 'default',
                  danger: true,
                  style: { borderRadius: '3px', fontWeight: 'bold' },
                }}
                cancelButtonProps={{
                  type: 'default',
                  style: {
                    borderRadius: '3px',
                    borderColor: '#1890ff',
                    color: '#1890ff',
                    fontWeight: 'bold',
                  },
                }}
              >
                <Button
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                  style={{ fontWeight: 'bold' }}
                >
                  Xóa tài khoản
                </Button>
              </Popconfirm>,
            ]}
          >
            {ContentView(data)}
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default DetailAccount
