import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { TRadiusSelect } from '../SaleProduct'
import { getListProduct, requestAllCategory } from '../SaleProductApi'
import { clearDataSaleProduct } from '../SaleProductSlice'

type TParams = {
  search: undefined | string
  status: undefined | number
  category_id: undefined | number
  page: number
  offset: undefined | number
  limit: number
}

export const useSaleProduct = () => {
  const location: any = useLocation()
  const dispatch = useDispatch()
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState({ loadingTable: false })
  const [listCategory, setListCategory] = useState<Array<TRadiusSelect>>([])
  const [params, setParams] = useState<TParams>({
    search: undefined,
    status: undefined,
    category_id: undefined,
    page: 1,
    offset: undefined,
    limit: 12,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })
  useEffect(() => {
    getListCategory()

    dispatch(clearDataSaleProduct())
  }, [])

  React.useEffect(() => {
    if (location.state) {
      setParams({
        ...params,
        search: location.state?.search,
        category_id: location.state?.category_id,
        status: location.state?.status,
        page: location.state?.page,
      })
      setPaging({
        ...paging,
        current: location.state?.page,
      })
    }
  }, [location.state])

  useEffect(() => {
    let run = setTimeout(() => {
      getDataProduct()
    }, 300)
    return () => clearTimeout(run)
  }, [params])

  const getListCategory = async () => {
    const res = await requestAllCategory({ limit: 999 })
    const list_data = res.data?.map((item: any) => {
      return { value: item.id, text: item.name }
    })
    setListCategory(list_data)
  }
  const getDataProduct = async () => {
    try {
      setIsLoading({ ...isLoading, loadingTable: true })
      const res = await getListProduct(params)
      let tableData = res.data?.map((itemX: any, indexX: number) => {
        return {
          ...itemX,
          key: itemX.id,
          category: itemX.product.product_category.name,
          product_code: itemX.product.code,
          product_name: itemX.product.name,
          stt:
            (res.meta.pagination.page - 1) * res.meta.pagination.limit +
            indexX +
            1,
          sale_status: itemX.check_sale && itemX.product.status ? 1 : 0,
        }
      })

      // if (params.status === 0) {
      //   tableData = res.data
      //     .filter((item: any) => !item.check_sale || !item.product.status)
      //     ?.map((itemX: any, indexX: number) => {
      //       return {
      //         ...itemX,
      //         key: itemX.id,
      //         category: itemX.product.product_category.name,
      //         product_code: itemX.product.code,
      //         product_name: itemX.product.name,
      //         stt:
      //           (res.meta.pagination.page - 1) * res.meta.pagination.limit +
      //           indexX +
      //           1,
      //         sale_status: 0,
      //       }
      //     })
      // } else {
      //   tableData = res.data?.map((item: any, index: number) => {
      //     return {
      //       ...item,
      //       key: item.id,
      //       category: item.product.product_category.name,
      //       product_code: item.product.code,
      //       product_name: item.product.name,
      //       stt:
      //         (res.meta.pagination.page - 1) * res.meta.pagination.limit +
      //         index +
      //         1,
      //       sale_status: item.check_sale && item.product.status ? 1 : 0,
      //     }
      //   })
      // }

      const formattedPaging = {
        total: res.meta?.pagination?.totalItems,
        current: res.meta?.pagination?.page,
        pageSize: res.meta?.pagination?.limit,
      }
      setDataSource(tableData)
      setPaging(formattedPaging)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading({ ...isLoading, loadingTable: false })
    }
  }

  return {
    isLoading,
    dataSource,
    paging,
    params,
    listCategory,
    setPaging,
    setIsLoading,
    setParams,
  }
}
