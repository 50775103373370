import { Form, Input, InputNumber, Modal } from 'antd'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import InputTooltip from 'common/components/Inputs/InputTooltip'
import { useEffect, useState } from 'react'
import { notificationError, notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { useOrder } from '../hook/useOrder'
import { billFullTank } from '../OrderApi'

type Props = {
  visible: boolean
  onCancel: any
  data?: any
  setIsLoading: any
  isLoading: any
  detailOrder: any
  setFullTank: any
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export default function AddBillFullTank({
  visible,
  onCancel,
  data,
  isLoading,
  setIsLoading,
  detailOrder,
  setFullTank,
}: Props) {
  const { getData } = useOrder()
  const [form] = Form.useForm()
  const [price, setPrice] = useState()
  const [title, setTitle] = useState()
  const addBill = async (data: any, resetFields: any) => {
    setIsLoading(true)
    try {
      const res = await billFullTank(data)
      if (res.code === 200) {
        notificationSuccess(`Bổ sung hóa đơn thành công`)
        getData()
      }
    } catch (error) {
      notificationError(`Thông tin bạn nhập không chính xác`)
      console.log(error)
    } finally {
      window.location.reload()
      setIsLoading(false)
      setFullTank(false)
    }
  }
  const onFinish = async (values: any) => {
    try {
      delete values.confirm
      let newData

      newData = {
        ...values,
        total_price_reality: values.total_price,
        total_price: values.total_price,
        amount: values.amount,
        order_id: detailOrder.id,
      }
      // console.log('newData: ', newData)
      addBill(newData, form.resetFields)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  useEffect(() => {
    if (data) {
    }
  }, [data])
  return (
    <Modal
      onCancel={() => {
        getData()
        onCancel()
      }}
      title="Bổ sung thông tin hóa đơn đổ đầy bình"
      visible={visible}
      maskClosable={false}
      footer={null}
    >
      <Form
        style={{ padding: 0 }}
        {...formItemLayout}
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        scrollToFirstError
      >
        <Form.Item label="Tên sản phẩm">
          <Input disabled placeholder={detailOrder.productName} />
        </Form.Item>
        <Form.Item label="Tên cửa hàng" name="shop">
          <Input disabled placeholder={detailOrder.shop} />
        </Form.Item>
        <Form.Item label="Giá sản phẩm" name="price">
          <Input
            disabled
            placeholder={formatPrice(detailOrder.price_reality) + 'đ'}
          />
        </Form.Item>
        <Form.Item
          label="Thành tiền"
          name="total_price"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                const reg = /^\d+$/
                if (reg.test(value) && value > 0 && value < 99999999999) {
                  return Promise.resolve()
                }
                return Promise.reject()
              },
              message:
                'Vui lòng nhập số tiền đúng định dạng! Giới hạn 99,999,999,999 đ',
            },
          ]}
        >
          {/* <Input
            placeholder="Số tiền"
            onChange={(e: any) => {
              form.setFieldsValue({
                amount:
                  e.target.value === ''
                    ? ''
                    : String(
                        +(
                          parseFloat(e.target.value) / detailOrder.price_reality
                        ).toFixed(3)
                      ),
              })
            }}
          /> */}
          <InputTooltip
            title={!title ? '' : formatPrice(title) + ' đ'}
            placement={'top'}
            trigger={['focus', 'click', 'hover']}
            placeholder="Số tiền"
            onChange={(e: any) => {
              form.setFieldsValue({
                amount:
                  e === ''
                    ? ''
                    : String(
                        Number(
                          parseFloat(e) / detailOrder.price_reality
                        ).toFixed(3)
                      ),
              })
              setTitle(e)
            }}
          />

          {/* <InputNumber
            style={{ borderRadius: 5, width: 315 }}
            placeholder="Số tiền"
            formatter={formatPrice}
            onChange={(value: number) => {
              form.setFieldsValue({
                amount: +(value / detailOrder.price_reality).toFixed(3),
              })
            }}
          /> */}
        </Form.Item>
        <Form.Item
          label="Số lít"
          name="amount"
          required
          rules={[
            {
              message: (
                <span>
                  Vui lòng nhập số lít đúng định dạng <br />
                  (ví dụ: 12.345)
                </span>
              ),
              validator: (_, value) => {
                const reg = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/
                const array = value.split('.')
                if (
                  reg.test(value) &&
                  value !== '' &&
                  value > 0 &&
                  array[0] !== '' &&
                  array[1] !== ''
                ) {
                  if (
                    (array[1] && array[1].length > 3) ||
                    (array[0] && array[0].length > 6)
                  )
                    return Promise.reject()
                  return Promise.resolve()
                }
                return Promise.reject()
              },
            },
          ]}
        >
          <Input
            placeholder="Số lít"
            onChange={(e: any) => {
              form.setFieldsValue({
                total_price:
                  e.target.value == ''
                    ? ''
                    : Number(
                        parseFloat(e.target.value) * detailOrder.price_reality
                      ).toFixed(0),
              })
            }}
          />
          {/* <InputTooltip
            trigger={['focus', 'hover', 'click']}
            title={formatPrice(valueTooltip)}
            placeholder="Số lít"
            onChange={(e: any) => {
              form.setFieldsValue({
                total_price:
                  e.target.value == ''
                    ? ''
                    : (
                        parseFloat(e.target.value) * detailOrder.price_reality
                      ).toFixed(0),
              })
              setValueTooltip(e.target.value)
            }}
          /> */}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <ButtonBottomModal
            isLoadingButton={isLoading}
            onCancel={() => {
              onCancel()
              getData()
            }}
            text="Ok"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
