import { CheckCircleOutlined } from '@ant-design/icons'
import { Form, Input, Col } from 'antd'
import { ButtonAdd } from 'common/components/Buttons'
import { useEffect, useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import { enterNumbersOnly } from 'utils/ruleForm'
import { getConfigRanks, updateConfigRanks } from '../ConfigApi'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export default function RankInfor() {
  const [form] = Form.useForm()
  const initialValues = {
    rank_member: 0,
    rank_silver: null,
    rank_gold: null,
    rank_diamond: null,
  }
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      setIsLoading(true)
      const res = await getConfigRanks()
      form.setFieldsValue({
        rank_silver: res.data[1].point,
        rank_gold: res.data[2].point,
        rank_diamond: res.data[3].point,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      const array_ranks = [
        { id: 2, point: values.rank_silver },
        { id: 3, point: values.rank_gold },
        { id: 4, point: values.rank_diamond },
      ]
      const res = await updateConfigRanks(array_ranks)
      if (res.code === 200) {
        notificationSuccess(res.message)
        getData()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div style={{ backgroundColor: 'white', padding: '16px 20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <p style={{ fontSize: '20px', color: '#B40000' }}>
          Thông tin điểm từng mức hạng
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Col style={{ width: '60%' }}>
          <Form
            {...formItemLayout}
            form={form}
            name="rank"
            labelAlign="left"
            onFinish={(values: any) => onFinish(values)}
            initialValues={initialValues}
            scrollToFirstError
          >
            <Form.Item label="Hạng thành viên" name="rank_member" rules={[]}>
              <Input placeholder="Điểm thành viên" disabled value={0} />
            </Form.Item>

            <Form.Item label="Hạng bạc" name="rank_silver" rules={[]}>
              <Input
                placeholder="Hạng bạc"
                onChange={(e: any) => {
                  let value = enterNumbersOnly(e.target.value)
                  if (parseInt(value))
                    form.setFieldsValue({ rank_silver: parseInt(value) })
                  else form.setFieldsValue({ rank_silver: '' })
                }}
              />
            </Form.Item>

            <Form.Item label="Hạng vàng" name="rank_gold" rules={[]}>
              <Input
                placeholder="Hạng vàng"
                onChange={(e: any) => {
                  let value = enterNumbersOnly(e.target.value)
                  if (parseInt(value))
                    form.setFieldsValue({ rank_gold: parseInt(value) })
                  else form.setFieldsValue({ rank_gold: '' })
                }}
              />
            </Form.Item>

            <Form.Item label="Hạng kim cương" name="rank_diamond" rules={[]}>
              <Input
                placeholder="Hạng kim cương"
                onChange={(e: any) => {
                  let value = enterNumbersOnly(e.target.value)
                  if (parseInt(value))
                    form.setFieldsValue({ rank_diamond: parseInt(value) })
                  else form.setFieldsValue({ rank_diamond: '' })
                }}
              />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <ButtonAdd
                text="Lưu thông tin "
                icon={<CheckCircleOutlined />}
                htmlType="submit"
                loading={isLoading}
              />
            </Form.Item>
          </Form>
        </Col>
      </div>
    </div>
  )
}
