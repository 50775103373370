import { Col, DatePicker, DatePickerProps, Input, Row, Select } from 'antd'
import useDebounce from 'features/admin/report/sale/hook/useDebounce'
import moment from 'moment'
import React from 'react'
import { IFilter } from './ChangeWarehouse'

interface IChanegWarehouseFilter {
  setFilterData: React.Dispatch<React.SetStateAction<IFilter>>
  filterData: IFilter
}

const ChanegWarehouseFilter = (props: IChanegWarehouseFilter) => {
  const { setFilterData, filterData } = props
  const [note, setNote] = React.useState<string>('')

  const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
    setFilterData({
      ...filterData,
      date: dateString,
    })
  }

  const onChangeWarehouse = (value: number) => {
    setFilterData({
      ...filterData,
      warehouse: value,
    })
  }

  const onChangeNote = (e: any) => {
    setNote(e.target.value)
  }

  const noteDebounce = useDebounce(note, 300)
  React.useEffect(() => {
    setFilterData({
      ...filterData,
      note: noteDebounce,
    })
  }, [noteDebounce])

  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <p>
          <b>
            Ngày chuyển <span style={{ color: 'red' }}>*</span>
          </b>
        </p>
        <DatePicker
          id="choose_date"
          onChange={onChangeDate}
          style={{ width: '100%' }}
          format={'DD/MM/YYYY'}
          defaultValue={moment()}
        />
      </Col>
      <Col span={8}>
        <p>
          <b>
            Đến kho <span style={{ color: 'red' }}>*</span>
          </b>
        </p>
        <Select
          id="choose_warehouse"
          allowClear
          style={{ width: '100%' }}
          placeholder="Chọn kho"
          onChange={(value: number) => onChangeWarehouse(value)}
        >
          <Select.Option value={1}>Kho 1</Select.Option>
          <Select.Option value={2}>Kho 2</Select.Option>
        </Select>
      </Col>
      <Col span={8}>
        <p>
          <b>Ghi chú</b>
        </p>
        <Input.TextArea
          value={note}
          allowClear
          onChange={(e: any) => onChangeNote(e)}
          placeholder="Ghi chú"
          autoSize={{ minRows: 1, maxRows: 5 }}
        />
      </Col>
    </Row>
  )
}

export default ChanegWarehouseFilter
